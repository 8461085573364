import { configureStore } from '@reduxjs/toolkit'
import  userLanguageSlice  from './Slices/userLanguageSlices'
import AuthSlices from './Slices/AuthSlices'
import UserInfoSlices from './Slices/UserInfoSlices'
export const store = configureStore({
  reducer: {
    Language : userLanguageSlice,
    Auth : AuthSlices,
    UserInfo : UserInfoSlices
  },
})