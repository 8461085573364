import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/SatinAlmaTalepleriEntering.webp'
import Image2 from '../Assets/Images/talebinizi-iletin.webp'
import Image3 from '../Assets/Images/talep-ve-teklif-toplama.webp'
import Image4 from '../Assets/Images/talepleri incele.webp'
import Image5 from '../Assets/Images/teklif verin.webp'
import Image6 from '../Assets/Images/MobileTalep.webp'


import { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function SatinAlmaTalepleri() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const styles = {
        Header1: { "width": { xs: "100%", md: "592px"} , "height":{ xs: "auto", md: "36px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "24px", "lineHeight": "36px", "color": "#000000" },
        Header2: { "width":{ xs: "100%", md:  "580px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": {xs:"30px",md:"54px"}, "lineHeight": "130%", "color": "#000000" },
        Header3: { "width": { xs: "100%", md: "561.11px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "18px", "lineHeight": "170%", "color": "#889AA4" },
        Header4: { "width":{ xs: "100%", md: "500px"}  , "height": { xs: "auto", md: "130px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": {xs:"20px",md:"50px"}, "lineHeight": "130%", "color": "#000000" },
        Header5: { "width": { xs: "100%", md: "480px"} , "height": { xs: "auto", md: "81px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "16px", "lineHeight": "170%", "color": "#303C43" },
        Header6: { "width":{ xs: "100%", md: "500px"} , "height": { xs: "auto", md: "100px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": {xs:"20px",md:"50px"}, "lineHeight": "130%", "color": "#FFFFFF" },
        Header7: { "width": { xs: "100%", md: "512px"} , "height":{ xs: "auto", md:  "93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "18px", "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" },
        Header8: { "width": { xs: "100%", md: "462px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "20px", "lineHeight": "170%", "textAlign": "center", "letterSpacing": "0.01em", "color": "#889AA4", "opacity": "0.9" },
        BoxMuteahhit: {
            display: "flex",
            justifyContent: "right",
            "background": "#303C43",
            "borderRadius": "20px",
            "width": "176px", "height": "64px"
        },
        BoxTMWrapper: {
            display: 'flex',
            "background": "#FFFFFF",
            "boxShadow": "0px 12px 40px rgba(211, 223, 234, 0.87)",
            "borderRadius": "20px",
            "width": "300px", "height": "64px"
        },
        BoxMuteahhitText: {
            display: 'flex',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "24px",
            "color": "#FFFFFF",
        },
        BoxTedarikci: {
            display: 'flex',
            "background": "#FFFFFF",
            "boxShadow": "0px 12px 40px rgba(211, 223, 234, 0.87)",
            "borderRadius": "20px",
            "width": "352px", "height": "64px"
        },
        BoxTedarikciText: {
            display: 'inline',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "24px",
            "color": "#A1ADB7",
        },
        BoxTedarikci2: {
            display: "flex",
            justifyContent: "center",
            width: '176px',
            marginTop: '1.1em'
        },
        BoxInline: {
            display: 'inline',
        },
    }

    const [selectedType, SetSelectedType] = useState(1);

    const [pptsData, setPptsData] = useState({
        Header1: translate.MuteahhitlerIcinYapRad,
        Header2: 'Satın alma talebinizi iletin, sizin adınıza en uygun tedarikçilere yönlendirelim!',
        Text1: 'Satın Alma Talepleri',
        TDesc1: 'Müteahhitler için satın alma taleplerinde rekabetçi teklifler toplamanın ve güvenilir tedarikçiye ulaşmanın ne kadar zor olduğunu biliyoruz. Müteahhitler ile tedarikçileri aynı platformda buluşturarak satın alma süreçlerini kolaylaştırıyoruz.',
        Text2: 'Kamu Yapım İhaleleri',
        TDesc2: 'İlettiğiniz talebiniz binlerce tedarikçinin üye olduğu sistemimizde ücretsiz olarak yayınlansın.',
    })

    const handleButtonClick = (key) => {
        if (key === 1) {
            SetSelectedType(2);
            setPptsData({
                Header1: translate.TedarikcilerIcinYapRad,
                Header2: 'Satın alma talebinizi iletin, sizin adınıza en uygun tedarikçilere yönlendirelim!',
                Text1: translate.SatinAlmaTalepleri,
                TDesc1: 'Müteahhitler için satın alma taleplerinde rekabetçi teklifler toplamanın ve güvenilir tedarikçiye ulaşmanın ne kadar zor olduğunu biliyoruz. Müteahhitler ile tedarikçileri aynı platformda buluşturarak satın alma süreçlerini kolaylaştırıyoruz.',
                Text2: 'Kamu Yapım İhaleleri',
                TDesc2: 'İlettiğiniz talebiniz binlerce tedarikçinin üye olduğu sistemimizde ücretsiz olarak yayınlansın.',
            })
        } else {
            SetSelectedType(1);
            setPptsData({
                Header1: translate.MuteahhitlerIcinYapRad,
                Header2: 'Satın alma talebinizi iletin, sizin adınıza en uygun tedarikçilere yönlendirelim!',
                Text1: translate.SatinAlmaTalepleri,
                TDesc1: 'Müteahhitler için satın alma taleplerinde rekabetçi teklifler toplamanın ve güvenilir tedarikçiye ulaşmanın ne kadar zor olduğunu biliyoruz. Müteahhitler ile tedarikçileri aynı platformda buluşturarak satın alma süreçlerini kolaylaştırıyoruz.',
                Text2: 'Kamu Yapım İhaleleri',
                TDesc2: 'İlettiğiniz talebiniz binlerce tedarikçinin üye olduğu sistemimizde ücretsiz olarak yayınlansın.',
            })
        }
    }

    return (
        <>
            <Navbar />
            <Grid mt={12} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid mt={10} pt={20} p={{xs:5,md:10}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header1}>
                            {translate.KamuYapimIhaleleri}
                        </Typography>
                        <Typography mt={2} sx={styles.Header2}>
                           {translate.IhaleDuyurusundanCokDahaFazlasi}
                        </Typography>
                        <Typography mt={4} sx={styles.Header3}>
                           {translate.GuncelKamuYapimIhaleDuyurulari}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid align='center' mt='4em' mb='4em' item xl={12} md={12} xs={12}>
                    <Box sx={styles.BoxInline}>
                        <Box sx={styles.BoxTMWrapper}>
                            <Box sx={selectedType === 1 ? styles.BoxMuteahhit : styles.BoxTedarikci}>
                                <Button sx={{ width: '100%' }} onClick={() => handleButtonClick(0)}>
                                    <Typography alignItems='center' sx={selectedType === 1 ? styles.BoxMuteahhitText : styles.BoxTedarikciText}>
                                       {translate.Müteahhitler}
                                    </Typography>
                                </Button>
                            </Box>
                            <Box sx={selectedType === 2 ? styles.BoxMuteahhit : styles.BoxTedarikci}>
                                <Button sx={{ width: '100%' }} onClick={() => handleButtonClick(1)}>
                                    <Typography alignItems='center' sx={selectedType === 2 ? styles.BoxMuteahhitText : styles.BoxTedarikciText}>
                                        {translate.Tedarikciler}
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Typography mt={8} sx={styles.Header4}>
                        {pptsData.Header1}
                    </Typography>
                    <Typography mt={3} sx={styles.Header8}>
                       {translate.SatinAlmaTalebiniziIletinSizinAdiniza}
                    </Typography>
                </Grid>

                <Grid  container spacing={0}>
                    <Grid p={5} pb={10} item xl={6} md={6} xs={12}>
                        <img width="100%" height="auto" src={selectedType === 1 ? Image2 : Image5} alt='Guncel Insaat Projeleri' />
                    </Grid>
                    <Grid p={5} pt={{xs:5,md:10}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header4}>
                            {translate.SatinAlmaTalepleri}
                        </Typography>
                        <Typography pt={5} sx={styles.Header5}>
                            {translate.MuteahhitlerIcinSatinAlmaTalep}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid pt={{xs:0,md:10}} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid  p={5} pt={{xs:5,md:6}}pl={{xs:"",md:10}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header4}>
                        {translate.KamuYapimIhaleleri}
                        </Typography>
                        <Typography pt={{xs:5,md:8}} sx={styles.Header5}>
                           {translate.IlettiginizTalebinizBinlerceTedarikcinin}
                        </Typography>
                    </Grid>
                    <Grid p={5} item xl={6} md={6} xs={12}>
                        <img width='100%' height="auto" src={selectedType === 1 ? Image3 : Image4} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid sx={{ backgroundColor: '#3C6780' }} container spacing={0}>
                    <Grid align='right' pt={0} pr={0} item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image6} alt='Guncel Insaat Projeleri' />
                    </Grid>
                    <Grid pt={30} p={5} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header6}>
                        {translate.TeklifleriAlin}
                        </Typography>
                        <Typography mt={1} sx={styles.Header7}>
                        {translate.KonusundaUzmanYuzlerceTedarikciden}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}
