import { Card, CardContent, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/SystemUI2.webp'
import Image2 from '../Assets/Images/SystemMobileUI.webp'
import Image3 from '../Assets/Images/AboutUs1.webp'
import Image4 from '../Assets/Images/AboutUs2.webp'
import Image5 from '../Assets/Images/Rectangle_6.webp'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { getData } from '../Assets/Scripts/dataAxios'

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | Hakkımızda';
    }, []);


    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    useEffect(() => {
        const getWorkersData = async () => {
            const res = await getData(`Landing/GetAllHomeEkip`);
            setWorkers(res.data.value);
        }
        getWorkersData();
    }, []);

    const [workers, setWorkers] = useState([]);
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1050);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1050);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const styles = {
        ImgHeader: {
            position: {xs:"relative",md:"relative"},
        width:{xs:"100%",md:"100%"},
        left:{xs:"unset",md:"%"},
        top: {xs:"10%",md:"0"}
        },
        Header1: {
            "width": { xs: "100%", md: "529px" },
            "height": { xs: "auto", md: "240px" },
            "left": { xs: "0", md: "424px" },
            "top": "191px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": { xs: "30px", md: "54px" },
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#000000"
        },
        ImagesWrapperBox: {
            marginTop: '4vh',
            height: { xs: "40vh", md: "40vh" },
            display: 'inline-flex',
            position: 'relative',
        },
        Image1BoxWrapper: {
            position: 'relative',
            display: 'inline-flex',
        },
        Image2BoxWrapper: {
            position: 'absolute',
            marginTop: "10%",
            right: "-16%",
        },
        AllWrapper: {

        },
        secondWrapper: {
            "background": "linear-gradient(to bottom,#F4F7F9 0%,#F4F7F9 65%,white 65%,white 100%)"
        },
        Text1: {
            "width": { md: "233px" },
            "height": { xs: "auto", md: "57px" },
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "30px", md: "44px" },
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#000000"
        },
        Text2: {
            "width": { md: "823px" },
            "height": { xs: "auto", md: "205px" },
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "18px", md: "24px" },
            "lineHeight": "170%",
            "textAlign": "center",
            "color": "#303C43",
            "padding": "0 20px"
        },
        BoxWrapper: {
            position: 'relative'
        },
        BoxInside: {
            position: 'absolute',
            zIndex: '2',
            top: '15%',
            left: { xs: "0", md: "50%" },
            "width": { xs: "100%", md: "500px" },
            "height": { xs: "auto", md: "259px" }
        },
        ImageText1: {
            "position": "absolute",
            "width": { md: "524px" },
            "height": { xs: "auto", md: "259px" },
            "fontFamily": "'Poppins'",
            "textAlign": { xs: "initial !important" },
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "18px", md: "22px" },
            "lineHeight": "170%",
            "color": "#FFFFFF",
            "padding": "0 20px"
        },
        ImageText2: {
            position: "relative",
            width:  "90%", 
            top: {xs:'10%',md:'15%',l:"30%"},
            left: '5%',
            "height": {xs:"unset",md:"205px"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize":{xs:"23px",sm:"20px",md:'25px',l:"auto"}, 
            "lineHeight": "170%",
            "color": "#303C43"
        },
        ImageBox: {
            "marginTop": { xs: "", md: "-5%" }
        },
        text5: { "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: "35px", md: "50px" }, "lineHeight": "130%", "textAlign": "center", "color": "#303C43" },
        text6: { "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "25px", md: "16px" }, "lineHeight": "150%", "textAlign": "center", "color": "#303C43", "padding": "0 20px" },
        cardHeader: { "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "18px", "lineHeight": "33px", "color": "#303C43", "flex": "none", "order": "0", "alignSelf": "stretch", "flexGrow": "0" },
        cardText: { "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "28px", "color": "#889AA4", "flex": "none", "order": "0", "flexGrow": "1" },
        CardWrapper: {
            border: 'none',
            boxShadow: 'none'
        },
        timeLineText: {
            "width": "340px", "height": "93px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "18px", "lineHeight": "170%", "color": "#000000"
        }
    }
    return (
        <>
            <Navbar />
            <Grid sx={styles.AllWrapper} container spacing={0}>
                <Grid container spacing={0} pt={18} sx={styles.secondWrapper}>
                    <Grid align='center' item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header1}>
                            {translate.InsaatSektorununVeriBankasiyiz}
                        </Typography>
                    </Grid>
                    <Grid align='center' sx={styles.ImageBox} item xl={12} md={12} xs={12}>
                        <Box sx={styles.ImagesWrapperBox}>
                            <Box sx={styles.Image1BoxWrapper}>
                                {isDesktop ? (
                                    <>
                                        <img style={{
                                            height: "595px"
                                        }} src={Image1} alt='yapiradar.com' />
                                        <Box sx={styles.Image2BoxWrapper}>
                                            <img style={{
                                                height: "520px",
                                                width: "100%"
                                            }} src={Image2} alt='yapiradar.com' />
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <img style={{
                                            height: "auto",
                                            width: "100%"
                                        }} src={Image1} alt='yapiradar.com' />
                                        <img style={{
                                            position: 'absolute',
                                            marginTop: "10%",
                                            right: "0",
                                            height: "auto",
                                            width: "50%", marginLeft: "40%"
                                        }} src={Image2} alt='yapiradar.com' />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid align='center' item xl={12} md={12} xs={12}>
                    <Typography sx={styles.Text1}>
                        {translate.Hikayemiz}
                    </Typography>
                    <Typography mt={5} sx={styles.Text2}>
                        {translate.TurkMuteahhitlerinYurtIciVeYurtDisi}
                    </Typography>
                </Grid>
                <Grid mt={5} align='center' item xl={12} md={12} xs={12}>
                    <Box sx={styles.BoxWrapper}>
                        {isDesktop ? (<img src={Image3}
                            alt='Yapiradar Hakkinda' />
                        ) : (
                            <img style={{
                                height: "auto",
                                width: "100%"
                            }} src={Image5}
                                alt='Yapiradar Hakkinda' />
                        )}

                        <Box sx={styles.BoxInside}>
                            <Typography sx={styles.ImageText1}>
                                {translate.YilindanBuYanaMuteahhitlerin}
                            </Typography>
                        </Box>
                    </Box>

                </Grid>

                <Grid sx={{ backgroundColor: '#F4F7F9', position: 'relative', height: 'auto' }} mt={10} container spacing={0} >
                    <Grid item xl={6} l={6} md={6} sm={12}  xs={12}>
                        <Typography sx={styles.ImageText2}>
                            {translate.YapiradarcomOlarak}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} md={6} xs={12} sx={styles.ImgHeader}>
                        <img width= "100%" src={Image4} alt='Yapiradar Hakkinda' />
                    </Grid>
                </Grid> 

                <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    container mt={10} spacing={0}>
                    <Grid maxWidth={1920} item xl={12} md={12} xs={12}>
                        <Typography sx={styles.text5}>
                            {translate.YapiradarEkibi}
                        </Typography>
                        <Typography sx={styles.text6}>
                            {translate.CalismalariIleGucumuzeGuc}
                        </Typography>
                    </Grid>
                    <Grid maxWidth={800} container align='center' mt={10} spacing={0} >
                        {workers.map((item) => {
                            if (
                                item.adSoyad === 'Çetin Karataş' ||

                                item.adSoyad === 'Arif Burhan Tarhan'
                            ) {
                                return (
                                    <Grid item xl={6} md={6} xs={6}>
                                        <Card sx={styles.CardWrapper}>
                                            <CardContent>
                                                <img style={{
                                                    borderRadius: '10px',
                                                    objectFit: 'cover',
                                                    width: '190px',
                                                    height: '260px'
                                                }}
                                                    src={`/images/Ekibimiz/${item.resimurl}`}
                                                    alt={item.adSoyad} />
                                                <Typography sx={styles.cardHeader}>
                                                    {item.adSoyad}
                                                </Typography>
                                                <Typography sx={styles.cardText}>
                                                    {item.gorevi}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            } else { return false }
                        })}
                    </Grid>
                    <Grid maxWidth={1920} container align='center' mt={10} spacing={0} >
                        {workers.map(item => {
                            if (
                                item.adSoyad !== 'Çetin Karataş' &&

                                item.adSoyad !== 'Arif Burhan Tarhan'
                            ) {
                                return (
                                    <Grid item xl={3} md={4} xs={6}>
                                        <Card sx={styles.CardWrapper}>
                                            <CardContent>
                                                <img style={{
                                                    borderRadius: '10px',
                                                    objectFit: 'cover',
                                                    width: '190px',
                                                    height: '190px'
                                                }} src={`/images/Ekibimiz/${item.resimurl}`} alt={item.adSoyad} />
                                                <Typography sx={styles.cardHeader}>
                                                    {item.adSoyad}
                                                </Typography>
                                                <Typography sx={styles.cardText}>
                                                    {item.gorevi}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            } else { return false }
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <TryForFree />
            <Footer />
        </>
    )
}

export default AboutUs