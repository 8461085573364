import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginPage from './Pages/LoginPage';
import DenemeTalebi from './Pages/DenemeTalebi';
import NotFound404Page from './Pages/NotFound404Page';
import Faydalar from './Pages/Faydalar';
import Hizmetlerimiz from './Pages/Hizmetlerimiz';
import GuncelInsaatProjeleri from './Pages/GuncelInsaatProjeleri';
import FirmaRehberi from './Pages/FirmaRehberi';
import PPTS from './Pages/PPTS';
import BayiYonetimModulu from './Pages/BayiYonetimModulu';
import KamuYapimIhaleleri from './Pages/KamuYapimIhaleleri';
import SatinAlmaTalepleri from './Pages/SatinAlmaTalepleri';
import PSTS from './Pages/PSTS';
import AboutUs from './Pages/AboutUs';
import DigitalCatalogue from './Pages/DigitalCatalogue';
import { store } from './Redux/store';
import { Provider } from 'react-redux';
import DigitalCatalogFirm from './Pages/DigitalCatalogFirm';
import DigitalCatalogFirmDetail from './Pages/DigitalCatalogFirmDetail';
import DigitalCatalogItems from './Pages/DigitalCatalogItems';
import DigitalCatalogItemDetail from './Pages/DigitalCatalogItemDetail'
import KVKK from './Pages/KVKK';
import ConnectWithUs from './Pages/ConnectWithUs';
import FirmaEkle from './Pages/FirmaEkle';
import { CookiesProvider } from "react-cookie";
import VerifyEmail from './Pages/VerifyEmail';
import UrunEkle from './Pages/UrunEkle';
import UrunGuncelle from './Pages/UrunGuncelle';
import Logout from './Pages/Logout';
import AydinlatmaMetni from './Pages/AydinlatmaMetni';
import AramaSonucu from './Pages/PazarYeri/AramaSonucu';
import PazarYeriLoginPage from './Pages/PazarYeriLogin';
import SpotUrunlerIndex from './Pages/SpotUrunler/SpotUrunlerIndex';
import SpotUrunGuncelle from './Pages/SpotUrunler/SpotUrunGuncelle';
import SpotUrunEkle from './Pages/SpotUrunler/SpotUrunEkle';
import SpotUrunDetay from './Pages/SpotUrunler/SpotUrunDetay';
import SSS from './Pages/SSS';
import DemoKayitBasarili from './Pages/Auth/DemoKayitBasarili';
import IhaleIndex from './Pages/Ihale/IhaleIndex';
import IhaleDetay from './Pages/Ihale/IhaleDetay';
import Ihalelerim from './Pages/Ihale/Ihalelerim';
import IhaleTeklif from './Pages/Ihale/IhaleTeklif';
import IhaleListesi from './Pages/Ihale/IhaleListesi';
import IhaleSonuc from './Pages/Ihale/IhaleSonuc';
import IhaleOlustur from './Pages/Ihale/IhaleOlustur';
import KrediSatinal from './Pages/Purchase/KrediSatinal';
import ESatinalYonlendir from './Pages/ESatinAlma/ESatinalYonlendir';
import EMuteahhitSatinalYonlendir from './Pages/ESatinAlma/EMuteahhitSatinal';
import LeadBasvuru from './Pages/LeadBasvuru';
import LeadBasvuruBasarili from './Pages/Auth/LeadBasvuruBasarili';
import CerezKullanimPolitikasi from './Pages/CerezKullanimPolitikasi';
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <NotFound404Page />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/PazarYeriLogin",
    element: <PazarYeriLoginPage />,
  },
  {
    path: '/Register',
    element: <DenemeTalebi />,
  },
  {
    path: '/LeadBasvuru/:id',
    element: <LeadBasvuru />,
  },{
    path:'/LeadBasvurunuzAlindi',
    element:<LeadBasvuruBasarili />
  },
  {
    path: '/SSS',
    element: <SSS />,
  },
  {
    path: '/Faydalar',
    element: <Faydalar />
  },
  {
    path: '/Hizmetler',
    element: <Hizmetlerimiz />
  },
  {
    path: '/Hakkimizda',
    element: <AboutUs />
  },
  {
    path: '/GuncelInsaatProjeleri',
    element: <GuncelInsaatProjeleri />
  },
  {
    path: '/FirmaRehberi',
    element: <FirmaRehberi />
  },
  {
    path: '/YapiradarCRM',
    element: <PPTS />
  },
  {
    path: '/BayiYonetimModulu',
    element: <BayiYonetimModulu />
  },
  {
    path: '/KamuYapimIhaleleri',
    element: <KamuYapimIhaleleri />
  },
  {
    path: '/SatinAlmaTalepleri',
    element: <SatinAlmaTalepleri />
  },
  {
    path: '/PSTS',
    element: <PSTS />
  },
  {
    path: '/Ihale',
    element: <IhaleIndex />
  },
  {
    path: '/Ihale/IhaleOlustur',
    element: <IhaleOlustur />
  },
  {
    path: '/Ihale/Detay/:id',
    element: <IhaleDetay />
  },
  {
    path: '/Ihale/Sonuc/:id',
    element: <IhaleSonuc />
  },
  {
    path: '/Ihale/TeklifVer/:id',
    element: <IhaleTeklif />
  },
  {
    path: '/Ihale/IhaleListesi',
    element: <IhaleListesi />
  },
  {
    path: '/Ihale/Ihalelerim',
    element: <Ihalelerim />
  },
  {
    path: '/PazarYeri',
    element: <DigitalCatalogue />
  },
  {
    path: '/DemoBasvurunuzAlindi',
    element: <DemoKayitBasarili />
  },
  {
    path: '/PazarYeri/Urun/:itemSeoUrl',
    element: <DigitalCatalogItemDetail />
  },
  {
    path: '/PazarYeri/Firma/:seourl',
    element: <DigitalCatalogFirmDetail />
  },
  {
    path: '/PazarYeri/Urunler',
    element: <DigitalCatalogItems />
  },
  {
    path: '/BizeUlasin',
    element: <ConnectWithUs />
  },
  {
    path: '/KVKK',
    element: <KVKK />
  },
  
  {
    path: '/CerezKullanimPolitikasi',
    element: <CerezKullanimPolitikasi />
  },
  {
    path: '/PazarYeri/FirmaEkle',
    element: <FirmaEkle />
  },
  {
    path: '/VerifyEmail',
    element: <VerifyEmail />
  },
  {
    path: '/PazarYeri/UrunEkle',
    element: <UrunEkle />
  },
  {
    path: '/PazarYeri/UrunGuncelle/:urunId',
    element: <UrunGuncelle />
  },
  {
    path: '/PazarYeri/AramaSonucu',
    element: <AramaSonucu />
  },
  {
    path: '/SpotUrunler',
    element: <SpotUrunlerIndex />
  },
  {
    path: '/SpotUrunler/UrunGuncelle/:urunId',
    element: <SpotUrunGuncelle />
  },
  {
    path: '/SpotUrunler/UrunEkle',
    element: <SpotUrunEkle />
  },
  {
    path: '/SpotUrunler/Urun/:itemSeoUrl',
    element: <SpotUrunDetay />
  },
  {
    path:'/KrediSatinal',
    element:<KrediSatinal />
  },
  {
    path:'/ESatinalma',
    element:<ESatinalYonlendir />
  },
  {
    path:'/ESatinalmaMuteahhit',
    element:<EMuteahhitSatinalYonlendir />
  },
  {
    path: '/LogOut',
    element: <Logout />
  },
  {
    path: '/AydinlatmaMetni',
    element: <AydinlatmaMetni />
  },
]);





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <RouterProvider router={router} />
    </CookiesProvider>
  </Provider>
);
