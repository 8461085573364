import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CarImage1 from '../../Assets/Images/CarImage1.webp'
function GuncelVeriler() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            marginTop: '0.5em',
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "30px", md: "50px" },
            "lineHeight": "130%",
            "color": "#000000",
            "textAlign": { xs: "center", md: "left" }
        },
        Header2: {
            marginTop: '3em',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "170%",
            "color": "#303C43",
            width: { xs: "100%", md: "716px" },
            "textAlign": { xs: "center", md: "left" }
        },
        ImageWrapper: {
            marginTop: '5em',
            width: '100%',
            display: 'inline-flex',
            overflowX: "scroll",
        },
        ImagePaper: {
            margin: '0px 3vw 0px 0vw',
        },
        ArrowButton: {
            margin: '0px 10px',
            "width": "61px",
            "height": "61px",
            "background": "#FFFFFF",
            "opacity": "0.5",
            "border": "1px solid #BCC7CE"
        },
        ArrowButtonWrapper: {
            position: 'absolute',
            bottom: '0',
            "display": "flex",
            "top": { xs: "10px", md: "unset" },
            "left": { xs: "100%", md: "0" },
        },
        Iconbutton: {
            color: '#BCC7CE',
            ':hover': {
                color: '#FF6600',
            }
        }

    }
    const value = [];

    function scrollDelay(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    useState(() => {
        setTimeout(async () => {
            const scrollElement = document.getElementById('scrollElement');
            const scrollElementwidth = document.getElementById('scrollElement').offsetWidth;

            for (var y = 0; y <= 21000; y += 373) {
                scrollElement.scrollTo({left: y, behavior: 'smooth'})
                await scrollDelay(1000)
            }
        }, 1500)
    }, [])
    return (
        <>
            <Grid p={{ xs: 3, md: 9 }} container spacing={0}>
                <Grid item xl={9} md={9} xs={12}>
                    <Typography sx={styles.Header1}>
                        {translate.GuncelVeriler}
                    </Typography>
                    <Typography sx={styles.Header2}>
                        {translate.UzmanSahaEkibimizeTurkiyeyiKarisKaris}
                    </Typography>
                </Grid>
                <Grid sx={{ position: 'relative' }} item xl={3} md={3} xs={3}>
                </Grid>
                <Box id='scrollElement' sx={styles.ImageWrapper}>
                    {(() => {
                        for (let i = 1; i < 54; i++) (
                            value.push(
                                <Paper sx={styles.ImagePaper}>
                                    <img src={require(`../../Assets/Images/Saha_Gorselleri/saha${i}.jpg`)} width='auto' height='219' alt='Product of Week' />
                                </Paper>
                            )
                        )
                        return value;
                    })()}

                </Box>
            </Grid>
        </>
    )
}

export default GuncelVeriler