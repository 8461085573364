import styled from "@emotion/styled";
import { TextField } from "@mui/material";



export const StyledFilterInput = styled(TextField)({
    width: '100%',
    height: '50px',
    alignContent: 'center',
    borderRadius: "20px",
    borderColor: 'none',
    '& .MuiInputBase-input': {
        borderRadius: "20px",
        color: "rgb(43,43,43)",
        fontsize: '15px',
        fontWeight: 400,
        borderColor: 'none',
        "fontFamily": "'Poppins'",
    },
    '& .MuiInputBase-root': {
        borderRadius: "20px",
        height: '50px',
        borderColor: 'none',
    }
})