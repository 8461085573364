import { Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import EnteringSearch from '../Components/DigitalCatalogue/EnteringSearch'
import FirmAllItems from '../Components/DigitalCatalogue/FirmAllItems'
import MostSellerItems from '../Components/DigitalCatalogue/MostSellerItems'
import TakeYourPlacement from '../Components/DigitalCatalogue/TakeYourPlacement'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import NavbarSearchable from '../Components/Navigation/NavbarSearchable'
import EnteringCatalogue from '../Components/DigitalCatalogue/EnteringCatalogue'

function DigitalCatalogItems() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var firmId = '';
  var UrunAdi = '';


  const link = useLocation();
  var pageType = 0;
  // 0 urunler 1 firmalar

  const getLinkType = (link) => {
    if (link.search) {
      const linkType = link.search.split('?')[1].split('=')[0];
      if (linkType === 'firmaid' || linkType === 'Firmaid') {
        firmId = link.search.split('=')[1]
        pageType = 1;
      }
      if (linkType === 'UrunAdi' || linkType === 'urunadi' || linkType === 'Urunadi') {
        UrunAdi = link.search.split('=')[1]
        pageType = 0;
      }
    }
  }


  getLinkType(link)
  return (
    <>
      <NavbarSearchable />
      <Grid mt={10} container spacing={0}>
        <EnteringCatalogue />
        <MostSellerItems urunAdi={UrunAdi} />
        <TakeYourPlacement />
        <TryForFree />
        <Footer />
      </Grid>
    </>
  )
}

export default DigitalCatalogItems