import { Box, Chip, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IhaleCustomTableText, StyledLabel } from '../Styled/Labels/StyledLabels'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { loadState } from '../../Assets/Scripts/LocalStorage';

const convertDateTimes = (param) => {
    const year = parseInt(param.slice(0, 4), 10);
    const month = parseInt(param.slice(4, 6), 10) - 1;
    const day = parseInt(param.slice(6, 8), 10);
    const hour = parseInt(param.slice(8, 10), 10);
    const minute = parseInt(param.slice(10, 12), 10);
    const second = parseInt(param.slice(12, 14), 10);
    const dateTime = new Date(year, month, day, hour, minute, second);
    const formattedDate = dateTime.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Europe/Istanbul'
    });
    return (formattedDate);
}

const parseDate = (dateString) => {
    const [day, month, year, hour, minute, second] = dateString.match(/\d+/g);
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
};

const calculateIhaleStartedDateTimes = (param2) => {
    const currentDateTime = new Date();
    const formattedCurrentDate = currentDateTime.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Europe/Istanbul'
    });

    // Tarihleri JavaScript Date nesnelerine dönüştür
    if (param2 !== undefined && param2 !== null && param2 !== 0) {
        const date1String = formattedCurrentDate;
        const date2String = convertDateTimes(param2);

        const date1 = parseDate(date1String);
        const date2 = parseDate(date2String);

        // İki tarih arasındaki zaman farkını milisaniye cinsinden al
        const timeDifference = date2 - date1;

        // Milisaniyeyi gün, saat, dakika ve saniyeye çevir
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return ({
            days: daysDifference,
            hours: hoursDifference,
            minutes: minutesDifference,
            seconds: secondsDifference,
        })
    }
}

function IhaleCustomTable({ props }) {
    //STATES AND VARIBALES________________________________________________________________________________________
    const [remainingTime, setRemainingTime] = useState(calculateIhaleStartedDateTimes(props?.ihaletarihiEnd?.toString()));
    const UserInfoData = JSON.parse(loadState('UserInfo'));



    //HOOKS_______________________________________________________________________________________________________
    useEffect(() => {
        const intervalId = setInterval(() => {
            const remaining = calculateIhaleStartedDateTimes(props?.ihaletarihiEnd?.toString());
            setRemainingTime(remaining);
        }, 1000); // Her 1 saniyede bir çalıştır
        // Temizleme fonksiyonu
        return () => clearInterval(intervalId);
    }, [props?.ihaletarihiEnd, remainingTime])



    // ... diğer kodlar ...


    //FUNCTIONS AND METHODS_______________________________________________________________________________________



    return (
        <a
            style={{
                margin: '10px',
                textDecoration: 'none'
            }}
            href={`/Ihale/Detay/${props?.seourl}`}>
            <Paper sx={{
                minHeight: '120px',
                width: '100%',
                display: 'inline-flex',
                position: 'relative',
                alignItems: 'center',
                margin: '1em 0',
                animation: props.isIhaleStart === 1 ? 'glow 1s infinite alternate' : 'none',
                '@keyframes glow': {
                    from: {
                        boxShadow: '0 0 3px 3px #aef4af',
                    },
                    to: {
                        boxShadow: '0 0 6px 6px #aef4af',
                    },
                },
                '&:hover': {
                    boxShadow: '0 0 15px 0 rgba(0,0,0,0.3)',
                    animation: props.isIhaleStart === 1 ? 'glow 0.3s infinite alternate' : 'none',
                }
            }}>
                <Grid sx={{ display: "inline-flex", alignItems: 'center' }} pl={5} pr={5} container spacing={1}>
                    <Grid item xl={2} lg={2} md={2} sm={4} xs={12}>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: '"Nunito", sans-serif',
                                    fontSize: '12px',
                                    lineHeight: '16.37px',
                                    fontWeight: 400,
                                    color: '#3C6780'
                                }}>
                                #{props?.id}
                                <Box sx={{ width: '100%', display: props?.isIhaleStart === 0 && props?.onayli === 0 && props?.onayistek === 1 ? 'block' : 'none' }}>
                                    <Typography color='white'>
                                        İhale Pasif
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', display: props?.isIhaleStart === 0 && props?.onayli === 0 && props?.onayistek === 0 ? 'block' : 'none' }}>
                                    <Typography color='black'>
                                        İhale Pasif
                                    </Typography>
                                </Box>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '24.55px',
                                    fontWeight: 700,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#FF6600'
                                }}>
                                {props?.ihaleadi}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: '"Nunito", sans-serif',
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    color: '#3C6780'
                                }}>
                                {props?.kategori}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={4} xs={6}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#B6CCD8'
                                }}>
                                İhale Tipi
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 600,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#0A354E'
                                }}>
                                {props?.tenderType}
                            </Typography>
                        </Box>

                    </Grid>

                    <Grid item xl={1.3} lg={5} md={5} sm={5} xs={12}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#B6CCD8'
                                }}>
                                İhale Durumu
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    lineHeight: '19.1px',
                                    fontWeight: 700,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#0A354E'
                                }}>
                                {props.isIhaleStart === 1 && 'Başladı'}
                                {props.isIhaleStart === 0 && 'Ön Teklif'}
                                {props.isIhaleStart === -1 && 'Tamamlandı'}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xl={UserInfoData.isAdmin ? 3 : 5} lg={UserInfoData.isAdmin ? 1 : 3} md={UserInfoData.isAdmin ? 1 : 3} sm={1} xs={12}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#B6CCD8'
                                }}>
                                İhale Açıklaması
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 600,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#0A354E'
                                }}>
                                {props?.kisaTanim && props.kisaTanim.length > 200
                                    ? props.kisaTanim.substring(0, 50) + (props.kisaTanim.length > 200 ? '...' : '')
                                    : props.kisaTanim
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid sx={{ display: UserInfoData.isAdmin ? '' : 'none' }} item xl={2} lg={2} md={2} sm={4} xs={12}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#B6CCD8'
                                }}>
                                Alıcı Firma
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 600,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#0A354E'
                                }}>
                                {props?.uyefirma}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={1.5} lg={1} md={1} sm={4} xs={12}>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                lineHeight: '19.1px',
                                fontWeight: 400,
                                fontFamily: '"Nunito", sans-serif',
                                color: '#B6CCD8'
                            }}>
                            İşin Yapılacağı Yer
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                lineHeight: '19.1px',
                                fontWeight: 600,
                                fontFamily: '"Nunito", sans-serif',
                                color: '#0A354E'
                            }}>
                            {props?.sehir} - {props?.ilce}
                        </Typography>
                    </Grid>
                    <Grid item xl={1.2} lg={1} md={1} sm={1.5} xs={6}>
                        <Box sx={{ display: props.isIhaleStart === 1 ? 'none' : '' }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#B6CCD8'
                                }}>
                                İhale Başlangıç Zamanı
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 600,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#0A354E'
                                }}>
                                {convertDateTimes(props?.ihaletarihiBegin.toString())}
                            </Typography>
                        </Box>

                        <Box sx={{ display: props.isIhaleStart === 1 ? '' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 400,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#B6CCD8'
                                }}>
                                Kalan Süre
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '19.1px',
                                    fontWeight: 600,
                                    fontFamily: '"Nunito", sans-serif',
                                    color: '#0A354E'
                                }}>
                                {props?.ihaletarihiEnd && `${remainingTime?.hours}:${remainingTime?.minutes}:${remainingTime?.seconds}`}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </a >

    )
}

export default IhaleCustomTable