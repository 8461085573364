import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import carImage from '../../Assets/Images/Car.webp'
import { useSelector } from 'react-redux';
function FieldTeam() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            "maxWidth": "100%",
            "height": "auto",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "50px", md: "65px" },
            "lineHeight": "130%",
            "color": "#000000"
        },
        Header2: {
            "maxWidth": "569px",
            "height": "93px",
            "left": "126px",
            "top": "6739px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "18px",
            "lineHeight": "170%",
            "color": "#303C43"
        },
        Button: {
            textTransform: "none",
            "display": "flex",
            "flexDirection": "row",
            "justifyContent": "center",
            "alignItems": "center",
            "padding": "15px 25px",
            "gap": "10px",
            "position": "absolute",
            "width": "170px",
            "height": "50px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "14px",
            color: 'white',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            }
        }
    }

    const navigate = useNavigate();
    const handleNavigate = (link) => {
        navigate(link)
    }


    return (
        <>
            <Grid sx={{
                backgroundColor: 'white',
                display: { xs: 'none', md: 'flex' }
            }}
                pt={10} container spacing={0}>
                <Grid pl={5} item xl={6} md={6} xs={6}>
                    <Typography mb={2} sx={styles.Header1}>
                        {translate.BirAyagimizHepSahada}
                    </Typography>
                    <Typography mb={5} sx={styles.Header2}>
                        {translate.UzmanSahaEkibimizTurkiyeyi}
                    </Typography>
                    <Button
                        onClick={() => handleNavigate('/Hizmetler')}
                        sx={styles.Button}>
                        {translate.Detaylar}
                    </Button>
                </Grid>

                <Grid item xl={6} md={6} xs={6}>
                    <img
                        style={{
                            width: '49vw',
                        }}
                        src={carImage} alt='yapiradar.com' />
                </Grid>
            </Grid>



            <Grid sx={{
                backgroundColor: 'white',
                display: { xs: 'flex', md: 'none' }
            }} pt={10} container spacing={0}>
                <Grid m={1} item xl={6} md={6} xs={12}>
                    <Typography mb={2} sx={{
                        "fontFamily": "'Poppins'",
                        "fontStyle": "normal",
                        "fontWeight": "600",
                        "fontSize": "35px",
                        "lineHeight": "130%",
                        "color": "#000000",
                        textAlign: 'center'
                    }}>
                        {translate.BirAyagimizHepSahada}
                    </Typography>
                    <Typography mb={5} sx={{
                        "fontFamily": "'Poppins'",
                        "fontStyle": "normal",
                        "fontWeight": "500",
                        "fontSize": "18px",
                        "lineHeight": "170%",
                        "color": "#303C43",
                        textAlign: 'center'
                    }}>
                        {translate.UzmanSahaEkibimizTurkiyeyi}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => handleNavigate('/Hizmetler')}
                            sx={styles.Button}>
                            {translate.Detaylar}
                        </Button>
                    </Box>
                </Grid>

                <Grid sx={{ marginTop: { xs: 10 } }} item xl={5} md={5} xs={12}>
                    <img
                        style={{
                            width: '100%',

                        }}
                        src={carImage} alt='yapiradar.com' />
                </Grid>
            </Grid>
        </>
    )
}

export default FieldTeam