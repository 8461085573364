import React, { useEffect, useRef, useState } from 'react'
import IhaleLayout from '../Layouts/IhaleLayout'
import { Box, Button, Divider, Grid, Link, Paper, TextField, Typography, } from '@mui/material'
import { IhaleDetayBaslik, StyledCardHeaderLabel, StyledLabel } from '../../Components/Styled/Labels/StyledLabels'
import { useParams } from 'react-router-dom'
import { getData, postData } from '../../Assets/Scripts/dataAxios'
import { ShowMessage } from '../../Components/Common/Alerts'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { IhaleThemeGeneral } from '../../Themes/IhaleThemeGeneral'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { loadState } from '../../Assets/Scripts/LocalStorage'
import { NumericFormat } from 'react-number-format'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';


const convertDateTimes = (param) => {
    const param2 = param.toString();
    const year = parseInt(param2.slice(0, 4), 10);
    const month = parseInt(param2.slice(4, 6), 10) - 1;
    const day = parseInt(param2.slice(6, 8), 10);
    const hour = parseInt(param2.slice(8, 10), 10);
    const minute = parseInt(param2.slice(10, 12), 10);
    const second = parseInt(param2.slice(12, 14), 10);
    const dateTime = new Date(year, month, day, hour, minute, second);
    const formattedDate = dateTime.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Europe/Istanbul'
    });

    return (formattedDate);
}
const parseDate = (dateString) => {
    const [day, month, year, hour, minute, second] = dateString.match(/\d+/g);
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
};


function IhaleDetay() {
    //STATES AND VARIABLES______________________________________________________________________________________________
    const [ihaleData, SetIhaleData] = useState({});
    const { id } = useParams();
    const [ihaleBaslamayaKalan, setihaleBaslamayaKalan] = useState(null);
    const [ihaleOnTeklifbedeli, SetIhaleOnTeklifBedeli] = useState(0);
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const UserInfoData = JSON.parse(loadState('UserInfo'));

    //HOOKS ______________________________________________________________________________________________
    useEffect(() => {
        const getIhaleData = async () => {
            try {
                const response = await getData(`Ihale/DijitalIhaleById?seourl=${id}`);
                SetIhaleData(response.data.value);
                if (response.data.value.ihaletarihiEnd !== null) {
                    if (response.data.value?.isIhaleStart === 0) {
                        calculateIhaleStartedDateTimes(response.data.value.ihaletarihiBegin.toString());
                    } else if (response.data.value?.isIhaleStart === 1) {
                        calculateIhaleStartedDateTimes(response.data.value.ihaletarihiEnd.toString());
                    } else {
                        console.log('bitti');
                    }
                }
            } catch (error) {
                ShowMessage('error', error);
            }
        }
        getIhaleData();
    }, [id])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (ihaleData.ihaletarihiBegin !== null && ihaleBaslamayaKalan !== null) {
                if (ihaleData?.isIhaleStart === 0) {
                    calculateIhaleStartedDateTimes(ihaleData.ihaletarihiBegin.toString());
                } else {
                    calculateIhaleStartedDateTimes(ihaleData.ihaletarihiEnd.toString());
                }

            }
            if (ihaleBaslamayaKalan?.days === 0 && ihaleBaslamayaKalan?.hours === 0 && ihaleBaslamayaKalan?.minutes === 0 && ihaleBaslamayaKalan?.seconds === 0) {
                window.location.reload();
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [ihaleBaslamayaKalan]);


    //FUNCTIONS ______________________________________________________________________________________________
    const calculateIhaleStartedDateTimes = (param2) => {
        const currentDateTime = new Date();
        const formattedCurrentDate = currentDateTime.toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Europe/Istanbul'
        });

        // Tarihleri JavaScript Date nesnelerine dönüştür
        if (param2 !== undefined && param2 !== null && param2 !== 0) {
            const date1String = formattedCurrentDate;
            const date2String = convertDateTimes(param2);
            const date1 = parseDate(date1String);
            const date2 = parseDate(date2String);
            // İki tarih arasındaki zaman farkını milisaniye cinsinden al
            const timeDifference = date2 - date1;
            // Milisaniyeyi gün, saat, dakika ve saniyeye çevir
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
            setihaleBaslamayaKalan({
                days: daysDifference,
                hours: hoursDifference,
                minutes: minutesDifference,
                seconds: secondsDifference,
            })
        }
    }



    var selectedFile;
    const handleUploadSartname = () => {
        selectedFile = fileInputRef.current.files[0];
        setSelectedFileName(selectedFile.name);

    }

    const handleClickOnTeklifVer = async () => {
        if (!fileInputRef.current.files[0]) {
            ShowMessage('error', 'Lütfen Teklif Teknik Şartnamesi Yükleyin')
        } else {
            const formData = new FormData();
            formData.append('id', 0);
            formData.append('aktif', 1);
            formData.append('type', 0);
            formData.append('ihaleId', ihaleData?.id);
            formData.append('moneyTypeId', 1);
            formData.append('file', fileInputRef.current.files[0])
            formData.append('fiyat', parseInt(ihaleOnTeklifbedeli));

            const response = await postData('Ihale/IhaleOnTeklifInsert', formData);
            if (response.data.isSucceeded) {
                ShowMessage('success', 'Teklifiniz Oluşturulmuştur.')
                setTimeout(function () {
                 //window.location.reload();
                }, 2000);
            } else {
                ShowMessage('error', response.data?.error?.errorMessage)
            }
        }
    }

    const handleIhaleOnayIstek = async () => {
        const res = await getData(`Ihale/IhaleOnayIstek?ihaleId=${parseInt(ihaleData?.id)}`);
        if (res.data.isSucceeded) {
            ShowMessage('success', 'İhale Onay Istek İşlemi Başarı İle Tamamlandı.');
            window.location.reload();
        } else {
            ShowMessage('error', res.data.error.errorMessage);
        }
    }

    const handleIhaleOnayla = async () => {
        const res = await getData(`Ihale/IhaleOnay?ihaleId=${parseInt(ihaleData?.id)}`);
        if (res.data.isSucceeded) {
            ShowMessage('success', 'İhale Onaylama İşlemi Başarı İle Tamamlandı.');
            window.location.reload();
        } else {
            ShowMessage('error', res.data.error.errorMessage);
        }
    }

    return (
        <IhaleLayout>
            <Grid sx={{ background: '#F4F7F9', padding: '1em' }} pl={5} pr={5} mb={10} container spacing={3} >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper sx={{
                        backgroundColor: (ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.isCreator === 1 && UserInfoData?.isAdmin === false) || ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.isCreator === 0 && UserInfoData?.isAdmin === true ? 'tomato' : '#08794F',
                        display: ihaleData.isIhaleStart === 0 && ihaleData?.onayli === 1 ? 'none' : 'flex',
                        height: '59px',
                        alignItems: 'center',
                        padding: '1em 4em',
                        borderRadius: '20px',

                    }}>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 0 && ihaleData?.isCreator === 1 && UserInfoData?.isAdmin === false ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                İhalenizin Yayınlanması için Yönetici Onayı istemelisiniz.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                Bilgilerinizi kontrol ettikten sonra <Button onClick={handleIhaleOnayIstek} variant="text" sx={{ mt: '-2px', color: 'white', fontWeight: 700 }}> Buradan </Button> yönetici onayı isteyebilirsiniz.
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 1 && ihaleData?.isCreator === 1 && UserInfoData?.isAdmin === false ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                İhaleniz Yönetici Onayı Bekliyor.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                İhaleniz yönetici onayına gönderilmiştir. Yönetici onayı sonrasında ihaleniz diğer kullanıcılara gösterilecektir.
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 0 && UserInfoData?.isAdmin === true ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                İhale için Onay <span style={{ fontWeight: 700, textDecoration: 'underline' }}>istenmemiştir.</span>
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                Yine de bu ihaleyi onaylamak istiyorsanız <Button onClick={handleIhaleOnayla} variant="text" sx={{ mt: '-2px', color: 'white', fontWeight: 700 }}> Buradan </Button> ihaleyi onaylayabilirsiniz.
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 1 && UserInfoData?.isAdmin === true ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                İhale Onaylanmayı Bekliyor.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                İhaleyi onaylamak istiyorsanız <Button onClick={handleIhaleOnayla} variant="text" sx={{ mt: '-2px', color: 'white', fontWeight: 700 }}> Buradan </Button> ihaleyi onaylayabilirsiniz.
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === -1 ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                Bu İhale Tamamlanmıştır.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                Devam eden ihaleleri görmek için lütfen   <Link underline='none' href={`/Ihale`} sx={{ mt: '-2px', color: 'white', fontWeight: 700 }}> buraya </Link> tıklayın.
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === 1 && UserInfoData !== false ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                İhale canlı teklif başlamıştır.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                Canlı ihaleye hemen teklif vermek için   <Link underline='none' href={`/Ihale/TeklifVer/${ihaleData?.id}`} sx={{ mt: '-2px', color: 'white', fontWeight: 700 }}> buraya </Link> tıklayın.
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: ihaleData?.isIhaleStart === 1 && UserInfoData === false ? 'block' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '19px',
                                    fontWeight: 600
                                }}
                                color='white'>
                                İhale canlı teklif başlamıştır.
                            </Typography>
                            <Typography
                                sx={{
                                    mt: '0.6em',
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    fontWeight: 400
                                }}
                                color='white'>
                                Bu gibi canlı ihalelere hemen teklif vermek için <Link underline='none' href={`/PazarYeri#MagazaniziAcin`} sx={{ mt: '-2px', color: 'white', fontWeight: 700 }}> buradan </Link> ücretsiz hesabınızı oluşturabilirsiniz.
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                    <Typography
                        sx={{
                            fontSize: '28px',
                            color: '#FF6600',
                            lineHeight: '39.01px',
                            fontWeight: 600,
                        }}
                    >
                        {ihaleData?.ihaleadi}
                    </Typography>
                </Grid>
                <Grid item xl={3} md={3} xs={12}>
                    <Paper sx={{
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                Kategori
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                {ihaleData?.kategori}
                            </Typography>
                        </Box>
                    </Paper>


                    <Paper sx={{
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                İşin Yapılacağı Şehir
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                {ihaleData?.ilceNavigation?.ad} - {ihaleData?.sehirNavigation?.ad}
                            </Typography>
                        </Box>
                    </Paper>


                    <Paper sx={{
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                İhale Tipi / Katılım Tipi
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                {ihaleData?.tenderType?.name} -   {ihaleData?.katilimTipi}
                            </Typography>
                        </Box>
                    </Paper>


                    <Paper sx={{
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                İhale Şartnamesi
                            </Typography>


                            <Box sx={{
                                display: UserInfoData !== false ? 'inline-flex' : 'none',
                                alignItems: 'center'
                            }}>
                                <FolderOpenOutlinedIcon sx={{ color: '#FF6600' }} />
                                <Typography
                                    sx={{
                                        mt: '3px',
                                        ml: '5px',
                                        height: '20px',
                                        fontSize: '14px',
                                        color: '#FF6600',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >   {
                                        ihaleData && ihaleData.ihaleDocumentNavigation?.length > 0 && (
                                            ihaleData?.ihaleDocumentNavigation.map((doc) => (
                                                doc.aktif === 1 ? (
                                                    <Link underline='none' href={`/images/IhaleDocuments/${doc.document}`}>
                                                        Dosyayı İndir
                                                    </Link>
                                                ) : null
                                            ))
                                        )
                                    }
                                </Typography>
                            </Box>

                            <Box sx={{
                                display: UserInfoData === false ? 'inline-flex' : 'none',
                                alignItems: 'center'
                            }}>
                                <FolderOpenOutlinedIcon sx={{ color: '#FF6600' }} />
                                <Typography
                                    sx={{
                                        mt: '3px',
                                        ml: '5px',
                                        height: '20px',
                                        fontSize: '14px',
                                        color: '#FF6600',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >   {
                                        ihaleData && ihaleData.ihaleDocumentNavigation?.length > 0 && (
                                            ihaleData?.ihaleDocumentNavigation.map((doc) => (
                                                doc.aktif === 1 ? (
                                                    <Link underline='none' href={`/PazarYeri#MagazaniziAcin`}>
                                                        Hemen Ücretsiz Deneyin
                                                    </Link>
                                                ) : null
                                            ))
                                        )
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                    <Paper sx={{
                        borderRadius: '24px',
                        height: '103px',
                        padding: '1.4em',
                        minHeight: '415px'

                    }}>
                        <Typography
                            sx={{
                                mb: '1em',
                                fontSize: '16px',
                                color: '#0A354E',
                                lineHeight: '24px',
                                fontWeight: 700,
                            }}
                        >
                            İhale Açıklaması
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: '#0A354E',
                                lineHeight: '19px',
                                fontWeight: 400,
                            }}
                        >
                            {ihaleData?.kisaTanim}
                        </Typography>
                    </Paper>
                </Grid>


                <Grid item xl={3} md={3} xs={12}>
                    <Paper sx={{
                        borderRadius: '24px',
                        height: '123px'

                    }}>
                        <Box sx={{ display: UserInfoData !== false ? '' : 'none', padding: '1.5em 2em' }}>
                            <Box sx={{ display: UserInfoData.isAdmin === false ? '' : 'none' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '18px',
                                        color: '#FF6600',
                                        lineHeight: '19px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {ihaleData?.firma?.firmaadi}
                                </Typography>

                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '12px',
                                        color: '#AAAAAA',
                                        lineHeight: '19px',
                                        fontWeight: 400,
                                    }}
                                >
                                    -Firma Kategorisi-
                                </Typography>
                            </Box>

                            <Box sx={{ display: UserInfoData.isAdmin === true ? '' : 'none' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '18px',
                                        color: '#FF6600',
                                        lineHeight: '19px',
                                        fontWeight: 700,
                                    }}
                                >
                                    <Link underline='none' href={`https://ypanel.yapiradar.com/Satis/UyeDetay?firmaid=${ihaleData?.firma?.id}`}>
                                        {ihaleData?.firma?.firmaadi}
                                    </Link>
                                </Typography>

                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '12px',
                                        color: '#AAAAAA',
                                        lineHeight: '19px',
                                        fontWeight: 400,
                                    }}
                                >
                                    -Firma Kategorisi-
                                </Typography>
                            </Box>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#3C6780',
                                    lineHeight: '19px',
                                    fontWeight: 700,
                                }}
                            >
                                {ihaleData?.firma?.adsoyad}
                            </Typography>
                        </Box>


                        <Box sx={{ display: UserInfoData === false ? '' : 'none', padding: '1.5em 2em' }}>
                            <Link underline='none' href={`/PazarYeri#MagazaniziAcin`}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '18px',
                                        color: '#FF6600',
                                        lineHeight: '19px',
                                        fontWeight: 700,
                                    }}
                                >
                                    Hemen Ücretsiz Deneyin
                                </Typography>

                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '12px',
                                        color: '#AAAAAA',
                                        lineHeight: '19px',
                                        fontWeight: 400,
                                    }}
                                >
                                    Daha fazla ihale görmek ve ihalelere teklif vermek için bizi hemen ücretsiz deneyin.
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#3C6780',
                                        lineHeight: '19px',
                                        fontWeight: 700,
                                    }}
                                >


                                </Typography>
                            </Link>
                        </Box>
                    </Paper>


                    <Paper sx={{
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                Başlangıç Zamanı
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                {
                                    ihaleData && ihaleData.ihaletarihiBegin && (
                                        convertDateTimes(ihaleData.ihaletarihiBegin.toString())
                                    )
                                }
                            </Typography>
                        </Box>
                    </Paper>


                    <Paper sx={{
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                Bitiş Zamanı
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                {
                                    ihaleData && ihaleData.ihaletarihiEnd && (
                                        convertDateTimes(ihaleData.ihaletarihiEnd.toString())
                                    )
                                }
                            </Typography>
                        </Box>
                    </Paper>

                    <Paper sx={{
                        display: ihaleData?.isIhaleStart === -1 ? 'none' : '',
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                {ihaleData?.isIhaleStart === 0 && 'Başlamaya Kalan Süre'}

                                {ihaleData?.isIhaleStart === 1 && 'Bitişe Kalan Süre'}
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.days) : '-'}
                                <span>  Gün </span>
                                {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.hours) : '-'}
                                <span>  Saat </span>
                                {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.minutes) : '-'}
                                <span>  Dakika </span>
                                {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.seconds) : '-'}
                                <span>  Saniye </span>
                            </Typography>
                        </Box>
                    </Paper>

                    <Paper sx={{
                        display: ihaleData?.isIhaleStart === -1 && (ihaleData.isCreator === 1 || UserInfoData.isAdmin === true) ? '' : 'none',
                        mt: '1em',
                        borderRadius: '24px',
                        height: '103px'

                    }}>
                        <Box sx={{ padding: '1.5em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                İhale Sonuçlarını Değerlendir
                            </Typography>

                            <Button
                                sx={{ width: 'fit-content' }}
                                onClick={() => window.location.href = `/Ihale/Sonuc/${ihaleData.seourl}`}
                                variant='text' fullWidth>
                                İhale Sonuç Sayfasına Git
                            </Button>
                        </Box>
                    </Paper>

                </Grid>

                <Grid
                    sx={{ display: UserInfoData.isAdmin === true ? '' : 'none' }}
                    item xl={8} md={8} xs={12}>
                    <Paper sx={{
                        minHeight: '200px',
                        borderRadius: '24px',
                    }}>
                        <Box sx={{ padding: '1.5em 2em 1em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                E-İhale Ön Teklifler
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '0em 2em 1em 2em' }}>
                            {
                                ihaleData?.ihaleTeklifs?.length > 0 ? (ihaleData?.ihaleTeklifs.map(item => {
                                    if (item.aktif) {
                                        return (
                                            <Box sx={{ display: 'inline-flex', width: '100%', mt: 2, mb: 2 }}>
                                                <Box sx={{ width: UserInfoData.isAdmin || ihaleData.isCreator ? '20%' : '30%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Teklif Tutarı
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='20px'>
                                                            {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(item.fiyat)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: UserInfoData.isAdmin || ihaleData.isCreator ? 'block' : 'none', width: '50%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Firma Adı
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                                            <Typography> {item.uyefirma} </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box sx={{ width: UserInfoData.isAdmin || ihaleData.isCreator ? '30%' : '65%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Teklif Teknik Şartnamesi
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                                            {item?.teklif_documents[0]?.document ? (<a href={`/images/IhaleTeklifDocument/${item?.teklif_documents[0]?.document}`}>Şartname</a>) : (<Typography> Ön Teklif Sözleşmesi Bulunmamaktadır. </Typography>)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                })) : (
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            color: '#0A354E',
                                            lineHeight: '19px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        Bu ihale için bir ön teklif bulunmamaktadır.
                                    </Typography>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>


                <Grid
                    sx={{ display: ihaleData.isCreator === 1 && UserInfoData.isAdmin === false ? '' : 'none' }}
                    item xl={8} md={8} xs={12}>
                    <Paper sx={{
                        minHeight: '200px',
                        borderRadius: '24px',
                    }}>
                        <Box sx={{ padding: '1.5em 2em 1em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                E-İhale Ön Teklifler
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '0em 2em 1em 2em' }}>
                            {
                                ihaleData?.ihaleTeklifs?.length > 0 ? (ihaleData?.ihaleTeklifs.map(item => {
                                    if (item.aktif) {
                                        return (
                                            <Box sx={{ display: 'inline-flex', width: '100%', mt: 2, mb: 2 }}>
                                                <Box sx={{ width: UserInfoData.isAdmin || ihaleData.isCreator ? '20%' : '30%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Teklif Tutarı
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='20px'>
                                                            {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(item.fiyat)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ width: UserInfoData.isAdmin || ihaleData.isCreator ? '30%' : '65%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Teklif Teknik Şartnamesi
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                                            {item?.teklif_documents[0]?.document ? (<a href={`/images/IhaleTeklifDocument/${item?.teklif_documents[0]?.document}`}>Şartname</a>) : (<Typography>Ön Teklif Sözleşmesi Bulunmamaktadır. </Typography>)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                })) : (
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            color: '#0A354E',
                                            lineHeight: '19px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        İhaleniz için verilmiş bir ön teklif bulunmamaktadır.
                                    </Typography>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>

                <Grid
                    sx={{ display: ihaleData.isCreator === 0 && UserInfoData.isAdmin === false ? '' : 'none' }}
                    item xl={8} md={8} xs={12}>
                    <Paper sx={{
                        minHeight: '200px',
                        borderRadius: '24px',
                    }}>
                        <Box sx={{ padding: '1.5em 2em 1em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                E-İhale İçin Verdiğiniz Ön Teklifler
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '0em 2em 1em 2em' }}>
                            {
                                ihaleData?.ihaleTeklifs?.length > 0 ? (ihaleData?.ihaleTeklifs.map(item => {
                                    if (item.aktif) {
                                        return (
                                            <Box sx={{ display: 'inline-flex', width: '100%', mt: 2, mb: 2 }}>
                                                <Box sx={{ width: UserInfoData.isAdmin || ihaleData.isCreator ? '20%' : '30%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Teklif Tutarı
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='20px'>
                                                            {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(item.fiyat)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: UserInfoData.isAdmin || ihaleData.isCreator ? 'block' : 'none', width: '50%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Firma Adı
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                                            <Typography> {item.uyefirma} </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box sx={{ width: UserInfoData.isAdmin || ihaleData.isCreator ? '30%' : '65%' }}>
                                                    <Box>
                                                        <Typography
                                                            color={IhaleThemeGeneral.palette.gray.primary}
                                                        >
                                                            Teklif Teknik Şartnamesi
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                                            {item?.teklif_documents[0]?.document ? (<a href={`/images/IhaleTeklifDocument/${item?.teklif_documents[0]?.document}`}>Şartname</a>) : (<Typography> Ön Teklif Sözleşmesi Bulunmamaktadır. </Typography>)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                })) : (
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            color: '#0A354E',
                                            lineHeight: '19px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        Bu ihaleye daha önce bir ön teklif oluşturmadınız.
                                    </Typography>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>


                <Grid
                    sx={{ display: ihaleData?.isIhaleStart === 0 && UserInfoData !== false ? '' : 'none' }}
                    item xl={4} md={4} xs={12}>
                    <Paper sx={{
                        borderRadius: '24px',
                    }}>
                        <Box sx={{ padding: '1.5em 2em 1em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                E-İhale Giriş Fiyatı Oluştur
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '0em 2em 1em 2em', }}>
                            <Grid container spacing={1}>
                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <NumericFormat
                                            disabled={ihaleData.isCreator === 1 || UserInfoData.isAdmin === true ? true : false}
                                            customInput={TextField}
                                            thousandSeparator=","
                                            prefix='₺'
                                            fullWidth
                                            placeholder='TL'
                                            onValueChange={(values, sourceInfo) => {
                                                SetIhaleOnTeklifBedeli(values.value)
                                                console.log(values.value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid mt={3} item xl={12} md={12} xs={12}>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleUploadSartname}
                                    />
                                    <Button
                                        onClick={() => fileInputRef.current.click()}
                                        disabled={ihaleData.isCreator === 1 || UserInfoData.isAdmin === true ? true : false}
                                        sx={{
                                            width: "100%",
                                            textTransform: 'none',
                                            color: 'black'
                                        }}
                                        variant='outlined'
                                        startIcon={<UploadFileIcon />}
                                    >
                                        <StyledLabel>
                                            {selectedFileName ? selectedFileName : "Ürün Teknik Şartnamesi Yükle"}
                                        </StyledLabel>
                                    </Button>
                                </Grid>
                                <Grid mt={3} item xl={12} md={12} xs={12}>
                                    <Button
                                        disabled={ihaleData.isCreator === 1 || UserInfoData.isAdmin === true ? true : false}
                                        onClick={handleClickOnTeklifVer}
                                        sx={{
                                            width: "100%"
                                        }}
                                        variant='outlined'
                                    >
                                        <StyledLabel>
                                            Ön Teklif Ver
                                        </StyledLabel>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ padding: '0em 2em 1em 2em', display: ihaleData.isCreator === 1 ? '' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'tomato',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                İhale sahipleri kendi ihalelerine ön teklif veremez.
                            </Typography>
                        </Box>

                        <Box sx={{ padding: '0em 2em 1em 2em', display: UserInfoData.isAdmin === true ? '' : 'none' }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: 'tomato',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                Sistem yöneticileri ihaleye ön teklif veremez.
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>


                <Grid
                    sx={{ display: ihaleData?.isIhaleStart === 1 && UserInfoData !== false ? '' : 'none' }}
                    item xl={4} md={4} xs={12}>
                    <Paper sx={{
                        borderRadius: '24px',
                    }}>
                        <Box sx={{ padding: '1.5em 2em 1em 2em' }}>
                            <Typography
                                sx={{
                                    mb: '0.6em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                Canlı İhaleye Git
                            </Typography>
                        </Box>
                        <Box sx={{ padding: '0em 2em 1em 2em' }}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#3C6780',
                                    lineHeight: '19px',
                                    fontWeight: 500,
                                }}
                            >
                                Canlı ihale devam ediyor.
                            </Typography>
                        </Box>

                        <Box sx={{ padding: '0em 2em 1em 2em' }}>
                            <Button
                                onClick={() => window.location.href = `/Ihale/TeklifVer/${ihaleData?.id}`}
                                sx={{ width: '100%', height: '50px' }}
                                variant='contained'
                            >
                                İhaleye Devam Et
                            </Button>
                        </Box>
                    </Paper>
                </Grid>


                {/* 
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Paper sx={{ display: ihaleData?.isIhaleStart === 0 || ihaleData?.isIhaleStart === 1 ? '' : 'none' }} elevation={0}>
                        <Box sx={{ display: ihaleData?.isIhaleStart === 0 ? '' : 'none' }} pl='3em' pr='4em' pt='1em' pb='1.5em'>
                            <Typography
                                sx={{
                                    display: ihaleData?.onayli === 1 ? '' : 'none'
                                }}
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                {
                                    UserInfoData.isAdmin || ihaleData.isCreator ? 'Oluşturulmuş Ön Teklifler' : 'E-İhale Giriş Fiyat Teklifiniz'
                                }
                            </Typography>

                            <Typography
                                sx={{
                                    display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 0 && ihaleData?.isCreator === 1 && UserInfoData?.isAdmin === false ? '' : 'none'
                                }}
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                İhale Pasif Durumda. Yayına Alınması için aşağıda bulunan Onay İste butonuna tıklayıp Yönetici Onayı istemelisiniz.
                            </Typography>

                            <Typography
                                sx={{
                                    display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 1 && UserInfoData?.isAdmin === true ? '' : 'none'
                                }}
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                İhale Pasif Durumda. Kullanıcı İhaleye Onay İstemektedir.
                            </Typography>

                            <Typography
                                sx={{
                                    display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 0 && UserInfoData?.isAdmin === true ? '' : 'none'
                                }}
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                İhale Pasif Durumda. Bu İhale için onay istenmemiştir. Yine de onaylamak için aşağıdaki butonu kullanabilirsiniz.
                            </Typography>


                            <Typography
                                sx={{
                                    display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 0 && ihaleData?.onayistek === 1 && ihaleData?.isCreator === 1 && UserInfoData?.isAdmin === false ? '' : 'none'
                                }}
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                İhale Pasif Durumda. Yayına Alınması için Yönetici Onayına Gönderilmiştir
                            </Typography>




                            {ihaleData?.ihaleTeklifs && ihaleData?.ihaleTeklifs.length > 0 ? (
                                ihaleData?.ihaleTeklifs.map((teklif, index) => (
                                    // Eğer teklif aktifse, fiyatı görüntüle
                                    teklif.aktif === 1 ? (
                                        <>
                                            <Typography key={index} fontSize='34px'>
                                                {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(teklif.fiyats?.[0]?.fiyat)}
                                            </Typography>

                                            <Typography sx={{ display: UserInfoData.isAdmin || ihaleData.isCreator ? 'block' : 'none' }} key={index} fontSize='16px'>
                                                {teklif.uyefirma}
                                            </Typography>

                                            <Link underline='none' href={`/images/IhaleTeklifDocument/${teklif.teklif_documents?.[0]?.document}`}>
                                                <StyledLabel>
                                                    {
                                                        UserInfoData.isAdmin || ihaleData.isCreator ? 'Teklif Şartnamesi' : 'Teklif Şartnameniz'
                                                    }

                                                </StyledLabel>
                                            </Link>

                                            <Divider sx={{ display: UserInfoData.isAdmin || ihaleData.isCreator ? 'block' : 'none' }} />
                                        </>
                                    ) : null
                                ))
                            ) : (
                                <>
                                    <Button
                                        variant='outlined'
                                        onClick={handleIhaleOnayIstek}
                                        sx={{
                                            display: UserInfoData.isAdmin === false && ihaleData?.isCreator === 1 && ihaleData?.onayistek === 0 && ihaleData?.onayli === 0 ? '' : 'none',
                                            width: '100%',
                                            mt: 3
                                        }}>
                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                            Onay Iste
                                        </Typography>
                                    </Button>


                                    <Typography mt={3} fontSize='24px' sx={{ textAlign: 'left', display: UserInfoData.isAdmin === false && ihaleData?.isCreator === 1 && ihaleData?.onayistek === 1 && ihaleData?.onayli === 0 ? '' : 'none' }}>
                                        Yönetici Onayı Bekliyor
                                    </Typography>

                                    <Typography mt={3} fontSize='24px' sx={{ textAlign: 'left', display: UserInfoData.isAdmin === true && ihaleData?.isCreator === 0 && ihaleData?.onayistek === 1 && ihaleData?.onayli === 0 ? '' : 'none' }}>
                                        Yönetici Onayı Bekliyor
                                    </Typography>

                                    <Typography mt={3} fontSize='24px' sx={{ textAlign: 'left', display: UserInfoData.isAdmin === false && ihaleData?.isCreator === 0 && ihaleData?.onayli === 1 ? '' : 'none' }}>
                                        Ön Teklifiniz Bulunmamaktadır.
                                    </Typography>

                                    <Button
                                        variant='outlined'
                                        onClick={handleIhaleOnayla}
                                        sx={{
                                            display: UserInfoData.isAdmin === true && ihaleData?.onayli === 0 ? '' : 'none',
                                            width: '100%',
                                            mt: 3
                                        }}>
                                        <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                            İhaleyi Onayla
                                        </Typography>
                                    </Button>
                                </>
                            )}
                        </Box>

                        <Box sx={{ display: ihaleData?.isIhaleStart === 1 ? '' : 'none' }} pl='3em' pr='4em' pt='1em' pb='1.5em'>
                            <Typography
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Son Teklifiniz
                            </Typography>

                            {ihaleData?.ihaleTeklifs && ihaleData?.ihaleTeklifs.length > 0 && (
                                <Typography
                                    fontSize='34px'
                                >
                                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(ihaleData?.ihaleTeklifs?.[0].fiyats?.[0]?.fiyat)}
                                </Typography>
                            )}
                        </Box>
                        <Divider />
                        <Box pt='1em' pb='2em'>
                            <Typography
                                sx={{ display: ihaleData?.isIhaleStart === 0 ? '' : 'none' }}
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Başlamasına Kalan Süre
                            </Typography>

                            <Typography
                                sx={{ display: ihaleData?.isIhaleStart === 1 ? '' : 'none' }}
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Bitişine Kalan Süre
                            </Typography>
                            <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'space-around' }}>
                                <Box sx={{ display: ihaleBaslamayaKalan !== null && parseInt(ihaleBaslamayaKalan) > 0 ? '' : 'block' }}>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.days) : '-'}
                                    </Typography>

                                    <Typography>
                                        Gün
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.hours) : '-'}
                                    </Typography>

                                    <Typography>
                                        Saat
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.minutes) : '-'}
                                    </Typography>

                                    <Typography>
                                        Dakika
                                    </Typography>
                                </Box>


                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.seconds) : '-'}
                                    </Typography>

                                    <Typography>
                                        Saniye
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>


                    <Paper sx={{ display: ihaleData?.isIhaleStart === -1 ? '' : 'none' }} elevation={0}>
                        <Box pl='3em' pr='4em' pt='1em' pb='1.5em'>
                            <Typography
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Verilmiş Son Teklifiniz
                            </Typography>

                            {ihaleData?.ihaleTeklifs && ihaleData?.ihaleTeklifs.length > 0 && (
                                <Typography
                                    fontSize='34px'
                                >
                                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(ihaleData?.ihaleTeklifs?.[0].fiyats?.[0]?.fiyat)}
                                </Typography>
                            )}
                        </Box>
                        <Divider />
                        <Box pt='1em' pb='2em'>
                            <Typography
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                Bu İhale Bitmiştir. İhale Bitiş Zamanı
                            </Typography>
                            <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'space-around' }}>
                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {
                                            ihaleData && ihaleData.ihaletarihiEnd && (
                                                convertDateTimes(ihaleData.ihaletarihiEnd.toString())
                                            )
                                        }
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                        <Box sx={{ display: UserInfoData?.isAdmin === false && ihaleData.isCreator === 0 ? '' : 'none' }}>
                            <Typography
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                Diğer İhaler İçin Devam edebilirsiniz.
                            </Typography>
                            <Box pr='3em' pl='3em' pb='2em'>
                                <Button
                                    onClick={() => window.location.href = `/Ihale/IhaleListesi?isIhaleStart=1`}
                                    variant='outlined' fullWidth>
                                    Henüz Başlamamış İhalelere Git
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ display: UserInfoData?.isAdmin === true || ihaleData.isCreator === 1 ? '' : 'none' }}>
                            <Typography
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                İhale Bitmiştir. Teklifleri görmek ve ihaleyi sonuçlandırmak için aşağıdaki butona basabilirsiniz.
                            </Typography>
                            <Box pr='3em' pl='3em' pb='2em'>
                                <Button
                                    onClick={() => window.location.href = `/Ihale/Sonuc/${ihaleData.seourl}`}
                                    variant='outlined' fullWidth>
                                    İhale Sonuç Sayfasına Git
                                </Button>
                            </Box>
                        </Box>
                    </Paper>

                    <Paper sx={{
                        mt: 2,
                        display: ihaleData?.isIhaleStart === 0 && ihaleData?.onayli === 1 && ihaleData?.isCreator === 0 && UserInfoData?.isAdmin === false ? '' : 'none',
                        backgroundColor: '',
                        padding: '1em 1em',

                    }}>
                        <StyledCardHeaderLabel sx={{
                            textAlign: 'center',
                        }}>
                            E-İhale Giriş Fiyatı Oluştur
                        </StyledCardHeaderLabel>

                        <Grid container spacing={1}>
                            <Grid mt={3} item xl={12} md={12} xs={12}>
                                <Box>
                                    <NumericFormat
                                        customInput={TextField}
                                        thousandSeparator=","
                                        prefix='₺'
                                        fullWidth
                                        placeholder='TL'
                                        onValueChange={(values, sourceInfo) => {
                                            SetIhaleOnTeklifBedeli(values.value)
                                            console.log(values.value);
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid mt={3} item xl={12} md={12} xs={12}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleUploadSartname}
                                />
                                <Button
                                    onClick={() => fileInputRef.current.click()}
                                    sx={{
                                        width: "100%",
                                        textTransform: 'none',
                                        color: 'black'
                                    }}
                                    variant='outlined'
                                    startIcon={<UploadFileIcon />}
                                >
                                    <StyledLabel>
                                        {selectedFileName ? selectedFileName : "Ürün Teknik Şartnamesi Yükle"}
                                    </StyledLabel>
                                </Button>
                            </Grid>
                            <Grid mt={3} item xl={12} md={12} xs={12}>
                                <Button
                                    onClick={handleClickOnTeklifVer}
                                    sx={{
                                        width: "100%"
                                    }}
                                    variant='outlined'
                                >
                                    <StyledLabel>
                                        Ön Teklif Ver
                                    </StyledLabel>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{
                        mt: 2,
                        display: ihaleData?.isIhaleStart === 1 ? '' : 'none',
                        backgroundColor: '',
                        padding: '1em 1em',
                    }}>
                        <StyledCardHeaderLabel sx={{
                            textAlign: 'center',
                        }}>
                            İhale Başladı !
                        </StyledCardHeaderLabel>

                        <Box sx={{ display: ihaleData?.ihaleTeklifs?.length > 0 ? '' : 'none' }}>
                            <Typography
                                mt={2}
                                mb={2}
                                sx={{ textAlign: 'justify' }}
                                color={IhaleThemeGeneral.palette.gray.main} fontSize='16px'
                            >
                                Daha fazla Teklifden yararlanmak için ve ihale durumunu takip etmen için hemen ihale teklifleri sayfasına devam edin dummy text
                            </Typography>

                            <Button
                                onClick={() => window.location.href = `/Ihale/TeklifVer/${ihaleData?.id}`}
                                sx={{ width: '100%', height: '80px' }}
                                variant='outlined'
                            >
                                İhaleye Devam Et
                            </Button>
                        </Box>

                        <Box sx={{ display: ihaleData?.ihaleTeklifs?.length === 0 ? '' : 'none' }}>
                            <Typography
                                mt={2}
                                mb={2}
                                sx={{ textAlign: 'justify' }}
                                color={IhaleThemeGeneral.palette.gray.main} fontSize='16px'
                            >
                                İhale başladı fakat bu ihaleye bir ön teklif oluşturmadığınız için ihaleye teklif oluşturamazsınız.
                            </Typography>

                            <Box pr='3em' pl='3em' pb='2em'>
                                <Button
                                    onClick={() => window.location.href = `/Ihale/IhaleListesi?isIhaleStart=1`}
                                    variant='outlined' fullWidth>
                                    Henüz Başlamamış İhalelere Git
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid> */}

            </Grid>
        </IhaleLayout >
    )
}

export default IhaleDetay