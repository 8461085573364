import { Button, Card, CardContent, Grid, IconButton, Link, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import { useSelector } from 'react-redux'
import { ShowMessage } from '../../Components/Common/Alerts';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import NoFirmLogo from '../../Assets/Images/FirmNoLogo.webp'
import NavbarSearchable from '../../Components/Navigation/NavbarSearchable';
import Footer from '../../Components/Footer';
import TryForFree from '../../Components/Landing Faydalar/TryForFree';
import SpotUrunlerItemCard from '../../Components/SpotUrunler/SpotUrunlerItemCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CategoryIcon from '@mui/icons-material/Category';
function SpotUrunDetay() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useParams();

  // Variables and States
  const user = useSelector((state) => JSON.parse(state.Auth.user));
  const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))

  const [showMoreButton, SetShowMoreButton] = useState(0);
  // set urunid

  const [itemid, setItemid] = useState(0);

  const [urunlerModalopen, SetUrunlerModalopen] = React.useState(false);
  const [itemDescriptionTab, setItemDescriptionTab] = useState('Urun Aciklamalari')
  const [itemData, setItemData] = useState();
  const [kategoriData, setKategoridata] = useState([]);
  const [defaultImageLink, setDefaultImageLink] = useState();

  const [urunadi, Seturunadi] = useState(null);
  const [marka, Setmarka] = useState(null);
  const [tanim, Settanim] = useState(null);
  const [kategori, Setkategori] = useState(null);
  const [uygulamaalani, Setuygulamaalani] = useState(null);
  const [teknikozellikler, Setteknikozellikler] = useState(null);
  const [kalitegaranti, Setkalitegaranti] = useState(null);
  const [videourl, Setvideourl] = useState(null);
  const [urunGorselleri, SetUrunGorselleri] = useState(null);
  const [urunGorselShow, SetUrunGorselShow] = useState(null);
  //style objects and styled style comps
  const styles = {
    ItemIconsLabel: {
      pt: 1,
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#FF6600",
      width: 'fitContent'
    },
    ItemIconsDesc: {
      pt: 1,
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "14px",
      "lineHeight": "120%",
      "color": "black",
      width: 'fitContent'
    },
    ItemBrand: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#8FBFFA",
      cursor: 'pointer',
      width: 'fitContent'
    },
    ItemName: {
      width: '100%',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "28px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "#000000"
    },
    ItemPrice: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "32px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "#000000"
    },
    ItemPerakendePrice: {
      textDecoration: 'line-through',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "22px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "#000000"
    },
    ItemDeliver: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "20px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "#000000"
    },
    ItemCount: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": "20px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "tomato"
    },
    ItemCountLast5Product: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": "20px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "red"
    },
    ItemDesc: {
      width: '100%',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "18px",
      "lineHeight": "150%",
      "color": "#3C6780"
    },
    CategoryText: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "14px",
      "lineHeight": "120%",
      "color": "#303C43"
    },
    OfferText: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "12px",
      "lineHeight": "130%",
      "width": "100%",
      "color": "#3C6780"
    },
    GetOfferBtn: {
      "margin":"10px 10px 10px 5px",
      "padding": "15px 25px",
      "gap": "10px",
      "width":{xs:"100%",md:"65%"} ,
      "height": "50px",
      "background": "#FF6600",
      "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
      "borderRadius": "14px",
      ':hover': {
        "background": "#FF6600",
        "boxShadow": "none",
        "borderRadius": "14px",
      }
    },
    ChangeItemSpecsBtn: {
      marginLeft: '5px',
      "padding": "15px 25px",
      "gap": "10px",
      "width": "250px",
      "height": "50px",
      "background": "#3C6780",
      "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
      "borderRadius": "14px",
      ':hover': {
        "background": "#3C6780",
        "boxShadow": "none",
        "borderRadius": "14px",
      }
    },
    DeleteItemBtn: {
      marginLeft: '5px',
      "padding": "15px 25px",
      "gap": "10px",
      "width": "250px",
      "height": "50px",
      "background": "red",
      "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
      "borderRadius": "14px",
      ':hover': {
        "background": "red",
        "boxShadow": "none",
        "borderRadius": "14px",
      }
    },
    GetOfferBtnText: {
      textTransform: 'none',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#FFFFFF",
      "flex": "none",
      "order": "0",
      "flexGrow": "0"
    },
    CategoriesList: {
      "border": "1px solid #BCC7CE",
      "borderRadius": "100px",
      "padding": "10px 15px",
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "12px",
      "lineHeight": "120%",
      "color": "#303C43",
      "flex": "none",
      "order": "0",
      "flexGrow": "0"
    },
    CategoriesListWrapper: {
      display: 'flex',
      width: '120px'
    },
    ItemImageSmall: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      margin: '0px 5px',
      "width": "102px",
      "height": "85px",
      "left": "129px",
      "top": "731px",
      "border": "1px solid #BCC7CE",
      "borderRadius": "20px",
      boxShadow: 'none'
    },
    DescHeaders: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#303C43",
      "flex": "none",
      "order": "0",
      "flexGrow": "0",
      "display": "inline-flex",
      "flexDirection": "row",
      "justifyContent": "center",
      "alignItems": "center",
      "padding": "10px 35px",
      "gap": "10px",
      "margin": '10px',
      "width": "154px",
      "height": "29px",
      "border": "1px solid #BCC7CE",
      "borderRadius": "100px",
      cursor: 'pointer',
    },
    DescHeadersClicked: {
      cursor: 'pointer',
      backgroundColor: '#303C43',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#FFFFFF",
      "flex": "none",
      "order": "0",
      "flexGrow": "0",
      "display": "inline-flex",
      "flexDirection": "row",
      "justifyContent": "center",
      "alignItems": "center",
      "padding": "10px 35px",
      "gap": "10px",
      "margin": '10px',
      "width": "154px",
      "height": "29px",
      "border": "1px solid #BCC7CE",
      "borderRadius": "100px",
    },
    DescHeader1: {
      "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "20px", "lineHeight": "35px", "display": "flex", "alignItems": "center", "color": "#303C43","flex-direction": "column",
    },
    DescHeader1Text: {
      "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "16px", "lineHeight": "26px", "color": "#303C43"
    },
    DescHeaderImageText: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "14px",
      "lineHeight": "35px",
      "color": "#889AA4"
    },
    DescHeaderImage: {
      height: '150px',
      width: '179px',
      "borderRadius": "20px",
      "boxShadow": 'none'
    },
    ModalStyle: {
      position: 'absolute',
      top: '10%',
      left: '10%',
      width: '75%',
      bgcolor: '#ffffff',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    textField: {
      marginTop: '1em',
      "background": "#FFFFFF",
      width: '437px',
      [`& fieldset`]: {
        borderRadius: 20,
      },
    },
    textFieldMultiLine: {
      marginTop: '1em',
      "background": "#FFFFFF",
      width: '437px'
    },
    SubmitButton: {
      marginTop: '2em',
      color: 'white',
      "padding": "15px 25px",
      "gap": "10px",
      "width": "437px",
      "height": "63px",
      "background": "#FF6600",
      "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
      "borderRadius": "20px",
      ':hover': {
        "background": "#FF6600",
        "boxShadow": "none",
      }
    },
    TextHeader: {
      marginTop: '1em',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "20px",
      "lineHeight": "130%"
    },
    Header1: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "28px",
      "lineHeight": "140%"
    },
    deleteItemModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#FFFFFF',
      boxShadow: 24,
      p: 4,
    },
    Header11: {
      "height": "56px",
      "top": "calc(50% - 56px/2 - 2987.5px)",
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": "40px",
      "lineHeight": "140%",
      "display": "flex",
      "alignItems": "center",
      "color": "#000000"
    },
    Card: {
      width: '250px',
      backgroundColor: '#F4F7F9',
      padding: 0,
      boxShadow: 'none',
      margin: '0px 3vw',
      cursor: 'pointer',
    },
    CardContent: {
      padding: 0,
      height: 'fitContent'
    },
    SeeAllBtn: {
      textTransform: 'none',
      color: '#FF6600'
    },
    SeeAllText: {

    },
    cardHeader: {
      paddingLeft: '1vw',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#8FBFFA",
      "flex": "none",
      "order": "0",
      "flexGrow": "0",
    },
    cardText: {
      paddingLeft: '1vw',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "22px",
      "lineHeight": "120%",
      "color": "#000000",
      "flex": "none",
      "order": "0",
      "flexGrow": "0",
    },
    newItemsWrapper: {
      display: "inline-flex",
      overflowX: "scroll",
      flexShrink: "0"
    },
    UrunDurumu:{
      display: 'flex' ,
      flexWrap: {xs: "no-wrap", md: "wrap"},
      
    }
  }

  //Hook comps
  const navigate = useNavigate();


  useEffect(() => {
    document.title = 'YapıRadar.com | ' + urunadi;
  }, [urunadi]);

  useEffect(() => {
    const GetItemData = async () => {
      const response = await getData(`Spot/DijitalFirmaSpotUrunById?seourl=${params.itemSeoUrl}`,);
      setItemid(response.data.value.id);
      setItemData(response.data.value);
      Seturunadi(response?.data?.value?.urunadi);
      Setmarka(response?.data?.value?.marka);
      Settanim(response?.data?.value?.tanim);
      Setkategori(response?.data?.value?.kategoriNavigation);
      Setuygulamaalani(response?.data?.value?.uygulamaalani);
      Setteknikozellikler(response?.data?.value?.teknikozellikler);
      Setkalitegaranti(response?.data?.value?.kalitegaranti);
      Setvideourl(response?.data?.value?.videourl);
      SetUrunGorselleri(response?.data?.value?.spotUrunGorselNavigation);
      setKategoridata(response?.data?.value.kategoriNavigation)
      setDefaultImageLink(`/images/SpotUrunler/${response.data.value?.spotUrunGorselNavigation?.[0]?.gorsel}`);
      if (response?.data?.value?.tanim.length > 400) {
        SetShowMoreButton(true);
      }
    }
    GetItemData()
      .catch(console.error);
  }, [])


  const handleTeklifAlClick = () => {
    const scrollElement = document.getElementById('teklifAlComp');
    scrollElement.scrollIntoView({
      behavior: 'smooth'
    });
  }

  const handlePaperShowImageClick = (e) => {
    setDefaultImageLink(`/images/SpotUrunler/${e}`);
  }


  const handleCardClick = (link) => {
    navigate(`/images/SpotUrunler/${link}`)
  }

  const handleNavigate = (link) => {
    window.location.href = link
  }

  const handleUrunGuncelleBtn = () => {
    navigate(`/SpotUrunler/UrunGuncelle/${itemid}`)
  }

  const handleUrunOnaylaIstekBtn = async () => {
    const res = await getData(`Spot/FirmaSpotUrunOnayIstek?dKurunId=${parseInt(itemid)}`);
    if (res.data.isSucceeded) {
      ShowMessage('success', 'Ürün Onay Istek İşlemi Başarı İle Tamamlandı.');
      window.location.reload();
    } else {
      ShowMessage('error', res.data.error.errorMessage);
    }
  }

  const handleUrunOnaylaBtn = async () => {
    const res = await getData(`Spot/FirmaSpotUrunOnay?dKurunId=${parseInt(itemid)}`);
    if (res.data.isSucceeded) {
      ShowMessage('success', 'Ürün Onaylama İşlemi Başarı İle Tamamlandı.');
      window.location.reload();
    } else {
      ShowMessage('error', res.data.error.errorMessage);
    }
  }

  const handleUrunAktifPasifBtn = async () => {
    const reqBody2 = {
      "id": parseInt(itemid),
      "aktif": itemData?.aktif === 0 ? 1 : 0,
      "dk_firma_spot_urunler_gorsel": [],
    }
    const res1 = await postData('Spot/FirmaSpotUrunInsertorUpdate', reqBody2)
    if (res1.data.isSucceeded) {
      ShowMessage('success', 'Ürün Onaylama İşlemi Başarı İle Tamamlandı.');
      window.location.reload();
    } else {
      ShowMessage('error', res1.data.error.errorMessage);
    }
  }

  const handleDeleteItem = async () => {
    const res1 = await postData('Spot/FirmaSpotUrunInsertorUpdate', { id: parseInt(itemid), isDelete: true })
    setopenDeleteItemModal(false)
    if (res1.data.isSucceeded) {
      ShowMessage('success', 'Ürün silme işlemi Tamamlandı')
      navigate(`/PazarYeri/Firma/${itemData?.firma?.seourl}`)
    } else {
      ShowMessage('error', 'Ürün silme işlemi sırasında bir hata oldu. Lütfen Yöneticinize başvurun')
    }
  }

  const [openDeleteItemModal, setopenDeleteItemModal] = React.useState(false);
  const handleDeleteItemModalOpen = () => setopenDeleteItemModal(true);
  const handleDeleteItemModalClose = () => setopenDeleteItemModal(false);


  const [newItemsData, SetNewItemsData] = useState([]);


  const handleShowMoreClick = (param) => {
    if (param) {
      document.getElementById('itemtanimDIV').style.height = 'auto'
      SetShowMoreButton(false);
    } else {
      document.getElementById('itemtanimDIV').style.height = '300px'
      SetShowMoreButton(true);
    }
  }


  useEffect(() => {
    const GetNewItemsData = async () => {
      const response = await postData('Spot/DijitalFirmaSpotUrunList', {
        "orderBy": {
          "key": "guncellemetarih",
          "value": false
        },
        "pageSize": 10,
        "currentPage": 0,
        "isPagingEnabled": true,
        "firmaids": [],
        "kategoriIds": []
      });
      SetNewItemsData(response.data.value.data);
    }
    if (kategoriData.length > 0) {
      GetNewItemsData();
    }
  }, [kategoriData])


  const ref = useRef();
  const handleSimilarItemsScrollRight = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  }

  const scrollElement = useRef(null)
  const scrollLeft = (val) => {
    scrollElement.current.scrollLeft += val
  }


  return (
    <>
      <NavbarSearchable />
      <Grid container spacing={0}>
        <Modal
          open={openDeleteItemModal}
          onClose={handleDeleteItemModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.deleteItemModal}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
              textTransform: 'none',
              "fontFamily": "'Poppins'",
              "fontStyle": "normal",
              "fontWeight": "600",
              "fontSize": "22px",
              "lineHeight": "120%",
              "flex": "none",
              "order": "0",
              "flexGrow": "0"
            }}>
              Ürün Sil
            </Typography>
            <Typography id="modal-modal-description" sx={{
              mt: 2,
              textTransform: 'none',
              "fontFamily": "'Poppins'",
              "fontStyle": "normal",
              "fontWeight": "600",
              "fontSize": "16px",
              "lineHeight": "120%",
              "flex": "none",
              "order": "0",
              "flexGrow": "0"
            }}>
              Onayınız sonrasında  {itemData?.urunadi} ürünü silinecektir
            </Typography>
            <Box sx={{
              marginTop: 3
            }}>
              <Button
                sx={{
                  marginLeft: '5px',
                  "padding": "15px 25px",
                  "gap": "10px",
                  "width": "150px",
                  "height": "50px",
                  "background": "red",
                  "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
                  "borderRadius": "14px",
                  ':hover': {
                    "background": "red",
                    "boxShadow": "none",
                    "borderRadius": "14px",
                  }
                }}
                onClick={handleDeleteItem}>
                <Typography sx={{
                  textTransform: 'none',
                  "fontFamily": "'Poppins'",
                  "fontStyle": "normal",
                  "fontWeight": "600",
                  "fontSize": "16px",
                  "lineHeight": "120%",
                  "color": "#FFFFFF",
                  "flex": "none",
                  "order": "0",
                  "flexGrow": "0"
                }}>
                  Ürünü Sil
                </Typography>
              </Button>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0
                }}
                onClick={handleDeleteItemModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Modal>
        <Grid align='center' item mb={5} mt={5} xl={12} md={12} xs={12}>
          <Box mb={1} sx={{
            display:
              UserData.FirmaId === itemData?.firmaid &&
                UserData.pazarYeriAdmin == true &&
                itemData?.onayli == 0 &&
                itemData?.onayistek == 1 ? '' : 'none'
          }}>
            <Paper
              sx={{
                width: '50%',
                height: '40px',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'red'
              }}
            >
              <Typography
                sx={{
                  "width": "100%",
                  "height": "auto",
                  "fontFamily": "'Poppins'",
                  "fontStyle": "normal",
                  "fontWeight": "400",
                  "fontSize": "auto",
                  "lineHeight": "130%",
                  "color": "#FFFFFF",
                  "border": "solid red 10px",
                }}
              >
                Ürününüz Şuan Yönetici Onayı Bekliyor.
              </Typography>
            </Paper>
          </Box>

          <Box mb={1} sx={{
            display:
              UserData.isAdmin &&
                itemData?.aktif == 0 ? '' : 'none'
          }}>
            <Paper
              sx={{
                width: '50%',
                height: 'auto',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'red'
              }}
            >
              <Typography
                sx={{
                  "width": "100%",
                "height": "auto",
                "fontFamily": "'Poppins'",
                "fontStyle": "normal",
                "fontWeight": "400",
                "fontSize": "auto",
                "lineHeight": "130%",
                "color": "#FFFFFF",
                "border": "solid red 10px",
                }}
              >
                Ürün Pasif
              </Typography>
            </Paper>
          </Box>

          <Box mb={1} sx={{
            display:
              UserData.isAdmin &&
                itemData?.onayli == 0 &&
                itemData?.onayistek == 1 ? '' : 'none'
          }}>
            <Paper
              sx={{
                width: '50%',
                height: 'auto',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'red'
              }}
            >
              <Typography
                sx={{
                  "width": "100%",
                  "height": "auto",
                  "fontFamily": "'Poppins'",
                  "fontStyle": "normal",
                  "fontWeight": "400",
                  "fontSize": "auto",
                  "lineHeight": "130%",
                  "color": "#FFFFFF",
                  "border": "solid red 10px",
                }}
              >
                Ürünü
                <Button
                  onClick={handleUrunOnaylaBtn}
                  sx={{ height: '19px', fontSize: 'auto', textTransform: 'none', fontWeight: '700', color: 'white' }}
                >
                  Buradan
                </Button>
                Onaylayabilirsiniz.
              </Typography>
            </Paper>
          </Box>

          <Box mb={1} sx={{
            display:
              user.FirmaId === itemData?.firmaid &&
                UserData.pazarYeriAdmin == true &&
                itemData?.onayli == 0 &&
                itemData?.onayistek == 0 ? '' : 'none'
          }}>
            <Paper
              sx={{
                width: '50%',
                height: 'auto',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'red'
              }}
            >
              <Typography
                sx={{
                  "width": "100%",
                  "height": "auto",
                  "fontFamily": "'Poppins'",
                  "fontStyle": "normal",
                  "fontWeight": "400",
                  "fontSize": "auto",
                  "lineHeight": "130%",
                  "color": "#FFFFFF",
                  "border": "solid red 10px",
                }}
              >
                Ürününüzün onaylanması için bilgilerinizi kontrol edip
                <Button
                  onClick={handleUrunOnaylaIstekBtn}
                  sx={{ height: '19px', fontSize: 'auto', textTransform: 'none', fontWeight: '700', color: 'white' }}
                >
                  Buradan
                </Button>
                onay isteyebilirsiniz.
              </Typography>
            </Paper>
          </Box>
        </Grid>

        <Grid container mt={5} mb={10} spacing={0}>
          <Grid align='center' item xl={6} md={6} xs={12}>
            <Box
              sx={{ height: '350px' }}
            >
              <img
                style={{
                  maxWidth: '550px',
                  maxHeight: '330px',
                }}
                src={`${defaultImageLink}`} alt='Yapiradar.com Urun' />
            </Box>
            <Box>
              <Box sx={{
                display: 'inline-flex'
              }}>
                {itemData?.spotUrunGorselNavigation?.map((item) => (
                  <Paper
                    key={item.id}
                    onClick={() => handlePaperShowImageClick(item.gorsel)}
                    sx={styles.ItemImageSmall}>
                    <img style={{
                      maxHeight: '80px',
                      maxWidth: '85px',
                      height: 'auto',
                      width: 'auto'
                    }} src={`/images/SpotUrunler/${item.gorsel}`} alt='Yapiradar.com Urun' />
                  </Paper>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} md={6} xs={12}>
            <Box>
              <img
                onClick={() => handleNavigate(`/PazarYeri/Firma/${itemData?.firma?.seourl}`)}
                style={{ maxHeight: '70px', "borderRadius": "30px 30px 0px 0px", cursor: 'pointer', }}
                src={itemData?.firma?.dk_firma_logos?.length > 0 ? `/images/Logo/${itemData?.firma?.dk_firma_logos?.[0]?.logo}` : NoFirmLogo} alt='Firma Logo' />
              <Link underline='none' href={`/PazarYeri/Firma/${itemData?.firma?.seourl}`}>
                <Typography
                  sx={styles.ItemDesc}>
                  {itemData?.firma?.firmaadikisa ? itemData?.firma?.firmaadikisa : itemData?.firma?.firmaadi}
                </Typography>
              </Link>
            </Box>

            <Box mt={3}>
              <Typography
                sx={styles.ItemBrand}>
                {itemData?.marka}
              </Typography>
            </Box>
            <Box>
              <Typography mt={2} sx={styles.ItemName}>
                {itemData?.urunadi}
              </Typography>
            </Box>
            <Box>
              <Typography mt={2} sx={styles.ItemPerakendePrice}>
                {itemData?.listeFiyat.toLocaleString()} {itemData?.listeFiyatMoneyType?.symbol}
              </Typography>
            </Box>
            <Box>
              <Typography mt={2} sx={styles.ItemPrice}>
                {itemData?.fiyat.toLocaleString()} {itemData?.moneyType?.symbol}
              </Typography>
            </Box>
            <Box>


            </Box>
            <Box  sx={styles.UrunDurumu}>
              <Grid item xl={6} md={6} xs={12}>
                <Grid sx={{ width: '100%', height: 'auto',flexWrap:{xs:"nowrap",md:"wrap"} }} align='center' container spacing={0}>
                  <Grid item xl={3} md={3} xs={12}>
                    <Paper sx={{ height: '100%', pl: 1, pr: 1, pt: 2, mr: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                        <PrecisionManufacturingIcon sx={{ fontSize: '40px' }} />
                      </Box>
                      <Typography mb={2.2} sx={styles.ItemIconsLabel}>
                        Urun Durumu
                      </Typography>
                      <Typography sx={styles.ItemIconsDesc}>
                        {itemData?.isUrunDurumu === 1 ? 'İkinci El' : 'Sıfır'}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xl={3} md={3} xs={12}>
                    <Paper sx={{ height: '100%', pl: 1, pr: 1, pt: 2, mr: 1, ml: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <LocalShippingIcon sx={{ fontSize: '40px' }} />
                      </Box>
                      <Typography sx={styles.ItemIconsLabel}>
                        Nakliye
                      </Typography>
                      <Typography sx={styles.ItemIconsDesc}>
                        {itemData?.isTurkiye_Sehir === 1 ? "Tüm Türkiye'ye " : ''}
                        {itemData?.isTurkiye_Sehir === 1 && itemData?.isNakliye === 1 ? 've' : ''}
                        {itemData?.isNakliye === 1 ? 'Depodan Direkt Teslim /' : ''}
                        {itemData?.isNakliyeFiyatDahil === 1 ? 'Ücretsiz Nakliye' : 'Ücretli Nakliye'}
                      </Typography>

                    </Paper>
                  </Grid>
                  <Grid item xl={3} md={3} xs={12}>
                    <Paper sx={{ height: '100%', pl: 1, pr: 1, pt: 2, mr: 1, ml: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CategoryIcon sx={{ fontSize: '40px' }} />
                      </Box>
                      <Typography mb={2.2} sx={styles.ItemIconsLabel}>
                        Stok
                      </Typography>
                      {itemData?.adet > 5 ? (
                        <Typography sx={styles.ItemIconsDesc}>
                          Toplam {itemData?.adet} Ürün
                        </Typography>
                      ) : (
                        <Typography sx={styles.ItemIconsDesc}>
                          Son {itemData?.adet} Ürün !
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xl={3} md={3} xs={12}>
                    <Paper sx={{ height: '100%', pl: 1, pr: 1, pt: 2, mr: 1, ml: 1 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <WorkspacePremiumIcon sx={{ fontSize: '40px' }} />
                      </Box>
                      <Typography sx={styles.ItemIconsLabel}>
                        Garanti Süresi
                      </Typography>
                      <Typography sx={styles.ItemIconsDesc}>
                        {itemData?.garanti}
                      </Typography>

                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Typography mt={2} sx={styles.ItemDesc}>
              <div
                id='itemtanimDIV'
                dangerouslySetInnerHTML={{ __html: itemData?.uygulamaalani }}
              />
            </Typography>
            <Box mt={5}>
              <Typography sx={styles.CategoryText}>
                Benzer Ürün Kategorileri
              </Typography>
              <Box mt={2} sx={styles.CategoriesListWrapper}>
                {kategoriData?.map((item) => (
                  <Typography key={item.id} sx={styles.CategoriesList}>
                    {item.adi}
                  </Typography>
                ))}
              </Box>
              <Box mt={5}>
                <Typography sx={styles.OfferText}>
                  {`*Aşağıdaki "Satın Al" butonuna tıklayarak firmaya ${itemData?.urunadi} ürünü için satın alma talebinizi iletebilirsiniz.`}
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Box>
             
                <Button
                  style={{
                    display:
                      UserData.isAdmin === true ||
                        (UserData.pazarYeriAdmin === true &&
                          UserData?.firmaId == itemData?.firma?.id)
                        ? '' : 'none'
                  }}
                  onClick={handleUrunGuncelleBtn}
                  sx={styles.ChangeItemSpecsBtn}>
                  <Typography sx={styles.GetOfferBtnText}>
                    Ürün Bilgilerini Güncelle
                  </Typography>
                </Button>
              </Box>

              <Box mt={2}>
                <Button
                  style={{
                    display:
                      UserData.isAdmin === true && (itemData?.onayli !== 0) ? '' : 'none'
                  }}
                  onClick={handleUrunAktifPasifBtn}
                  sx={styles.ChangeItemSpecsBtn}>
                  <Typography sx={styles.GetOfferBtnText}>
                    {itemData?.aktif == 0 ? 'Ürünü Aktife Al' : 'Ürünü Pasife Al'}
                  </Typography>
                </Button>

                <Button
                  style={{
                    display:
                      UserData.isAdmin === true ||
                        (UserData.pazarYeriAdmin === true &&
                          UserData?.firmaId == itemData?.firma?.id)
                        ? '' : 'none'
                  }}
                  onClick={handleDeleteItemModalOpen}
                  sx={styles.DeleteItemBtn}>
                  <Typography sx={styles.GetOfferBtnText}>
                    Ürünü Sil
                  </Typography>
                </Button>
              </Box>
              <Button
                  onClick={handleTeklifAlClick}
                  sx={styles.GetOfferBtn}>
                  <Typography sx={styles.GetOfferBtnText}>
                    Satın Al
                  </Typography>
                </Button>


              <Box>
                <Button
                  style={{
                    display:
                      UserData.FirmaId === itemData?.firmaid &&
                        itemData?.onayli === 0 &&
                        itemData?.onayistek == 0 &&
                        (UserData.pazarYeriAdmin === true &&
                          UserData?.FirmaId == itemData?.firma?.id)
                        ? '' : 'none'
                  }}
                  onClick={handleUrunOnaylaIstekBtn}
                  sx={styles.ChangeItemSpecsBtn}>
                  <Typography sx={styles.GetOfferBtnText}>
                    Ürün Onay İste
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          sx={{ backgroundColor: '#F4F7F9' }}
          align='center' pt={10} mt={5} pb={5} item xl={12} md={12} xs={12}>
          <Grid item xl={12} md={12} xs={12}>
            <Typography
              onClick={() => setItemDescriptionTab('Urun Aciklamalari')}
              sx={itemDescriptionTab === 'Urun Aciklamalari' ? styles.DescHeadersClicked : styles.DescHeaders}>
              Ürün Açıklamaları
            </Typography>
            <Box sx={{ display: itemData?.teknikozellikler == null ? 'none' : 'inline' }}>
              <Typography
                onClick={() => setItemDescriptionTab('Teknik Ozellikler')}
                sx={itemDescriptionTab === 'Teknik Ozellikler' ? styles.DescHeadersClicked : styles.DescHeaders}>
                Teknik Özellikler
              </Typography>
            </Box>

            <Box sx={{ display: itemData?.teknikozellikler == null ? 'none' : 'inline' }}>
              <Typography
                onClick={() => setItemDescriptionTab('Dosyalar')}
                sx={itemDescriptionTab === 'Dosyalar' ? styles.DescHeadersClicked : styles.DescHeaders}>
                Dosyalar
              </Typography>
            </Box>

            <Box sx={{ display: itemData?.videourl === null ? 'none' : 'inline' }}>
              <Typography
                onClick={() => setItemDescriptionTab('Videolar')}
                sx={itemDescriptionTab === 'Videolar' ? styles.DescHeadersClicked : styles.DescHeaders}>
                Videolar
              </Typography>
            </Box>
          </Grid>

          {/* ACIKLAMA TAB */}
          <Box key='1' sx={{
            display: itemDescriptionTab === 'Urun Aciklamalari' ? 'block' : 'none'
          }}>
            <Grid align='left' mt={10} p={3} item xl={12} md={12} xs={12}>
              <Typography sx={styles.DescHeader1Text}>
                <div
                  style={{ height: '100%' }}
                  dangerouslySetInnerHTML={{ __html: itemData?.tanim }}
                />
              </Typography>
            </Grid>
            <Grid align='left' mt={10} pr={30} pl={30} item xl={12} md={12} xs={12}
              sx={{ display: itemData?.kalitegaranti == null ? 'none' : 'block' }}
            >
              <Typography sx={styles.DescHeader1}>
                Kalite Garanti
              </Typography>

              <Typography mt={3} sx={styles.DescHeader1Text}>
                {itemData?.kalitegaranti}
              </Typography>
              {/* <Typography mt={3} mb={3} sx={styles.DescHeader1Text}>
            TSE Standartlarına göre üretilmiştir.
          </Typography>

          <img src={require(`../../Assets/Images/TSE.png`)} alt='malzeme item urunler' /> */}
            </Grid>
          </Box>

          {/* TEKNIK OZELLIK TAB */}
          <Box key='2' sx={{
            display: itemDescriptionTab === 'Teknik Ozellikler' ? 'block' : 'none'
          }}>
            <Grid align='left' mt={10} p={3} item xl={12} md={12} xs={12}>
              <Typography sx={styles.DescHeader1}>
                Teknik Özellikler
              </Typography>

              <Typography sx={styles.DescHeader1Text}>
                <div
                  style={{ height: '100%' }}
                  dangerouslySetInnerHTML={{ __html: itemData?.teknikozellikler }}
                />
              </Typography>
            </Grid>
          </Box>


          {/* Dosyalar TAB */}
          <Box key='3' sx={{
            display: itemDescriptionTab === 'Dosyalar' ? 'block' : 'none'
          }}>
            <Grid align='center' container spacing={0}>
              <Grid align='center' mt={10}  item xl={12} md={12} xs={12}>
                <Typography sx={{ "fontFamily": "'Poppins'","justifyContent":"space-evenly" ,"fontStyle": "normal", "fontWeight": "600", "fontSize": "20px", "lineHeight": "35px", "display": "flex", textAlign: 'center', "color": "#303C43" }}>
                  Kalite Belgeleri
                </Typography>
                {itemData?.urunDocumentNavigation?.map((item) => {
                  if (item.documentType === 0) {
                    return (
                      <Link underline='none' href={`/images/SpotUrunDocuments/${item.document}`}>
                        <Typography>
                          {item?.name}
                        </Typography>
                      </Link>
                    )
                  }
                })}
              </Grid>

              <Grid align='center' mt={10}  item xl={12} md={12} xs={12}>
                <Typography sx={styles.DescHeader1}>
                  Garanti Belgeleri
                </Typography>
                {itemData?.urunDocumentNavigation?.map((item) => {
                  if (item.documentType === 1) {
                    return (
                      <Link underline='none' href={`/images/SpotUrunDocuments/${item.document}`}>
                        <Typography>
                          {item?.name}
                        </Typography>
                      </Link>
                    )
                  }
                })}
              </Grid>



              <Grid align='center' mt={10} item xl={12} md={12} xs={12}>
                <Typography sx={styles.DescHeader1}>
                  Kataloglar
                </Typography>
                {itemData?.urunDocumentNavigation?.map((item) => {
                  if (item.documentType === 2) {
                    return (
                      <Link underline='none' href={`/images/SpotUrunDocuments/${item.document}`}>
                        <Typography>
                          {item?.name}
                        </Typography>
                      </Link>
                    )
                  }
                })}
              </Grid>



              <Grid align='center' mt={10}  item xl={12} md={12} xs={12}>
                <Typography sx={styles.DescHeader1}>
                  Sertifikalar
                </Typography>
                {itemData?.urunDocumentNavigation?.map((item) => {
                  if (item.documentType === 3) {
                    return (
                      <Link underline='none' href={`/images/SpotUrunDocuments/${item.document}`}>
                        <Typography>
                          {item?.name}
                        </Typography>
                      </Link>
                    )
                  }
                })}
              </Grid>
            </Grid>
          </Box>

          {/* Videolar TAB */}
          <Box key='4' sx={{
            display: itemDescriptionTab === 'Videolar' ? 'block' : 'none'
          }}>
            <Grid align='center' mt={10} p={{xs:'5',md:'15'}}  item xl={12} md={12} xs={12}>
              <Typography sx={styles.DescHeader1}>
                Videolar
              </Typography>

              <Typography sx={styles.DescHeader1Text}>
                {itemData?.videourl ? itemData?.videourl : 'Bu ürün için video bilgisi bulunamadı.'}
              </Typography>
            </Grid>
          </Box>

        </Grid>

        <Grid container mt={10} spacing={0}>
          <Grid pl={5} pr={5} pt={10} container spacing={0} sx={{ backgroundColor: '#F4F7F9' }}>
            <Grid item xl={6} md={6} xs={12}>
              <Typography sx={styles.Header11}>
                Benzer Spot Ürünler
              </Typography>
            </Grid>
            <Grid align='right' mt={5} item xl={6} md={6} xs={12}>
              <Button
                onClick={() => handleNavigate(`/PazarYeri/AramaSonucu?Kategori=${itemData?.kategoriNavigation[0]?.parent?.parentid},AltKategori=${itemData?.kategoriNavigation[0]?.parent?.id},KategoriDetay=${itemData?.kategoriNavigation[0]?.id},AramaTuru=Urunler`)}
                sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                <Typography sx={styles.SeeAllText}>
                  {itemData?.kategoriNavigation[0]?.adi} Kategorisindeki Diğer Ürünler
                </Typography>
              </Button>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center' }} item xl={0.5} md={0.5} xs={0.5}>
              <Box >
                <IconButton>
                  <ArrowBackIcon onClick={() => { scrollLeft(-450) }} />
                </IconButton>
              </Box>
            </Grid>
            <Grid ref={scrollElement} mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={11} md={11} xs={11}>
              <Box pb={3} mb={2} sx={styles.newItemsWrapper}>
                {newItemsData?.map((item) => (
                  <SpotUrunlerItemCard props={item} />
                ))}
              </Box>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center',padding:'0',color:'rgb(255 102 0)' }} item xl={0.5} md={0.5} xs={0.5}>
              <Box >
                <IconButton>
                  <ArrowForward onClick={() => { scrollLeft(+450) }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TryForFree />
      <Footer />
    </>
  )
}

export default SpotUrunDetay