import { Grid, Typography, Container } from '@mui/material'
import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';

function KVKK() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | KVKK';
    }, []);

    const styles = {
        Header1: {
            width: '100%',
            fontFamily: "'Gilroy'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "50px",
            lineHeight: "120%",
            color: "#000000",
            textAlign: 'center',
            marginBottom: '1em'
        },
        Header2: {
            margin: '2em 0',
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "25px",
            lineHeight: "35px",
            display: "flex",
            alignItems: "center"
        },
        Header3: {
            margin: '2em 0',
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "35px",
            display: "flex",
            alignItems: "center",
            color: "#303C43"
        },
        Text: {
            textAlign: "justify",
            margin: '2em 0',
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "26px",
            color: "#303C43"
        }
    };

    return (
        <>
            <Navbar />
            <Container sx={{ background: '#F4F7F9', mt:12, paddingTop: '5em', paddingBottom: '5em' }} maxWidth="lg">
                <Typography sx={styles.Header1}>
                    Kişisel Verileri İşleme, Saklama ve İmha Politikası
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography sx={styles.Header3}>
                            Yapıradar Kişisel Verileri İşleme, Saklama ve İmha Prosedürü
                        </Typography>
                        <div id="sss">
                        <div class="sikcasorulansorular">
                                <div class="accordion panel-group" id="accordion">
                                    <h4>
                                        KİŞİSEL VERİLERİ İŞLEME,
                                        SAKLAMA VE İMHA POLİTİKASI
                                    </h4>
                                    <strong>
                                        BÖLÜM 1 - KİŞİSEL VERİ İŞLEME POLİTİKASI GİRİŞ
                                    </strong>
                                    <h4>
                                        1.1 AMAÇ
                                    </h4>
                                    <p>
                                        Bu Politika, Yapı Radar A.Ş. (“Yapı Radar” veya “Şirket, Firma” ) tarafından
                                        gerçekleştirilmekte olan kişisel verileri işleme, saklama ve imha
                                        faaliyetlerinden kaynaklanan veri sorumlusu sıfatına ilişkin iş ve işlemler ile
                                        çalışanların çalışırken ellerine geçen ya da bilgileri dahline giren tüm Kişisel
                                        Verileri saklanması, işlenmesi yahut imha edilmesine dair süreç ve
                                        yöntemleri açık bir şekilde ortaya koymak için hazırlanmıştır. Bu politika,
                                        6698 sayılı Kişisel Verileri Koruma Kanunu başta olmak üzere hukuki
                                        dayanağını ondan alan ikincil mevzuat ile Kişisel Verileri Koruma Kurumu’nun
                                        almış olduğu kararlara uygun olarak çıkartılmıştır. Bu politikanın amacı, Yapı
                                        Radar’ın 7 Nisan 2016 tarihli ve 29677 sayılı Resmi Gazete’de yayımlanmış
                                        olan Kişisel Verilerin Korunması Kanunu’na (“KVKK”) uyumlu bir şekilde
                                        işlenmesini ve korunmasını sağlamak için izlenecek yöntem ve ilkeleri
                                        düzenleyerek kamuya açıklamaktadır.
                                    </p>
                                    <h4>1.2 KAPSAM</h4>
                                    <p>
                                        Bu Politika, görevleri kapsamında Kişisel Verileri tamamen veya kısmen
                                        otomatik yol ve araçlarla işleyen ya da bir kayıt sisteminin bir parçasını
                                        oluşturan ya da bir kayıt sisteminin bir parçasını oluşturması amaçlanan
                                        Kişisel Verileri (otomatik yol ve araçlar dışında) başka yol ve araçlarla işleyen
                                        tüm çalışanlarımıza; Yapı Radar çalışanları, çalışan adayları, hizmet
                                        sağlayıcıları, ziyaretçiler, Yapı Radar adayları ve diğer üçüncü kişilere ait
                                        kişisel verilerin işlenmesi faaliyetlerine ilişkin olarak uygulanır.
                                    </p>
                                    <h4>1.3 TANIMLAR</h4>
                                    <table class="table mt-3">
                                        <tr>
                                            <td>Kişisel Veri</td>
                                            <td>
                                                İsim, adres, telefon numarası,
                                                e-posta adresi veya benzeri kimlik bilgileri gibi Veri Süjesiyle ilgili her türlü
                                                bilgi anlamına gelir.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Kişisel Verilerin İşlenmesi</td>
                                            <td>
                                                Kişisel verilerin tamamen veya
                                                kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak
                                                kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması,
                                                muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması,
                                                aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya
                                                da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü
                                                işlem
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Kişisel Sağlık Verisi</td>
                                            <td>
                                                Kimliği belirli ya da belirlenebilir
                                                gerçek kişinin fiziksel ve ruhsal sağlığına ilişkin her türlü bilgi ile kişiye
                                                sunulan sağlık hizmetiyle ilgili bilgiler.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Alıcı Grubu</td>
                                            <td>
                                                Veri sorumlusu tarafından kişisel
                                                verilerin aktarıldığı gerçek veya tüzel kişi kategorisi.
                                                Açık Rıza : Belirli bir konuya ilişkin,
                                                bilgilendirmeye dayanan ve özgür iradeyle açıklanan rıza
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Anonim Hale Getirme</td>
                                            <td>
                                                Kişisel verilerin, başka verilerle
                                                eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek
                                                kişiyle ilişkilendirilemeyecek hale getirilmesi.
                                                Özel Nitelikli Kişisel Veriler : Bir kişinin ırkı, etnik kökeni, siyasi
                                                düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti,
                                                dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti
                                                ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik veriler
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>VERBİS</td>
                                            <td>
                                                Veri sorumluları (Yapı Radar) veri sicil
                                                bilgi sistemi
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Veri İrtibat Kişisi</td>
                                            <td>
                                                Kişisel verileri koruma kurumu
                                                ile kurulacak iletişim için Yapı Radar Yönetim Kurulu tarafından VERBİS’e
                                                kayıt esnasından bildirilen gerçek kişi.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Veri İşleyen</td>
                                            <td>
                                                Veri sorumlusunun verdiği yetkiye
                                                dayanarak onun adına kişisel veri işleyen gerçek veya tüzel kişi.
                                                Veri Süjesi ya da İlgili Kişi : Verinin ait olduğu, kimliği
                                                belirlenmiş veya belirlenebilir gerçek kişi.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Çalışan</td>
                                            <td>
                                                Bir iş Sözleşmesine dayanarak Yapı
                                                Radar’da istihdam edilen kişiler
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Çalışan adayı</td>
                                            <td>
                                                Yapı Radar’e iş başvurusunda
                                                bulunarak veya herhangi bir yolla özgeçmişini ve ilgili bilgilerini Yapı Radar’e
                                                erişilebilir kılan gerçek kişiler
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Müşteri/ Müteahhit Adayı</td>
                                            <td>
                                                Herhangi bir sözleşmesel ilişki
                                                olup olmadığına bakılmaksızın Yapı Radar tarafından yürütülen faaliyetler
                                                kapsamında iş ilişkileri dolayısıyla kişisel verileri elde edilen gerçek kişiler.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Ziyaretçiler</td>
                                            <td>
                                                Yapı Radar’ın fiziksel
                                                tesislerine çeşitli amaçlarla girmiş olan veya internet sitelerini ziyaret eden
                                                gerçek kişiler.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                İmha
                                            </td>
                                            <td>
                                                Kişisel verilerin silinmesi, yok edilmesi
                                                veya anonim hale getirilmesi
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Periyodik İmha</td>
                                            <td>
                                                Kanunda yer alan kişisel verilerin
                                                işlenme şartlarının tamamının ortadan kalkması durumunda kişisel verileri
                                                saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla re’sen
                                                gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Üçüncü Kişiler</td>
                                            <td>
                                                Prosedürde tanımlanmamış olmasına
                                                rağmen işbu Prosedür çerçevesinde kişisel verileri işlenen tedarikçi, mağdur,
                                                aile bireyleri vb. dâhil fakat bunlarla sınırlı olmamak üzere diğer gerçek
                                                kişiler.
                                            </td>
                                        </tr>
                                    </table>
                                    <h4>
                                        BÖLÜM 2- KİŞİSEL VERİLERİN İŞLENMESİ VE
                                        SAKLANMASI HUSUSLARI
                                    </h4>
                                    <p>
                                        Yapı Radar kişisel verileri işlerken kanunlar başta olmak üzere işin
                                        gereklerinden kaynaklı olarak aşağıda belirtilen hususlara uygun olarak
                                        kişisel veri almaktadır:
                                    </p>
                                    <ul>
                                        <li>Genel ilkelere,</li>
                                        <li>Kişisel Veri İşleme Şartlarına,</li>
                                        <li>Özel Nitelikli Kişisel Verilere uygun olarak hareket eder.</li>
                                    </ul>
                                    <p>
                                        Bu sebeplere uygun olmadan kişisel veri alınamaz. Bu şekilde kişisel veri
                                        işlendiği farkedildiği durumlarda veri imha edilir. Aşağıda işleme hususlarını
                                        ayrıntılı olarak inceleyeceğiz.
                                    </p>
                                    <h4>
                                        2.1. GENEL İLKELERE UYGUNLUK
                                    </h4>
                                    <strong>
                                        a. Hukuka ve Dürüstlük Kuralına Uygun İşleme
                                    </strong>
                                    <p>
                                        Yapı Radar, kişisel verilerin işlenmesinde de hukuksal düzenlemelerle
                                        getirilen ilkeler ile işlenen verinin tabi bulunduğu genel güven ve dürüstlük
                                        kuralına uygun hareket eder. Yapı Radar dürüstlük kuralı gereğince kişisel
                                        verileri işlerken ilgili kişinin çıkarlarını göz önünde bulundurur. Kişisel verinin
                                        eline geçtiği ilk andan verinin imhası sürecine kadar Yapı Radar verisi işlenen
                                        kişi aleyhine hareket etmez. İlgili kişinin verisini işlerken belirtmediği, ilgili
                                        kişinin öngöremeyeceği şekilde bir veri işleme yahut aktarım faaliyetinde
                                        bulunamaz. Hukuk ve dürüstlük kurallarına uygun yapılan veri işleme faaliyeti
                                        açık, belirli ve şeffaftır.
                                    </p>
                                    <strong>
                                        b. Kişisel Verilerin Doğru ve Gerektiğinde Güncel Olmasını Sağlama
                                    </strong>
                                    <p>
                                        Yapı Radar, kişisel verilerin işlendiği süre boyunca doğru ve güncel olması
                                        için gerekli önlemleri almakta ve belirli sürelerle kişisel verilerin doğruluğunun
                                        ve güncelliğinin sağlanmasına ilişkin gerekli mekanizmaları kurmaktadır.
                                        Güncellenecek veriler söz konusu olduğunda Yapı Radar’te bulunan verilerin
                                        güncelliğini kaybetmemiş veriler olması önemlidir. Yapı Radar, kişisel
                                        verilerin yanlış yahut eksik tutulmasından kaynaklı olarak kişilerin
                                        uğrayabileceği zararların farkındadır. Bu sebeple oluşturulmuş sistemle Yapı
                                        Radar ilgili kişilerin verilerini 1 yıl aralıklarla günceller. 1 yıl aralıklarla
                                        güncellenmeyen veriler bakımından artık güncel veri niteliğinde
                                        sayılamayacağından dolayı güncel veri hükümleri uygulanmaz. Veri
                                        gncellemesi yalep eden ilgili kişinin talebi en kısa süre içinde güncellenir. İlgili
                                        kişi tekrar başvurarak verilerinin güncellenmiş haline erişebilir.
                                    </p>
                                    <strong>
                                        c. Kişisel Verilerin Belirli, Açık ve Meşru Amaçlarla İşlenmesi
                                    </strong>
                                    <p>
                                        Yapı Radar kişisel verileri işlerken bir amaç belirler. Bu amaç açık, net ve
                                        kesindir. Yapı Radar’in ilgili verilerini sadece işleme amacı değil, elde etme,
                                        toplama amacı da meşru, belirli, açık ve nettir. Buradan anlaşılan şudur ki;
                                        Yapı Radar verileri işlerken faaliyet konusuna uyumlu amaçlarla bu verileri
                                        işleyecektir. Amaçlar faaliyet çerçevesinden ayrık vaziyette yahut alakasız
                                        değildir. Belirlenen bu amaçlar İnternet Sitemizde bulunan Aydınlatma
                                        Metninde açıklamalı olarak bulunmaktadır. Bir veri ile toplama amacından
                                        apayrı başka konuyla ilgili yeni bir amaç söz konusu olduğu durumlarda ilgili
                                        kişinin açık rızasının alınması gereken durumlardan olması şartıyla, ilgili
                                        kişiden yeniden açık rıza alınır.
                                    </p>
                                    <strong>
                                        d. Kişisel Verilerin İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olması
                                    </strong>
                                    <p>
                                        Yapı Radar, kişisel verileri alırken belirlenen amaca hizmet edecek nitelikte
                                        olan ve bu amaçla alınabilecek en az veriyi işlemektedir. İşlenen kişisel
                                        veriler yeterli, ilgili ve işlenme amaçlarıyla sınırlı olarak işlenmektedir. Alınan
                                        kişisel veriler ile veri alma aracı arasında bağlantı bulunmaktadır. Enetk
                                        aldığı kişisel verileri açıkça göstermediği amaçlar haricinde işleyemez yahut
                                        kullanamaz. Sonradan ortaya çıkan yahut belirlenen amaçla uyumlu olmayan
                                        yeni bir veri işleme amacının sonradan ortaya çıkması durumunda Yapı
                                        Radar verilerin ilk defa toplanması sırasında sağlanması gereken şartlar yeni
                                        amaçlar için de tekrar sağlanmaktadır. Ayrıca Yapı Radar elde ettiği kişisel
                                        verileri Kanun düzenlemeleri saklı kalmak kaydıyla sözleşmenin şekline göre
                                        belirler. Yapı Radar öncelikle ilgili mevzuatta kişisel verilerin saklanması için
                                        bir süre öngörülüp öngörülmediğini tespit etmekte, bir süre belirlenmişse bu
                                        süreye uygun davranmaktadır. Yasal bir süre mevcut değil ise kişisel veriler
                                        işlendikleri amaç için gerekli olan süre kadar saklanmaktadır. Kişisel veriler
                                        belirlenen saklama sürelerinin sonunda periyodik imha sürelerine veya veri
                                        sahibi başvurusuna uygun olarak ve belirlenen imha yöntemleri (silme
                                        ve/veya yok etme ve/veya anonimleştirme) ile imha edilmektedir.
                                    </p>
                                    <h4>
                                        2.2. KİŞİSEL VERİ İŞLEME ŞARTLARINA UYGUNLUK
                                    </h4>
                                    <p>
                                        Kişisel verilerin işlenebilmesi için Kanun tarafından bazı koşulların varlığı
                                        aranmaktadır. Kanunun 5 ve 6. Maddelerinde sayılan bu işleme koşulları, veri
                                        koruma hukukunun esasını oluşturan kişisel verilerin işlenmesi yasağının
                                        istisnaları, işlemeyi hukuka uygun hale getiren sebeplerdir. Kişisel verileri
                                        işleme sebepleri kanunda sınırlı sayıda sayılmış olup Yapı Radar bu amaçlar
                                        dışında başka amaçla verileri işlemez.
                                    </p>
                                    <strong>
                                        a. Kişisel Veri Sahibinin Açık Rızasının Bulunması
                                    </strong>
                                    <p>
                                        Kişisel veri işlenmesinin şartlarından biri ilgili kişi tarafından verilmiş açık rıza
                                        beyanıdır. Bu beyanı Yapı Radar meşru amaçlarla alır. İlgili kişiden açık rıza
                                        alınabilmesi için ilk olarak kişiye aydınlatma yapılarak Yapı Radar’e vereceği
                                        veri için aydınlatılır. Aydınlatma beyanında da yer alan amaçlarla sınırlı
                                        olarak belirli bir konuya ilişkin ilgili kişiden veriler alınabilir.
                                        Açık rıza haricinde kanunun açık rızaya gerek olmaksızın işlenebileceği
                                        durumlar kanunda sayılmıştır.
                                    </p>
                                    <strong>
                                        b. Kanunlarda Açıkça Öngörülmesi
                                    </strong>
                                    <p>
                                        Yapı Radar işlenmesi kamu yararı için gerçekleştirilen bir görevin ifası için
                                        zorunlu olması veya veri sorumlusunun resmi yetkisini kullanması
                                        bağlamında gerekli olması durumunda ilgili kişinin açık rızası olmaksızın veri

                                        işleme faaliyetinde bulunabilmektedir. Yapı Radar bu şekilde şekli anlamda
                                        kanun maddelerinin zorunlu kıldığı ölçülerde veri işler. Örneğin Yapı Radar;
                                        Türk Ticaret Kanunu, Sosyal Güvenlik Kanunu, Borçlar Kanunu kapsamında
                                        tutulması zorunlu olarak gösterilen verileri tutmak için açık rıza almaz. Kanun
                                        kapsamında emredici hükümlere göre aydınlatma yapar ve verileri işler. Bu
                                        sebeplerle işlenen veriler için KVKK gereğince ilgili kişinin verinin silinmesini
                                        ya da imhasını talep etme hakkı yoktur.
                                    </p>
                                    <strong>
                                        c. Fiili İmkansızlık Sebebiyle İlgilinin Açık Rızasının Alınamaması
                                    </strong>
                                    <p>
                                        Rızasına hukuki olarak geçerlilik tanımayan kişilerle, fiili imkansızlık
                                        nedeniyle açık rızasının alınamayacağı kişilerde uygulanmaktadır. İlgili kişinin
                                        veya üçüncü kişinin hayatı yahut beden bütünlüğünün korunması zorunlu
                                        olan hallerde bu işleme faaliyeti sağlık verileri hariç yapılmaktadır.
                                    </p>
                                    <strong>
                                        d. Sözleşmenin Kurulması veya İfasıyla Doğrudan İlgi Olması
                                    </strong>
                                    <p>
                                        Yapı Radar ile ilgili kişi arasında yapılmış olan mevcut ve geçerli bir
                                        sözleşmenin ifası ya da yeni bir sözleşmenin kurulmasına yönelik zorunlu
                                        işlemler çerçevesinde kişisel veri işleyebilir. Genel olarak sözleşmelerde veri
                                        alımı sözleşmenin mütemmim cüzü niteliğindedir. Bu sebeple sözlemenin
                                        kurulması için kişisel verileri işleme zorunlu mahiyette olduğundan Yapı
                                        Radar açık rıza aranmaksızın sözleşme yaptığı kişilerin sözleşmeyle alakalı
                                        olan verilerini işlemektedir. Kişisel verilerin alınacağı sözleşmede yer almasa
                                        dahi sözleşmenin ifası için doğrudan doğruya gerekli olan kişisel veriler
                                        işlenecektir. Yalnızca sözleşmenin esaslı unsurları değil tali unsurları
                                        bakımından olan kişisel veriler de bu kapsamda değerlendirilir. Örneğin Yapı
                                        Radar ile yapılan satış sözleşmesinde, sözleşmenin gereklerine göre Yapı
                                        Radar kişisel verileri işler.
                                    </p>
                                    <strong>
                                        e. Şirketin Hukuki Yükümlülüğünü Yerine Getirmesi
                                    </strong>
                                    <p>
                                        Kanunen öngörülmüş olma ve sözleşmenin ifası için gerekli olma halleri saklı
                                        kalmak üzere Yapı Radar hukuki yükümlülüklerini yerine getirmek amacıyla
                                        kişilerin verisini açıkça rıza almaksızın işleyebilmektedir. Yapı Radar
                                        kanunlar dışındaki hukuki düzenlemelerden, mahkeme kararlarından ve
                                        usulünce verilmiş resmi makam talimatlarından doğan hukuki
                                        yükümlülüklerden kaynaklı veri işleme faaliyetini bu kapsamda açık rıza
                                        almaya gerek kalmaksızın gerçekleştirir. Cumhurbaşkanı kararnamesi,
                                        yönetmelik, tebliğ v.b. gibi adli ve idari merciler tarafından çıkartılan
                                        düzenleyici işlemler, mahkemeye güvenlik kamerası yahut parmak izi
                                        bilgilerinin ibrazı, belli kurumlar tarafından istenen bilgilerin belirtilen veri
                                        tabanına kaydedilmesi gibi
                                        Yapı Radar veri işlemeye yönelik bir hukuki zorunluluk olmadıkça hukuki
                                        yükümlülük için veri işlemez.
                                    </p>
                                    <strong>
                                        f. Kişisel Veri Sahibinin Kişisel Verisini Alenileştirmesi
                                    </strong>
                                    <p>

                                        İlgili kişinin kendisi tarafından alenileştirilen, bir başka ifadeyle herhangi bir
                                        şekilde kamuoyuna açıklanmış olan kişisel veriler Yapı Radar tarafından açık
                                        rıza olm aksızın işlenebilecektir. Fakat h
                                        er halükarda verisini alenileştirmiş olan ilgili kişinin verileri alenişleştirme
                                        sebebinden başkaca bir şekilde Yapı Radar tarafından işlenmez. Örneğin
                                        Yapı Radar düzenlemiş olduğu eğitim yahut her türlü sosyal faaliyet gibi
                                        durumlarda katılımcıların fotoğraflarının çekilmesi için örneğin fotoğraf
                                        çekilen yere gitmiş olması durumunda fotoğrafları alenişleştirdiği kabul
                                        edilecek ve bu şekilde açık rıza aranmaksızın Yapı Radar kişisel verileri
                                        işleyebilecektir.
                                    </p>
                                    <strong>
                                        g. Bir Hakkın Tesisi veya Korunması için Veri İşlemenin Zorunlu Olması
                                    </strong>
                                    <p>
                                        Yapı Radar hukuken korunan bir hakkın kurulması, korunması veya
                                        kullanılması için zorunluluk arz eden verileri işlerken de ilgili kişiden açık rıza
                                        almamaktadır. Bu bakımdan yalnız ilgili kişilerin hakları değil üçünü kişilerin
                                        de haklarına ilişkin bu gibi hakların korunması için de veriler saklanabilir.
                                        Örneğin Yapı Radar iş ilişkisi bittikten sonra dava zamanaşımı boyunca işten
                                        ayrılan personelin, ilerleyen zamanlarda örneğin dava açılması durumunda
                                        kullanılmak üzere verilerini saklayabilmektedir.
                                        h. Şirketimizin Meşru Menfaati için Veri İşlemenin Zorunlu Olması
                                        Yapı Radar, meşru menfaatleri için ilgili kişinin kişisel verilerini işlemek
                                        zorunda olduğu hallerde ilgili kişinin temel hak ve özgürlüklerine zarar
                                        vermemek şartıyla işleme faaliyetinde bulunabilir. Bu Yapı Radar için sınırsız
                                        bir yetki değil meşru menfaat ve çıkarların ağır bastığı durumlarda
                                        uygulanacak bir yetkidir. Belirli, açık ve meşru olan çıkar ve menfaatler için
                                        bu hüküm uygulama alanı bulacaktır.
                                    </p>
                                    <h4>
                                        2.3. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENMESİ
                                    </h4>
                                    <p>
                                        Yapı Radar kişilerin özel nitelikli kişisel verilerini kanunda sayılan
                                        haller ayrı olmak üzere açık rıza almakla işleyebilmektedir. Özel Nitelikli
                                        Kişisel Veriler yalnızca ilgili kişinin Açık Rızasının bulunması yahut, cinsel
                                        hayat ve kişisel sağlık verileri dışındaki Özel Nitelikli Kişisel Veriler
                                        bakımından kanunlarda açıkça işlemenin zorunlu tutulması halinde işlenebilir.
                                        Sağlık ve cinsel hayata ilişkin kişisel veriler, ancak kamu sağlığının
                                        korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin
                                        yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi
                                        amacıyla, sır saklama yükümlülüğü altında bulunan kişiler (örn: Şirket hekimi)
                                        veya yetkili kurum ve kuruluşlar tarafından açık rıza almaksızın işlenebilir.
                                        Özel nitelikli kişisel verilerin işlenmesi ve saklanması bakımından Kurum
                                        tarafından öngörülen idari ve teknik tedbirlerin tümü alınır. Şirket, Özel

                                        Nitelikli Kişisel Verilerin işlenmesi süreçlerinde yer alan çalışanlara yönelik
                                        gerekli eğitimleri vermekte, gizlilik sözleşmesi yapmakta, bilgi güvenliği
                                        taahütnameleriyle bu konuda çalışan personele bilgilendirme yapılmaktadır.
                                        Özel nitelikli kişisel verilerin işlenmesine ilişkin olarak yetki matrisleri oldukça
                                        azaltılmıştır. Yalnızca özel nitelikli kişisel veri ile yaptığı iş doğrudan ilgili olan
                                        personelin ilgili verilere erişim imkanı bulunmaktadır. Periyodik imha
                                        yöntemiyle saklama süresi sona ermiş özel nitelikli kişisel veriler imha
                                        edilmektedir. Bu önlemler detaylı olarak bu politikanın devamında yer alan
                                        … bölümünde açıklanmıştır.
                                    </p>
                                    <h4>
                                        2.4. KİŞİSEL VERİLERİN AKTARILMASI
                                    </h4>
                                    <p>
                                        Kişisel verilerin üçüncü kişilere aktarılması da bir kişisel veri işleme
                                        faaliyetidir. Yapı Radar kişilerin kişisel verilerini açık rıza olmaksızın
                                        aktarmaz. Yapılacak aktarım bakımından kanunda gösterilen genel ve özel
                                        nitelikli kişisel verileri işleme kurallarına uygun olarak bu aktarımı
                                        gerçekleştirir. Şirketimiz gerekli hallerde kişisel verileri 3. Kişilere
                                        aktarabilmektedir.
                                    </p>
                                    <p>
                                        Kişisel veri sahibinin açık rızası olmasa dahi aşağıda belirtilen şartlardan bir
                                        ya da birkaçının mevcut olması halinde Şirketimiz tarafından gerekli özen
                                        gösterilerek ve Kurul tarafından öngörülen yöntemler de dahil gerekli tüm
                                        güvenlik önlemleri alınarak kişisel veriler üçüncü kişilere aktarılabilecektir.
                                    </p>
                                    <ul>
                                        <li>
                                            Kişisel verilerin aktarılmasına ilişkin ilgili faaliyetlerin kanunlarda açıkça
                                            öngörülmesi,
                                        </li>
                                        <li>
                                            Kişisel verilerin Şirket tarafından aktarılmasının bir sözleşmenin kurulması
                                            veya ifasıyla doğrudan doğruya ilgili ve gerekli olması,
                                        </li>
                                        <li>
                                            Kişisel verilerin aktarılmasının Şirketimizin hukuki yükümlülüğünü yerine
                                            getirebilmesi için zorunlu olması,
                                        </li>
                                        <li>
                                            Kişisel verilerin veri sahibi tarafından alenileştirilmiş olması şartıyla,
                                            alenileştirme amacıyla sınırlı bir şekilde Şirketimiz tarafından aktarılması,
                                        </li>
                                        <li>
                                            Kişisel verilerin Şirket tarafından aktarılmasının Şirket’in veya veri sahibinin
                                            veya üçüncü kişilerin haklarının tesisi, kullanılması veya korunması için
                                            zorunlu olması,
                                        </li>
                                        <li>
                                            Veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla Şirket
                                            meşru menfaatleri için kişisel veri aktarımı faaliyetinde bulunulmasının
                                            zorunlu olması,
                                        </li>
                                        <li>
                                            Fiili imkansızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya
                                            rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının
                                            hayatı veya beden bütünlüğünü koruması için zorunlu olmasıdır.
                                        </li>
                                    </ul>
                                    <h4>
                                        2.5. İLGİLİ KİŞİLERİN AYDINLATILMASI
                                    </h4>
                                    <p>
                                        Yapı Radar, Kanun’un 10. maddesine ve ikincil mevzuata uygun olarak,
                                        kişisel veri sahiplerini aydınlatmaktadır. Bu kapsamda Yapı Radar
                                        aydınlatmada, kişisel verilerin veri sorumlusu olarak kim tarafından, hangi

                                        amaçlarla işlendiği, hangi amaçlarla kimlerle paylaşıldığı, hangi yöntemlerle
                                        toplandığı ve hukuki sebebi ve veri sahiplerinin kişisel verilerinin işlenmesi
                                        kapsamında sahip olduğu hakları konusunda ilgili kişileri bilgilendirmektedir.
                                    </p>
                                    <h4>
                                        BÖLÜM 3- KİŞİSEL VERİLERİN KORUNMASI İÇİN ALINAN
                                    </h4>
                                    <strong>TEDBİRLER</strong>
                                    <p>
                                        Yapı Radar kendi alanıyla ilgili işleriyle olduğu kadar kişisel verilerin
                                        işlenmesi hususunda da önem göstermektedir. Kişisel veri işlemenin en
                                        önemli hususu işlenen verinin saklanması ve korunmasıdır. Yapı Radar özel
                                        ve genel nitelikli kişisel veriler bakımından kanundan kaynaklanan koruma
                                        gereklerine uygun olarak tedbir almaktadır. Yapı Radar bu tedbirleri belirli bir
                                        veriye ilişkin olarak saklamayıp tüm verilerin korunması ve saklanması
                                        bakımından koruma tedbirlerini almaktadır.
                                        İlk olarak kişisel veriler için alınan en sağlam tedbir gerekli olmayan veriyi
                                        elde tutmamaya ilişkindir. Yapı Radar teknoloji ve bilimsel gelişme seviyesine
                                        uygun olarak işleme faaliyetinin doğası, önemi, kapsamı, bağlam ve amacını
                                        tespit ederek elinde bulunan kişisel verisi bulunan gerçek kişilerin hak ve
                                        özgürlükleri açısından çeşitli olasılık ve ciddiyetlere sahip risklerin olup
                                        olmadığını tespit ederek buna göre koruma tedbiri alır ve riskleri saptar.
                                        Yapı Radar kişisel verilerin hukuka aykırı olarak işlenmesi, erişilmesini
                                        önlemek kişisel verilerin muhafazasını gerektiği gibi sağlamak amacıyla her
                                        türlü teknik ve idari tedbiri alır. Kişisel verilerin imhası, alınan tedbirler ve
                                        korunması bakımından veri envanterine işlenmiş verilerin açıklanmış halidir.
                                        Aşağıda da belirtileceği üzere Yapı Radar’in özel nitelikli kişisel veriler için
                                        daha kapsamlı ve daha geniş güvenlik önlemlerini içeren tedbirleri
                                        bulunmaktadır.
                                    </p>
                                    <h4>
                                        3.1 ORTAMLAR VE GÜVENLİK TEDBİRLERİ
                                    </h4>
                                    <p>
                                        Yapı Radar tarafından alınan kişisel veriler uygun ortamlarda işlenir ve
                                        saklanır. Kişisel verilerin saklanması için kullanılan kayıt ortamları genel
                                        itibariyle matbu ortamlar, yerel dijital ortamlar ortamlardır.
                                    </p>
                                    <table>
                                        <tr>
                                            <td>Matbu Ortamlar</td>
                                            <td>
                                                Verilerin kağıt ya da mikrofilmler üzerine
                                                basılarak tutulduğu ortamlardır.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Yerel Dijital Ortamlar</td>
                                            <td>
                                                Şirket bünyesinde yer alan otomasyon sistemleri,
                                                kayıt sistemleri, sunucular, sabit ya da taşınabilir diskler, optik diskler, bulut
                                                gibi sair dijital ortamlardır.
                                            </td>
                                        </tr>
                                    </table>
                                    <p>
                                        Yapı Radar, kişisel verilerin güvenli bir şekilde saklanması ile hukuka aykırı
                                        olarak işlenmesi ve erişilmesinin önlenmesi için ilgili kişisel veri ile tutulduğu
                                        ortamın niteliklerine uygun olarak gerekli tüm teknik ve idari tedbirleri
                                        almaktadır.
                                    </p>
                                    <strong>
                                        3.1.1. Kişisel Verilerin Hukuka Aykırı Olarak İşlenmesini Önlemek,
                                        Verilere Hukuka Aykırı Olarak Erişilmesini Önlemek ve Verilerin
                                        Muhafazasını Sağlamak için Alınan Teknik Tedbirler
                                    </strong>
                                    <ul>
                                        <li>
                                            Yapı Radar tarafından kişisel verilerin korunmasına ilişkin olarak teknolojinin
                                            imkan verdiği ölçüde teknik önlemler alınmakta ve alınan önlemler dönemsel
                                            olarak teknolojik gelişmelere göre güncellenmektedir. Alınan önlemlerin
                                            uygulanmasına yönelik düzenli aralıklar ile denetim yapılmaktadır.
                                        </li>
                                        <li>
                                            Veri güvenliğini sağlayacak yazılım ve sistemler kurulmakta ve
                                            kullanılmaktadır, kişisel verilere hem şirket içerisinden hem de dışarıdan
                                            hukuka aykırı bir şekilde erişilmesi ve/veya müdahale yapılmasının
                                            önlenmesi için verileri barındıran sunucular güncel virüs koruma yazılımları
                                            ile korunmakta, oturum kaydı alan oturum yönetimi yazılımları, parola
                                            yönetim yazılımları ve güvenlik duvarları başta olmak üzere çeşitli
                                            katmanlarda korunma sağlanmaktadır.
                                        </li>
                                        <li>
                                            Kişisel verilere erişim yetkisi, belirlenen veri işleme amacı doğrultusunda
                                            sınırlandırılmakta ve yetkiler düzenli olarak gözden geçirilmektedir. Bilmesi
                                            gereken prensibi temeline dayanarak yetkilendirme yapılmaktadır.
                                        </li>
                                        <li>
                                            Saklama alanlarına yönelik teknik güvenlik sistemleri kurulmakta, bilişim
                                            sistemleri üzerindeki güvenlik zafiyetlerinin tespitine yönelik güvenlik testleri
                                            ve araştırmaları yapılmakta, yapılan test ve araştırmaların sonucunda tespit
                                            edilen mevcut ya da muhtemel risk olarak değerlendirilebilecek konular
                                            giderilmektedir.
                                        </li>
                                        <li>
                                            Kişisel verilerin hukuka uygun ve güvenli bir biçimde saklanmasını sağlamak
                                            için teknolojik gelişmelere uygun sistemler ve yedekleme yazılımları
                                            kullanılmaktadır.
                                        </li>
                                        <li>
                                            Şirkette alınan tedbirleri uygulamakla görevli olan bir personel bulunmaktadır.
                                        </li>
                                        <li>
                                            Kişisel verilerin tutulduğu ortamlara veriye erişim kısıtlanarak yalnızca yetkili
                                            kişilerin, kişisel verinin saklanma amacı ile sınırlı olarak bu verilere
                                            erişmesine izin verilmekte ve tüm erişimler kayıt altına alınmaktadır.
                                        </li>
                                    </ul>
                                    <strong>
                                        3.1.2 Kişisel Verilerin Hukuka Aykırı Olarak İşlenmesini Önlemek, Verilere
                                        Hukuka Aykırı Olarak Erişilmesini Önlemek ve Verilerin Muhafazasını
                                        Sağlamak için Alınan İdari Tedbirler
                                    </strong>
                                    <p>
                                        Kişisel verilerin hukuka aykırı olarak işlenmesini önlemek, verilere
                                        hukuka aykırı olarak erişilmesini önlemek ve verilerin muhafazasını sağlamak
                                        için alınan başlıca idari tedbirler aşağıda listelenmiştir:
                                    </p>
                                    <ul>
                                        <li>
                                            Yapı Radar bünyesinde Kişisel Verileri Koruma Kanuna göre düzenlenmeler
                                            yapılmıştır.
                                        </li>
                                        <li>
                                            Yapı Radar çalışanları, kişisel verilerin korunması ve hukuka uygun olarak
                                            işlenmesi, bilgi güvenliğine uygun davranış, özel hayatın gizliliği konularında
                                            düzenli olarak bilgilendirilmekte ve eğitilmektedir.
                                        </li>
                                        <li>
                                            Yapı Radar; bilgi güvenliği, özel hayatın gizliliği ve kişisel verilerin korunması
                                            alanındaki gelişmeleri takip etmek ve gerekli aksiyonları almak üzere hukuki
                                            ve teknik danışmanlık hizmeti alınmaktadır.
                                        </li>
                                        <li>
                                            Yapı Radar’ün yürütmüş olduğu tüm faaliyetler detaylı olarak tüm iş birimleri
                                            özelinde analiz edilmiş, bu analiz neticesinde ilgili iş birimlerinin
                                            gerçekleştirmiş olduğu iş süreçleri özelinde kişisel veri işleme faaliyetleri
                                            belirlenmiş ve kişisel veri envanterine işlenmiştir.
                                        </li>
                                        <li>
                                            Envanter Yapı Radar tarafından düzenli olarak güncellenmektedir.
                                        </li>
                                        <li>
                                            Yapı Radar iş birimlerinin yürütmüş olduğu kişisel veri işleme faaliyetleri
                                            özelinde, kişisel veri işleme şartlarına uygunluğun sağlanması için yerine
                                            getirilecek olan gereklilikler, her bir iş birimi ve detay faaliyet özelinde
                                            belirlenmiştir.
                                        </li>
                                        <li>
                                            Uyum gerekliliklerinin sağlanması için ilgili iş birimleri özelinde farkındalık
                                            yaratılmakta ve uygulama kuralları belirlenmekte; bu hususların ve
                                            uygulamanın sürekliliğini sağlamak için şirket içi politikalar hayata
                                            geçirilmekte ve denetimler yapılmaktadır.
                                        </li>
                                        <li>
                                            Çalışanlar ve üçüncü şahıslar ile imzalanan sözleşme ve belgelere, kişisel
                                            verilerin hukuka uygun olarak işlenmesi, korunması ve veri gizliliğini
                                            sağlamak amacıyla hükümler eklenmiş, tarafların sorumlulukları açıkça
                                            düzenlenerek hukuka ve sözleşmeye aykırı veri işleme faaliyetleri için
                                            yaptırım getiren hükümler uygulanmıştır.
                                        </li>
                                    </ul>
                                    <h4>3.1.3 Kişisel Verilerin Korunmasında Şirket İçi Denetim</h4>
                                    <p>
                                        Yapı Radar, KVKK uyarınca, kendi bünyesinde gerekli denetimleri
                                        personeliyle yapmakta veya dışarıdan bu konuyla ilgili hizmet almaktadır. Bu
                                        denetimden kaynaklanan sonuçları, Şirketin iç işleyişi kapsamında, üst
                                        yönetime ve konu ile ilgili bölüme raporlanmakta, yapılacakları planlanmakta
                                        ve alınan tedbirlerin iyileştirilmesi için planlananların takibi, ilgili süreç
                                        sahipleri ve bu işle ilgilenen personel tarafından takip edilerek
                                        yürütülmektedir.
                                    </p>
                                    <h4>
                                        BÖLÜM 4- KİŞİSEL VERİLERİN SAKLANMA VE İMHASI
                                    </h4>
                                    <strong>
                                        4.1. SAKLAMA VE İMHA, ANONİM HALE GETİRME NEDENLERİ
                                    </strong>
                                    <strong>
                                        4.1.1. Saklama Nedenleri
                                    </strong>
                                    <p>
                                        Yapı Radar bünyesinde bulunan kişisel veriler, Aydınlatma metninde,
                                        Envanterde ve Ek-1’de yer alan amaçlar kapsamında işlemekte ve
                                        saklanmaktadır. Yapı Radar aldığı tüm tedbirleri işbu saklama faaliyetinin
                                        Kanundaki şartlara uygun olabilmesi için gerçekleştirmektedir.
                                    </p>
                                    <strong>
                                        4.1.2 İmha Nedenleri
                                    </strong>
                                    <p>
                                        Yapı Radar, KVKK md.5-6’da yer alan şartlar yahut ilgili kişinin başvurusu
                                        üzerine uhdesinde bulunan verileri imha edebilir. Kanun’un 5’nci ve 6’ncı
                                        maddelerinde sayılan nedenler aşağıdakilerden ibarettir:
                                    </p>
                                    <ul>
                                        <li>Kanunlarda açıkça öngörülmesi.</li>
                                        <li>
                                            Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan
                                            veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir
                                            başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.
                                        </li>
                                        <li>
                                            Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
                                            kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli
                                            olması.
                                        </li>
                                        <li>
                                            Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu
                                            olması.
                                        </li>
                                        <li>
                                            İlgili kişinin kendisi tarafından alenileştirilmiş olması.
                                        </li>
                                        <li>
                                            Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu
                                            olması.
                                        </li>
                                        <li>
                                            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
                                            sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.
                                        </li>
                                    </ul>
                                    <strong>

                                        4.1.3 İmha Yöntemleri
                                    </strong>
                                    <p>
                                        Şirketimiz, kişisel verileri işlendikleri amaç için gerekli olan süre ve ilgili
                                        faaliyetin tabi olduğu yasal mevzuatta öngörülen minimum sürelere uygun
                                        olarak muhafaza etmektedir. Bu kapsamda, Şirketimiz öncelikle ilgili
                                        mevzuatta kişisel verilerin saklanması için bir süre öngörülüp öngörülmediğini
                                        tespit etmekte, bir süre belirlenmişse bu süreye uygun davranmaktadır. Yasal
                                        bir süre mevcut değil ise kişisel veriler işlendikleri amaç için gerekli olan süre
                                        kadar saklanmaktadır. Kişisel veriler belirlenen saklama sürelerinin sonunda
                                        periyodik imha sürelerine veya veri sahibi başvurusuna uygun olarak ve
                                        belirlenen imha yöntemleri (silme ve/veya yok etme ve/veya anonimleştirme)
                                        ile imha edilmektedir.
                                    </p>
                                    <strong>
                                        4.1.3.1. Silme yöntemleri
                                    </strong>
                                    <p>
                                        Kişisel verileri imha ederken fiziki ortamda tutulan veriler için Kağıt kesme
                                        makinesi ile öğütme, yakma, karalama ve geri dönüşüm yöntemleriyle
                                        silinmektedir.
                                        Otomatik ortamda tutulan kişisel veriler ise Sabit disk veya USB bellek gibi
                                        veri barındıran ortamlara geri dönülemeyecek şekilde, yakma, delme gibi
                                        fiziksel yöntemlerle zarar verilmesi, Şifreleme yöntemleri ile verinin
                                        şifrelenerek veriye ilgili kullanıcı tarafından erişimin engellenmesi, Veri
                                        barındıran manyetik ortamların (sabit disk, CD-ROM veya USB bellek vb.)
                                        manyetik etkisinin yok edilmesi işlemi, Verilerin bulunduğu veri tabanlarında
                                        rol ve izin ataması yapılarak ilgili kullanıcının veri tabanına erişiminin
                                        engellenmesi ve Yazılımlar vasıtasıyla veya fabrika ayarlarına geri döndürme
                                        suretiyle var olan verilerin üzerine yenilerinin yazılması, İlgili kullanıcının,
                                        erişim politikaları ile ilgili veriye erişiminin engellenmesi yöntemleri ile silme
                                        işlemi gerçekleştirilmektedir.
                                    </p>
                                    <strong>
                                        4.1.4 Anonim Hale Getirme Yöntemi
                                    </strong>
                                    <p>
                                        Kişisel verilerin korunması bakımından silme işleminden daha güvenli bir yol
                                        olan anonim hale getirme işleminde ilgili kişisel verilerin ilgili kişilerle
                                        bağlantısı koparılmakta ve bu şekilde kişisel veri olmaktan çıkartılmaktadır.
                                    </p>
                                    <strong>
                                        4.1.4.2 Maskeleme
                                    </strong>
                                    <p>
                                        Bu yöntem, kişisel veri niteliğindeki bir bilginin, belirli alanlarının kaldırılarak
                                        veya değiştirilerek ilgili kişi ile bağının kesilmesini ifade eder. Entek olarak,
                                        otomatik ortamında tuttuğumuz verilere saklama süresinin dolması
                                        durumunda isim, soy isim ve benzeri kişisel verilerin bazı kısımları
                                        yıldızlanarak saklanacaktır. Değişkenleri yahut kayıtları çıkarma,
                                        rasgeleştirme, vb. yöntemiyle kişisel veriler sistematik olmaktan
                                        çıkartılmaktadır. Verilerden Bazı değişkenler çıkartılarak verilerin kime ait
                                        olduğunun belirlenemeyeceği durumlara getirilmektedir.
                                    </p>
                                    <strong>
                                        4.1.4.3. Gürültü Eklenmesi
                                    </strong>
                                    <p>
                                        Bu yöntem ile veri kümesinde yer alan değer, ekleme veya çıkarma işlemi ile
                                        belirlenen ölçüde değiştirilmektedir. Örneğin, veri kümesinde yer alan her bir
                                        yaş değerine 5 eklenmesi sonucunda bu yaş değeri değiştirilmiş ve yeni
                                        değerler oluşturulmuştur.
                                    </p>
                                    <h4>
                                        BÖLÜM 5 - VERİ SAHİPLERİNİN KANUN KAPSAMINDAKİ HAKLARI
                                    </h4>
                                    <p>
                                        Dilediğiniz zaman Yapı Radar’ye başvurarak kişisel verilerinizin;
                                    </p>
                                    <ul>
                                        <li>
                                            İşlenip işlenmediğini, işlenme amacını ve amacına uygun kullanıp
                                            kullanılmadığı öğrenebilir ve işlenmiş ise bu konuda bilgi isteyebilir,
                                        </li>
                                        <li>
                                            Kanun’a uygun olarak yurt içinde ve yurt dışında bilgilerinizin paylaşıldığı
                                            üçüncü kişileri öğrenebilir,
                                        </li>
                                        <li>
                                            Bilgilerinizin eksik ya da hatalı işlendiğini düşünüyorsanız düzeltilmesini
                                            isteyebilir,
                                        </li>
                                        <li>
                                            Kanunun 7. maddesinde öngörülen şartlar çerçevesinde bilgilerinizin
                                            silinmesini ya da yok edilmesini talep edebilir,
                                        </li>
                                        <li>
                                            Bilgilerinizin aktarıldığı üçüncü kişilere (c) ve (d) bentlerinde belirtilen
                                            taleplerinizin bildirilmesini ve aynı işlemleri gerçekleştirmelerini isteyebilir,
                                        </li>
                                        <li>
                                            Bilgilerinizin, otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir
                                            sonucun ortaya çıkmasına itiraz edebilir veya kanuna aykırı olarak
                                            kaydedildiğini veya kullanıldığını düşünüyorsanız ve bu sebeple zarara
                                            uğramışsanız zararın giderilmesini isteyebilirsiniz.
                                        </li>
                                    </ul>
                                    <p>
                                        Kanun kapsamındaki haklarınızdan yararlanmak için başvurularınızı, yazılı
                                        olarak Yapı Radar’ye iletebilir, detaylı bilgi almak için Kişisel Verileri Koruma
                                        Kurumu’nun internet sayfasını ziyaret edebilirsiniz.
                                    </p>
                                    <p>
                                        Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen haklarınızı
                                        kullanmak için yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin
                                        açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır
                                        olması, talep ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına
                                        hareket ediyor iseniz bu konuda noter tarafından tasdiklenmiş özel
                                        vekâletnamenizi ibraz etmeniz gerekecektir.
                                    </p>
                                    <p>
                                        Başvurularınızda, ad-soyad, imza, T.C. kimlik numarası, ikamet veya işyeri
                                        adresi, e-posta adresi, telefon ve faks numarası, talep konusu unsurlarının
                                        bulunması “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ”
                                        uyarınca zorunludur. Söz konusu unsurları barındırmayan başvurular Yapı
                                        Radar tarafından reddedilecektir.
                                    </p>
                                    <p>
                                        Yapı Radar’nın Kanun’dan, ikincil düzenlemelerden ve Kurul kararlarından
                                        doğan sebeplerle değişiklik yapma hakkı her zaman saklıdır. Aydınlatma
                                        metninde yapılacak değişiklikler ve güncel metin tarafınıza tebliğ edildiği tarih
                                        itibariyle derhal geçerlilik kazanacaktır.
                                    </p>
                                    
                                    

                                    <h4>
                                        <a href="/AydinlatmaMetni">
                                            Ek-1 AYDINLATMA METNİ İÇİN TIKLAYIN
                                        </a>
                                    </h4>
                                    <h4>
                                        EK-2 SAKLAMA SÜRELERİ
                                    </h4>
                                    <p>
                                        Yerel kolejlere kayıt, 2005
                                    </p>
                                    <table class="table mt-3">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Veri Türü Saklama
                                                </th>
                                                <th>
                                                    Süresi
                                                </th>
                                                <th>
                                                    Saklama Başlangıç
                                                    Tarihi
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Şirketin Genel Verileri</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Mali İşler ve Vergilere İlişkin Kayıtlar</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Maaş bordrosu ve maaş bilgileri</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>İşe Alım Olumlu Süreç (İK dosyası)</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>İşe Alım Olumsuz Süreç</td>
                                                <td>6 Ay</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>İK, istihdam, emeklilik bilgileri çalışanlar için</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ayrılan çalışana ilişkin tutulan veri</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Çalışan sağlık ve iş güvenliği kayıtları/verileri</td>
                                                <td>15 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hukuki metinler ve sözleşmeler</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>İş bağlantılarına ait veriler</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Potansiyel müşterilere ait veriler</td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Müşterilere ait veriler </td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Güvenlik (CCTV, ziyaretçi kayıt, araç plaka ve
                                                    v.b.)
                                                </td>
                                                <td>6 Ay</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Pazarlama Faaliyetleri Planlama ve İcrası
                                                </td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    İdari İşler Süreçleri (Araç, gereç tahsisi ve
                                                    v.b.)
                                                </td>
                                                <td>10 Yıl</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Bilgi İşlem Departmanı Log / Kayıt / Takip
                                    Sistemleri
                                                </td>
                                                <td>6 Ay</td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    İnternet Sitesi Ziyaretçisi
                                                </td>
                                                <td></td>
                                                <td>
                                                    Saklama süresinin
                                                    bitimini takip eden ilk
                                                    periyodik imha
                                                    süresinde
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>
                                        EK-3 SAKLAMA AMAÇLARI
                                    </h4>
                                    <table class="table table-hover mt-3">
                                        <thead>
                                            <tr>
                                                <th>VERİ TÜRÜ</th>
                                                <th>DOĞRUDAN AMAÇLAR</th>
                                                <th>DOLAYLI AMAÇLAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    İşe Alım
                                                    Seçme Değerlendirme
                                                </td>
                                                <td>
                                                    İşe Alım Süreçlerinin
                                                    Yönetilmesi,
                                                    İnsan Kaynakları
                                                    Faaliyetlerinin Devamı ve
                                                    Geliştirilmesi,
                                                    İşe Alım Olacağı Takdirde
                                                    Teklif Mektubu
                                                    Oluşturulması,
                                                    Çalışan Adaylarının
                                                    Başvuru Süreçlerinin
                                                    Yürütülmesi/Yönetilmesi,
                                                    Çalışan Adayı Seçme
                                                    Süreçlerinin Yürütülmesi,
                                                    İş Akdine Hazırlık ve
                                                    Mevzuat Gereği Olan
                                                    İşlemlerin Yapılması,
                                                    Mülakat Sürecindeki
                                                    Yapılan Görüşme
                                                    Gerekleri
                                                </td>
                                                <td>
                                                    Çalışan Adayının Uygun
                                                    Pozisyona Yerleştirilmesi,
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Özlük</td>
                                                <td>
                                                    Çalışanlar İçin İş Akdi ve
                                                    Mevzuat Kaynaklı
                                                    Yükümlülüklerin Yerine
                                                    Getirilmesi,
                                                    İş Süreçlerinin
                                                    Yönetilmesi,
                                                    Özlük Dosyasının
                                                    Hazırlanması
                                                </td>
                                                <td>
                                                    Yetkili Kişi, Kurum Ve
                                                    Kuruluşlara Bilgi
                                                    Verilmesi,
                                                    Şirket stratejilerinin
                                                    belirlenmesi ve
                                                    uygulanması
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Finans</td>
                                                <td>
                                                    Şirket tarafından yürütülen
                                                    bağımsız denetim,
                                                    muhasebe hizmetleri ve
                                                    danışmanlık vb.
                                                    hizmetlerin ifası
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Kalite Kontrol
                                                </td>
                                                <td>
                                                    Şirketin ticari ve iş
                                                    stratejilerinin belirlenmesi
                                                    ve uygulanması amacı
                                                    doğrultusunda yürütülen
                                                    süreçleri ve operasyonları,
                                                    finans operasyonları,
                                                    iletişim, pazar araştırması
                                                    ve sosyal sorumluluk
                                                    aktiviteleri, satın alma
                                                    operasyonlarının
                                                    yürütülmesi, müşteri
                                                    projelerinin yerine
                                                    getirilmesi
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default KVKK;
