import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TablePagination, Typography } from '@mui/material'
import NavbarSearchable from '../../Components/Navigation/NavbarSearchable'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer'
import SpotUrunlerItemCard from '../../Components/SpotUrunler/SpotUrunlerItemCard'
import { postData } from '../../Assets/Scripts/dataAxios'

function SpotUrunlerIndex() {
    const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const [newItemsData, SetNewItemsData] = useState([]);
    const [resultCount, SetResultCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(24);
    const [paramsFLAG, setParamsFLAG] = useState(false);

    const styles = {
        Header1: {
            "width": "100%",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "800",
            "fontSize": {xs:"35px",md:"50px"},
            "lineHeight": "120%",
            "textAlign": "center",
            "color": "#000000"
        },
        Header2: {
            "width": "100%",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": {xs:"15px",md:"22px"},
            "lineHeight": "140%",
            "textAlign":{xs:"justify",md:"center"},
            "color": "#303C43"
        },
        JoinButton: {
            "padding": "15px 25px",
            "gap": "10px",
            "height": "50px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "14px",
            ':hover': {
                "padding": "15px 25px",
                "gap": "10px",
                "height": "50px",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "14px",
            }
        },
        JoinButton1: {
            display: UserData.firmaId !== 0 && !UserData ? '' : 'none',
            "padding": "15px 25px",
            "gap": "10px",
            "height": "50px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "14px",
            ':hover': {
                "padding": "15px 25px",
                "gap": "10px",
                "height": "50px",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "14px",
            }
        },
    }

    const navigate = useNavigate();
    const handleClickCreateFirma = () => {
        navigate('/PazarYeri/FirmaEkle')
    }

    const handleClickJoinCatalogue = () => {
        const id = 'scrollToJoinCatalogue';
        const yOffset = -30;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    useEffect(() => {
        GetNewItemsData();
        document.title = 'YapıRadar.com | Spot Ürünler';
    }, [paramsFLAG])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        GetNewItemsData();
    };


    const GetNewItemsData = async () => {
        const response = await postData('Spot/DijitalFirmaSpotUrunList', {
            "orderBy": {
                "key": "guncellemetarih",
                "value": true
            },
            "pageSize": rowsPerPage,
            "currentPage": page,
            "isPagingEnabled": true,
            "firmaids": [],
            "kategoriIds": [],
            "urunadi": null
        });
        SetNewItemsData(response.data.value.data);
        SetResultCount(response.data.value.recordsFiltered);
        const scrollElement = document.getElementById('scrollToTable');
        scrollElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <>
            <NavbarSearchable />
            <Grid container spacing={0}>
                <Grid
                    sx={{
                        display: UserData.firmaId !== 0 && !UserData ? '' : 'none'
                    }}
                    pb={5} pl={{xs:5,md:10}} pr={{xs:5,md:10}}  align='center' item xl={12} md={12} xs={12}>
                    <Typography mt={5} sx={styles.Header1}>
                        SPOT ÜRÜNLER SİZLERLE BULUŞUYOR
                    </Typography>
                    <Typography mt={5} mb={5} sx={styles.Header2}>
                        {translate.PazarYeriHeader2}
                    </Typography>
                    <Button
                        onClick={handleClickJoinCatalogue}
                        sx={styles.JoinButton1}>
                        <Typography sx={{
                            color: 'white',
                            "fontFamily": "'Poppins'",
                            "fontStyle": "normal",
                            "fontWeight": "600",
                            "fontSize": "18px",
                            "textAlign": "center",
                        }}>
                            {translate.PazarYerindeYeriniziAlin}
                        </Typography>
                    </Button>
                </Grid>

                <Grid
                    sx={{
                        display: UserData.firmaId !== 0 && UserData ? '' : 'none'
                    }}
                    pb={5} pl={{xs:5,md:10}} pr={{xs:5,md:10}} align='center' item xl={12} md={12} xs={12}>
                    <Typography mt={5} sx={styles.Header1}>
                        SPOT ÜRÜNLER SİZLERLE BULUŞUYOR
                    </Typography>
                    <Typography mt={5} mb={5} sx={styles.Header2}>
                        {translate.PazarYeriHeader2}
                    </Typography>
                    <Button
                        onClick={handleClickJoinCatalogue}
                        sx={styles.JoinButton1}>
                        <Typography sx={{
                            color: 'white',
                            "fontFamily": "'Poppins'",
                            "fontStyle": "normal",
                            "fontWeight": "600",
                            "fontSize": "18px",
                            "textAlign": "center",
                        }}>
                            {translate.PazarYerindeYeriniziAlin}
                        </Typography>
                    </Button>
                </Grid>
                <Grid sx={{
                    display: UserData.isAdmin === true ? '' : 'none'
                }} align='right' pr={3} mb={5} item xl={12} md={12} xs={12}>
                    <a
                        style={{
                            textDecoration: 'none',
                            "width": "1200px",
                            "fontFamily": "'Poppins'",
                            "fontStyle": "normal",
                            "fontWeight": "400",
                            "fontSize": "16px",
                            "lineHeight": "140%",
                            "textAlign": "center",
                            "color": "#303C43"
                        }}
                        href='/SpotUrunler/UrunEkle'>
                        Spot Urun Ekle
                    </a>
                </Grid>
                <Grid
                    sx={{
                        display: UserData.firmaId == 0 && UserData ? '' : 'none'
                    }}
                    pl={10} pr={10} mb={10} align='center' item xl={12} md={12} xs={12}>
                    <Typography mt={5} sx={styles.Header1}>
                        Pazar Yeri İle Firmanızı Oluşturun
                    </Typography>
                    <Typography mt={7} mb={5} sx={styles.Header2}>
                        Ürünlerini ve firmasını sınırları online mağazada tanıtmak isteyen firmalar çoktan burada yerini aldı. Dijital Yapı Katalogu ile siz de sınırlarınızı aşmaya hazır mısınız?
                    </Typography>
                    <Button
                        onClick={handleClickCreateFirma}
                        sx={styles.JoinButton}>
                        <Typography sx={{ color: 'white' }}>
                            Firmanızı Oluşturun
                        </Typography>
                    </Button>
                </Grid>

                <Grid align='center' item xl={12} md={12} xs={12}>
                    <Grid id='scrollToTable' pl={{xs:5,md:10}} pr={{xs:5,md:10}}  container spacing={0}>
                        {newItemsData !== null && newItemsData.length > 0 ? (newItemsData.map((item) => (
                            <Grid key={item.id} mb={5} item xl={3} md={3} xs={12}>
                                <SpotUrunlerItemCard props={item} />
                            </Grid>
                        )))
                            : (
                                <Grid align='center' key={1} mb={5} item xl={12} md={12} xs={12}>
                                    Arama Sonucuna Uygun Bir Ürün Bulunamadı
                                </Grid>
                            )}
                        <Grid align='center' item xl={12} md={12} xs={12} pr={20} mt={5}>
                            <TablePagination
                                sx={{ display: newItemsData !== null && newItemsData.length > 0 ? '' : 'none' }}
                                labelDisplayedRows={
                                    ({ from, to, count }) => {
                                        return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                                    }}
                                component="div"
                                count={resultCount}
                                page={page}
                                key='Urunler'
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Sayfada şu kadar sonuç göster"
                                rowsPerPageOptions={[
                                    12, 20, 24, 48, 60
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default SpotUrunlerIndex