import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux';
import CalculatorImage from '../../Assets/Images/BlockWithPhone.png'
import zIndex from '@mui/material/styles/zIndex';
function Katalog() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "20px", md: "50px"},
            "lineHeight": "170%",
            "color": "#FF6600"
        },
        Header2: {
            marginTop: '0.5em',
            "height": "auto",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "20px", md: "50px"},
            "lineHeight": "130%",
            "color": "#000000"
        },
        Header3: {
            marginTop: '2em',
            "height": "auto",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "16px", md: "16px"},
            "lineHeight": "170%",
            "color": "#303C43"
        },
        HeaderWrapper: {
            position: { xs: "relative", md: "absolute"},
            top: { xs: "0", md: '135px'},
            zIndex:"2",
            "width":{ xs: "100%", md: "50%"},
        },
        HeaderImg: {
            "display" : { xs: "flex", md: "block"},
             "flex-direction" :{ xs: "column", md: "column"},
               "flex-wrap" : { xs: "nowrap", md: "unset"},
               "position":"relative" 
        },

    }
    return (
        <>
            <Grid sx={{ backgroundColor: '#E9ECED', position: 'relative' }} pl={{xs:3,md:5}}  pb={5} container spacing={0}>
                <Grid item xl={12} md={12} xs={12}>
                <Box align='right'sx={styles.HeaderImg} >
                        <img src={CalculatorImage} alt='maliyet orani' />
                    </Box>
                    <Box p={1} sx={styles.HeaderWrapper}>
                        <Typography sx={styles.Header2}>
                            {translate.KatalogVePromosyonUrunuDagitimi}
                        </Typography>
                        <Typography  sx={styles.Header3}>
                            {translate.PromosyonUrunleriniziSirketVeUrunKataloglarinizi}
                        </Typography>
                    </Box>
                   
                </Grid>
            </Grid>
        </>
    )
}

export default Katalog