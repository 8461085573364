import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import Image404 from '../Assets/Images/404Page.webp'
import Navbar from '../Components/Navbar'

function NotFound404Page() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        navigate('/')
    }


    const styles = {
        bodyGrid: {
            backgroundColor: '#F4F7F9',
            minHeight: '83vh'
        },
        pageText:
        {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "16px",
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#889AA4"
        },
        buttonText: {
            "color": "#FFFFFF",
            "flex": "none",
            "order": "0",
            "flexGrow": "0"
        },
        returnHomeButton: {
            "padding": "15px 25px",
            "gap": "10px",
            "width": "278px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                background: ' #FF6600'
            }
        }


    }



    return (
        <>
            <Navbar />
            <Grid align='center' mt={15} sx={styles.bodyGrid} container spacing={0} >
                <Grid pt={4} item xl={12} md={12} xs={12} >
                    <img src={Image404} alt='yapiradar.com page not found' />
                    <Typography mb={4} sx={styles.pageText}>
                        Sayfa Bulunamadı.
                    </Typography>
                    <Button onClick={handleClick} sx={styles.returnHomeButton}>
                        <Typography sx={styles.buttonText}>
                            Ana Sayfaya Dön
                        </Typography>
                    </Button>
                </Grid>
            </Grid>

        </>
    )
}

export default NotFound404Page