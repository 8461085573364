import styled from "@emotion/styled";
import { Typography } from "@mui/material";



/////// IHALE DETAY ////////

export const IhaleDetayHeaderLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "fontSize": "34px",
    "lineHeight": "120%",
})

export const IhaleDetayDescHeaderLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 700,
    "fontSize": "16px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "rgb(43,43,43)"
})

export const IhaleDetayBaslikLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "lineHeight": "120%",
})

export const IhaleDetayIcerikLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 400,
    "fontSize": "16px",
    "lineHeight": "140%",
    "color": "rgb(171,171,171)"
})

export const IhaleDetaySecondaryHeader = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "lineHeight": "120%",
})

//General Labels 
export const StyledLabel = styled(Typography)({
    width: '100%',
    fontSize: '16px',
    fontFamily:'"Poppins"',
})

export const StyledCardHeaderLabel = styled(Typography)({
    width: '100%',
    fontSize: '20px',
    fontFamily:'"Poppins"',
    "lineHeight": "120%",
})





export const PageHeaderText = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "lineHeight": "120%",
})

export const PageDescriptionText = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 400,
    "lineHeight": "140%",
})

export const IhaleCustomTableText = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "lineHeight": "140%",
})

export const IhaleCustomTableColumnHeader = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 600,
    "fontSize": "18px",
    "lineHeight": "140%",
    "color": "black"
})

export const IhalePageHeaderLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "fontSize": "34px",
    "lineHeight": "120%",
    "textAlign": "left",
    "color": "White"
})

export const IhalePageHeaderLabel2 = styled(Typography)({
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "fontSize": "28px",
    "lineHeight": "120%",
    "textAlign": "left",
})



export const IhaleDescHeader = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 800,
    "fontSize": "28px",
    "lineHeight": "120%",
    "textAlign": "center",
})


export const IhalePageDescriptionLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 400,
    "fontSize": "18px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "White"
})

export const IhaleTableHeaderLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 400,
    "fontSize": "20px",
    "lineHeight": "140%",
    "color": "black"
})


export const IhalePageCardHeader = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 700,
    "fontSize": "27px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "black"
})


export const IhalePageCardLabel = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 600,
    "fontSize": "15px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "black"
})

export const IhaleNo = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 700,
    "fontSize": "27px",
    "lineHeight": "140%",
    "textAlign": "center",
    "color": "black"
})


export const IhaleNumarasi = styled(Typography)({
    textDecoration: 'none',
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 600,
    "fontSize": "15px",
    "lineHeight": "140%",
    "textAlign": "center",
    "color": "black"
})

export const IhaleCardHeader = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 400,
    "fontSize": "14px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "black"
})

export const IhaleCardFirmaAdi = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 600,
    "fontSize": "18px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "black"
})

export const IhaleDetayBaslik = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 700,
    "fontSize": "16px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "rgb(43,43,43)"
})

export const IhaleDetayIcerik = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 400,
    "fontSize": "16px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "rgb(171,171,171)"
})


export const StyledFilterHeader = styled(Typography)({
    "width": "100%",
    "fontFamily": "'Poppins'",
    "fontStyle": "normal",
    "fontWeight": 600,
    "fontSize": "21px",
    "lineHeight": "140%",
    "textAlign": "left",
    "color": "#303C43"
})