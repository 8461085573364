import { Grid, } from '@mui/material'
import React from 'react'
import BussinesPartnersPaper from '../Components/Landing HOME/BussinesPartnersPaper';
import MaximumEfficiency from '../Components/Landing HOME/MaximumEfficiency';
import Entering from '../Components/Landing HOME/Entering';
import CustomerPartners from '../Components/Landing HOME/CustomerPartners'
import WhatWeDo from '../Components/Landing HOME/WhatWeDo';
import DigitalCatalog from '../Components/Landing HOME/DigitalCatalog';
import PPTS from '../Components/Landing HOME/PPTS';
import FieldTeam from '../Components/Landing HOME/FieldTeam';
import ThePress from '../Components/Landing HOME/ThePress';
import TryNow from '../Components/Landing HOME/TryNow';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { Box } from '@mui/system';

function Landing1() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const PageData = {
        Entering: {
            Header1: 'İnşaat Veri Bankası Özellikleriyle İşinizi Büyütmek İçin Hemen Ücretsiz Deneyin',
            Header2: 'İnşaat sektörünün veri bankası',
            ButtonText: 'Hemen Dene',
            YapiradarWithNumbers: {
                text: 'Rakamlarla Yapiradar.com',
                data: [
                    {
                        id: 1,
                        name: 'Güncel Proje Bilgisi',
                        value: '27.296',
                        logo: 'EnteringProjectInfo'
                    },
                    {
                        id: 2,
                        name: 'Aylık Güncelleme',
                        value: '15.656',
                        logo: 'EnteringMonthlyUpdate'
                    },
                    {
                        id: 3,
                        name: 'Firma Sayısı',
                        value: '43.973',
                        logo: 'EnteringFirmNumber'
                    },
                    {
                        id: 4,
                        name: 'Talep Sayısı',
                        value: '23.860',
                        logo: 'EnteringDemand'
                    }
                ]
            }
        },
        MaximumEfficiency: [
            {
                id: 1,
                name: 'Güncel Şantiye Görseli',
                text: 'Uzman saha ekiplerimiz tarafından ziyaret edilen şantiyelerin güncel görsellerini ve şantiyelerin ilerleyişini görün.',
                logo: 'EfficiencyImage',
            },
            {
                id: 2,
                name: 'Şantiye Konumu',
                text: 'Saha ekiplerimiz tarafından bizzat şantiyenin içinden paylaşılan konum bilgisi ile şantiye ziyaretlerinizi nokta atışı gerçekleştirin.',
                logo: 'EfficiencyLocation',
            },
            {
                id: 3,
                name: 'Çözüm Ortakları',
                text: 'Sadece müteahhitlerin değil projelerin çözüm ortakları olan mimar, elektrik-mekanik proje ve taahhüt firmalarının da bilgilerine erişin.',
                logo: 'EfficiencyPartners',
            },
            {
                id: 4,
                name: '60 Günlük Güncelleme',
                text: '60 günlük periyotlarda güncellenen proje detayları ile en doğru şantiye bilgilerine ulaşın.',
                logo: 'EfficiencyUpdate',
            },
            {
                id: 5,
                name: 'Yapıradar CRM',
                text: 'Binlerce proje arasından sizlere uygun olanları belirlemeye yarayan Yapıradar CRM ile sistemden maksimum satış verimi sağlayın.',
                logo: 'EfficiencyPPTS',
            },
            {
                id: 6,
                name: 'Haftalık Bülten',
                text: 'Sisteme yeni eklenen ve güncellenen projelerin raporunu haftalık olarak mail adresinizde görün.',
                logo: 'EfficiencyWeekly',
            }
        ],
        CustomerPartners: {
            Header: `Küçük işletmelerden kurumsal firmalara kadar <b> 1000'den fazla </b> markanın güvendiği sistem`
        },
        DigitalCatalog: {
            Header1: 'Pazar Yerindeki Yerinizi Alın!',
            Header2: ' İnşaat sektörünün tüm lider ve profesyonelleri Pazar Yerinde!   Daha görünür olmak, kazancınızı arttırmak için ürünlerinizi ve firmanızı Pazar Yerinde tanıtın.          ',
            ButtonText: 'Pazar Yerinde Yerinizi Alın',
        },
        BussinessPartners: {
            Header: 'Mutlu iş ortakları',
            data: [
                {
                    id: 1,
                    Logo: 'Bosch',
                    Name: 'Umit Camurcuk',
                    Job: 'Front-End Developer',
                    Text: 'İnşaat sektöründe özellikle proje odaklı çalışan firmaların, hem yeni iş ve bağlantı bulması, hem de projeler hakkında detaylı bilgiye erişimi değerli olduğu kadar uğraştırıcı bir süreçtir. YapıRadar bu süreci kolaylaştıran önemli bir araç.'
                },
                {
                    id: 2,
                    Logo: 'Siemens-logo 1',
                    Name: 'Can Murat Gül',
                    Job: 'Kurumsal İletişim Uzmanı',
                    Text: 'Yapıradar.com’da sadece proje bilgilerine değil satın alma taleplerine de ulaşıyor olmamız büyük avantaj. Direkt ihtiyaca yönelik teklif vermek bizi bir kaç adım ileriye taşıyor.'
                },
                {
                    id: 3,
                    Logo: 'Viessmann',
                    Name: 'Canan Buzluca',
                    Job: 'Pazarlama Sorumlusu',
                    Text: "Yapıradar.com'un bize göre en büyük avantajı bizimle kurduğu dirsek teması. Bu iletişimi de uyguladıkları satış sonrası destek politikası sayesinde sağlayabiliyorlar."
                }
            ]
        },
        ThePress: [
            {
                id: 1,
                Name: 'Webbrazi',
                Text: 'Güncel inşaat projeleri platformu YapıRadar, 10 milyon TL değerleme ile 600 bin TL yatırım aldı',
                image: 'Rectangle 39'
            },
            {
                id: 2,
                Name: 'Habertürk',
                Text: "YapıRadar değerini 34 milyon TL'ye taşıdı.",
                image: 'Rectangle 39'
            },
            {
                id: 3,
                Name: 'Habertürk',
                Text: "YapıRadar değerini 34 milyon TL'ye taşıdı.",
                image: 'Rectangle 39'
            }

        ]
    }
    return (
        <div style={{ backgroundColor: '#F4F7F9' }}>
            <Grid
                display="flex"
                justifyContent="center"
                alignItems="center" pt={12} container spacing={0}>
                <Entering />
                <CustomerPartners header={PageData.CustomerPartners.Header} />
                <WhatWeDo />
                <DigitalCatalog data={PageData.DigitalCatalog} />
                <BussinesPartnersPaper header={PageData.BussinessPartners.Header} data={PageData.BussinessPartners.data} />
                <MaximumEfficiency data={PageData.MaximumEfficiency} />
                <PPTS />
                <FieldTeam />
                <ThePress data={PageData.ThePress} />
                <TryNow />
                <Footer />
            </Grid>
        </div>
    )
}

export default Landing1