import { Grid, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/giris-gorsel-firmaRehberi.webp'
import Image2 from '../Assets/Images/aradiginiz-firmalari-bulun.webp'
import Image3 from '../Assets/Images/iletisim-ve-yetkili-bilgilerine-ulasin.webp'
import Image4 from '../Assets/Images/ConnectWithFirm.webp'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function FirmaRehberi() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const styles = {
        Header1: { "width": { xs: "100%", md: "592px"}, "height":{ xs: "auto", md: "36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "20px", md: "24px" }, "lineHeight": "36px", "color": "#000000" },
        Header2: { "width":  { xs: "100%", md:"541px"}, "height": { xs: "auto", md: "210px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize":{ xs: "20px", md: "54px" }, "lineHeight": "130%", "color": "#000000" },
        Header3: { "width":  { xs: "100%", md:"561.11px"}, "height": { xs: "auto", md: "93px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "18px" }, "lineHeight": "170%", "color": "#889AA4" },
        Header4: { "width":  { xs: "100%", md:"480px"}, "height":  { xs: "auto", md: "130px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize":{ xs: "20px", md: "50px"} , "lineHeight": "130%", "color": "#000000" },
        Header5: { "width":  { xs: "100%", md:"480px"}, "height":  { xs: "auto", md:"81px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "20px", md: "16px" } , "lineHeight": "170%", "color": "#303C43" },
        Header6: { "width":  { xs: "100%", md:"500px"}, "height":  { xs: "auto", md: "195px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "20px", md: "50px" }, "lineHeight": "130%", "color": "#FFFFFF" },
        Header7: { "width":  { xs: "100%", md:"512px"}, "height":  { xs: "auto", md: "93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "18px" }, "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" },

        HeaderTop :{
            display: {md:"flex"}, 
            flexDirection: {md:"row-reverse"},
            
        },
        Header1Top:{
       
            "textAlign": {xs:"center",md:"left"},
            "paddingTop": {xs:"10vw",md:""}
            },

    }
    return (
        <>
            <Navbar />
            <Grid mt={12} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9'}} container spacing={0}>
                    <Grid pb={{xs:3,md:""}} pl={{xs:3,md:15}} pr={{xs:3,md:""}} item xl={6} md={6} xs={12} sx={styles.Header1Top}>
                        <Typography sx={styles.Header1}>
                           {translate.FirmaRehberi}
                        </Typography>
                        <Typography mt={2} sx={styles.Header2}>
                            {translate.SinirlariniziAsin}
                        </Typography>
                        <Typography mt={3} sx={styles.Header3}>
                           {translate.SektordeFaaliyetGosteren}
                        </Typography>
                    </Grid>
                    <Grid  item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid mt={{xs:0,md:5}} container spacing={0} sx={styles.HeaderTop}>
                    
                    <Grid p={5} m="auto"item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header4}>
                           {translate.AradiginizFirmalariBulun}
                        </Typography>
                        <Typography pt={5}  sx={styles.Header5}>
                            {translate.HedeflediginizFirmalariAlfabetikSiralama}
                        </Typography>
                    </Grid>
                    <Grid p={{xs:5,md:10}}item xl={6} md={6} xs={12}>
                        <img  width="100%" align={"center"} src={Image2} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid pt={5} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid p={5} item xl={6} md={6} xs={12}>
                        <Typography mb={{xs:0,md:4}} sx={styles.Header4}>
                           {translate.IletisimVeYetkiliBilgilerineUlasin}
                        </Typography>
                        <Typography pt={5} sx={styles.Header5}>
                            {translate.BuldugunuzFirmalarinAdresTelefon}
                        </Typography>
                    </Grid>
                    <Grid p={{xs:5,md:10}}  item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image3} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid sx={{ backgroundColor: '#3C6780' }} container spacing={0}>
                    <Grid pt={{xs:3,md:15}} pl={{xs:5,md:5}} pr={{xs:5,md:""}}item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header6}>
                        {translate.FirmalarlaIletisimeGecin}
                        </Typography>
                        <Typography mt={3} sx={styles.Header7}>
                        {translate.HedefKitlenizArasindaYerAlan}
                        </Typography>
                    </Grid>
                    <Grid align='right' pt={5} pr={{xs:1,md:5}}item xl={6} md={6} xs={12}>
                        <img width='100%' height='auto' src={Image4} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}
