import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/girisPPTS.webp'
import Image2 from '../Assets/Images/personel-takibi.webp'
import Image3 from '../Assets/Images/proje-takibi-ve-satis-analizi.webp'
import Logo6 from '../Assets/Images/Interface Favorite Star.webp'
import Logo5 from '../Assets/Images/Money Graph Pie Chart.webp'
import Logo3 from '../Assets/Images/Interface File Clipboard Text.webp'
import Logo4 from '../Assets/Images/Interface Setting Slider Horizontal Square.webp'
import Logo2 from '../Assets/Images/Interface User Multiple.webp'
import Logo1 from '../Assets/Images/Phone Signal Full.webp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function PPTS() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const styles = {
        Header1: { "width":"100%", "height":"auto", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "24px"}, "lineHeight": "36px", "color": "#000000" ,"textAlign":{xs: "center", md:"left" }},
        Header2: {  "width":"100%", "height":"auto", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: "20px", md: "54px"}, "lineHeight": "130%", "color": "#000000" ,"textAlign":{xs: "center", md:"left" }},
        Header3: { "width":"100%", "height":"auto", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "18px"}, "lineHeight": "170%", "color": "#889AA4" ,"textAlign":{xs: "center", md:"left" }},
        Header4: { "width":"100%", "height":"auto" , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "20px", md: "50px"}, "lineHeight": "130%", "color": "#000000","textAlign":{xs: "center", md:"left" }},
        Header5: { "width":"100%", "height":"auto", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "16px"}, "lineHeight": "170%", "color": "#303C43" ,"textAlign":{xs: "center", md:"left" }},
        Header6: { "width":"100%", "height":"auto", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": { xs: "20px", md: "50px"}, "lineHeight": "140%", "textAlign": "center" , "color": "#FFFFFF" ,"textAlign":{xs: "center",  }},
        Header7: { "width":"100%", "height":"auto","fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "18px"}, "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" ,"textAlign":{xs: "center", }},
        paperText: { "width": "100%", "height":"auto", "left": "97px", "top": "483px", "fontFamily": "'Poppins'", "fontStyle": "normal","fontSize": { xs: "20px", md: "18px"}, "fontWeight": "600", "fontSize": "18px", "lineHeight": "170%", "textAlign": "center", "color": "#FFFFFF" ,"textAlign":{xs: "center",  }},
        Paper: {
            backgroundColor: '#3C6780',
            margin: '35px 0px',
            border: '0',
            boxShadow: 'none',
        },
        HeaderTop :{
            display: {md:"flex"}, 
            flexDirection: {md:"row-reverse"}
        },
    }
    return (
        <>
            <Navbar />
            <Grid mt={12} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid sx={{ mt:'10px',pb:{lg:"60px",l:"60px",md:"0px",xs:"0px"},pt:"60px", pl:"60px", pr:"20px"}} item xl={6} lg={6} md={10} sm={12}  xs={12}>
                        <Typography sx={styles.Header1}>
                            {translate.ProjePerformansTakipSistemi}
                        </Typography>
                        <Typography mb={3} mt={2} sx={styles.Header2}>
                            {translate.ProjeTakibiVeYonetimiArtik}
                        </Typography>
                        <Typography mt={8} sx={styles.Header3}>
                            {translate.YeniNesilCRMModulumuz}
                        </Typography>
                    </Grid>
                    <Grid mt={-10} item xl={6} lg={6} md={10} sm={12} xs={12} p={4}>
                        <img style={{marginTop:"30px"}} width='100%' src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid mt={5} container spacing={0}sx={styles.HeaderTop}>
                   
                    <Grid p={{xs:'40px', md:"80px" }} pl={15} item xl={6} md={6} xs={12}>
                        <Typography mb={3} sx={styles.Header4}>
                            {translate.ProjeTakibiVeSatisAnalizi}
                        </Typography>
                        <Typography pt={5} sx={styles.Header5}>
                            {translate.PPTSIleTakipEttiginiz}
                        </Typography>
                    </Grid>
                    <Grid pl={10} item xl={6} md={6} xs={12} p={8}>
                        <img  width='100%' src={Image2} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid pt={5} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid mt='10px' p={{xs:"40px",md:'90px'}} pl={15} pt={5} pb={5} item xl={6} md={6} xs={12}>
                        <Typography mb={3} sx={styles.Header4}>
                            {translate.PersonelTakibi}
                        </Typography>
                        <Typography sx={styles.Header5}>
                            {translate.PPTSIleRaporlamaVePersonel}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} md={6} xs={12} p={8}>
                        <img width='100%' src={Image3} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid sx={{ backgroundColor: '#3C6780' }} p={10} pb={10} container spacing={0}>
                    <Grid align='center' pt={5} item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header6}>
                            {translate.PPTSModuluIleNelerYapabilirsiniz}
                        </Typography>
                    </Grid>
                    <Grid sx={{ backgroundColor: '#3C6780' }}align='center' pr={2} container >
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo1} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.SatisEkibininCalismaPerformansiniTakipEdebilir}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo2} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ProjelereBirVeyaBirdenFazla}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >   
                            <Paper sx={styles.Paper}>
                                <img src={Logo3} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ProjeYetkilileriIleYapmisOldugunuz}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo4} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.VerdiginizTeklifleriKaydedebilirVeFiltreleyebilir}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo5} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ProjeTahminiGelirEkleyerek}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo6} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.TakipEttiginizProjeleriFavorilereEkleyebilir}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}
