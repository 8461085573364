import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NavbarSearchable from '../../Components/Navigation/NavbarSearchable';
import { Box, Button, ButtonGroup, Card, CardContent, Checkbox, Grid, Link, Paper, Skeleton, TablePagination, Typography } from '@mui/material';
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import Footer from '../../Components/Footer';
import FirmaNoBackground from '../../Assets/Images/FirmaNoBackground2.webp'
import NoFirmLogo from '../../Assets/Images/FirmNoLogo.webp'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ItemCard from '../../Components/PazarYeri/ItemCard';

function AramaSonucu() {
    const params = useLocation();
    const [newItemsData, SetNewItemsData] = useState([]);
    const [resultCount, SetResultCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(24);

    const [parentKategoriId, setParentKategoriId] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [detayKategori, setDetayKategori] = useState([]);

    const [categoriesFLAG, setCategoriesFLAG] = useState(false);
    const [paramsFLAG, setParamsFLAG] = useState(false);
    const [urunAdi, setUrunAdi] = useState('');

    const [allFirmsData, setAllFirmsData] = useState([]);
    const [searchforFirmsOrItems, setSearchforFirmsOrItems] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        refreshSearch();
        if (searchforFirmsOrItems === 'Firmalar') {
            GetFirmaData();
        } else if (parentKategoriId.length === 0) {
            if (paramsFLAG) {
                GetFirmaData();
                GetNewItemsData();
            }
        }
        else {
            if (paramsFLAG) {
                GetNewItemsData();
            }
        }
        document.title = 'YapıRadar.com | PazarYeri Arama Sonucu';
    }, [paramsFLAG])

    useEffect(() => {
        if (categoriesFLAG) {
            handleRefreshPage();
        }
    }, [categoriesFLAG])

    const refreshSearch = async () => {
        let parameters = decodeURI(params.search.substring(1)).split(',')
        parameters.length > 0 && parameters.map((item) => {
            let param = item.split('=');
            if (param[0] == 'AramaTuru') {
                setSearchforFirmsOrItems(param[1])
            }
            if (param[0] == 'kelime') {
                setUrunAdi(param[1])
            }
            if (param[0] === 'Kategori' || param[0] === 'kategori') {
                if (param[1] !== '') {
                    setParentKategoriId([parseInt(param[1])]);
                } else {
                    setParentKategoriId([]);
                }
            }
            if (param[0] === 'AltKategori' || param[0] === 'altkategori') {

                if (param[1].includes('-') && param[1] !== '') {
                    let categories = param[1].split('-');
                    let categorySubFixed = [];
                    categories.map((item) => {
                        if (item !== 'NaN' && item !== null) {
                            categorySubFixed.push(parseInt(item));
                        }
                    })
                    setSubCategories(categorySubFixed);
                } else {
                    if (param[1] !== '') {
                        setSubCategories([parseInt(param[1])]);
                    }
                }
            }
            if (param[0] === 'KategoriDetay' || param[0] === 'kategoridetay') {
                if (param[1].includes('-') && param[1] !== '') {
                    let categories = param[1].split('-');
                    let categorySubFixed = [];
                    categories.map((item) => {
                        if (item !== 'NaN' && item !== null) {
                            categorySubFixed.push(parseInt(item));
                        }
                    })
                    setDetayKategori(categorySubFixed);
                } else {
                    if (param[1] !== '') {
                        setDetayKategori([parseInt(param[1])]);
                    }
                }
            }
            if (param[0] === 'Sayfa') {
                setPage(parseInt(param[1]));
            }
            if (param[0] === 'sayfauzunlugu') {
                setRowsPerPage(parseInt(param[1]));
            }
            setParamsFLAG(true)
        })
    }

    const GetNewItemsData = async () => {
        console.log(detayKategori);
        const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
            "orderBy": {
                "key": "guncellemetarih",
                "value": true
            },
            "pageSize": rowsPerPage,
            "currentPage": page,
            "isPagingEnabled": true,
            "firmaids": [],
            "kategoriIds": detayKategori,
            "urunadi": urunAdi
        });
        SetNewItemsData(response.data.value.data);
        SetResultCount(response.data.value.recordsFiltered);
        const scrollElement = document.getElementById('scrollToTable');
        scrollElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const GetFirmaData = async () => {
        const firmaDataRes = await postData('DijitalKatalog/DijitalFirmaList', {
            "orderBy": {
                "key": "kayittarih",
                "value": false
            },
            "pageSize": rowsPerPage,
            "currentPage": page,
            "isPagingEnabled": true,
            "firmaAdi": urunAdi,
            "ulke": [],
            "sehir": [],
            "İlce": [],
            "faliyetIds": [],
            "kategoriIds": detayKategori,
        });
        setAllFirmsData(firmaDataRes.data.value.data);
        SetResultCount(firmaDataRes.data.value.recordsFiltered)

        const scrollElement = document.getElementById('scrollToTable');
        scrollElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

    const handleSearchTypeChange = (param) => {
        let subCategoriesFixed = subCategories.toString();
        subCategoriesFixed = subCategoriesFixed.replaceAll(',', '-');

        let subChildCategoriesFixed = detayKategori.toString();
        subChildCategoriesFixed = subChildCategoriesFixed.replaceAll(',', '-');

        let url = '/PazarYeri/AramaSonucu?'

        if (parentKategoriId) {
            url += `Kategori=${parentKategoriId},`
        }

        if (subCategoriesFixed) {
            url += `AltKategori=${subCategoriesFixed},`
        }

        if (subChildCategoriesFixed) {
            url += `KategoriDetay=${subChildCategoriesFixed},`
        }

        if (param) {
            url += `AramaTuru=${param},`
        }

        if (page) {
            url += `Sayfa=${page},`
        }

        window.location.replace(url)
    }

    const styles = {
        Header1: {
            "height": { xs: "auto", md: "56px" },
            "top": "calc(50% - 56px/2 - 2987.5px)",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "25px", md: "30px" },
            "lineHeight": "140%",
            "display": "flex",
            "alignItems": "center",
            "color": "#000000",

        },
        Card: {
            width: { xs: "100%", md: "250px" },
            height: '100%',
            padding: 0,
            margin: '0px 3vw',
        },
        CardContent: {
            padding: 0,
        },
        cardHeader: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#8FBFFA",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer'
            }
        },
        cardText: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "22px",
            "lineHeight": "120%",
            "color": "#000000",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer'
            }
        },
        newItemsWrapper: {
            display: 'inline-flex',
        },
        ShowMoreBtn: {
            "boxSizing": "border-box",
            "border": "1px solid #BCC7CE",
            "borderRadius": "25px",
            width: { xs: "100%", md: "750px" },
            height: '50px',
        },
        HeaderFirmalar: {
            marginTop: '1em',
            "height": "56px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "40px",
            "lineHeight": "140%",
            "color": "#000000"
        },
        CardFirm: {
            width: { xs: "100%", md: "370px" },
            borderRadius: '20px',
            padding: '0',
        },
        CardContentFirm: {
            padding: '0',
        },
        CardBoxWrapper: {
            position: 'relative',
        },
        CardBrandTextOnImage: {
            position: 'absolute',
            bottom: '10%',
            left: '10%',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "20px",
            "lineHeight": "140%",
            "color": "#FFFFFF"
        },
        CardBrandLogoOnImage: {
            objectFit: 'contain',
            width: { xs: "100%", md: "100px" },
            height: '65px',
            position: 'absolute',
            bottom: 0,
            left: '7%',
        },
        CardBtnWrapper: {
            marginTop: '10px',
            "display": "flex",
            marginRight: '10px',
            "justifyContent": "right"
        },
        CardBtnDetay: {
            "padding": "7px 14px",
            "gap": "10px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        CardBtnDetayText: {
            textDecoration: 'none',
            textTransform: 'none',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "22px",
            "color": "#303C43"
        },
        desktopDisplay: {
            height: '100%',
            display: parentKategoriId.length !== 0 ? '' : 'none',
            width: { xs: "100%", md: "20.833333%" },
            flexBasis: { xs: "100%", md: "50%" },
            maxWidth: "100%"
        }

    }

    const handleCardClick = (link) => {
        navigate(link)
    }

    const handleCategoryClick = (type, e, parentId) => {
        if (type === 1) {
            if (e.target.checked) {
                setSubCategories(current => [...current, parseInt(e.target.id)])

                categories.forEach(item => {
                    item.children.forEach(itemChild => {
                        if (itemChild.id === parseInt(e.target.id)) {
                            itemChild.children.forEach(itemChildrenDetay => {
                                setDetayKategori(current => [...current, itemChildrenDetay.id])
                            })
                        }
                    })
                })

            } else {
                var array = subCategories
                var index = array.indexOf(parseInt(e.target.id))
                if (index !== -1) {
                    array.splice(index, 1);
                    setSubCategories(array);
                }
                categories.forEach(item => {
                    item.children.forEach(itemChild => {
                        if (itemChild.id === parseInt(e.target.id)) {
                            itemChild.children.forEach(itemChildrenDetay => {
                                var array2 = detayKategori
                                var index2 = array2.indexOf(parseInt(itemChildrenDetay.id))
                                var index3 = array2.indexOf(itemChildrenDetay.id)
                                if (index2 !== -1) {
                                    array2.splice(index2, 1);
                                    setDetayKategori(array2);
                                }
                                if (index3 !== -1) {
                                    array2.splice(index2, 1);
                                    setDetayKategori(array2);
                                }
                            })
                        }
                    })
                })
            }
        }
        if (type === 2) {
            if (e.target.checked) {
                setDetayKategori(current => [...current, parseInt(e.target.id)])
            } else {
                var array = detayKategori
                var index = array.indexOf(parseInt(e.target.id))
                if (index !== -1) {
                    array.splice(index, 1);
                    setDetayKategori(array);
                }

                var array2 = subCategories
                var index2 = array2.indexOf(parseInt(parentId))
                if (index2 !== -1) {
                    array2.splice(index2, 1);
                    setSubCategories(array2);
                }
            }
        }
        setCategoriesFLAG(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleRefreshPage(newPage);
    };

    const handleRefreshPage = (sayfa) => {
        let url = '/PazarYeri/AramaSonucu?'
        if (parentKategoriId) {
            url += `Kategori=${parentKategoriId},`
        }

        if (subCategories.length !== 0) {
            let subCategoriesFixed = subCategories.toString();
            subCategoriesFixed = subCategoriesFixed.replaceAll(',', '-');

            url += `AltKategori=${subCategoriesFixed},`
        }

        if (detayKategori.length !== 0) {
            let subChildCategoriesFixed = detayKategori.toString();
            subChildCategoriesFixed = subChildCategoriesFixed.replaceAll(',', '-');

            url += `KategoriDetay=${subChildCategoriesFixed},`
        } else {
            url += `KategoriDetay=,`
        }

        if (searchforFirmsOrItems) {
            url += `AramaTuru=${searchforFirmsOrItems},`
        }

        if (sayfa) {
            url += `Sayfa=${sayfa},`
        }
        window.location.replace(url)

        // window.location.replace(`/PazarYeri/AramaSonucu?Kategori=${parentKategoriId},AltKategori=${subCategoriesFixed},KategoriDetay=${subChildCategoriesFixed},AramaTuru=${searchforFirmsOrItems},Sayfa=${sayfa}`)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('DijitalKatalog/KategoriList');
            SetCategories(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, []);

    const isCategoryHasCheckedSubItem = (param) => {
        let response = false;
        categories.forEach(parentCategory => {
            parentCategory.children.forEach(middleCategory => {
                if (middleCategory.id === param) {
                    middleCategory.children.forEach(childrenCategory => {
                        let isChecked = detayKategori.indexOf(childrenCategory.id)
                        if (isChecked !== -1) {
                            response = true;
                        }
                    })
                }
            })
        })
        return response;
    }

    const isCategoryHasCheckedSubItemAll = (param) => {
        let response = true;
        categories.forEach(parentCategory => {
            parentCategory.children.forEach(middleCategory => {
                if (middleCategory.id === param) {
                    middleCategory.children.forEach(childrenCategory => {
                        let isChecked = detayKategori.indexOf(childrenCategory.id)
                        if (isChecked === -1) {
                            response = false;
                        }
                    })
                }
            })
        })
        return response;
    }

    return (
        <>
            <NavbarSearchable />
            <Grid mt={0} mb={10} container spacing={0}>
                <Grid sx={{ p: 1 }} item xl={6} md={6} xs={12}>
                    <Typography
                        sx={{
                            fontFamily: "'Poppins',sans-serif",
                            fontSize: { xs: "15px", md: '20px' },
                            backgroundColor: "#e2eef98f",
                            borderRadius: "10px",
                            textAlign: "center"
                        }}
                    >
                        {urunAdi} {urunAdi !== '' ? 'İçin' : ""} Arama Sonuçları
                    </Typography>
                </Grid>
                <Grid sx={{ pr: 3 }} align='right' item xl={6} md={6} xs={12}>
                    {/* <ButtonGroup sx={{ display: parentKategoriId.length > 0 ? '' : 'none' }} variant="text">
                        <Button disabled={searchforFirmsOrItems === 'Urunler' ? true : false} onClick={() => handleSearchTypeChange('Urunler')}>Ürünler</Button>
                        <Button disabled={searchforFirmsOrItems === 'Firmalar' ? true : false} onClick={() => handleSearchTypeChange('Firmalar')}>Firmalar</Button>
                    </ButtonGroup> */}
                </Grid>
                <Grid sx={{}} mt={2} item xl={12} md={12} xs={12}>
                    <Box>
                        <Grid container spacing={0} item xs={12}>
                            <Grid sx={styles.desktopDisplay} item xl={2.5} md={2.5} xs={6} >
                                <Paper sx={{ pl: 2, pr: 2 }}>
                                    {categories.length === 0 && <Skeleton variant="rectangular" width='100%' height={20} />}
                                    {
                                        subCategories.length > 1 ? (
                                            categories.map((item) => {
                                                if (item.id === parentKategoriId[0]) {
                                                    return (
                                                        <>
                                                            <p style={{ textAlign: 'center', fontSize: '22px' }}>
                                                                {item.adi}
                                                            </p>
                                                            {item.children.map((item2) => (
                                                                <Accordion defaultExpanded={isCategoryHasCheckedSubItem(item2.id) === true || subCategories.indexOf(item2.id) !== -1} key={item.id} sx={{ p: 2, mt: 2 }}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <Box>
                                                                            <Checkbox
                                                                                id={item2.id}
                                                                                onChange={(e) => { handleCategoryClick(1, e, 0) }}
                                                                                style={{ display: 'inline-flex' }}
                                                                                defaultChecked={isCategoryHasCheckedSubItemAll(item2.id) === true || subCategories.indexOf(item2.id) !== -1 ? true : false}
                                                                                inputProps={
                                                                                    { 'aria-label': 'Checkbox demo' }
                                                                                }
                                                                            />
                                                                            <Typography sx={{ fontSize: '14px', display: 'contents' }}>
                                                                                {item2.adi}
                                                                            </Typography>
                                                                        </Box>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>

                                                                        {
                                                                            item2.children.map((item3) => (
                                                                                <Box key={item3.id} pl={2}>
                                                                                    <Checkbox
                                                                                        id={item3.id}
                                                                                        onChange={(e) => { handleCategoryClick(2, e, item2.id) }}
                                                                                        defaultChecked={subCategories.indexOf(item2.id) !== -1 || detayKategori.indexOf(item3.id) !== -1 ? true : false} />
                                                                                    <p style={{ fontSize: '12px', display: 'contents' }}>
                                                                                        {item3.adi}
                                                                                    </p>
                                                                                </Box>
                                                                            ))
                                                                        }
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            ))}
                                                        </>

                                                    )
                                                }
                                            })
                                        ) : (categories.map((item) => {
                                            if (item.id === parentKategoriId[0]) {
                                                return (
                                                    <>
                                                        <p style={{ textAlign: 'center', fontSize: '22px' }}>
                                                            {item.adi}
                                                        </p>
                                                        {item.children.map((item2) => (
                                                            <Accordion defaultExpanded={isCategoryHasCheckedSubItem(item2.id) === true || item2.id === subCategories[0] ? true : false} key={item.id} sx={{ p: 2, mt: 2, display: "contents" }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Box>
                                                                        <Checkbox
                                                                            id={item2.id}
                                                                            onChange={(e) => { handleCategoryClick(1, e, 0) }}
                                                                            style={{ display: 'inline-flex' }}
                                                                            defaultChecked={isCategoryHasCheckedSubItemAll(item2.id) === true || item2.id === subCategories[0] ? true : false}
                                                                            inputProps={
                                                                                { 'aria-label': 'Checkbox demo' }
                                                                            }
                                                                        />
                                                                        <p style={{ fontSize: '14px', display: 'contents' }}>
                                                                            {item2.adi}
                                                                        </p>
                                                                    </Box>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {item2.children.map((item3) => (
                                                                        <Box key={item3.id} pl={2}>
                                                                            <Checkbox
                                                                                id={item3.id}
                                                                                key={item2.id}
                                                                                onChange={(e) => { handleCategoryClick(2, e, item2.id) }}
                                                                                defaultChecked={item3.id === subCategories[0] || item2.id === subCategories[0] || detayKategori.indexOf(item3.id) !== -1 ? true : false} />
                                                                            <p style={{ fontSize: '12px', display: 'contents' }}>
                                                                                {item3.adi}
                                                                            </p>
                                                                        </Box>
                                                                    ))}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        ))}
                                                    </>
                                                )
                                            }
                                        }))
                                    }
                                </Paper>
                            </Grid>
                            <Grid display="flex" sx={{ display: searchforFirmsOrItems === 'Urunler' || searchforFirmsOrItems === '' ? '' : 'none' }} item xl={parentKategoriId.length === 0 ? 12 : 9.5} md={parentKategoriId.length === 0 ? 12 : 9.5} xs={parentKategoriId.length === 0 ? 12 : 12}>
                                <Grid id='scrollToTable' container spacing={0}>
                                    <Grid pl={5} pb={6} mt={2.5} item xl={12} md={12} xs={12}>
                                        <Typography sx={styles.Header1} >
                                            Ürünler
                                        </Typography>
                                    </Grid>
                                    {newItemsData !== null && newItemsData.length > 0 ? (newItemsData.map((item) => (
                                        <Grid key={item.id} mb={5} item xl={4} l={4} md={4} s={8} xs={12}>
                                            <ItemCard props={item} />
                                        </Grid>
                                    )))
                                        : (
                                            <Grid align='center' key={1} mb={5} item xl={12} md={12} xs={12}>
                                                Arama Sonucuna Uygun Bir Ürün Bulunamadı
                                            </Grid>
                                        )}
                                    <Grid align='center' item xl={12} md={12} xs={12} pr={20} mt={5}>
                                        <TablePagination
                                            sx={{ display: newItemsData !== null && newItemsData.length > 0 ? '' : 'none' }}
                                            labelDisplayedRows={
                                                ({ from, to, count }) => {
                                                    return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                                                }}
                                            component="div"
                                            count={resultCount}
                                            page={page}
                                            key='Urunler'
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            labelRowsPerPage="Sayfada şu kadar sonuç göster"
                                            rowsPerPageOptions={[
                                                12, 20, 24, 48, 60
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid sx={{ display: searchforFirmsOrItems === 'Firmalar' || searchforFirmsOrItems === '' || parentKategoriId.length === 0 ? '' : 'none' }} item xl={parentKategoriId.length === 0 ? 12 : 9.5} md={parentKategoriId.length === 0 ? 12 : 9.5} xs={parentKategoriId.length === 0 ? 12 : 12}>
                                <Grid id='scrollToTable' container spacing={0}>
                                    <Grid pb={5} ml={5} mt={3} item xl={12} md={12} xs={6}>
                                        <Typography sx={styles.Header1} >
                                            Firmalar
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        item xl={12} md={12} xs={12} pl={2} pr={2}>
                                        <Grid container align='center' spacing={0}>
                                            {allFirmsData !== null && allFirmsData.length > 0 ? (allFirmsData?.map(item => (
                                                <Grid key={item.id} item mt={5} xl={4} md={4} xs={12}>
                                                    <Card key={item.id} sx={styles.CardFirm}>
                                                        <CardContent sx={styles.CardContentFirm}>
                                                            <Box sx={styles.CardBoxWrapper}>
                                                                <Link underline="none" href={`/PazarYeri/Firma/${item.seourl}`} >
                                                                    <img style={{ width: '370px', height: '168px' }} src={
                                                                        item.gorsel ? `/images/FirmaGorsel/${item?.gorsel}` : FirmaNoBackground
                                                                    } alt='test t' />
                                                                    <img style={styles.CardBrandLogoOnImage}
                                                                        src={item?.logo ? `/images/Logo/${item?.logo}` : NoFirmLogo}
                                                                        alt='Firma Logosu Bulunamadı' />
                                                                </Link>
                                                            </Box>
                                                            <Box sx={styles.CardBtnWrapper}>
                                                                <Link underline="none" href={`/PazarYeri/Firma/${item.seourl}`} >
                                                                    <Box sx={styles.CardBtnDetay}>
                                                                        <Typography sx={styles.CardBtnDetayText}>
                                                                            {item?.firmaadikisa ? item.firmaadikisa : item.firmaadi}
                                                                        </Typography>
                                                                    </Box>
                                                                </Link>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            ))) : (
                                                <Grid key={1} mb={5} item xl={12} md={12} xs={12}>
                                                    Arama Sonucuna Uygun Bir Firma Bulunamadı
                                                </Grid>

                                            )}

                                        </Grid>
                                    </Grid>
                                    <Grid align='center' item xl={12} md={12} xs={6} pr={20} mt={5}>
                                        <TablePagination
                                            sx={{ display: allFirmsData !== null && allFirmsData.length > 0 ? '' : 'none' }}
                                            labelDisplayedRows={
                                                ({ from, to, count }) => {
                                                    return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                                                }}
                                            component="div"
                                            count={resultCount}
                                            page={page}
                                            key='Firmalar'
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            labelRowsPerPage="Sayfada şu kadar sonuç göster"
                                            rowsPerPageOptions={[
                                                12, 20, 24, 48, 60
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid >
            </Grid >
            <Footer />
        </>
    )
}

export default AramaSonucu