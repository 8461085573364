import React from 'react'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import { Box, Container, Grid, Typography } from '@mui/material'
import { useEffect } from 'react';

function AydinlatmaMetni({ isShowNavbarFooter = true }) {

    console.log(isShowNavbarFooter)

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | Aydınlatma Metni';
    }, []);

    const styles = {
        Header1: {
            "height": "120px",
            "fontFamily": "'Gilroy'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "50px",
            "lineHeight": "120%",
            "color": "#000000"
        }
    }



    return (
        <>
            <Box sx={{
                display: isShowNavbarFooter ? '' : 'none'
            }}>
                <Navbar />
            </Box>
            <Container sx={{ background: '#F4F7F9', mt: 12, mb: 5 }} maxWidth="lg">
                <Grid sx={{ background: '#F4F7F9' }} align='center' justifyContent="center" container spacing={0}>
                    <Grid pt={20} pb={5} item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header1} >
                            Aydınlatma Metni
                        </Typography>
                    </Grid>

                    <Grid item xl={12} md={12} xs={12} pl={3} pr={3} >
                        <div>
                            <div >
                                <div>
                                    <p>
                                        İnşaat sektöründe hali hazırda devam eden ve yeni başlayacak projelerle kişileri
                                        elektronik ortamda buluşturmak üzere kurulan Yapı Radar alanında uzman
                                        kişilerle danışmanlık ve satış hizmeti sunmakta, aynı zamanda alanındaki
                                        özverisini kişisel verileri koruma bakımından da göstermektedir. Bu bakımdan Yapı
                                        Radar Anayasa Madde 20, 6698 sayılı kişisel Verileri Koruma Kanunu ve her türlü
                                        ikincil dereceden yönetmelik, genelge gibi kişisel verileri düzenlemeye yarayan
                                        genel ve özel kanun varsa hepsine uyarak kişisel verileri toplar, muhafaza eder ve
                                        gerektiğinde siler. 6698 sayılı kanun gereğince YAPI RADAR “Veri Sorumlusu”
                                        olarak kanun ve mevzuatta bulunan yönergelere uygun şekilde hareket eder.
                                    </p>
                                    <h4>VERİ SORUMLUSU OLARAK YAPI RADAR</h4>
                                    <p>
                                        Kanun uyarınca Yapı Radar merkez olarak Gayrettepe Mah. Yıldız Posta Caddesi Akın Sitesi 1.Blok No:6/13
                                        Beşiktaş / İstanbul adresinde faaliyet
                                        göstermektedir. Kanunda belirtildiği üzere YAPI RADAR “Veri Sorumlusu ”dur.
                                    </p>
                                    <h4>YAPI RADAR VERİLERİ NEDEN İŞLER?</h4>
                                    <p>
                                        Parekende satış yapmak üzere çeşitli faaliyetlerde bulunan YAPI RADAR gerek
                                        hukuki yükümlülüklerini yerine getirirken gerekse yaptığı iş ile alakalı verilen
                                        hizmet gereğince kişisel verileri depolamak ve işlemek durumundadır. Kişisel
                                        verileriniz YAPI RADAR tarafından;
                                    </p>
                                    <ul style={{ textAlign: 'left' }}>
                                        <li>4857 sayılı İş Kanunu</li>
                                        <li>3194 Sayılı İmar Kanunu</li>
                                        <li>488 Sayılı Damga Vergisi Kanunu</li>
                                        <li>492 Sayılı Harçlar Kanunu</li>
                                        <li>5232 Sayılı Türk Ceza Kanunu</li>
                                        <li>5271 Sayılı Ceza Muhakemesi Kanunu</li>
                                        <li>
                                            5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu’nda ve bu
                                            Kanun’a bağlı çıkarılan ikinci düzenlemelerde belirtilen yükümlülüklerimizi
                                            yerine getirmek,
                                        </li>
                                        <li>5520 Sayılı Kurumlar Vergisi Kanunu</li>
                                        <li>
                                            5651 Sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi Ve Bu
                                            Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun
                                        </li>
                                        <li>5809 Elektronik Haberleşme Kanunu</li>
                                        <li>6098 Sayılı Türk Borçlar Kanunu</li>
                                        <li>6102 Sayılı Ticaret Kanunu</li>
                                        <li>
                                            6331 sayılı İş Sağlığı ve Güvenliği Kanunu’nda ve bu Kanun’a bağlı çıkarılan
                                            ikinci düzenlemelerde belirtilen yükümlülüklerimizi yerine getirmek,
                                            Hükümlerine uyum sağlamak ve;
                                        </li>
                                        <li>
                                            4857 sayılı İş Kanunu’nda ve bu Kanun’a bağlı çıkarılan ikinci düzenlemelerde
                                            belirtilen yükümlülüklerimizi yerine getirmek,
                                        </li>
                                        <li>
                                            5510 sayılı Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu’nda ve bu
                                            Kanun’a bağlı çıkarılan ikinci düzenlemelerde belirtilen yükümlülüklerimizi
                                            yerine getirmek,
                                        </li>
                                        <li>
                                            6331 sayılı İş Sağlığı ve Güvenliği Kanunu’nda ve bu Kanun’a bağlı çıkarılan
                                            ikinci düzenlemelerde belirtilen yükümlülüklerimizi yerine getirmek,
                                        </li>
                                        <li>
                                            6698 sayılı Kişisel Verilerin Korunması Kanunu’nda ve bu Kanun’a bağlı
                                            çıkarılan ikinci düzenlemelerde belirtilen yükümlülüklerimizi yerine getirmek,
                                        </li>
                                        <li>
                                            Gerektiğinde ilgili kamu kurum ver kuruluşlarının, adli veya idari yargı
                                            mercilerinin ve kolluk güçlerinin ihtiyacı olan bilgileri sağlamak,
                                            amaçlarıyla kanunda belirtilen sınırlar çerçevesinde hukuka, dürüstlük kurallarına
                                            uygun ve işbu amaçlarla her daim bağlantılı, sınırlı ve ölçülü şekilde işlenmektedir.
                                        </li>
                                    </ul>
                                    <h4>YAPI RADAR’NİN KİŞİSEL VERİLERİ AKTARMA NEDENLERİ</h4>
                                    <p>
                                        Kişisel verileriniz, yukarıda sayılan amaçlar dâhilinde, Kanun’un 8. ve 9.
                                        maddesinde belirtilen şartlara uygun olarak iş ilişkisi içinde olduğumuz firmalara,
                                        müteahhitlere, başvuruculara, fabrikalara, mevzuatta izin verilen gerçek ve tüzel
                                        kişilere, gerektiğinde yetkili diğer kamu kurum ve kuruluşlarına, sözleşmesel ilişki
                                        kurulan ilgili tarafa, adli ve idari yargı mercilerine, kolluk kuvvetlerine, iş ilişkisi
                                        içerisinde olduğumuz kurum ve kuruluşlara, dışarıdan hizmet alınan danışmanlık,
                                        teknoloji ve eğitim Şirketlerine aktarılabilmektedir.
                                    </p>
                                    <h4>
                                        KİŞİSEL VERİLER NASIL TOPLANIYOR VE HUKUKİ SEBEPLERİ
                                        NELERDİR?
                                    </h4>
                                    <p>
                                        Kişisel verileriniz; YAPI RADAR tarafından kendisi yahut yetkilendirilmiş veri
                                        işleyen gerçek veya tüzel kişiler tarafından, e-posta, telefon, internet sitesi,
                                        muhtelif sözleşmeler, kâğıt ortamında tutulan formlar, elektronik ortamalarda yer
                                        alan alenileştirilmiş veriler ve tutanaklar gibi vasıtalarla otomatik ve otomatik
                                        olmayan yöntemlerle sözlü, yazılı veya elektronik ortamda toplanmaktadır.
                                        <br />
                                        Bu kapsamda;
                                    </p>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>İlgili Kişi Grubu</th>
                                                <th>Alınan Kişisel veri</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Personel</td>
                                                <td>
                                                    Ad-Soyad, Kimlik Bilgileri, Cinsiyet, Uyruk, Doğum Yeri,
                                                    Doğum Tarihi, Telefon Numarası, E-Posta Adresi, Adres
                                                    Bilgileri, Kan Grubu, Banka Hesap Bilgileri, Medeni Durum,
                                                    Askerlik Bilgileri, Eğitim Bilgisi, Log Kayıtları, Vesikalık
                                                    Fotoğraf, Sağlık Bilgileri, Sertifikalar, İş Tecrübesi,
                                                    Referanslar, Aile Durumuna İlişkin Bilgiler
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Çalışan
                                                    Adayı/Başvurucu/
                                                </td>
                                                <td>
                                                    Ad-Soyad, Kimlik-Pasaport Bilgileri, Cinsiyet, Uyruk, Ana-
                                                    Baba Adı, Doğum Yılı, Doğum Yeri, Medeni Durum, Eğitim
                                                    Bilgisi, İmza, Telefon Numarası, E-Posta Adresi, Vesikalık
                                                    Fotoğraf, Adres Bilgileri, Banka Hesap Bilgileri

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Müteahhit/Yatırımcı
                                                </td>
                                                <td>
                                                    Ad-Soyad, Kimlik Bilgileri, Cinsiyet, Banka Bilgileri, adres,
                                                    E-posta Adresi, Telefon Numarası, Kişisel Web Adresi,
                                                    Şirket Bilgileri
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>
                                        tipindeki özel ve genel nitelikli kişisel veriler Kanun’un 5. maddesinin 2/a-c-ç-e-f
                                        bentleri ve 6. maddesinin 3. fıkrası uyarınca, temel hak ve özgürlüklerinize zarar
                                        vermemek kaydıyla ve YAPI RADAR’ın meşru menfaatleri, kurulan veya kurulacak
                                        olan sözleşme hukuki sebeplerine dayalı olarak işlenmektedir.
                                    </p>
                                    <h4>VERİ SAHİPLERİNİN KANUN KAPSAMINDAKİ HAKLARI</h4>
                                    <p>
                                        Dilediğiniz zaman YAPI RADAR’a başvurarak kişisel verilerinizin;
                                    </p>
                                    <ul>
                                        <li>
                                            İşlenip işlenmediğini, işlenme amacını ve amacına uygun kullanıp
                                            kullanılmadığı öğrenebilir ve işlenmiş ise bu konuda bilgi isteyebilir,
                                        </li>
                                        <li>
                                            Kanun’a uygun olarak yurt içinde ve yurt dışında bilgilerinizin paylaşıldığı
                                            üçüncü kişileri öğrenebilir,
                                        </li>
                                        <li>
                                            Bilgilerinizin eksik ya da hatalı işlendiğini düşünüyorsanız düzeltilmesini
                                            isteyebilir,
                                        </li>
                                        <li>
                                            Kanunun 7. maddesinde öngörülen şartlar çerçevesinde bilgilerinizin
                                            silinmesini ya da yok edilmesini talep edebilir,
                                        </li>
                                        <li>
                                            Bilgilerinizin aktarıldığı üçüncü kişilere (c) ve (d) bentlerinde belirtilen
                                            taleplerinizin bildirilmesini ve aynı işlemleri gerçekleştirmelerini isteyebilir,
                                        </li>
                                        <li>
                                            Bilgilerinizin, otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir
                                            sonucun ortaya çıkmasına itiraz edebilir veya kanuna aykırı olarak
                                            kaydedildiğini veya kullanıldığını düşünüyorsanız ve bu sebeple zarara
                                            uğramışsanız zararın giderilmesini isteyebilirsiniz.
                                        </li>
                                    </ul>
                                    <p>
                                        Kanun kapsamındaki haklarınızdan yararlanmak için başvurularınızı, yazılı olarak
                                        YAPI RADAR’a iletebilir, detaylı bilgi almak için Kişisel Verileri Koruma
                                        Kurumu’nun internet sayfasını ziyaret edebilirsiniz.
                                        Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen haklarınızı
                                        kullanmak için yapacağınız ve kullanmayı talep ettiğiniz hakka ilişkin
                                        açıklamalarınızı içeren başvuruda; talep ettiğiniz hususun açık ve anlaşılır olması,
                                        talep ettiğiniz konunun şahsınız ile ilgili olması veya başkası adına hareket ediyor
                                        iseniz bu konuda noter tarafından tasdiklenmiş özel vekâletnamenizi ibraz etmeniz
                                        gerekecektir.
                                        Başvurularınızda, ad-soyad, imza, T.C. kimlik numarası, ikamet veya işyeri adresi,
                                        e-posta adresi, telefon ve faks numarası, talep konusu unsurlarının bulunması
                                        “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ” uyarınca
                                        zorunludur. Söz konusu unsurları barındırmayan başvurular YAPI RADAR
                                        tarafından reddedilecektir.
                                    </p>
                                    <p>
                                        YAPI RADAR’in işbu aydınlatma metninde Kanun’dan, ikincil düzenlemelerden ve
                                        Kurul kararlarından doğan sebeplerle değişiklik yapma hakkı her zaman saklıdır.
                                        Aydınlatma metninde yapılacak değişiklikler ve güncel metin tarafınıza tebliğ
                                        edildiği tarih itibariyle derhal geçerlilik kazanacaktır.
                                    </p>
                                    <div style={{ textAlign: 'left' }}>
                                        <strong>

                                            YAPI RADAR
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </Container>
            <Box sx={{
                display: isShowNavbarFooter ? '' : 'none'
            }}>
                <Footer />
            </Box>
        </>
    )
}

export default AydinlatmaMetni