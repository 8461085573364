import { Grid, Typography, Container } from '@mui/material'
import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';

function CerezKullanimPolitikasi() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | KVKK';
    }, []);

    const styles = {
        Header1: {
            width: '100%',
            fontFamily: "'Gilroy'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "50px",
            lineHeight: "120%",
            color: "#000000",
            textAlign: 'center',
            marginBottom: '1em'
        },
        Header2: {
            margin: '2em 0',
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "25px",
            lineHeight: "35px",
            display: "flex",
            alignItems: "center"
        },
        Header3: {
            margin: '2em 0',
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "35px",
            display: "flex",
            alignItems: "center",
            color: "#303C43"
        },
        Text: {
            textAlign: "justify",
            margin: '2em 0',
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "26px",
            color: "#303C43"
        }
    };

    return (
        <>
            <Navbar />
            <Container sx={{ background: '#F4F7F9', mt:12, paddingTop: '5em', paddingBottom: '5em' }} maxWidth="lg">
                <Typography sx={styles.Header1}>
                Yapıradar Çerez Kullanım Politikası
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography sx={styles.Header3}>
                        ÇEREZ KULLANIMI
                        </Typography>
                        <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-8">
                <div class="whiteArea">
                    <div class="parentTextInside">
                        <div id="sss">
                            <div class="sikcasorulansorular">
                                <div class="accordion panel-group" id="accordion">
                                    <strong>
                                        ÇEREZ (“COOKIE”)
                                    </strong>
                                    <p>
                                        İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı deneyiminizi
                                        geliştirebilmek için Cookie kullanıyoruz. Cookie kullanılmasını tercih etmezseniz
                                        tarayıcınızın ayarlarından Cookie’leri silebilir ya da engelleyebilirsiniz. Ancak bunun internet
                                        sitemizi kullanımınızı etkileyebileceğini hatırlatmak isteriz. Tarayıcınızdan Cookie
                                        ayarlarınızı değiştirmediğiniz sürece bu sitede çerez kullanımını kabul ettiğinizi varsayacağız.
                                        Sitemizde yer alan servislerin kullanımı için bizimle doğrudan veya dolaylı olarak bazı bilgi
                                        ve veriler paylaşmaktasınız. Bu bilgiler siteye kayıt olurken verdiğiniz kişisel bilgiler (isim,
                                        eposta adresi gibi) veya siteyi kullanımınız esnasında topladığımız bilgilerdir. (IP, cihaz,
                                        konum ve tarayıcı bilgileri, gezinti ve etkileşim içeriği, tarih ve bağlantı süresi gibi veriler)
                                        Bilgi ve verilerinizi saklama konusunda azami özeni göstermekteyiz.
                                    </p>
                                    <h4>
                                        İNTERNET SİTESİNDE KULLANILAN ÇEREZLER
                                    </h4>
                                    <strong>
                                        Çerez Nedir ve Neden Kullanılmaktadır?
                                    </strong>
                                    <p>
                                        Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ
                                        sunucusuna depolanan küçük metin dosyalarıdır.
                                        İnternet Sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:
                                    </p>
                                    <ul>
                                        <li>
                                            İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan
                                            hizmetleri geliştirmek,
                                        </li>
                                        <li>
                                            İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve
                                            sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek;
                                        </li>
                                        <li>
                                            İnternet Sitesinin, sizin ve Şirketimizin hukuki ve ticari güvenliğinin teminini sağlamak.
                                        </li>
                                    </ul>
                                    <h4>
                                        İnternet Sitemizde Kullanılan Çerez Türleri
                                    </h4>
                                    <table class="table mt-3">
                                            <tr>
                                                <td>
                                                    Oturum Çerezleri
                                                </td>
                                                <td>
                                                    Oturum çerezleri ziyaretçilerimizin İnternet Sitesini ziyaretleri
                                                    süresince kullanılan, tarayıcı kapatıldıktan sonra silinen geçici
                                                    çerezlerdir. <br />
                                                    Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz
                                                    süresince İnternet Sitesinin düzgün bir biçimde çalışmasının
                                                    teminini sağlamaktır. <br />
                                                    Örneğin; birden fazla sayfadan oluşan çevrimiçi formları
                                                    doldurmanızın sağlanmaktadır.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Kalıcı Çerezler
                                                </td>
                                                <td>
                                                    Kalıcı çerezler İnternet Sitesinin işlevselliğini artırmak,
                                                    ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla
                                                    kullanılan çerez türleridir. <br />
                                                    Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve
                                                    tarayıcılar vasıtasıyla cihazınızda depolanır.
                                                    Kalıcı çerezlerin bazı türleri; İnternet Sitesini kullanım amacınız
                                                    gibi hususlar göz önünde bulundurarak sizlere özel öneriler
                                                    sunulması için kullanılabilmektedir. <br />
                                                    Kalıcı çerezler sayesinde İnternet Sitemizi aynı cihazla tekrardan
                                                    ziyaret etmeniz durumunda, cihazınızda İnternet Sitemiz
                                                    tarafından oluşturulmuş bir çerez olup olmadığı kontrol edilir ve
                                                    var ise, sizin siteyi daha önce ziyaret ettiğiniz anlaşılır ve size
                                                    iletilecek içerik bu doğrultuda belirlenir ve böylelikle sizlere
                                                    daha iyi bir hizmet sunulur.
                                                </td>
                                            </tr>
                                    </table>

                                    <strong>
                                        İnternet Sitemizde Kullanılan Çerezler
                                    </strong>
                                    <table class="table mt-3">
                                        <tr>
                                            <td>
                                                Otantikasyon
                                                Çerezleri
                                            </td>
                                            <td>
                                                Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş
                                                yapmaları durumunda, bu tür çerezler ile, ziyaretçinin internet
                                                sitesinde ziyaret ettiği her bir sayfada site kullanıcısı olduğu
                                                belirlenerek, kullanıcının her sayfada şifresini yeniden girmesi
                                                önlenir.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Analitik Çerezler
                                            </td>
                                            <td>
                                                Analitik çerezler ile internet sitesini ziyaret edenlerin sayıları,
                                                internet sitesinde görüntülenen sayfaların tespiti, internet sitesi
                                                ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi
                                                analitik sonuçların üretimini sağlayan çerezlerdir.
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </>
    );
}

export default CerezKullanimPolitikasi;
