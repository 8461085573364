import { Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AskForAnOffer from '../Components/DigitalCatalogue/AskForAnOffer'
import ItemPreview from '../Components/DigitalCatalogue/ItemPreview'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import NavbarSearchable from '../Components/Navigation/NavbarSearchable'

function DigitalCatalogItem() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const params = useParams();
    return (
        <>
            <NavbarSearchable />
            <Grid mt={3} container spacing={0}>
                <ItemPreview itemSeoUrl={params.itemSeoUrl} />
                <AskForAnOffer  type='item' itemSeoUrl={params.itemSeoUrl} />
                <TryForFree />
                <Footer />
            </Grid>

        </>
    )
}

export default DigitalCatalogItem