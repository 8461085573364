import { Button, Grid, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { postData } from '../Assets/Scripts/dataAxios'
import { ShowMessage } from '../Components/Common/Alerts'
import Navbar from '../Components/Navbar'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ReCAPTCHA from "react-google-recaptcha";
import Footer from '../Components/Footer';

function ConnectWithUs() {

    const [adsoyad, Setadsoyad] = useState(false);
    const [mail, Setmail] = useState(false);
    const [telefon, Settelefon] = useState(false);
    const [mesaj, Setmesaj] = useState(false);
    const [ip, setIP] = useState('');
    const [chaptcha, Setchaptcha] = useState(false);

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        getData()
    }, [])

    const handleSubmit = async () => {
        if (!adsoyad) {
            ShowMessage('error', "Lütfen Adınzı ve Soyadınızı Giriniz.")
        }
        else if (!telefon) {
            ShowMessage('error', "Lütfen Geçerli Bir Telefon Numarası Giriniz.")
        }
        else if (!mail) {
            ShowMessage('error', "Lütfen Geçerli Bir Mail Adresi Giriniz.")
        }
        else if (!mesaj) {
            ShowMessage('error', "Lütfen Bir Mesaj Giriniz.")
        }
        else if (!chaptcha) {
            ShowMessage('error', "Lütfen Robot Olmadığınızı Doğrulayınız")
        } else {
            const reqData = {
                adsoyad: adsoyad,
                mail: mail,
                telefon: telefon,
                mesaj: mesaj,
                ip: ip
            }
            const res = await postData('Landing/IletisimKayit', reqData);
            if (res.data.isSucceeded) {
                ShowMessage('success', 'İletişim Kaydınız Alındı. En Kısa Sürede Tarafınıza Dönüş Sağlanacaktır.')
            } else {
                ShowMessage('success', res.data.message)
            }
        }

    }

    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "16px", md: "30px" },
            "lineHeight": "130%",
            "color": "#3c677f",
            "justifyContent": "center",
        },
        Header2: {
            margin: '1em 0',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "16px",
            "lineHeight": "130%",
            "color": "#889AA4"
        },
        Text1: {
            marginRight: '30px',
            "height": "60px",
            "background": "#FFFFFF",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
            },
        },
        Text2: {
            marginTop: '10px',
            marginRight: '30px',
            "height": "60px",
            "background": "#FFFFFF",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
            },
        },
        Text3: {
            marginTop: '10px',
            "height": "60px",
            "background": "#FFFFFF",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
            },
        },
        Text4: {
            marginTop: '10px',
            marginBottom: '10px',
            "width": "100%",
            "height": "60px",
            "background": "#FFFFFF",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
            },
        },
        SubmitBtn: {
            marginTop: '10px',
            "padding": "15px 25px",
            "gap": "10px",
            "width": "100%",
            "height": "50px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                "background": "#FF6600",
                "boxShadow": "none",
            }
        },
        IconHeader: { display: 'inline', "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "12.5px", "lineHeight": "28px", "color": "#303C43" },
        IconText: { "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "13px", "lineHeight": "22px", "color": "#303C43 !important", "opacity": "0.75" },
    }

    function onChange(value) {
        Setchaptcha(value);
    }
    return (
        <>
            <Navbar />
            <Box sx={{ background: "#F4F7F9" }} >

                <Grid container spacing={0} style={{ justifyContent: "center " }} >

                    <Grid container justifyContent={"center"} textAlign={"center"} >
                        <Grid item xl={12} md={12} xs={12} borderRadius={"20px"} p={1.5} >

                            <Typography sx={styles.Header1} mt={{ xs: 10, md: 15 }} mb={{ xs: 0, md: 5 }}  >
                                Yapıradar Bilgi Teknolojileri A.Ş.
                            </Typography>
                        </Grid>
                        <Grid item xl={2.8} md={2.8} xs={5.8} mr={0.5} mb={0.5} backgroundColor={"#436c840a"} borderRadius={"20px"} p={1.5} >
                            <LocationOnIcon sx={{ color: '#8FBFFA', display: 'inline', marginBottom: "-6px", marginRight: "5px", textAlign: "center" }} />
                            <Typography sx={styles.IconHeader}>
                                Merkez Ofis
                            </Typography>
                            <Typography sx={styles.IconText}>
                                Gayrettepe Mah. Yıldız Posta Caddesi
                            </Typography>
                            <Typography sx={styles.IconText}>
                                Akın Sitesi 1.Blok No:6/13
                            </Typography>
                            <Typography sx={styles.IconText}>
                                Beşiktaş / İstanbul
                            </Typography>

                        </Grid>
                        <Grid item xl={2.8} md={2.8} xs={5.8} mr={0.5} mb={0.5} backgroundColor={"#436c840a"} borderRadius={"20px"} p={1.5}>
                            <LocationOnIcon sx={{ color: '#8FBFFA', marginBottom: "-6px", marginRight: "5px" }} />
                            <Typography sx={styles.IconHeader}>
                                Teknopark Ofisi
                            </Typography>
                            <Typography sx={styles.IconText}>
                                Yıldız Teknik Üniversitesi Teknopark
                            </Typography>
                            <Typography sx={styles.IconText}>
                                Çiftehavuzlar Mah. Eski Londra Asfaltı Cad.
                            </Typography>
                            <Typography sx={styles.IconText}>
                                A1 Blok No: B34 41400
                            </Typography>
                            <Typography sx={styles.IconText}>
                                Esenler / İSTANBUL
                            </Typography>
                        </Grid>
                        <Grid item xl={2.8} md={2.8} xs={5.8} mr={0.5} mb={0.5} backgroundColor={"#436c840a"} borderRadius={"20px"} p={1.5} >
                            <SupportAgentIcon sx={{ color: '#8FBFFA', marginBottom: "-6px", marginRight: "5px" }} />
                            <Typography sx={styles.IconHeader}>
                                Müşteri Hizmetleri
                            </Typography>
                            <Typography sx={styles.IconText} >
                                <a href="tel:+908504415554" style={{ color: "rgb(105 133 149)", textDecoration: "none", fontSize: "13px" }}>+90 (850) 441 55 54</a>
                            </Typography>
                            <Typography sx={styles.IconText}>
                                <a href="tel:+902168070737" style={{ color: "rgb(105 133 149)", textDecoration: "none", fontSize: "13px" }}>+90 (216) 807 07 37</a>
                            </Typography>
                        </Grid>

                        <Grid item xl={2.8} md={2.8} xs={5.8} mr={0.5} mb={0.5} backgroundColor={"#436c840a"} borderRadius={"20px"} p={1.5}   >
                            <AlternateEmailIcon sx={{ color: '#8FBFFA', marginBottom: "-6px", marginRight: "5px", }} />
                            <Typography sx={styles.IconHeader}>
                                E-posta
                            </Typography>
                            <Typography sx={styles.IconText}>
                                <a href="mailto:info@yapiradar.com" style={{ color: "rgb(105 133 149)", textDecoration: "none", fontSize: "13px" }}>info@yapiradar.com</a>


                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid item xl={6} md={6} xs={12} pl={{ md: 10, xs: 1 }}  >

                        <Box sx={{
                            position: { xs: 'static', md: 'relative' },
                        }}>
                            <Box sx={{
                                position: { xs: 'static', md: 'relative' },
                                top: { md: '20%' },
                                paddingLeft: { xs: 0, md: '5%' },
                                paddingRight: { xs: 2, md: 0 },
                                zIndex: { xs: 1, md: 'auto' },
                                background: { xs: '#F4F7F9', md: 'none' },
                                padding: { xs: 2, md: 0 }
                            }}>

                                <Grid container spacing={0} >
                                    <Box sx={{ width: "100%" }}>
                                        <Typography sx={styles.Header2}>
                                            Talebinizi oluşturmak ve projeleri incelemek için lütfen aşağıdaki formu doldurunuz.
                                        </Typography>

                                        <TextField
                                            onChange={e => Setadsoyad(e.target.value)}
                                            label="Ad Soyad"
                                            sx={styles.Text1}
                                            fullWidth
                                        />
                                        <TextField
                                            onChange={e => Settelefon(e.target.value)}
                                            label="+90 *** *** ** **"
                                            sx={styles.Text2}
                                            fullWidth
                                        />
                                        <TextField
                                            onChange={e => Setmail(e.target.value)}
                                            label="Email"
                                            sx={styles.Text3}
                                            fullWidth
                                        />
                                        <TextField
                                            onChange={e => Setmesaj(e.target.value)}
                                            label="Mesajınız..."
                                            sx={styles.Text4}
                                            fullWidth
                                        />
                                        <ReCAPTCHA
                                            sitekey='6LeLpvUUAAAAAB0T9m7tJzNJ2Uhm8L8vAxVjn2Cn'
                                            onChange={onChange} />
                                        <Button
                                            onClick={handleSubmit}
                                            sx={styles.SubmitBtn}>
                                            Gönder
                                        </Button>
                                    </Box>

                                </Grid>


                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={6} md={6} xs={12} pl={{ md: 5, xs: 1 }} pr={{ md: 10, xs: 1 }} mt={{ xs: 3, md: 3 }}>
                        <div style={{ height: '100%', width: '100%' }}>
                            <iframe
                                title="gmap_canvas"
                                src="https://maps.google.com/maps?q=yapiradar.com&t=&z=17&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0"
                                scrolling="no"
                                style={{ height: '100%', width: '100%', borderRadius: "10px" }}
                            ></iframe>
                        </div>
                    </Grid>

                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default ConnectWithUs
