import { Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import React, { useRef } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/system';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { useEffect } from 'react';
import { getData } from '../../Assets/Scripts/dataAxios';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
function ThePress(children) {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const [data, SetData] = useState(children.data);
    const scrollElement = useRef(null)
    const scrollLeft = (val) => {
        scrollElement.current.scrollLeft += val
    }

    const styles = {
        Header: {
            display: 'inline',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "50px",
            "lineHeight": "117.5%",
            "color": "#000000"
        },
        ShowAllBtn: {
            float: 'right',
            marginRight: '4em',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "14px",
            "lineHeight": "117.5%",
            "color": "#FF6600",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            "backgroundColor":"#ffc89796",
            "borderRadius":"20px"
        },
        CardContent: {
            maxWidth: '600px',
            backgroundColor: "transparent",
            boxShadow: 'none'
        },
        cardText1: {
            marginBottom: '1em',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "17px",
            "lineHeight": "117.5%",
            "color": "#8FBFFA",

        },
        cardText2: {
            maxHeight: '50px',
            marginBottom: '1em',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "26px",
            "minHeight": '120px',
            "lineHeight": "140%",
            "color": "#303C43",
            maxWidth: '476px',
        },
        Wrapper: {
            display: 'inline-block',
        },
        WrapperGrid: {
            position: "relative"
        },
        bussinessPartnersArea: {
            display: "flex",
            overflowX: "scroll",
            flexShrink: "0"
        },
        scrollBussinessPartners: {
            zIndex: 2, "position": "absolute",
            bottom: "5vh",
            right: "10vw",
            overflow: "hidden",
            borderRadius: "50%",
            background: "rgba(143, 191, 250, 0.2)",
            border: "2px solid #D7E9FF",
            boxShadow: "0px 34px 44px -10px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(22px)",
            width: "102px",
            height: "102px",
            boxSizing: "border-box"
        },
        scrollBussinessPartnersLine: {
            position: "absolute",
            width: "86px",
            height: "86px",
            top: "6px",
            left: "6px",
            backgroundColor: "transparent",
            overflow: "hidden",
            borderRadius: "50%",
            boxSizing: "border-box",
            border: "2px solid #8FBFFA"
        },
        image: {
            bottom: '2em'
        }
    }


    useEffect(() => {
        const GetData = async () => {
            const res = await getData('Landing/BasindaBiz');
            SetData(res.data.value);
        }
        GetData()
            .catch(console.error);
    }, [])


    return (
        <>
            <Grid p={6} container spacing={0}>
                <Grid mt={10} p={2} item xl={12} md={12} xs={12}>
                    <Typography sx={styles.Header}>
                      {translate.BasindaBiz}
                    </Typography>
                    <Button
                        endIcon={<ArrowForwardIcon />}
                        sx={styles.ShowAllBtn}
                    >
                      {translate.TumunuGor}
                    </Button>
                </Grid>
                <Grid sx={styles.WrapperGrid} item p2={10} mt={5} xl={12} md={12} xs={11}>

                    <Box sx={styles.scrollBussinessPartners}>
                        <Box alignItems='center' justifyContent='center' justifyItems='center' display='flex' sx={styles.scrollBussinessPartnersLine}>
                            <IconButton
                                onClick={() => { scrollLeft(-450) }}
                                size='small'
                            >
                                <ArrowBackIos />
                            </IconButton>
                            <IconButton
                                onClick={() => { scrollLeft(+450) }}
                                size='small'
                            >
                                <ArrowForwardIos />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box ref={scrollElement} sx={styles.bussinessPartnersArea}>
                        {data.map((data) => (
                            <Box key={data.id} sx={styles.Wrapper}>
                                <Card key={data.id} sx={styles.CardContent}>
                                    <CardContent sx={{ position: 'relative' }}>
                                        <Link target="_blank" style={{
                                            height:'50px',
                                            textDecoration: 'none',
                                            marginTop:'10px',
                                            marginBottom:'20px'
                                        }} to={data.metin}>
                                            <Typography sx={styles.cardText1}>
                                                {data.baslik}
                                            </Typography>
                                        </Link>
                                        <img style={styles.image} sizes="(max-width: 500px) 500px, 565px" src={data.gorsel} alt='yapiradar.com' />
                                    </CardContent>
                                </Card>
                            </Box>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default ThePress