import { Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import EnteringCatalogue from '../Components/DigitalCatalogue/EnteringCatalogue'
import Firms from '../Components/DigitalCatalogue/Firms'
import TakeYourPlacement from '../Components/DigitalCatalogue/TakeYourPlacement'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import NavbarSearchable from '../Components/Navigation/NavbarSearchable'

function DigitalCatalogFirm() {
  const user = useSelector((state) => JSON.parse(state.Auth.user));


  const location = useLocation();
  var searchWorld = '';
  var searchFilter = '';
  var sayfa = 0;


  if (location.search) {
    const searchString = location.search.substring(1).split(',')
    if (searchString.length > 0) {
      searchString.forEach(item => {
        if (item.split('=')[0] === 'firmaAdi' || item.split('=')[0] === 'urunAdi') {
          searchWorld =  decodeURIComponent(item.split('=')[1]);
        }
        else if (item.split('=')[0] === 'sayfa') {
          sayfa = parseInt(item.split('=')[1]);
        }
      })
    }
  }
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavbarSearchable />
      <Grid mt={10} container spacing={0}>
        <EnteringCatalogue />
        <Firms searchString={searchWorld} />
        <TakeYourPlacement />
        <TryForFree />
        <Footer />
      </Grid>
    </>
  )
}

export default DigitalCatalogFirm