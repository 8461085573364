import styled from "@emotion/styled";
import { Button } from "@mui/material";




export const StyledFilterSearchButton = styled(Button)({
    textTransform: "none",
    "justifyContent": "center",
    "alignItems": "center",
    "padding": "15px 25px",
    "gap": "10px",
    "width": "100%",
    "height": "63px",
    "background": "#FF6600",
    "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
    "borderRadius": "20px",
    textTransform: "none",
    color: "#FFFFFF",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "120%",
    ':hover': {
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "15px 25px",
        "gap": "10px",
        "width": "100%",
        "height": "63px",
        "background": "#FF6600",
        "boxShadow": "none",
        "borderRadius": "20px",
    }
})