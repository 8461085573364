import styled from "@emotion/styled";
import { Autocomplete } from "@mui/material";




export const FilterDropdownMenu = styled(Autocomplete)({
    height: '50px',
    width: '100%',
    fontsize: '16px',
    paddingBottom: 0,

    "&.MuiAutocomplete-root .MuiAutocomplete-inputRoot": {
        height: '50px',
        borderRadius:"20px",
        alignContent: 'center',
        border: 'none'
    },
    '& .MuiInputBase-input': {

    },
    '& .MuiFormControl-root': {

    },
    "& label, & label.Mui-focused": {
        color: "rgb(43,43,43)",
        fontsize: '15px',
        fontWeight: 400,
        "fontFamily": "'Poppins'",
    }
})