import React from 'react'
import NavbarIhale from '../../Components/Navigation/NavbarIhale'
import { Box } from '@mui/material'
import Footer from '../../Components/Footer'
import { IhaleThemeGeneral } from '../../Themes/IhaleThemeGeneral'
import { ThemeProvider } from '@emotion/react'
function IhaleLayout({ children }) {
    return (
        <>
            <NavbarIhale />
            <ThemeProvider theme={IhaleThemeGeneral}>

                <div style={{ maxWidth: '1920px', margin: '0 auto' }}>
                    <Box mt={14}>
                        {children}
                    </Box>
                </div>
                <Footer />
            </ThemeProvider>
        </>


    )
}

export default IhaleLayout