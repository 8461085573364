import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import EnteringCatalogue from '../Components/DigitalCatalogue/EnteringCatalogue'
import NewItems from '../Components/DigitalCatalogue/NewItems'
import ProductOfWeek from '../Components/DigitalCatalogue/ProductOfWeek'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import CommercialImage from '../Assets/Images/Commercial.webp'
import TakeYourPlacement from '../Components/DigitalCatalogue/TakeYourPlacement'
import NavbarSearchable from '../Components/Navigation/NavbarSearchable'
import KategoridekiUrunler from '../Components/PazarYeri/KategoridekiUrunler'

const CatalogueData = {
    ProductOfWeek: {
        Image: 'ProductOfWeek.webp',
        Brand: 'Bocci',
        Header: 'Beyazdan çok daha fazlası',
        Description: 'Bu yıl skalasına beton, petrol mavisi ve Burgonya bordosunu ekleyen İtalyan kökenli Bocchi, 25 farklı renk seçeneği sunan yeni renk skalasını 21 Mart Dünya Renk Günü’nde yayınladığı reklam filmiyle lanse ediyor.'
    },

}

function DigitalCatalogue() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavbarSearchable />
            <Grid container spacing={0}>
                <EnteringCatalogue />
                <KategoridekiUrunler />
                {/* <ProductOfWeek data={CatalogueData.ProductOfWeek} /> */}
                <Grid item xl={12} md={12} xs={12}>
                    <a target="_blank" href='https://www.eczacibasi.com.tr/tr/anasayfa'>
                        <img
                            style={{
                                width: '100%',
                            }}
                            src={CommercialImage} alt='yapiradar.com commercial' />
                    </a>
                </Grid>
                <NewItems />
                <TakeYourPlacement />
            </Grid>
            <TryForFree />
            <Footer />
        </>
    )
}

export default DigitalCatalogue