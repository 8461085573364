import { createTheme } from "@mui/material";

export const IhaleThemeGeneral = createTheme({
    palette: {
        primary: {
            main: '#f45c16',
        },
        secondary: {
            main: '#3C6780',
        },
        gray: {
            main: '#6e6e6e', // Gri tonu
            primary: '#b6b6b6', // Primary rengi
            secondary: '#F50057', // Secondary rengi
        },
    },
    typography: {
        allVariants: {
            fontFamily: "'Poppins',sans-serif",
            textTransform: 'none'
        }
    },
    MuiList: {
        styleOverrides: {
            root: {
                '&.MuiMultiSectionDigitalClock-root': {
                    width: '100px',
                    '.MuiMenuItem-root': {
                        width: '80px',
                    },
                },
            },
        },
    },
})