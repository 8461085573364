import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Box } from '@mui/material';
import TryNow from '../Components/Landing HOME/TryNow';

const styles = {
  Header1: {
    textTransform: "none",
    color: "#000000",
    textAlign: 'center',
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: { xs: "40px", md: "50px" },
    lineHeight: "60px",
  },
  HeaderText: {
    width: 1600,
    mt: 3,
    textTransform: "none",
    color: "#889AA4",
    textAlign: { xs: "justify", md: 'center' },
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: { xs: "15px", md: "18px" },
    lineHeight: "30.6px",
    padding: { xs: "12px", md: "" }
  },
  QuestionText: {
    textTransform: "none",
    color: '#303C43',
    textAlign: { xs: "left", md: 'center' },
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: { xs: "15px", md: "25px" },
    lineHeight: "35px",
  },
  AnswerText: {
    mt: 1,
    textTransform: "none",
    color: '#303C43',
    textAlign: 'left',
    fontFamily: "'Poppins', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28px",
  },
  Accordion: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
    marginTop: 8,
  },
  AccordionSummary: {
    marginLeft: 10,
    marginRight: 10,
  }
}

function SSS() {
  return (
    <div>
      <Navbar />

      <Box p={2} pt={{ xs: 20, md: 30 }} pb={{ xs: 10, md: 20 }} sx={{ backgroundColor: '#F4F7F9', fontSize: "40px" }}>
        <Typography sx={styles.Header1}>
          Sıkça Sorulan Sorular
        </Typography>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography sx={styles.HeaderText}>
            Yapiradar.com;  planlanan ve yapımı devam eden güncel inşaat projeleri, kamu yapım ihaleleri, aktif satın alma talepleri, sektörde faaliyet gösteren müteahhit ve çözüm ortağı firmaların bilgilerinden oluşan büyük bir veriyi üyelerinin kullanımına sunan dijital proje bilgi bankasıdır.
          </Typography>
        </Box>
      </Box>

      <Box mb={10} mr={{ xs: 4, md: 20 }} ml={{ xs: 4, md: 20 }}>
        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>YAPIRADAR Nedir?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com;  planlanan ve yapımı devam eden güncel inşaat projeleri, kamu yapım ihaleleri, aktif satın alma talepleri, sektörde faaliyet gösteren müteahhit ve çözüm ortağı firmaların bilgilerinden oluşan büyük bir veriyi üyelerinin kullanımına sunan dijital proje bilgi bankasıdır.
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>YAPIRADAR Ne İşe Yarar?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com'a  üye olarak proje arama ve takip süreçlerini kolaylaştırabilir, iş yükünüzü hafifletebilir ve cironuzu artırabilirsiniz.  Güncel inşaat projelerinin tüm detaylarına tek tıkla ulaşarak zamandan, iş gücünden ve gizli maliyetlerden tasarruf edebilir, karar vericilerin iletişim bilgilerine erişerek başarılı satış süreçleri oluşturabilirsiniz. Müteahhitseniz satın alma talepleriniz için yetkin ve güvenilir tedarikçilere ulaşabilir, ürün ve hizmet alımlarınız için rekabetçi teklifler toplayabilirsiniz. Tedarikçiyseniz yayınladığımız satın alma talepleri arasından ürün grubunuza uygun olanlara teklif vererek yeni iş fırsatları yaratabilirsiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>YAPIRADAR'da Paylaşılan Proje Kategorileri Nelerdir?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com'da konut, villa, turizm tesisi, alışveriş merkezi, ticaret merkezi, cadde dükkan, ofis, iş merkezi, home ofis, fabrikalar ve sanayi tesisleri, sağlık tesisi, huzur evi, eğitim binası, yurt binası, dini yapı, hizmet binası, kültürel yapı, spor yapıları, otopark çevre düzenlemesi, ulaştırma, altyapı ve enerji kategorilerine ait proje bilgileri yer alıyor.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Sisteminiz Sayesinde  Projelere Katkı Sağlayan Firmalar Görüntülenebilir mi?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com ile inşaat projelerine katkı sağlayan  müteahhit, yatırımcı, elektrik proje, elektrik taahhüt, mekanik proje, mekanik taahhüt, kaba yüklenici, alt yüklenici, mimar, iç mimar, kaba yapı ve ince yapı taşeronları ve yapı denetim firmalarının yetkili/iletişim bilgilerine ulaşabilirsiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Yapiradar.com'da Hangi Bilgiler Paylaşılıyor? </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com'da yatırımcı ve müteahhit firmaların güncel satın alma taleplerini, projelerin teknik detaylarını, şantiyelerin konumlarını, fotoğraflarını, yetkili ve çözüm ortaklarının iletişim bilgilerini, inşaat sektöründe faaliyet gösteren binlerce firmanın iletişim, ulaşım ve yetkili bilgilerini, güncel kamu yapım ihale duyuruları ve kesinleşmiş sonuçlarını görüntüleyebilirsiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Sunulan Bilgiler Ne Kadar Güncel?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Sistemimizde yer alan projeler  kategorilerine ve ilerleme hızlarına göre 30-60 gün aralığında güncelleniyor.  Müteahhitlerden alınan ürün ve hizmet satın alma talepleri ise 7-15 ve 30 gün aralıklarla güncelleniyor.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Yapiradar.com Proje Bilgilerine Nasıl Ulaşıyor?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Her biri alanında uzman kişilerden oluşan proje ekibimiz internet üzerinden detaylı arama yaparak, firmaları telefonla arayarak proje detayları ve son durumları hakkında bilgi alıyor.  Sistemimizdeki tüm bilgilerin güncelliğini koruyabilmek için, edindiğimiz her bilgiyi  mutlaka firmadaki yetkili kişilerden doğruladıktan sonra üyelerimizle paylaşıyoruz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Yapıradar CRM Nedir?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Projelerin ve proje takip performansların istatistiksel olarak analizini sağlayan, çalışma verimliliğinin artmasında büyük rol oynayan yeni nesil takip modülüdür.
            </Typography>
            <Typography sx={styles.AnswerText}>
              Bu modül aynı zamanda şirket yöneticilerinin satış ekibinin çalışma performansını gözlemleyebildiği, istediği projeleri belirlediği temsilcilere atayarak daha verimli çalışmalarını sağladığı bir performans takip aracıdır.
            </Typography>
            <Typography sx={styles.AnswerText}>
              Satış personeli, proje yetkilileri ile yapmış olduğu görüşmelerin detaylarını not edebildiği gibi verdikleri teklifleri kaydederek filtreleyebilir, projelere tahmini gelir ekleyerek satış performans analizi yapabilir, takip ettikleri projeleri favorilerine ekleyebilir ve bu süreçlerle ilgili hatırlatma kaydedebilirler. Bir projeye birden fazla satış personeli atayabilirler.
            </Typography>
            <Typography sx={styles.AnswerText}>
              Yapıradar CRM sunduğu avantajların başında "kolay raporlama" gelir.Yapıradar CRM ile hem ilgilendiğiniz projelerin analizini yapmanıza hem de satış ekibinizin performasını ölçmenize yardımcı olacak raporlar oluşturabilir ve hazırladığınız raporları "excel" formatında kolaylıkla indirebilirsiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Sisteme Hangi Cihazlardan Giriş Yapabilir?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com’a bilgisayar, tablet ve telefon gibi tüm cihazlardan giriş yapabilirsiniz. Ayrıca Google Play ve App Store’dan uygulamamızı ücretsiz olarak yükleyerek sistemimize her zaman ve her yerden erişebilirsiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>YAPIRADAR Ne Kadar Mobil?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Google Play ve App Store’dan uygulamamızı yükleyerek sistemimizi ücretsiz olarak kullanabilirsiniz. Projeleri ve firmaları tek dokunuşla arayabilir, projenin konumunu görüntüleyebilir ve yol tarifi alabilirsiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Hangi Firmalara Hizmet Veriyorsunuz?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yatırımcılar, müteahhitler, inşaat malzemeleri tedarikçileri ve üreticileri başta olmak üzere kaba yapı, alt yüklenici, proje yönetim, mimarlık ofisleri, elektrik taahhüt, elektrik proje, mekanik taahhüt, mekanik proje başta olmak üzere sektörün her alanında faaliyet gösteren firmalara hizmet veriyoruz.
            </Typography>
          </AccordionDetails>
        </Accordion>



        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Yapiradar.com'a Neden Üye Olmalıyım?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              Yapiradar.com'la güncel proje bilgilerinden anında haberdar olabilir, yeni projeleri takip ederek iş fırsatları yakalayabilirsiniz.
            </Typography>
            <Typography sx={styles.AnswerText}>
              Üyelerimizin  güncel inşaat projelerini, müteahhit ve çözüm ortağı firma bilgilerini,  satın alma taleplerini ve kamu yapım ihale duyurularını tek bir platformdan takip etmelerini sağlayarak iş yüklerini hafifletiyoruz.
            </Typography>
            <Typography sx={styles.AnswerText}>
              Sistemimize yılda 12.000’den fazla yeni inşaat projesi, 14.000’den fazla müteahhit ve çözüm ortağı bilgisi ve 6.000’den fazla yeni satın alma talebi ekliyoruz. Üyelerimizin tüm bu bilgilere tek bir platformdan ulaşmasını aracılık ederek yılda ortalama 43.000 saat iş gücünden tasarruf etmelerini sağlıyoruz. Her bir üyemizin proje araştırma, saha çalışmaları ve personel için harcadığı giderleri %95 oranında azaltıyoruz.
            </Typography>
            <Typography sx={styles.AnswerText}>
              Üyelerimize yeni kazanç kapıları aralıyoruz. Sistemimizde yer alan satın alma talepleriyle yılda ortalama 750 milyon dolar değerinde ürün ve hizmet alımına aracılık ediyoruz.
            </Typography>

          </AccordionDetails>
        </Accordion>


        <Accordion sx={styles.Accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={styles.QuestionText}>Yapiradar.com'a Nasıl Üye Olabilirim?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={styles.AnswerText}>
              www.yapiradar.com adresinde yer alan iletişim formunu doldurmanız halinde yetkili arkadaşlarımız en kısa sürede sizinle iletişime geçecektir. Ayrıca yine web sitemizde yer alan Canlı Destek'ten bizimle iletişime geçerek üyelik talebinde bulunabilirsiniz.
              Yapiradar.com, bütçe dostu modüler üyelik sistemiyle çalışır. Modüler üyelik, proje kategorilerimiz ve ek özelliklerimiz arasından hedeflerinize uygun olanları seçebildiğiniz bir sistemdir. Bu sistemde sadece seçtiğiniz hizmetlerin bedelini ödersiniz.
            </Typography>
          </AccordionDetails>
        </Accordion>

      </Box>

      <TryNow />
      <Footer />
    </div>
  );
}

export default SSS