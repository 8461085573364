import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/PSTS Entering.webp'
import Image2 from '../Assets/Images/online-musteri-datasi.webp'
import Image3 from '../Assets/Images/anlik-konum.webp'
import Logo1 from '../Assets/Images/PSTS 1.webp'
import Logo2 from '../Assets/Images/PSTS 2.webp'
import Logo4 from '../Assets/Images/PSTS 4.webp'
import Logo5 from '../Assets/Images/PSTS 5.webp'
import Logo6 from '../Assets/Images/PSTS 6.webp'
import Logo7 from '../Assets/Images/PSTS 7.webp'
import Logo8 from '../Assets/Images/PSTS 8.webp'
import Logo9 from '../Assets/Images/PSTS 9.webp'
import Logo3 from '../Assets/Images/PSTS 3.webp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'


export default function PSTS() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const styles = {
        Header1: { "width":{ xs: "100%", md: "592px"} , "height": { xs: "auto", md: "36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "16px", md: "24px"} , "lineHeight": "36px", "color": "#000000" ,"textAlign":{xs: "center", md:"left" }},
        Header2: { "width": { xs: "100%", md: "620px"}, "height": { xs: "auto", md: "210px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: "30px", md: "54px"}, "lineHeight": "130%", "color": "#000000","textAlign":{xs: "center", md:"left" } },
        Header3: { "width": { xs: "100%", md: "561.11px"}, "height":{ xs: "auto", md: "93px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "15px", md: "18px"}, "lineHeight": "170%", "color": "#889AA4","textAlign":{xs: "center", md:"left" },"marginTop":{xs:"0",md:"20px"} },
        Header4: { "width": { xs: "100%", md: "450px"}, "height":{ xs: "auto", md: "auto"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "25px", md: "50px"}, "lineHeight": "130%", "color": "#000000" ,"textAlign":{xs: "center", md:"left" }},
        Header5: { "width": { xs: "100%", md: "480px"}, "height": { xs: "auto", md: "81px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "15px", md:"16px"}, "lineHeight": "170%", "color": "#303C43","textAlign":{xs: "center", md:"left" }},
        Header6: { "width": { xs: "100%", md: "750px"}, "height": { xs: "auto", md: "130px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "20px", md:"50px"}, "lineHeight": "130%", "textAlign": "center", "color": "#FFFFFF" },
        Header7: { "width": { xs: "100%", md: "512px"}, "height": { xs: "auto", md: "93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "18px"}, "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" },
        paperText: { "width": { xs: "100%", md: "304px"}, "height": { xs: "auto", md:"68px"}, "left": "97px", "top": "483px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontSize":{ xs: "20px", md: "18px"},"fontWeight": "600", "fontSize": "18px", "lineHeight": "170%", "textAlign": "center", "color": "#FFFFFF" },
        Paper: {
            backgroundColor: '#3C6780',
            margin: '35px 0px',
            border: '0',
            boxShadow: 'none',
        }
    }
    return (
        <>
            <Navbar />
            <Grid mt={12} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pt={10} p={{xs:5,md:10}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header1}>
                            {translate.PersonelSahaTakipSistemi}
                        </Typography>
                        <Typography mt={2} sx={styles.Header2}>
                            {translate.MusteriPortfoyunuzuVeZiyaretlerinizi}
                        </Typography>
                        <Typography pt={3} sx={styles.Header3}>
                            {translate.PSTSIleMusteriPortfoyunuzu}
                        </Typography>
                    </Grid>
                    <Grid mt={-10} item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid mt={{xs:5,md:10}} mb={{xs:5,md:10}} container spacing={0}>
                    <Grid pl={5} pr={5} pb={0} item xl={6} md={6} xs={12}>
                        <img width="100%" src={Image2} alt='Guncel Insaat Projeleri' />
                    </Grid>
                    <Grid p={5}  item xl={6} md={6} xs={12}>
                        <Typography mb={3} sx={styles.Header4}>
                            {translate.OnlineMusteriDatasi}
                        </Typography>
                        <Typography pt={5} sx={styles.Header5}>
                            {translate.PSTSIleSahaZiyaretlerini}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid pt={{xs:5,md:10}} pb={{xs:5,md:10}} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pl={{xs:5,md:10}} pr={5} pb={2} pt={9} item xl={6} md={6} xs={12}>
                        <Typography mb={3} sx={styles.Header4}>
                            {translate.AnlikKonumBilgisi}
                        </Typography>
                        <Typography  sx={styles.Header5}>
                            {translate.AnlikKonumBilgisininEklenmesi}
                        </Typography>
                    </Grid>
                    <Grid item pt={3} pl={3} pr={3} xl={6} md={6} xs={12}>
                        <img width="100%" src={Image3} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid sx={{ backgroundColor: '#3C6780' }} container spacing={0}>
                    <Grid align='center' pt={10} pl={10} pr={10} item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header6}>
                            {translate.PSTSAvantajlari}
                        </Typography>
                    </Grid>
                    <Grid align='center' p={5} container >
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo1} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ProjeVePersonelTakibi}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo2} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.MusteriDatasiOlusturma}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo3} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.MusteriZiyaretiTakibi}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo4} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.KonumBilgisiAnlikEkleme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo5} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.SahaZiyaretininRaporlanmasi}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo6} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ZiyaretBilgisineNotEkleme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo7} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ZiyaretGecmisiniGoruntule}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo8} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ProjelereBedelVeKisiEkleyebilme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={4} md={4} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo9} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                    {translate.ProjelereTemsilciVeyaKisiAtayabilme}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}
