import React, { useEffect, useRef, useState } from 'react'
import IhaleLayout from '../Layouts/IhaleLayout'
import { Box, Button, Divider, Grid, Paper, TextField, Typography, } from '@mui/material'
import { IhaleDetayBaslik, StyledCardHeaderLabel, StyledLabel } from '../../Components/Styled/Labels/StyledLabels'
import { useParams } from 'react-router-dom'
import { getData, postData } from '../../Assets/Scripts/dataAxios'
import { ShowMessage } from '../../Components/Common/Alerts'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { IhaleThemeGeneral } from '../../Themes/IhaleThemeGeneral'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { loadState } from '../../Assets/Scripts/LocalStorage'




const convertDateTimes = (param) => {
    const year = parseInt(param.slice(0, 4), 10);
    const month = parseInt(param.slice(4, 6), 10) - 1;
    const day = parseInt(param.slice(6, 8), 10);
    const hour = parseInt(param.slice(8, 10), 10);
    const minute = parseInt(param.slice(10, 12), 10);
    const second = parseInt(param.slice(12, 14), 10);
    const dateTime = new Date(year, month, day, hour, minute, second);
    const formattedDate = dateTime.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Europe/Istanbul'
    });

    return (formattedDate);
}
const parseDate = (dateString) => {
    const [day, month, year, hour, minute, second] = dateString.match(/\d+/g);
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
};


function IhaleSonuc() {
    //STATES AND VARIABLES______________________________________________________________________________________________
    const [ihaleData, SetIhaleData] = useState({});
    const { id } = useParams();
    const [ihaleBaslamayaKalan, setihaleBaslamayaKalan] = useState(null);
    const [ihaleOnTeklifbedeli, SetIhaleOnTeklifBedeli] = useState(0);
    const fileInputRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const UserInfoData = JSON.parse(loadState('UserInfo'));



    //HOOKS ______________________________________________________________________________________________
    useEffect(() => {
        const getIhaleData = async () => {
            try {
                const response = await getData(`Ihale/DijitalIhaleById?seourl=${id}`);
                SetIhaleData(response.data.value);
                if (!UserInfoData.isAdmin && response.isCreator === 0) {
                    window.location.href = '/Ihale'
                }
                if (response.data.value.ihaletarihiEnd !== null) {
                    if (response.data.value.isIhaleStart === 0) {
                        calculateIhaleStartedDateTimes(response.data.value.ihaletarihiBegin.toString());
                    } else if (response.data.value.isIhaleStart === 1) {
                        calculateIhaleStartedDateTimes(response.data.value.ihaletarihiEnd.toString());
                    } else {
                        console.log('bitti');
                    }
                }
            } catch (error) {
                ShowMessage('error', error);
            }
        }
        getIhaleData();
    }, [id])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (ihaleData.ihaletarihiBegin !== null && ihaleBaslamayaKalan !== null) {
                if (ihaleData?.isIhaleStart === 0) {
                    calculateIhaleStartedDateTimes(ihaleData.ihaletarihiBegin.toString());
                } else {
                    calculateIhaleStartedDateTimes(ihaleData.ihaletarihiEnd.toString());
                }

            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [ihaleBaslamayaKalan]);


    //FUNCTIONS ______________________________________________________________________________________________
    const calculateIhaleStartedDateTimes = (param2) => {
        const currentDateTime = new Date();
        const formattedCurrentDate = currentDateTime.toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Europe/Istanbul'
        });

        // Tarihleri JavaScript Date nesnelerine dönüştür
        if (param2 !== undefined && param2 !== null && param2 !== 0) {
            const date1String = formattedCurrentDate;
            const date2String = convertDateTimes(param2);
            const date1 = parseDate(date1String);
            const date2 = parseDate(date2String);
            // İki tarih arasındaki zaman farkını milisaniye cinsinden al
            const timeDifference = date2 - date1;
            // Milisaniyeyi gün, saat, dakika ve saniyeye çevir
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
            setihaleBaslamayaKalan({
                days: daysDifference,
                hours: hoursDifference,
                minutes: minutesDifference,
                seconds: secondsDifference,
            })
        }
    }

    const handleTeklifAksiyon = async (id, param) => {
        const req = {
            teklifId: id,
            isOnay: param,
            note: '',
            isEdit: false,
        }

        Swal.fire({
            title: param ? 'Teklifi Kabul Et' : 'Teklifi Reddet',
            text: param ? 'Seçilen Teklifi Kabul etmek istiyor musunuz ? Kabul etme yorumunuzu da yazabilirsiniz.' : 'Seçilen Teklifi Red etmek istiyor musunuz ? Red etme yorumunuzu da yazabilirsiniz.',
            input: "text",
            icon: param ? 'success' : 'error',
            confirmButtonText: 'Tamam',
            showCancelButton: true,
            preConfirm: async (login) => {
                try {
                    req.note = login;
                    const response = await postData('Ihale/TeklifOnaySonuc', req);
                    if (!response?.data?.isSucceeded) {
                        ShowMessage('error', response?.data?.error?.errorMessage)
                    }
                } catch (error) {
                    Swal.showValidationMessage(`
                    Request failed: ${error}
                  `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload();
            }
        })

        // 
        // if (!response.data?.isSucceeded) {
        //    
        // }
    }
    return (
        <IhaleLayout>
            <Grid sx={{ background: '#F4F7F9', padding: '1em' }} pl={5} pr={5} mb={10} container spacing={3} >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper sx={{
                        backgroundColor: '',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '1em 1em',
                        borderRadius: '20px'
                    }}>
                        <Box sx={{ width: '100%' }}>
                            <IhaleDetayBaslik>
                                İhale Teklif Aşaması Tamamlanmıştır. Yönetici olarak İhale Tekliflerini Değerlendiriniz.
                            </IhaleDetayBaslik>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper elevation={0} >
                        <Box pl='2em' pr='4em' pt='1em' pb='1.5em' >
                            <Typography fontSize='24px'>
                                İhaleye Verilen Teklifler
                            </Typography>
                        </Box>
                        {
                            ihaleData?.ihaleTeklifs?.length > 0 && (
                                ihaleData.ihaleTeklifs?.map((item, index) => {
                                    if (item.aktif === 1) {
                                        return (
                                            <Paper key={index} elevation={0} sx={{
                                                border: '1px solid #ececec ',
                                                m: 1,
                                                height: '80px',
                                                borderRadius: '20px',
                                                animation: item.isSonucOnay === 1 ? 'glow 0.6s infinite alternate' : 'glow2 0.6s infinite alternate',
                                                '@keyframes glow2': {
                                                    from: {
                                                        boxShadow: '0 0 1.4px 1.4px #ececec',
                                                    },
                                                    to: {
                                                        boxShadow: '0 0 4.2px 4.2px #ececec',
                                                    },
                                                },
                                                '@keyframes glow': {
                                                    from: {
                                                        boxShadow: '0 0 1.4px 1.4px #aef4af',
                                                    },
                                                    to: {
                                                        boxShadow: '0 0 4.2px 4.2px #aef4af',
                                                    },
                                                },
                                            }}>
                                                <Grid sx={{ display: "inline-flex", alignItems: 'center', height: '100%', pl: '1em' }} container spacing={0}>
                                                    <Grid
                                                        sx={{ display: "contents", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                        item xl={1.5} md={1.5} xs={12}>
                                                        <Box sx={{ display: item.isSonucOnay === null ? 'block' : 'none' }}>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Teklif Durumu

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize='17px'>
                                                                    Onay / Red bekliyor
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        <Box sx={{ display: item.isSonucOnay === 0 ? 'block' : 'none' }}>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Teklif Durumu

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize='17px'>
                                                                    Red Edildi
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        <Box sx={{ display: item.isSonucOnay === 1 ? 'block' : 'none' }}>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Teklif Durumu

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize='17px'>
                                                                    Kazanan Teklif
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid
                                                        sx={{ textAlign: 'left', display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                        item xl={2} md={2} xs={12}>
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Teklif Bedeli

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize='28px'>
                                                                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.fiyat)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid
                                                        sx={{ display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                        item xl={3} md={3} xs={12}>
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Firma

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize='17px'>
                                                                    {item.uyefirma}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        sx={{ display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                        item xl={2} md={2} xs={12}>
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Teklif Sözleşmesi

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography fontSize='17px'>
                                                                    <a href=''>Teklif Sozlesme Dokuman</a>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>



                                                    <Grid
                                                        sx={{ display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                        item xl={1} md={1} xs={12}>
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    Teklif Zamanı

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography>
                                                                    20.12.2023 12:46
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>


                                                    <Grid
                                                        sx={{ display: "", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                        item xl={2.5} md={2.5} xs={12}>
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    color={IhaleThemeGeneral.palette.gray.primary}
                                                                >
                                                                    {item.isSonucOnay === null ? 'Aksiyon' : "Not"}

                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ display: item.isSonucOnay === null ? 'flex' : 'none', justifyContent: "space-around" }}>
                                                                <Button
                                                                    variant='outlined'
                                                                    onClick={e => handleTeklifAksiyon(item.id, true)}>
                                                                    Onayla
                                                                </Button>
                                                                <Button
                                                                    variant='outlined' f
                                                                    onClick={e => handleTeklifAksiyon(item.id, false)}>
                                                                    Reddet
                                                                </Button>
                                                            </Box>

                                                            <Box sx={{ display: item.isSonucOnay === 1 ? 'flex' : 'none', justifyContent: "space-around" }}>
                                                                <Typography>
                                                                    {item?.sonucNot}
                                                                </Typography>
                                                            </Box>

                                                            <Box sx={{ display: item.isSonucOnay === 0 ? 'flex' : 'none', justifyContent: "space-around" }}>
                                                                <Typography>
                                                                    {item?.sonucNot}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    }
                                })
                            )
                        }
                    </Paper>
                </Grid>

                {/* <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Paper sx={{ display: ihaleData?.isIhaleStart === 0 || ihaleData?.isIhaleStart === 1 ? '' : 'none' }} elevation={0}>
                        <Box sx={{ display: ihaleData?.isIhaleStart === 0 ? '' : 'none' }} pl='3em' pr='4em' pt='1em' pb='1.5em'>
                            <Typography
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Giriş Fiyat Teklifiniz
                            </Typography>

                            {ihaleData?.ihaleTeklifs && ihaleData?.ihaleTeklifs.length > 0 ? (
                                <Typography
                                    fontSize='34px'
                                >
                                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(ihaleData?.ihaleTeklifs?.[0].fiyats?.[0].fiyat)}
                                </Typography>
                            ) : (
                                <Typography
                                    fontSize='24px'
                                >
                                    Ön Teklifiniz Bulunmamaktadır
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ display: ihaleData?.isIhaleStart === 1 ? '' : 'none' }} pl='3em' pr='4em' pt='1em' pb='1.5em'>
                            <Typography
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Son Teklifiniz
                            </Typography>

                            {ihaleData?.ihaleTeklifs && ihaleData?.ihaleTeklifs.length > 0 && (
                                <Typography
                                    fontSize='34px'
                                >
                                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(ihaleData?.ihaleTeklifs?.[0].fiyats?.[0].fiyat)}
                                </Typography>
                            )}
                        </Box>
                        <Divider />
                        <Box pt='1em' pb='2em'>
                            <Typography
                                sx={{ display: ihaleData?.isIhaleStart === 0 ? '' : 'none' }}
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Başlamasına Kalan Süre
                            </Typography>

                            <Typography
                                sx={{ display: ihaleData?.isIhaleStart === 1 ? '' : 'none' }}
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Bitişine Kalan Süre
                            </Typography>
                            <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'space-around' }}>
                                <Box sx={{ display: ihaleBaslamayaKalan !== null && parseInt(ihaleBaslamayaKalan) > 0 ? '' : 'block' }}>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.days) : '-'}
                                    </Typography>

                                    <Typography>
                                        Gün
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.hours) : '-'}
                                    </Typography>

                                    <Typography>
                                        Saat
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.minutes) : '-'}
                                    </Typography>

                                    <Typography>
                                        Dakika
                                    </Typography>
                                </Box>


                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihaleBaslamayaKalan !== null ? (ihaleBaslamayaKalan.seconds) : '-'}
                                    </Typography>

                                    <Typography>
                                        Saniye
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>


                    <Paper sx={{ display: ihaleData?.isIhaleStart === -1 ? '' : 'none' }} elevation={0}>
                        <Box pl='3em' pr='4em' pt='1em' pb='1.5em'>
                            <Typography
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                E-İhale Verilmiş Son Teklifiniz
                            </Typography>

                            {ihaleData?.ihaleTeklifs && ihaleData?.ihaleTeklifs.length > 0 && (
                                <Typography
                                    fontSize='34px'
                                >
                                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(ihaleData?.ihaleTeklifs?.[0].fiyats?.[0].fiyat)}
                                </Typography>
                            )}
                        </Box>
                        <Divider />
                        <Box pt='1em' pb='2em'>
                            <Typography
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                Bu İhale Bitmiştir. İhale Bitiş Zamanı
                            </Typography>
                            <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'space-around' }}>
                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {
                                            ihaleData && ihaleData.ihaletarihiEnd && (
                                                convertDateTimes(ihaleData.ihaletarihiEnd.toString())
                                            )
                                        }
                                    </Typography>
                                </Box>

                            </Box>
                        </Box>

                        <Typography
                            pl='3em' pr='4em' pt='1em' pb='1.5em'
                            color={IhaleThemeGeneral.palette.gray.primary}
                        >
                            Diğer İhaler İçin Devam edebilirsiniz.
                        </Typography>
                        <Box pr='3em' pl='3em' pb='2em'>
                            <Button
                                onClick={() => window.location.href = `/Ihale`}
                                variant='outlined' fullWidth>
                                Henüz Başlamamış İhalelere Git
                            </Button>
                        </Box>
                    </Paper>

                    <Paper sx={{
                        mt: 2,
                        display: ihaleData?.isIhaleStart === 0 ? '' : 'none',
                        backgroundColor: '',
                        padding: '1em 1em',

                    }}>
                        <StyledCardHeaderLabel sx={{
                            textAlign: 'center',
                        }}>
                            E-İhale Giriş Fiyatı Oluştur
                        </StyledCardHeaderLabel>

                        <Grid container spacing={1}>
                            <Grid mt={3} item xl={12} md={12} xs={12}>
                                <Box>
                                    <TextField
                                        type='number'
                                        onChange={(e) => SetIhaleOnTeklifBedeli(e.target.value)}
                                        fullWidth
                                        placeholder='TL'
                                        label='Teklifiniz'
                                    />
                                </Box>
                            </Grid>
                            <Grid mt={3} item xl={12} md={12} xs={12}>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleClickOnTeklifVer}
                                />
                                <Button
                                    onClick={() => fileInputRef.current.click()}
                                    sx={{
                                        width: "100%",
                                        textTransform: 'none',
                                        color: 'black'
                                    }}
                                    variant='outlined'
                                    startIcon={<UploadFileIcon />}
                                >
                                    <StyledLabel>
                                        {selectedFileName ? selectedFileName : "Ön Sözleşme Metnini Yükle"}
                                    </StyledLabel>
                                </Button>
                            </Grid>
                            <Grid mt={3} item xl={12} md={12} xs={12}>
                                <Button
                                    onClick={handleClickOnTeklifVer}
                                    sx={{
                                        width: "100%"
                                    }}
                                    variant='outlined'
                                >
                                    <StyledLabel>
                                        Ön Teklif Ver
                                    </StyledLabel>
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{
                        mt: 2,
                        display: ihaleData?.isIhaleStart === 1 ? '' : 'none',
                        backgroundColor: '',
                        padding: '1em 1em',

                    }}>
                        <StyledCardHeaderLabel sx={{
                            textAlign: 'center',
                        }}>
                            İhale Başladı !
                        </StyledCardHeaderLabel>

                        <Typography
                            mt={2}
                            mb={2}
                            sx={{ textAlign: 'justify' }}
                            color={IhaleThemeGeneral.palette.gray.main} fontSize='16px'
                        >
                            Daha fazla Teklifden yararlanmak için ve ihale durumunu takip etmen için hemen ihale teklifleri sayfasına devam edin dummy text
                        </Typography>

                        <Button
                            onClick={() => window.location.href = `/Ihale/TeklifVer/${ihaleData?.id}`}
                            sx={{ width: '100%', height: '80px' }}
                            variant='outlined'
                        >
                            İhaleye Devam Et
                        </Button>
                    </Paper>
                </Grid> */}

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Paper elevation={0}>
                        <Box pl='2em' pr='4em' pt='1em' pb='1.5em' >
                            <Typography fontSize='24px'>
                                İhale Bilgileri
                            </Typography>
                        </Box>
                        <Grid pt='2em' pl='2em' pr='4em' pb='4em' container spacing={4}>
                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <TextSnippetIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }}
                                            color={IhaleThemeGeneral.palette.gray.main}
                                            fontSize='20px'
                                            fontWeight={600}>
                                            İhale Adı
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.ihaleadi}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <CategoryIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            Kategori
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.kategori}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <LocationOnIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                        İşin Yapılacağı Şehir           
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.ilceNavigation?.ad}  {ihaleData?.sehirNavigation?.ad}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <CalendarMonthIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            İhale Başlangıç Tarihi
                                        </Typography>
                                        <Typography>
                                            {
                                                ihaleData && ihaleData.ihaletarihiBegin && (
                                                    convertDateTimes(ihaleData.ihaletarihiBegin.toString())
                                                )
                                            }

                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xl={6} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <DescriptionIcon sx={{ mr: '0.5em' }} />
                                    </Box>

                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            Açıklama
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.kisaTanim}
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <FormatListNumberedIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            İhale Tipi
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.tenderType?.name}
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <CalendarMonthIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            İhale Bitiş Tarihi
                                        </Typography>

                                        <Typography>
                                            {
                                                ihaleData && ihaleData.ihaletarihiEnd && (
                                                    convertDateTimes(ihaleData.ihaletarihiEnd.toString())
                                                )
                                            }
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>


                    <Paper mt={3} elevation={0}>
                        <Box pl='2em' pr='4em' pt='1em' pb='1.5em' >
                            <Typography fontSize='24px'>
                                Firma Bilgileri
                            </Typography>
                        </Box>

                        <Grid pl='2em' pr='4em' pb='4em' container spacing={2}>
                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <TextSnippetIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            Firma Adı
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.firma?.firmaadi}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <CategoryIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            Kategori
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.kategori}
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item xl={3} md={4} xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <PersonIcon sx={{ mr: '0.5em' }} />
                                    </Box>
                                    <Box>
                                        <Typography mb={1} sx={{ display: 'inline-flex' }} color={IhaleThemeGeneral.palette.gray.main} fontSize='20px' fontWeight={600}>
                                            Firma Yetkilisi
                                        </Typography>

                                        <Typography>
                                            {ihaleData?.firma?.adsoyad}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>



                <Grid item xl={12} md={12} xs={12}>
                    <Paper elevation={0}>
                        <Typography>
                            E-İhale Eski Giriş Fiyat Teklifleri
                        </Typography>
                        {
                            ihaleData?.ihaleTeklifs?.length > 0 && ihaleData?.ihaleTeklifs.map(item => {
                                return (
                                    <Box>
                                        {item.uyefirma} {item.fiyat}
                                    </Box>
                                )
                            })
                        }
                    </Paper>
                </Grid>
            </Grid>
        </IhaleLayout >
    )
}

export default IhaleSonuc