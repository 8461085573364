import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import FirmPreview from '../Components/DigitalCatalogue/FirmPreview'
import AskForAnOffer from '../Components/DigitalCatalogue/AskForAnOffer'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useEffect } from 'react'
import NavbarSearchable from '../Components/Navigation/NavbarSearchable'

function DigitalCatalogFirmDetail() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const params = useParams();
    return (
        <>
            <NavbarSearchable />
            <Grid mt={3} container spacing={0}>
                <FirmPreview firmaSeoUrl={params.seourl} />
                <AskForAnOffer type='firm' firmaSeoUrl={params.seourl} />
                <TryForFree />
                <Footer />
            </Grid>
        </>
    )
}

export default DigitalCatalogFirmDetail