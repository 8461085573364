import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import groupLogo from '../../Assets/Images/Group 428.webp'
import { useSelector } from 'react-redux';
function DigitalCatalog(children) {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            maxWidth: '700px',
            textAlign: 'left',
            fontSize: '50px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '130%',
            color: '#000000',
            maxWidth: '700px',
            textAlign: 'center',
            fontFamily: "'Poppins', sans-serif"
        },
        Header2: {
            fontSize: { xs: '17px', md: "20px" },
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '170%',
            color: '#303C43',
            maxWidth: '700px',
            textAlign: 'justify',
            fontFamily: "'Poppins', sans-serif"

        },
        Button: {
            align: 'left',
            color: '#FFFFFF',
            background: '#FF6600',
            width: { xs: '300px', },
            height: '53px',
            marginTop: '3em',
            boxShadow: "0px 9px 22px rgba(34, 12, 0, 0.25)",
            borderRadius: "14px",
            fontFamily: "'Poppins', sans-serif",
            fontStyle: 'normal',
            fontSize: '16px',
            fontWeight: 500,
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            },

        },
        cardHeaderText: {
            maxWidth: '700px',
            fontFamily: "'Poppins', sans-serif",
            fontStyle: 'normal',
            fontSize: '27px',
            fontWeight: 700,
            textAlign: 'justify',
            color: "#2a4269",
        },
    }

    const navigate = useNavigate();
    const handleNavigate = (link) => {
        navigate(link);
    }

    return (
        <> <Typography
            sx={styles.Header1} mb={5}>
            {translate.PazarYeriAcildi}
        </Typography>
            <Grid container mb={3} spacing={0} p={5}>
                <Grid align='center' item xl={6} md={6} xs={12}>
                    <Box>
                        <Typography align='center' mt={3}
                            sx={styles.cardHeaderText}>
                            İnşaat Tedarikçileri
                        </Typography>
                    </Box>

                    <Box>
                        <Typography align='center' mt={3}
                            sx={styles.Header2}>
                            Pazaryeri ile inşaat tedarikçilerinin ürünleri milyonlara ulaşıyor!
                            Ücretsiz mağazanızı açın ve Türkiye’nin en büyük inşaat pazaryerindeki yerinizi alın.

                        </Typography>
                    </Box>

                    <Box
                        sx={{ display: 'flex', justifyContent: 'left', maxWidth: '700px', }}>
                        <Button
                            onClick={() => { window.location.href = '/PazarYeri#MagazaniziAcin' }}
                            variant='contained'
                            sx={styles.Button}>
                            {translate.PazarYerindeYeriniziAlin}
                        </Button>
                    </Box>


                    <Typography align='center' mt={6}
                        sx={styles.cardHeaderText}>
                        Müteahhitler ve Proje Sahipleri

                    </Typography>

                    <Box>
                        <Typography align='center' mt={3}
                            sx={styles.Header2}>
                            Pazaryeri ile müteahhitler binlerce tedarikçiye ve ürünlerine ulaşıyor!
                            Spot mağaza özelliği ile yüksek indirimli outlet ürünleri keşfedin.

                        </Typography>
                    </Box>

                    <Box
                        sx={{ display: 'flex', justifyContent: 'left', maxWidth: '700px', }}>
                        <Button
                            onClick={() => { window.location.href = '/PazarYeri' }}
                            variant='contained'
                            sx={styles.Button}>
                            {translate.PazarYeriniKesfedin}
                        </Button>
                    </Box>

                </Grid>
                <Grid align='center' sx={{ marginTop: { xs: 5 } }} item xl={6} md={6} xs={12} >
                    <img
                        style={{
                            maxWidth: '88%'
                        }}
                        src={groupLogo} alt='group logo' />
                </Grid>
            </Grid>
        </>
    )
}

export default DigitalCatalog