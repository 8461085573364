import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import { Button, Divider, Grid, IconButton, ListItemText, Menu, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import Logo from '../Assets/Images/logo.webp'
import navTryNow from '../Assets/Images/NavbarTryNow.webp'
import { Box } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import { LogOut } from '../Redux/Slices/AuthSlices';
import { ShowMessage } from './Common/Alerts';
import { deleteUserInfo } from '../Redux/Slices/UserInfoSlices';
import { getData } from '../Assets/Scripts/dataAxios';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import PazarYeriSVG from '../Assets/Icons/PazarYeriSVG.svg'

function Navbar() {
    const user = useSelector((state) => JSON.parse(state.Auth.user));
    const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));


    const [dropMenu, setDropMenu] = useState(false);
    const [activeLink, setActiveLink] = useState('Faydalar')
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorElLogin, setAnchorElLogin] = React.useState(null);
    const openLoginMenu = Boolean(anchorElLogin);
    const handleClickLogin = (event) => {
        setAnchorElLogin(event.currentTarget);
    };
    const handleCloseLogin = () => {
        setAnchorElLogin(null);
    };

    const handleLinkNavigate = (url) => {
        navigate(url);
        navigate(0);
    }

    const handleProductClick = () => {
        if (dropMenu === false) {
            setDropMenu(true)
        }
        else {
            setDropMenu(false)
        }
    }

    const AddActiveLinkStyle = (url) => {
        setTimeout(
            function () {
                const element = document.getElementById(url);
                console.log(element)
                if (element) {
                    element.classList.add('active')
                }
            }, 1000);

    }

    const location = useLocation();



    useState(() => {
        setActiveLink(location.pathname.substring(1))
        AddActiveLinkStyle(location.pathname.substring(1))
    }, [])



    const dispatch = useDispatch();
    const handleLogOut = async () => {
        const resLogOut = await getData('Auth/LogOut');
        if (resLogOut.data.isSucceeded || (resLogOut.data.isSucceeded === false && resLogOut.data.value === 'Bulunamadi')) {
            dispatch(LogOut());
            dispatch(deleteUserInfo());
            ShowMessage('success', 'Çıkış yapıldı. Anasayfaya yönlendiriliyorsunuz.');
            setTimeout(() => {
                navigate('/')
            }, 1500)
        } else {
            ShowMessage('error', resLogOut.data.error.errorMessage);
        }
    }


    const styles = {
        appBar: {
            minHeight: '100px',
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'transparent',
            backdropFilter: "none",
            boxShadow: "none",
            paddingRight: { xl: '2em', lg: '0' }
        },
        navLinks: {
            margin: "'0 10px'",
            textTransform: "none",
            color: "#3C6780",
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "19px",
            lineHeight: "120%",
            ':hover': {
                color: ' #303C43',
                "& .navLinkBlog": {
                    color: '#FF6600'
                }
            },
        },
        buttonColor: {
            ':hover': {
                backgroundColor: 'transparent',
            },
        },
        buttonColor1: {
            marginLeft: "10vh",
            ':hover': {
                backgroundColor: 'transparent',
            },
        },
        navDeneBtn: {
            backgroundColor: "#FF6600",
            boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
            borderRadius: "14px",
            width: "162px",
            height: "47px",
            marginRight: '1em',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            },
        },
        navKatalogBtn: {
            backgroundColor: "#3C6780",
            boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
            borderRadius: "14px",
            width: "150px",
            height: "47px",
            marginRight: '1em',
            ':hover': {
                bgcolor: '#3C6780',
                boxShadow: 'none',
            },
        },
        navKatalogBtnText: {
            margin: 0,
            padding: 0,
            textTransform: "none",
            color: "#FFFFFF",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%"
        },
        navDeneBtnText: {
            textTransform: "none",
            color: "#FFFFFF",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%"
        },

        navDeneBtnMobile: {
            textTransform: "none",
            "justifyContent": "center",
            "alignItems": "center",
            "padding": "15px 25px",
            "gap": "10px",
            "width": "333px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "justifyContent": "center",
                "alignItems": "center",
                "padding": "15px 25px",
                "gap": "10px",
                "width": "333px",
                "height": "63px",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "20px",
            }
        },

        navLoginBtn: {
            textTransform: "none",
            boxSizing: "border-box",
            width: "94px",
            height: "47px",
            opacity: "0.5",
            border: "1px solid #3C6780",
            filter: "drop-shadow(0px 18px 50px rgba(111, 160, 221, 0.25))",
            borderRadius: "14px"
        },
        navLoginBtnMobile: {
            textTransform: "none",
            "alignItems": "center",
            "padding": "15px 25px",
            "gap": "10px",
            "width": "333px",
            "height": "63px",
            "background": "#FFFFFF",
            "boxShadow": "0px 18px 50px rgba(111, 160, 221, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "alignItems": "center",
                "padding": "15px 25px",
                "gap": "10px",
                "width": "333px",
                "height": "63px",
                "background": "#FFFFFF",
                "boxShadow": "none",
                "borderRadius": "20px",
            }
        },
        navLinkBlog: {
            width: "129px",
            height: "19px",
            fontFamily: "'Damion', cursive",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%",
            color: "#889AA4",
            flex: "none",
            order: "4",
            flexGrow: "0"
        },
        navLinksGrid: {
            left: '5vw',
            zIndex: 99,
            color: 'black',
            top: '100px',
            pt: 2,
            position: 'fixed',
            backgroundColor: 'white',
            "boxSizing": "border-box",
            "width": "90vw",
            "height": { xl: '420px', md: '620px' },
            "background": "white",
            "boxShadow": "0px 76.07px 91.113px -25.04px rgba(0, 0, 0, 0.18)",
            "backdropFilter": "none",
            "borderRadius": "30px"
        },
        LinkPaper: {
            "marginLeft": '2em',
            "background": "#ffffffcf",
            "width": "290px",
            "height": "163px",
            "padding": "1px",
            "border": "none",
            "boxShadow": "none",
            "borderRadius": "20px",
            cursor: 'pointer',
            ':hover': {
                "background": "#FBFBFB",
                "border": "1px solid #8FBFFA",
                "boxShadow": "0px 14px 10px -10px rgba(37, 50, 66, 0.25)",
                "borderRadius": "20px",
            },
            '&.active': {
                "background": "#FFFFFF",
                "border": "1px solid #8FBFFA",
                "boxShadow": "0px 14px 10px -10px rgba(37, 50, 66, 0.25)",
                "borderRadius": "20px",
            },
        },
        PaperHeader: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#000000"
        },
        PaperText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "150%",
            "color": "#738691",
            "left": "7.71%",
            "right": "75.9%",
            "top": "46.55%",
            "bottom": "43.98%"
        },
        LinkPaperTryNow: {
            "width": "330px",
            "height": "186px",
            "background": "#FF6600",
            "backdropFilter": "blur(22px)",
            "borderRadius": "30px 0px",
            bottom: 0,
            right: 0,
            position: 'absolute'
        },
        LinkPaperTryNowText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "33px",
            "lineHeight": "120%",
            "color": "#FFFFFF"
        },
        goTryBtn: {
            "background": "#8FBFFA",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "12px",
            marginTop: '13px',
            marginLeft: '20px',
            ':hover': {
                background: ' #8FBFFA'
            },
        },
        userNameText: {
            textTransform: "none",
            color: "#889AA4",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%",
            width: '162px',
            textAlign: 'center'
        }
    }



    const [anchorElMobileMenu, setAnchorElMobileMenu] = React.useState('none');
    const openMobileMenu = Boolean(anchorElMobileMenu);
    const handleClickMobileMenu = (event) => {
        setAnchorElMobileMenu('block');
    };
    const handleCloseMobileMenu = () => {
        setAnchorElMobileMenu('none');
    };


    const [anchorElMobileMenuUrunler, setAnchorElMobileMenuUrunler] = React.useState('none');
    const handleClickMobileMenuUrunler = (event) => {
        if (anchorElMobileMenuUrunler === 'none') {
            setAnchorElMobileMenuUrunler('block');
        } else {
            setAnchorElMobileMenuUrunler('none');
        }
    };
    const handleCloseMobileMenuUrunler = () => {

    };


    return (
        <>
            <AppBar position='absolute' sx={styles.appBar}>
                <Grid container justify='center'>
                    <Grid pl={5} mt='17px' item xl={2.5} lg={1.5} md={2.5} xs={2.5}>
                        <Box sx={{ cursor: 'pointer' }} onClick={() => handleLinkNavigate('/')}>
                            <img src={Logo} width='200px' alt='Yapiradar.com Logo' />
                        </Box>
                    </Grid>
                    <Grid
                        sx={{
                            display: {
                                xs: 'none', md: 'none', l: "none", lg: 'block'
                            }
                        }}
                        mt='10px' item display='block' justifyContent='space-between' xl={4.5} lg={5.5} md={4.5} xs={4.5}>
                        <Button onClick={() => handleLinkNavigate('/Faydalar')} sx={styles.buttonColor1} >
                            <Typography sx={styles.navLinks}
                                style={{
                                    color: activeLink === 'Faydalar' ? '#303C43' : '#3C6780'
                                }}
                                display="inline">
                                {translate.Faydalar}
                            </Typography>
                        </Button>
                        <Button
                            onMouseEnter={handleProductClick}
                            endIcon={<ArrowDropDownIcon
                                sx={{ color: '#303C43' }} />}
                            sx={styles.buttonColor}>
                            <Typography sx={styles.navLinks} display="inline">
                                {translate.Urunler}
                            </Typography>
                        </Button>
                        <Button onClick={() => handleLinkNavigate('/Hizmetler')} sx={styles.buttonColor}>
                            <Typography
                                style={{
                                    color: activeLink === 'Hizmetler' ? '#303C43' : '#3C6780'
                                }}
                                sx={styles.navLinks} display="inline">
                                {translate.Hizmetler}
                            </Typography>
                        </Button>
                        <Button onClick={() => handleLinkNavigate('/Hakkimizda')} sx={styles.buttonColor}>
                            <Typography
                                style={{
                                    color: activeLink === 'Hakkimizda' ? '#303C43' : '#3C6780'
                                }}
                                sx={styles.navLinks} display="inline">
                                {translate.Hakkimizda}
                            </Typography>
                        </Button>
                        {/* <Button onClick={() => handleLinkNavigate('/YapiGundem')} sx={styles.buttonColor}>
                            <Typography sx={styles.navLinks} display="inline">
                                Yapı Gündem <span className='navLinkBlog' style={styles.navLinkBlog}>Blog</span>
                            </Typography>
                        </Button> */}

                    </Grid>

                    <Grid item xl={5} lg={5} xs={9.5} pr={2} mt='0.4em' textAlign='right'>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none', sm: 'none', md: 'none', l: "none", lg: 'block', xl: 'block'
                                }
                            }}>
                            <Button onClick={() => handleLinkNavigate('/Pazaryeri')} sx={{
                                backgroundColor: "#3C6780",
                                boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
                                borderRadius: "14px",
                                width: "40%",
                                height: "47px",
                                marginRight: '1em',
                                ':hover': {
                                    bgcolor: '#3C6780',
                                    boxShadow: 'none',
                                },
                            }}>
                                <img
                                    style={{ filter: 'brightness(0) invert(1)', height: '30px', marginRight: '10px' }}
                                    src={PazarYeriSVG} />
                                <Typography
                                    mr={2} ml={2} sx={{
                                        margin: 0,
                                        padding: 0,
                                        textTransform: "none",
                                        color: "#FFFFFF",
                                        fontFamily: "'Poppins'",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "120%"
                                    }} display="inline">
                                    {translate.InsaatPazarYeri}
                                </Typography>
                            </Button>
                            {user === false ? (
                                <Button onClick={() => handleLinkNavigate('/Register')} variant='contained' sx={styles.navDeneBtn}>
                                    <Typography sx={styles.navDeneBtnText}>
                                        {translate.UcretsizDene}
                                    </Typography>
                                </Button>
                            ) :
                                (<>
                                    <Button
                                        variant='contained'
                                        sx={styles.navDeneBtn}
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <Typography sx={styles.navDeneBtnText}>
                                            Uygulamaya Git
                                        </Typography>

                                    </Button>
                                    <Menu
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        PaperProps={{
                                            style: {
                                                width: 180,
                                            },
                                        }}
                                        disableScrollLock={true}
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            onMouseLeave: handleClose
                                        }}
                                    >
                                        <MenuItem
                                            onClick={handleClose}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }} href='https://app.yapiradar.com/'>
                                                <Typography
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#FF6600",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}
                                                    display='inline'>
                                                    YAPI
                                                </Typography>
                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    RADAR
                                                </Typography>
                                                <Typography sx={{
                                                    margin: "'0 10px'",
                                                    textTransform: "none",
                                                    color: "#FF6600",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "10px",
                                                    lineHeight: "120%",
                                                }}
                                                    display='inline'>
                                                    .com
                                                </Typography>
                                            </a>
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ display: UserData.dkFirmaid === 0 ? 'none' : '' }}
                                            onClick={handleClose}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href={`/PazarYeri/Firma/${UserData.dkFirmaSeo}`}>
                                                <Typography sx={{
                                                    margin: "'0 10px'",
                                                    textTransform: "none",
                                                    color: "#3C6780",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    lineHeight: "120%",
                                                    ':hover': {
                                                        color: ' #303C43',
                                                        "& .navLinkBlog": {
                                                            color: '#FF6600'
                                                        }
                                                    },
                                                }}>
                                                    Firma Profilim
                                                </Typography>
                                            </a>
                                        </MenuItem>

                                        <MenuItem
                                            sx={{ display: UserData.dkFirmaid !== 0 ? 'none' : '' }}
                                            onClick={handleClose}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href={`/PazarYeri/FirmaEkle`}>
                                                <Typography sx={{
                                                    margin: "'0 10px'",
                                                    textTransform: "none",
                                                    color: "#3C6780",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    lineHeight: "120%",
                                                    ':hover': {
                                                        color: ' #303C43',
                                                        "& .navLinkBlog": {
                                                            color: '#FF6600'
                                                        }
                                                    },
                                                }}>
                                                    Mağazanızı Açın
                                                </Typography>
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </>
                                )}


                            {user === false ? (
                                <>
                                    <Button
                                        sx={styles.navLoginBtn}
                                        id="basic-button"
                                        aria-controls={openLoginMenu ? 'login-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openLoginMenu ? 'true' : undefined}
                                        onClick={handleClickLogin}
                                        onMouseOver={handleClickLogin}
                                    >
                                        <Typography className='navLoginBtnText'>
                                            {translate.GirisYap}
                                        </Typography>

                                    </Button>
                                    <Menu
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        PaperProps={{
                                            style: {
                                                width: 'fit-content',
                                            },
                                        }}
                                        disableScrollLock={true}
                                        id="login-menu"
                                        anchorEl={anchorElLogin}
                                        open={openLoginMenu}
                                        onClose={handleCloseLogin}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            onMouseLeave: handleCloseLogin
                                        }}
                                    >
                                        <MenuItem
                                            onClick={handleCloseLogin}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href="/Login">
                                                <Typography
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#FF6600",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}
                                                    display='inline'>
                                                    YAPI
                                                </Typography>
                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    RADAR
                                                </Typography>

                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "0px 5px",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    {translate.Giris}
                                                </Typography>
                                            </a>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={handleCloseLogin}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href="/PazarYeriLogin">
                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "'0 7px'",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    {translate.Pazar1}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#FF6600",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}
                                                    display='inline'>
                                                    {translate.Yeri1}
                                                </Typography>

                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "5px 10px",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    {translate.Giris}
                                                </Typography>
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (<Button onClick={() => handleLogOut()} sx={styles.navLoginBtn}>
                                <Typography className='navLoginBtnText'>
                                    {translate.CikisYap}
                                </Typography>
                            </Button>)}
                        </Box>


                        <Box
                            sx={{
                                display: {
                                    xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'
                                }
                            }}
                        >
                            <IconButton
                                sx={{
                                    display: anchorElMobileMenu === 'none' ? 'contents' : 'none'
                                }}
                                onClick={handleClickMobileMenu}
                            >
                                <MenuIcon />
                            </IconButton>

                            <IconButton
                                sx={{
                                    display: anchorElMobileMenu === 'none' ? 'none' : 'contents'
                                }}
                                onClick={handleCloseMobileMenu}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </AppBar>
            <Box sx={{ display: dropMenu === true ? 'fixed' : 'none', minHeight: '520px', position: 'absolute' }}>
                <Grid onMouseLeave={handleProductClick} sx={styles.navLinksGrid} container spacing={0}>
                    <Grid item xl={3} md={4} xs={12} >
                        <Paper id='GuncelInsaatProjeleri' onClick={() => handleLinkNavigate('/GuncelInsaatProjeleri')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.GuncelInsaatProjeleri}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.TurkMuteahhitlerinYurtIciveDisi}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                        <Paper id='FirmaRehberi' onClick={() => handleLinkNavigate('/FirmaRehberi')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.FirmaRehberi}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.InsaatEkosistemineHizmetVeren}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                        <Paper id='PPTS' onClick={() => handleLinkNavigate('/YapiradarCRM')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.ProjeTakipSistemiPPTS}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.YeniNesilProjelerCRM}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                        <Paper id='BayiYonetimModulu' onClick={() => handleLinkNavigate('/BayiYonetimModulu')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.BayiYonetimModulu}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.BayilerinizleIsYonlendirinVerimlilik}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                        <Paper id='KamuYapimIhaleleri' onClick={() => handleLinkNavigate('/KamuYapimIhaleleri')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.KamuYapimIhaleleri}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.KamununTumYapimIhalelerini}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                        <Paper id='SatinAlmaTalepleri' onClick={() => handleLinkNavigate('/SatinAlmaTalepleri')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.SatinAlmaTalepleri}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.MuteahhitFirmalarinSatinAlmaTaleplerini}

                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xl={3} md={4} xs={12}>
                        <Paper id='PSTS' onClick={() => handleLinkNavigate('/PSTS')} sx={styles.LinkPaper}>
                            <Typography mt={4} ml={2} sx={styles.PaperHeader}>
                                {translate.SahaTakipSistemiPSTS}
                            </Typography>

                            <Typography mt={2} ml={2} sx={styles.PaperText}>
                                {translate.SahaPersonelinizinGorusmeSureclerini}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid p={0} m={0} item xl={3} md={4} xs={12}>
                        <Paper sx={styles.LinkPaperTryNow}>
                            <Typography mt={4} ml={2} sx={styles.LinkPaperTryNowText}>
                                {translate.SimdiUcretsizDene}
                            </Typography>
                            <Box >
                                <Box sx={{ display: 'inline-flex' }}>
                                    <IconButton onClick={() => handleLinkNavigate('/Register')} sx={styles.goTryBtn}>
                                        <ArrowForwardIcon />
                                    </IconButton >
                                </Box>
                                <Box sx={{ position: 'absolute', right: '0', bottom: '-7px' }}>
                                    <img src={navTryNow} alt='yapiradar simdi dene' />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>


            <Paper
                sx={{
                    width: '100vw',
                    marginTop: 12,
                    display: anchorElMobileMenu
                }}>
                <MenuList dense>
                    <MenuItem
                        onClick={() => handleLinkNavigate('/Faydalar')}
                        sx={{
                            margin: '10px 0',
                            paddingLeft: '5%'
                        }}
                    >
                        <Typography
                            sx={{
                                "width": "99px",
                                "height": "26px",
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "500",
                                "fontSize": "18px",
                                "lineHeight": "120%",
                                "color": "#3C6780"
                            }}
                        >
                            Faydalar
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleClickMobileMenuUrunler()}
                        sx={{
                            margin: '10px 0',
                            paddingLeft: '5%'
                        }}
                    >
                        <Button
                            sx={{
                                padding: '0',
                                textAlign: 'left',
                                textTransform: 'none',
                                whiteSpace: 'none'
                            }}
                            endIcon={anchorElMobileMenuUrunler === 'none' ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        >
                            <Typography
                                sx={{
                                    "height": "26px",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "500",
                                    "fontSize": "18px",
                                    "lineHeight": "120%",
                                    "color": "#3C6780"
                                }}>
                                Ürünler

                            </Typography>
                        </Button>

                    </MenuItem>
                    <Paper sx={{
                        boxShadow: 'none',
                        display: anchorElMobileMenuUrunler,
                        paddingLeft: '5%'
                    }}>
                        <MenuList dense>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/GuncelInsaatProjeleri')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Güncel İnşaat Projeleri
                                </Typography>

                            </MenuItem>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/KamuYapimIhaleleri')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Kamu Yapım İhaleleri
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/SatinAlmaTalepleri')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Satın Alma Talepleri
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/FirmaRehberi')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Firma Rehberi
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/YapiradarCRM')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Yapıradar CRM
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/Faydalar')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Personel Saha Takip Sistemi
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{ marginBottom: '5px' }}
                                onClick={() => handleLinkNavigate('/PSTS')}
                            >
                                <Typography
                                    sx={{
                                        "width": "99px",
                                        "height": "26px",
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "250%",
                                        "color": "#889AA4"
                                    }}>
                                    Bayi Yönetim Modülü
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                    <MenuItem
                        onClick={() => handleLinkNavigate('/Hizmetler')}
                        sx={{
                            margin: '10px 0',
                            paddingLeft: '5%'
                        }}
                    >
                        <Typography
                            sx={{
                                "width": "99px",
                                "height": "26px",
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "500",
                                "fontSize": "18px",
                                "lineHeight": "120%",
                                "color": "#3C6780"
                            }}>
                            Hizmetler
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleLinkNavigate('/Hakkimizda')}
                        sx={{
                            margin: '10px 0',
                            paddingLeft: '5%'
                        }}
                    >
                        <Typography
                            sx={{
                                "width": "99px",
                                "height": "26px",
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "500",
                                "fontSize": "18px",
                                "lineHeight": "120%",
                                "color": "#3C6780"
                            }}>
                            Hakkımızda
                        </Typography>
                    </MenuItem>
                    <Box
                        sx={{
                            background: '#F4F7F9'
                        }}
                    >
                        {/* <Box align='center' pt={3}>
                            <Button onClick={() => handleLinkNavigate('/Pazaryeri')}
                                sx={{
                                    textTransform: "none",
                                    "justifyContent": "center",
                                    "alignItems": "center",
                                    "padding": "15px 25px",
                                    "gap": "10px",
                                    "width": "333px",
                                    "height": "63px",
                                    "background": "#3C6780",
                                    "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
                                    "borderRadius": "20px",
                                    ':hover': {
                                        "justifyContent": "center",
                                        "alignItems": "center",
                                        "padding": "15px 25px",
                                        "gap": "10px",
                                        "width": "333px",
                                        "height": "63px",
                                        "background": "#3C6780",
                                        "boxShadow": "none",
                                        "borderRadius": "20px",
                                    }

                                }}>
                                <img
                                    style={{ filter: 'brightness(0) invert(1)', height: '30px', marginRight: '10px' }}
                                    src={PazarYeriSVG} />
                                <Typography
                                    mr={2} ml={2} sx={{
                                        margin: 0,
                                        padding: 0,
                                        textTransform: "none",
                                        color: "#FFFFFF",
                                        fontFamily: "'Poppins'",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "120%"
                                    }} display="inline">
                                    {translate.InsaatPazarYeri}
                                </Typography>
                            </Button>
                        </Box> */}

                        <Box align='center' pt={3}>
                            <Button onClick={() => handleLinkNavigate('/Register')} variant='contained'
                                sx={styles.navDeneBtnMobile}>
                                <Typography sx={styles.navDeneBtnText}>
                                    {translate.UcretsizDene}
                                </Typography>
                            </Button>
                        </Box>
                        <Box align='center' mt={2} pb={5}>
                            <Button onClick={() => window.open("https://www.yapiradar.com/Login", "_self")}
                                sx={styles.navLoginBtnMobile}>
                                <Typography className='navLoginBtnText'>
                                    {translate.GirisYap}
                                </Typography>
                            </Button>
                        </Box>

                    </Box>
                </MenuList>
            </Paper>
        </>
    )
}

export default Navbar