import React, { useCallback, useEffect, useState } from 'react'
import IhaleLayout from '../Layouts/IhaleLayout'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import CollapsibleTable from '../../Components/Ihale/IhaleTable'
import { Box, Button, ButtonGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import IhaleGecmisiTable from '../../Components/Ihale/IhaleGecmisiTable'
import { IhaleCardFirmaAdi } from '../../Components/Styled/Labels/StyledLabels'
import { loadState } from '../../Assets/Scripts/LocalStorage'
import { postData } from '../../Assets/Scripts/dataAxios'
import { ShowMessage } from '../../Components/Common/Alerts'
import TablePagination from '@mui/material/TablePagination';
import IhaleCustomTable from '../../Components/Ihale/IhaleCustomTable'






const convertDateTimes = (param) => {
  const param2 = param.toString()
  const year = parseInt(param2.slice(0, 4), 10);
  const month = parseInt(param2.slice(4, 6), 10) - 1;
  const day = parseInt(param2.slice(6, 8), 10);
  const hour = parseInt(param2.slice(8, 10), 10);
  const minute = parseInt(param2.slice(10, 12), 10);
  const second = parseInt(param2.slice(12, 14), 10);
  const dateTime = new Date(year, month, day, hour, minute, second);
  const formattedDate = dateTime.toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'Europe/Istanbul'
  });
  return (formattedDate);
}



function Ihalelerim() {
  //States and Variables 
  const navigate = useNavigate();
  const [ihaleSwitchButtonDisabled, SetIhaleSwitchButtonDisabled] = useState(false);
  const [ihaleHeaderText, SetIhaleHeaderText] = useState('Oluşturduğum İhaleler');
  const [ihaleler, SetIhaleler] = useState([]);
  const [tekliflerim, SetTekliflerim] = useState([]);
  const [resultCount, SetResultCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const UserInfoData = JSON.parse(loadState('UserInfo'));
  const [filters, SetFilters] = useState(
    {
      "orderBy": {
        "key": "Id",
        "value": true
      },
      "pageSize": 10,
      "currentPage": 0,
      "isPagingEnabled": true,
      "firmaids": [],
      "kategoriIds": [],
      "ihaleadi": "",
      "myTeklif": true,
      'sehirs': []
    }
  )

  const [filters2, SetFilters2] = useState(
    {
      "orderBy": {
        "key": "Id",
        "value": true
      },
      "pageSize": 10,
      "currentPage": 0,
      "isPagingEnabled": true,
      "firmaids": [],
      "kategoriIds": [],
      "ihaleadi": "",
      "myIhale": true,
      'sehirs': []
    }
  )

  //Hooks 

  const fetchData = useCallback(async () => {
    try {
      const response = await postData('Ihale/DijitalFirmaIhaleList', filters)
      SetTekliflerim(response.data.value.data)
    } catch (error) {
      ShowMessage('error', error)
    }
  }, [filters])


  const fetchData2 = useCallback(async () => {
    try {
      const response = await postData('Ihale/DijitalFirmaIhaleList', filters2)
      SetIhaleler(response.data.value.data)
    } catch (error) {
      ShowMessage('error', error)
    }
  }, [filters2])

  useEffect(() => {
    fetchData();
    fetchData2();
  }, [fetchData, fetchData2]);


  //Functions and Methods
  const handleIhaleSwitchButtonClick = (paramValue) => {
    if (paramValue === 1) {
      SetIhaleSwitchButtonDisabled(false)
      SetIhaleHeaderText('Oluşturduğum İhaleler')
    } else {
      SetIhaleSwitchButtonDisabled(true)
      SetIhaleHeaderText('Katıldığım İhaleler')
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(parseInt(newPage));
    SetFilters({
      ...filters,
      'currentPage': newPage
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    SetFilters({
      ...filters,
      'pageSize': event.target.value
    })
  };

  return (
    <IhaleLayout>

      <Grid container spacing={0}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper elevation={0} sx={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
            mt: 2,
            mb: 2
          }} >
            <Box pl={2}>
              <Button onClick={() => navigate('/Ihale/IhaleOlustur')} sx={{
                backgroundColor: "#FF6600",
                boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
                borderRadius: "14px",
                width: "220px",
                height: "47px",
                marginRight: '1em',
                ':hover': {
                  bgcolor: '#FF6600',
                  boxShadow: 'none',
                },
              }}>
                <Typography
                  mr={2} ml={2} sx={{
                    margin: 0,
                    padding: 0,
                    textTransform: "none",
                    color: "#FFFFFF",
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "120%"
                  }} display="inline">
                  İhale Oluştur
                </Typography>
              </Button>
            </Box>

            <Box>
              <ButtonGroup variant="text" aria-label="text button group">
                <Button
                  onClick={() => handleIhaleSwitchButtonClick(1)}
                  disabled={!ihaleSwitchButtonDisabled}
                >
                  Oluşturduğum İhale Geçmişi
                </Button>

                <Button
                  onClick={() => handleIhaleSwitchButtonClick(2)}
                  disabled={ihaleSwitchButtonDisabled}
                >
                  Katıldığım İhale Geçmişi
                </Button>
              </ButtonGroup>
            </Box>
          </Paper>
        </Grid>
      </Grid >
      <Grid sx={{ display: ihaleSwitchButtonDisabled === false ? '' : 'none' }} item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid sx={{ background: '#F4F7F9', padding: '1em' }} mt={8} mb={8} item xl={12} md={12} xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} padding='1em 1em 0em 1em'>
            <Box sx={{ display: 'block' }}>
              <Typography
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontSize: '20px',
                  lineHeight: '24.55px',
                  fontWeight: 700,
                  color: '#3C6780'
                }}
              >
                Oluşturduğum İhaleler
              </Typography>

              <Typography
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontSize: '13px',
                  lineHeight: '17.73px',
                  fontWeight: 700,
                  color: '#007F90'
                }}>
                Şuana kadar {resultCount} adet ihale oluşturdunuz.
              </Typography>
            </Box>
          </Box>

          <Box pt='1em'>
            {
              Array.isArray(ihaleler) && ihaleler.length > 0 ? (
                ihaleler.map((item, index) => (
                  <IhaleCustomTable
                    key={index}
                    props={item}
                  />
                ))
              ) : (
                <Typography padding='1em 1em 1em 1em'>Katıldığınız Bir İhale Bulunamadı.</Typography>
              )
            }
            <Box padding='1em 1em 1em 1em'>
              <TablePagination
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                  }}
                component="div"
                count={resultCount}
                sx={{ color: '#B6CCD8' }}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Sayfada şu kadar sonuç göster"
                rowsPerPageOptions={[
                  5, 10, 25, 50
                ]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid sx={{ display: ihaleSwitchButtonDisabled === true ? '' : 'none' }} item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid sx={{ background: '#F4F7F9', padding: '1em' }} mt={8} mb={8} item xl={12} md={12} xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }} padding='1em 1em 0em 1em'>
            <Box sx={{ display: 'block' }}>
              <Typography
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontSize: '20px',
                  lineHeight: '24.55px',
                  fontWeight: 700,
                  color: '#3C6780'
                }}
              >
                Katıldığım İhaleler
              </Typography>

              <Typography
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  fontSize: '13px',
                  lineHeight: '17.73px',
                  fontWeight: 700,
                  color: '#007F90'
                }}>
                Şuana kadar {resultCount} adet ihaleye teklif verdiniz.
              </Typography>
            </Box>
          </Box>

          <Box pt='1em'>
            {
              Array.isArray(ihaleler) && ihaleler.length > 0 ? (
                tekliflerim.map((item, index) => (
                  <IhaleCustomTable
                    key={index}
                    props={item}
                  />
                ))
              ) : (
                <Typography padding='1em 1em 1em 1em'>Katıldığınız Bir İhale Bulunamadı.</Typography>
              )
            }

            <Box padding='1em 1em 1em 1em'>
              <TablePagination
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                  }}
                component="div"
                count={resultCount}
                sx={{ color: '#B6CCD8' }}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Sayfada şu kadar sonuç göster"
                rowsPerPageOptions={[
                  5, 10, 25, 50
                ]}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

    </IhaleLayout >
  )
}

export default Ihalelerim