import { Grid, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/GuncelInsaat1.webp'
import Image2 from '../Assets/Images/nokta-atisi-proje-arama.webp'
import Image3 from '../Assets/Images/proje-detaylari.webp'
import Image5 from '../Assets/Images/Periyodik-Guncelleme.webp'
import Image4 from '../Assets/Images/HandHoldingPhone.webp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

function GuncelInsaatProjeleri() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const styles = {
        HeaderTop :{
            display: {md:"flex"}, 
            flexDirection: {md:"row-reverse"}
        },
        Header1Top:{
       
        "textAlign": {xs:"center",md:"left"}
        },
        Header4Top:{
            "padding": {xs:"",md:"5%"},
            "textAlign": {xs:"center",md:"left"},
            
            
            },
            Header5Top:{
                "padding": {xs:0,md:"5%"},
                "textAlign": {xs:"center",md:"left"}
                },
                Image2Top:{
                    "relative": {xs:"", md:"right"},
                    "display":{xs:"", md:"flex"},
                    "flexDirection": {xs:"",md:"row-reverse"}

                },

        Header1: { "width": { xs: "100%", md: "592px"}, "height": { xs: "auto", md: "36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "20px", md: "24px" } , "lineHeight": "36px", "color": "#000000" },
        Header2: { "width": { xs: "100%", md: "541px"}, "height": { xs: "auto", md: "210px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: "35px", md: "54px" }, "lineHeight": "130%", "color": "#000000" },
        Header3: { "width": { xs: "100%", md: "561.11px"}, "height":{ xs: "auto", md: "130px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "20px"}, "lineHeight": "170%", "color": "#889AA4" },
        Header4: { "width": { xs: "100%", md: "480px"}, "height": { xs: "auto", md: "93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "30px", md: "50px"} , "lineHeight": "130%", "color": "#000000" },
        Header5: { "width": { xs: "100%", md: "480px"}, "height": { xs: "auto", md: "81px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "16px", md: "20px"}, "lineHeight": "170%", "color": "#303C43" },
        Header6: { "width": { xs: "100%", md: "500px"}, "height":{ xs: "auto", md: "100px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize":{ xs: "30px", md: "50px"} , "lineHeight": "130%", "color": "#FFFFFF" },
        Header7: { "width": { xs: "100%", md: "512px"}, "height": { xs: "auto", md: "93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "15px", md: "25px"}, "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" }
   
    }
    return (
        <>
            <Navbar />
            <Grid mt={{xs:12,md:5}} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pt={{xs:5,md:20}} pl={{xs:0,md:15}} p={{xs:3,md:""}} item xl={6} md={6} xs={12} sx={styles.Header1Top}>
                        <Typography sx={styles.Header1}>
                           {translate.GuncelInsaatProjeleri}
                        </Typography>
                        <Typography mb={7} mt={2} sx={styles.Header2} >
                            {translate.ProjeTakibindeFazlamizVarEksigimizYok}
                        </Typography>
                        <Typography mt={2} sx={styles.Header3}  >
                          {translate.TurkMuteahhitlerTarafindanYurtIci}
                        </Typography>
                    </Grid>
                    <Grid mt={0} item xl={6} md={6} xs={12}>
                        <img pb={10} width='100%' src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid  mt={{xs:4,md:8}} container spacing={0}  sx={styles.HeaderTop} >
                <Grid  pt={2} p={3}item xl={6} md={6} xs={12} sx={styles.Header4Top} >
                        <Typography mb={3} sx={styles.Header4}>
                            {translate.NoktaAtisiProjeArama}
                        </Typography>
                        <Typography pt={5} sx={styles.Header5}>
                           {translate.DetayliProjeAramaOzelligiyle}
                        </Typography>
                    </Grid>
                    <Grid mt={{xs:4,md:8}} item xl={6} md={6} xs={12} sx={styles.Image2Top} pl={5} pb={5} pr={5}>
                        <img width='100%'src={Image2} alt='Guncel Insaat Projeleri' />
                    </Grid>
                  
                </Grid>
                <Grid pt={"30px"} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pl={15} p={{xs:2,md:0}} pt={5} pb={5}item xl={6} md={6} xs={12}  sx={styles.Header5Top} >
                        <Typography mb={3} pt={3} sx={styles.Header4}>
                            {translate.ProjeDetaylari}
                        </Typography>
                        <Typography  sx={styles.Header5}>
                            {translate.ProjelerinBaslangicVeBitisTarihiAsamasi}
                        </Typography>
                    </Grid>
                    <Grid item xl={6} md={6} xs={12} p={5}>
                        <img width='100%'  src={Image3} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid mt={"10px"} container spacing={0} sx={styles.HeaderTop}>
                <Grid align= "center"  pt={{xs:0,md:5}} mt={{xs:5,md:5}} item xl={6} md={6} xs={12} sx={styles.Header4Top}>
                        <Typography mb={{xs:0,md:5}}  sx={styles.Header4}>
                            {translate.PeriyodikGuncelleme}
                        </Typography>
                        <Typography pb={{xs:0,md:7}} pt={3} sx={styles.Header5}>
                            {translate.GunlukPeriyotlarla}  
                        </Typography>
                    </Grid>
                    <Grid  item xl={6} md={6} xs={12} p={5}>
                        <img  width='100%' src={Image5} alt='Guncel Insaat Projeleri' />
                    </Grid>
                   
                </Grid>

                <Grid sx={{ backgroundColor: '#3C6780' }} container spacing={0}>
                    <Grid pt={10} pl={{xs:5,md:30}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header6}>
                            {translate.ProjeYetkilileri}
                        </Typography>
                        <Typography mt={3} sx={styles.Header7}>
                            {translate.ProjedekiYetkiliKisiyiAramakla}
                        </Typography>
                    </Grid>
                    <Grid align='center' pt={5}  item xl={6} md={6} xs={12} >
                        <img  width='400vw' height='auto' src={Image4} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}

export default GuncelInsaatProjeleri