import { Avatar, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getData } from '../../Assets/Scripts/dataAxios';
import { useSelector } from 'react-redux';


function BussinesPartnersPaper(children) {
    const data = children.data
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    
    const scrollElement = useRef(null)
    const scrollLeft = (val) => {
        scrollElement.current.scrollLeft += val
    }

    const [commnetData, SetCommnetData] = useState([]);
    useEffect(() => {
        const GetCommetData = async () => {
            const response = await getData(`Landing/GetAllHomeYorumlar`,);
            SetCommnetData(response.data.value);
        }
        GetCommetData()
            .catch(console.error);
    }, [])
    const styles = {
        Header: {
            fontSize: {xs:'35px',md:'50px'},
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '130%',
            color: '#000000',
            maxWidth: '470px',
            textAlign: 'left'
        },
        bussinessPartnersGrid:
        {
            overflow: "hidden",
            position: "relative"
        },
        scrollBussinessPartners: {
            zIndex: 2,
            "position": "absolute",
            bottom: "5vh",
            right: "10vw",
            overflow: "hidden",
            borderRadius: "50%",
            background: "rgba(143, 191, 250, 0.2)",
            border: "2px solid #D7E9FF",
            boxShadow: "0px 34px 44px -10px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(22px)",
            width: "102px",
            height: "102px",
            boxSizing: "border-box"
        },
        scrollBussinessPartnersLine: {
            position: "absolute",
            width: "86px",
            height: "86px",
            top: "6px",
            left: "6px",
            backgroundColor: "transparent",
            overflow: "hidden",
            borderRadius: "50%",
            boxSizing: "border-box",
            border: "2px solid #8FBFFA"
        },
        bussinessPartnersArea: {
            display: "flex",
            overflowX: "scroll",
            flexShrink: "0"
        },
        bussinesPartnersCard: {
            display: 'flex',
            background: "#F4F7F9",
            border: "1px solid #8FBFFA",
            borderRadius: "30px",
            boxSizing: "border-box",
            minWidth:{xs:"355px", md:"400px"} ,
            width: "25vw",
            height: "339px",
            margin: "0 20px",
            flexBasis: "40%",
            flexShrink: "0"
        },
        bussinessPartnerName: {
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "120%",
            letterSpacing: "0.01em",
            color: "#000000"
        },
        bussinessPartnerJob: {
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "120%",
            marginTop: "3px",
            letterSpacing: "0.01em",
            color: "#889AA4"
        },
        bussinessPartnersText: {
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            letterSpacing: "0.01em",
            color: "#303C43"
        },
        Logo: {
            maxHeight: '3em'
        },
        LogoWrapper: { position: 'absolute', bottom: '2em' }

    }
    return (
        <>
            <Grid container spacing={0} p={3} pt={10} pb={10} sx={{ backgroundColor: 'white' }}>
                <Grid ml={3} item xl={12} md={12} xs={12}>
                    <Typography align='center'
                        sx={styles.Header}>
                      {translate.MutluIsOrtaklari}
                    </Typography>
                </Grid>
                <Grid item pt={5} xl={12} md={12} xs={12}
                    sx={styles.bussinessPartnersGrid}>
                    <Box sx={styles.scrollBussinessPartners}>
                        <Box alignItems='center' justifyContent='center' justifyItems='center' display='flex' sx={styles.scrollBussinessPartnersLine}>
                            <IconButton
                                onClick={() => { scrollLeft(-450) }}
                                size='small'
                            >
                                <ArrowBackIosIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => { scrollLeft(+450) }}
                                size='small'
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box ref={scrollElement} sx={styles.bussinessPartnersArea}>
                        {commnetData.map((data) => (
                            <Card key={data.id} sx={styles.bussinesPartnersCard}>
                                <CardContent sx={{ position: 'relative' }}>
                                    <Box m={2}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Avatar display='inline' sx={{ margin: '10px 10px' }}></Avatar>
                                            <Box sx={{ margin: '10px 10px' }}>
                                                <Typography sx={styles.bussinessPartnerName}>{data.yetkili}</Typography>
                                                <Typography sx={styles.bussinessPartnerJob}>{data.gorevi}</Typography>
                                            </Box>
                                        </Box>
                                        <Box mt={3}>
                                            <Typography sx={styles.bussinessPartnersText}>
                                                {data.yorumu}
                                            </Typography>
                                        </Box>
                                        <Box sx={styles.LogoWrapper} mt={5}>
                                            <img style={styles.Logo} src={data.logoUrl} alt='yapiradar.com' />
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>





    )
}

export default BussinesPartnersPaper