import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux';
import CalculatorImage from '../../Assets/Images/Calculator.webp'
function Maliyet() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "30px",
            "lineHeight": "170%",
            "color": "#FF6600"
        },
        Header2: {
            marginTop:'0.5em',
            "width":{xs:"100%",md:"501px"},
            "height": "195px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": {xs:"37px",md:"50px"},
            "lineHeight": "130%",
            "color": "#000000"
        },
        Header3: {
            marginTop:'2em',
            "width": {xs:"100%",md:"480px"},
            "height": "189px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"14px",md:"16px"},
            "lineHeight": "170%",
            "color": "#303C43"
        },
        HeaderWrapper: {
            position: {xs:"relative",md:"absolute"},
            padding: {xs:"7vw",md:"5vw"},
            top: {xs:"auto",md:"auto"}
        },
        HeaderTop :{
            display: {md:"flex"}, 
            flexDirection: {md:"row-reverse"}
        },

    }
    return (
        <>
            <Grid sx={{ backgroundColor: '#ECEFF4', position: 'relative' }} container spacing={0}>
                <Grid item xl={12} md={12} xs={12}>
                    <Grid sx={styles.HeaderWrapper}>
                        <Typography sx={styles.Header1}>
                            {translate.CebiniziKoruyoruz}
                        </Typography>
                        <Typography sx={styles.Header2}>
                           {translate.MaliyetleriniziOranindaAzaltiyoruz}
                        </Typography>
                        <Typography sx={styles.Header3}>
                            {translate.InsaatFirmalarininProjeArastirma}
                        </Typography>
                    </Grid>
                    <Box align='right'>
                        <img style={{ heigt: '', width: '80%' }} src={CalculatorImage} alt='maliyet orani' />

                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Maliyet