import React, { useEffect, useRef } from 'react'
import IhaleLayout from '../Layouts/IhaleLayout'
import { useParams } from 'react-router-dom';
import { Box, Button, Chip, Divider, Grid, Paper, TextField, Typography, Link } from '@mui/material'
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useState } from 'react';
import { useCallback } from 'react';
import { loadState } from '../../Assets/Scripts/LocalStorage';
import { ShowMessage } from '../../Components/Common/Alerts';
import { getData } from '../../Assets/Scripts/dataAxios';
import { StyledLabel } from '../../Components/Styled/Labels/StyledLabels';
import { IhaleThemeGeneral } from '../../Themes/IhaleThemeGeneral';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { NumericFormat } from 'react-number-format'

const parseDate = (dateString) => {
    const [day, month, year, hour, minute, second] = dateString.match(/\d+/g);
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
};

const convertDateTimes = (param) => {
    const year = parseInt(param.slice(0, 4), 10);
    const month = parseInt(param.slice(4, 6), 10) - 1;
    const day = parseInt(param.slice(6, 8), 10);
    const hour = parseInt(param.slice(8, 10), 10);
    const minute = parseInt(param.slice(10, 12), 10);
    const second = parseInt(param.slice(12, 14), 10);
    const dateTime = new Date(year, month, day, hour, minute, second);
    const formattedDate = dateTime.toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Europe/Istanbul'
    });

    return (formattedDate);
}


//wss://testapi.yapiradar.com/api/Ihale/SendHubapi?ihaleId=6793
//{"protocol":"json","version":1}
function IhaleTeklif() {
    //STATES AND VARIABLES ______________________________________________________________________________________________
    const params = useParams();
    const UserData = JSON.parse(loadState('User'));
    const AuthToken = UserData.AuthKey ? UserData.AuthKey : '';
    const socketUrl = `wss://testapi.yapiradar.com/RealTimeIhaleHub?ihaleId=${params.id}&Authorization=${AuthToken}`
    const [messageHistory, setMessageHistory] = useState([]);
    const [lastMssge, setLastMssge] = useState([]);
    const [ihaleFiyatlariSocket, SetIhaleFiyatlariSocket] = useState([]);
    const [onlineUserCount, SetOnlineUserCount] = useState([]);
    const [ihaleData, SetIhaleData] = useState({});
    const [ihalebitis, setIhaleBitis] = useState(null);
    const [ihaleTeklifBedeli, setIhaleTeklifBedeli] = useState(0);
    const [tekliflerim, setTekliflerim] = useState([]);
    const [ihaleSiram, SetIhaleSiram] = useState(0);
    const teklifRef = useRef();
    const teklifSirasiRef = useRef();
    const audio = new Audio('../../Assets/Sounds/Coinsfallingsoundeffect.mp3');
    const UserInfoData = JSON.parse(loadState('UserInfo'));

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        share: false,
        shouldReconnect: () => false,
    });


    //HOOKS ______________________________________________________________________________________________

    useEffect(() => {
        const getIhaleData = async () => {
            try {
                const response = await getData(`Ihale/DijitalIhaleById?Id=${params.id}`)
                SetIhaleData(response.data.value);
                handleClickSendMessage();
                if (response.data.value.ihaletarihiEnd !== null) {
                    calculateIhaleStartedDateTimes(response.data.value.ihaletarihiEnd.toString())
                }
            } catch (error) {
                ShowMessage('error', error)
            }
        }

        getIhaleData();
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => prev.concat(lastMessage));
            const lastMssg = JSON.parse(lastMessage.data.slice(0, -1));
            if (lastMssg.target === 'TeklifCallHubResponse') {
                playSound();
                setTekliflerim(lastMssg.arguments[0].myTeklif);
                SetIhaleFiyatlariSocket(lastMssg.arguments[0].teklifList);
                setLastMssge(lastMssg.arguments?.[0]);
                SetOnlineUserCount(lastMssg.arguments?.[0].connectionsCount)
            } else if (lastMssg.target === 'TeklifEchoHubResponse') {
                SetIhaleFiyatlariSocket(lastMssg.arguments?.[0].teklifList)
            } else if (lastMssg.target === 'IhaleConnections') {
                console.log(lastMssg.arguments?.[0])
                SetOnlineUserCount(lastMssg.arguments?.[0]?.connectionsCount)
            }
        }
    }, [lastMessage, setMessageHistory]);



    useEffect(() => {
        const intervalId = setInterval(() => {
            if (ihaleData.ihaletarihiEnd !== null && ihalebitis !== null) {
                calculateIhaleStartedDateTimes(ihaleData.ihaletarihiEnd.toString());
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [ihalebitis]);



    //FUNCTIONS __________________________________________________________________________________________________________________
    const calculateIhaleStartedDateTimes = (param2) => {
        const currentDateTime = new Date();
        const formattedCurrentDate = currentDateTime.toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Europe/Istanbul'
        });
        // Tarihleri JavaScript Date nesnelerine dönüştür
        if (param2 !== undefined && param2 !== null && param2 !== 0) {
            const date1String = formattedCurrentDate;
            const date2String = convertDateTimes(param2);
            const date1 = parseDate(date1String);
            const date2 = parseDate(date2String);
            // İki tarih arasındaki zaman farkını milisaniye cinsinden al
            const timeDifference = date2 - date1;
            // Milisaniyeyi gün, saat, dakika ve saniyeye çevir
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
            setIhaleBitis({
                days: daysDifference,
                hours: hoursDifference,
                minutes: minutesDifference,
                seconds: secondsDifference,
            })
        }
    }
    const handleClickSendMessage = useCallback(() => sendMessage('{"protocol":"json","version":1}'), []);

    const handleClickSendMessage2 = () => {
        sendMessage(`{"type":1, "target":"SendToTeklif","arguments": [{"IhaleId": ${params.id},"moneyTypeId": 1,"Fiyat": ${ihaleTeklifBedeli}}]}`);
        ShowMessage('success', 'Teklifiniz Oluşturuldu.')
        // handleScroll2();
    };

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const setIhaleTeklifBedeliChange = (e) => {
        setIhaleTeklifBedeli(e.target.value);
    }

    const handleScroll = () => {
        teklifRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll2 = () => {
        teklifSirasiRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const playSound = () => {
        audio.play();
    };

    return (
        <IhaleLayout>
            <Grid ref={teklifSirasiRef} sx={{ background: '#F4F7F9', padding: '1em' }} pl={5} pr={5} mb={10} container spacing={3}>
                <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                    <Grid mb={3} container spacing={4}>
                        <Grid item xl={9} md={9} xs={12}>
                            <Paper sx={{ pt: '2em', pl: '2em', pr: '2em', minHeight: '200px' }} elevation={0}>
                                {
                                    ihaleFiyatlariSocket && ihaleFiyatlariSocket.length > 0 && (
                                        <div>
                                            {ihaleFiyatlariSocket.map((item, index) => (
                                                <Paper key={index} elevation={0} sx={{
                                                    border: '1px solid #ececec ',
                                                    m: 1,
                                                    height: '80px',
                                                    borderRadius: '20px',
                                                    animation: index === 0 ? 'glow 0.6s infinite alternate' : 'glow2 0.6s infinite alternate',
                                                    '@keyframes glow2': {
                                                        from: {
                                                            boxShadow: '0 0 1.4px 1.4px #ececec',
                                                        },
                                                        to: {
                                                            boxShadow: '0 0 4.2px 4.2px #ececec',
                                                        },
                                                    },
                                                    '@keyframes glow': {
                                                        from: {
                                                            boxShadow: '0 0 1.4px 1.4px #aef4af',
                                                        },
                                                        to: {
                                                            boxShadow: '0 0 4.2px 4.2px #aef4af',
                                                        },
                                                    },
                                                }}>
                                                    <Grid sx={{ display: "inline-flex", alignItems: 'center', height: '100%' }} container spacing={0}>
                                                        <Grid
                                                            sx={{ display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                            item xl={2} md={2} xs={12}>
                                                            <Box>
                                                                <Box>
                                                                    <Typography
                                                                        color={IhaleThemeGeneral.palette.gray.primary}
                                                                    >
                                                                        {index === 0 ? 'En İyi Teklif' : ' Teklif Sırası'}

                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography fontSize='24px'>
                                                                        {index + 1}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            sx={{ display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                            item xl={UserInfoData.isAdmin || ihaleData.isCreator ? 3 : 5} md={UserInfoData.isAdmin || ihaleData.isCreator ? 2 : 5} xs={12}>
                                                            <Box>
                                                                <Box>
                                                                    <Typography
                                                                        color={IhaleThemeGeneral.palette.gray.primary}
                                                                    >
                                                                        Teklif Bedeli

                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography fontSize='28px'>
                                                                        {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.fiyat)}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            sx={{ display: UserInfoData.isAdmin || ihaleData.isCreator ? "inline-flex" : 'none', alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                            item xl={5} md={5} xs={12}>
                                                            <Box>
                                                                <Box>
                                                                    <Typography
                                                                        color={IhaleThemeGeneral.palette.gray.primary}
                                                                    >
                                                                        Firma Adı

                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography sx={{ textAlign: 'left' }} fontSize='16px'>
                                                                        {item?.firmaAdi}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>

                                                        <Grid
                                                            sx={{ display: "inline-flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}
                                                            item xl={UserInfoData.isAdmin || ihaleData.isCreator ? 2 : 5} md={UserInfoData.isAdmin || ihaleData.isCreator ? 2 : 5} xs={12}>
                                                            <Box>
                                                                <Box>
                                                                    <Typography
                                                                        color={IhaleThemeGeneral.palette.gray.primary}
                                                                    >
                                                                        Teklif Zamanı

                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography>
                                                                        20.12.2023 12:46
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>

                                            ))}
                                        </div>
                                    )
                                }
                            </Paper>
                        </Grid>


                        <Grid item xl={3} md={3} xs={12}>
                            <Paper sx={{ pt: '2em', pl: '2em', pr: '2em', mb: '1em', minHeight: '200px' }} elevation={0}>
                                <Typography
                                    mb='1em'
                                >
                                    İhaledeki Kişi Sayısı : {onlineUserCount !== null && onlineUserCount}
                                </Typography>
                                <Typography
                                    mb='1em'
                                >
                                    Toplam Teklif Sayısı : {lastMssge !== null && lastMssge?.toplamTeklifSayisi}
                                </Typography>
                                <Typography
                                    mb='1em'
                                >
                                    Ortalama Teklif Tutarı : {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(lastMssge?.ortalamaBedel)}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid ref={teklifRef} item xl={12} md={12} xs={12}>
                        <Typography mb={2} mt={3} pl={3} fontSize='26px'>
                            Teklif Oluştur
                        </Typography>

                        <Paper sx={{ minHeight: '120px', p: '0em 0em 1em 0em' }} elevation={0}>
                            <Grid sx={{ minHeight: '120px' }} container spacing={0}>
                                <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xl={3} md={3} xs={12}>
                                    <Box sx={{ display: UserInfoData.isAdmin === false && ihaleData.isCreator === 0 ? '' : 'none' }}>
                                        <Typography color={IhaleThemeGeneral.palette.gray.primary}>
                                            Son Teklifiniz
                                        </Typography>
                                        <Typography
                                            fontSize='24px'
                                        >
                                            {
                                                tekliflerim?.[0]?.fiyat !== null && (new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(tekliflerim?.[0]?.fiyat))
                                            }
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: UserInfoData.isAdmin === true || ihaleData.isCreator === 1 ? '' : 'none' }}>
                                        <Typography color={IhaleThemeGeneral.palette.gray.primary}>
                                            Son Teklifiniz
                                        </Typography>
                                        <Typography
                                            fontSize='18px'
                                        >
                                            Bu ihaleye teklif veremezsiniz.
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xl={3} md={3} xs={12}>
                                    <Box>
                                        <Typography color={IhaleThemeGeneral.palette.gray.primary}>
                                            Yeni Teklifiniz
                                        </Typography>
                                        <NumericFormat
                                            disabled={UserInfoData.isAdmin === true || ihaleData.isCreator === 1 ? true : false}
                                            customInput={TextField}
                                            thousandSeparator=","
                                            prefix='₺'
                                            fullWidth
                                            placeholder='TL'
                                            onValueChange={(values, sourceInfo) => {
                                                setIhaleTeklifBedeli(values.value)
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xl={3} md={3} xs={12}>
                                    <Box>
                                        <Typography>
                                            *En düşük fiyat sizde ise daha düşük teklif oluşturamazsınız
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xl={3} md={3} xs={12}>
                                    <Box>
                                        <Button
                                            disabled={UserInfoData.isAdmin === true || ihaleData.isCreator === 1 ? true : false}
                                            onClick={handleClickSendMessage2}
                                            sx={{
                                                width: "100%",
                                                minWidth: '240px'
                                            }}
                                            variant='outlined'
                                        >
                                            <StyledLabel >
                                                Teklif Ver
                                            </StyledLabel>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <Paper elevation={0}>
                        <Box pl='3em' pr='4em' pt='1em' pb='1.5em' sx={{ display: ihaleData.isCreator === 1 || UserInfoData.isAdmin === true ? 'none' : 'inline-flex', width: '80%', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography
                                    color={IhaleThemeGeneral.palette.gray.primary}
                                >
                                    Son Teklifiniz
                                </Typography>

                                <Typography
                                    fontSize='34px'
                                >
                                    {

                                        tekliflerim?.[0]?.fiyat !== null && (new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(tekliflerim?.[0]?.fiyat))

                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ display: ihaleData.isCreator === 1 || UserInfoData.isAdmin === true ? 'none' : '' }} />
                        <Box pt='1em' pb='2em'>
                            <Typography
                                pl='3em' pr='4em' pt='1em' pb='1.5em'
                                color={IhaleThemeGeneral.palette.gray.primary}
                            >
                                Kalan Süre
                            </Typography>
                            <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'space-around' }}>
                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihalebitis !== null ? (ihalebitis.hours) : '-'}
                                    </Typography>

                                    <Typography>
                                        Saat
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihalebitis !== null ? (ihalebitis.minutes) : '-'}
                                    </Typography>

                                    <Typography>
                                        Dakika
                                    </Typography>
                                </Box>


                                <Box>
                                    <Typography
                                        fontSize='34px'
                                    >
                                        {ihalebitis !== null ? (ihalebitis.seconds) : '-'}
                                    </Typography>

                                    <Typography>
                                        Saniye
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box pr='3em' pl='3em' pb='2em'>
                            <Button disabled={UserInfoData.isAdmin === true || ihaleData.isCreator === 1 ? true : false} onClick={handleScroll} variant='outlined' fullWidth>
                                Hemen Teklif Oluştur
                            </Button>
                        </Box>
                    </Paper>
                    <Typography mt={3} pl={3} fontSize='26px'>
                        Eski Teklifleriniz
                    </Typography>
                    <Box sx={{
                        maxHeight: '350px', overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none'
                    }}>
                        {
                            Array.isArray(tekliflerim) && tekliflerim.length > 0 ? (
                                tekliflerim.map((item, index) => (
                                    <Paper key={index} mt={2} elevation={0} sx={{ textAlign: 'center', height: '65px', display: 'flex', alignItems: 'center', mt: '1em', pl: '2em' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', pr: '1em', pl: '1em' }}>
                                            <Typography textAlign='center' fontSize='22px'>
                                                {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', currencyDisplay: 'symbol' }).format(item.fiyat)}
                                            </Typography>

                                            <Typography textAlign='center' fontSize='14px'>
                                                {3 * index + 2} Dakika Önce
                                            </Typography>
                                        </Box>
                                    </Paper>
                                ))
                            ) : (
                                <Paper mt={2} elevation={0} sx={{ textAlign: 'center', height: '65px', display: 'flex', alignItems: 'center', mt: '1em', pl: '2em' }}>
                                    <Typography textAlign='center' fontSize='22px' key='noData'>
                                        ----
                                    </Typography>
                                </Paper>
                            )
                        }
                    </Box>
                </Grid>



                <Grid sx={{ background: '#F4F7F9', padding: '2em' }} pl={5} pr={5} mb={10} container spacing={3} >
                    <Grid item xl={12} md={12} xs={12}>
                        <Typography
                            sx={{
                                fontSize: '28px',
                                color: '#FF6600',
                                lineHeight: '39.01px',
                                fontWeight: 600,
                            }}
                        >
                            {ihaleData?.ihaleadi}
                        </Typography>
                    </Grid>
                    <Grid item xl={3} md={3} xs={12}>
                        <Paper sx={{
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    Kategori
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#0A354E',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {ihaleData?.kategori}
                                </Typography>
                            </Box>
                        </Paper>


                        <Paper sx={{
                            mt: '1em',
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    İşin Yapılacağı Şehir
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#0A354E',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {ihaleData?.ilceNavigation?.ad} - {ihaleData?.sehirNavigation?.ad}
                                </Typography>
                            </Box>
                        </Paper>


                        <Paper sx={{
                            mt: '1em',
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    İhale Tipi
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#0A354E',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {ihaleData?.tenderType?.name}
                                </Typography>
                            </Box>
                        </Paper>


                        <Paper sx={{
                            mt: '1em',
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    İhale Şartnamesi
                                </Typography>


                                <Box sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center'
                                }}>
                                    <FolderOpenOutlinedIcon sx={{ color: '#FF6600' }} />
                                    <Typography
                                        sx={{
                                            mt: '3px',
                                            ml: '5px',
                                            height: '20px',
                                            fontSize: '14px',
                                            color: '#FF6600',
                                            lineHeight: '19px',
                                            fontWeight: 500,
                                        }}
                                    >   {
                                            ihaleData && ihaleData.ihaleDocumentNavigation?.length > 0 && (
                                                ihaleData?.ihaleDocumentNavigation.map((doc) => (
                                                    doc.aktif === 1 ? (
                                                        <Link underline='none' href={`/images/IhaleDocuments/${doc.document}`}>
                                                            Dosyayı İndir
                                                        </Link>
                                                    ) : null
                                                ))
                                            )
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xl={6} md={6} xs={12}>
                        <Paper sx={{
                            borderRadius: '24px',
                            height: '103px',
                            padding: '1.4em',
                            minHeight: '415px'

                        }}>
                            <Typography
                                sx={{
                                    mb: '1em',
                                    fontSize: '16px',
                                    color: '#0A354E',
                                    lineHeight: '24px',
                                    fontWeight: 700,
                                }}
                            >
                                İhale Açıklaması
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    color: '#0A354E',
                                    lineHeight: '19px',
                                    fontWeight: 400,
                                }}
                            >
                                {ihaleData?.kisaTanim}
                            </Typography>
                        </Paper>
                    </Grid>


                    <Grid item xl={3} md={3} xs={12}>
                        <Paper sx={{
                            borderRadius: '24px',
                            height: '123px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '18px',
                                        color: '#FF6600',
                                        lineHeight: '19px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {ihaleData?.firma?.firmaadi}
                                </Typography>

                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '12px',
                                        color: '#AAAAAA',
                                        lineHeight: '19px',
                                        fontWeight: 400,
                                    }}
                                >
                                    -Firma Kategorisi-
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#3C6780',
                                        lineHeight: '19px',
                                        fontWeight: 700,
                                    }}
                                >
                                    {ihaleData?.firma?.adsoyad}
                                </Typography>
                            </Box>
                        </Paper>


                        <Paper sx={{
                            mt: '1em',
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    Başlangıç Zamanı
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#0A354E',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {
                                        ihaleData && ihaleData.ihaletarihiBegin && (
                                            convertDateTimes(ihaleData.ihaletarihiBegin.toString())
                                        )
                                    }
                                </Typography>
                            </Box>
                        </Paper>


                        <Paper sx={{
                            mt: '1em',
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    Bitiş Zamanı
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        color: '#0A354E',
                                        lineHeight: '19px',
                                        fontWeight: 500,
                                    }}
                                >
                                    {
                                        ihaleData && ihaleData.ihaletarihiEnd && (
                                            convertDateTimes(ihaleData.ihaletarihiEnd.toString())
                                        )
                                    }
                                </Typography>
                            </Box>
                        </Paper>

                        <Paper sx={{
                            display: ihaleData.isIhaleStart === -1 && (ihaleData.isCreator === 1 || UserInfoData.isAdmin === true) ? '' : 'none',
                            mt: '1em',
                            borderRadius: '24px',
                            height: '103px'

                        }}>
                            <Box sx={{ padding: '1.5em 2em' }}>
                                <Typography
                                    sx={{
                                        mb: '0.6em',
                                        fontSize: '16px',
                                        color: '#0A354E',
                                        lineHeight: '24px',
                                        fontWeight: 700,
                                    }}
                                >
                                    İhale Sonuçlarını Değerlendir
                                </Typography>

                                <Button
                                    sx={{ width: 'fit-content' }}
                                    onClick={() => window.location.href = `/Ihale/Sonuc/${ihaleData.seourl}`}
                                    variant='text' fullWidth>
                                    İhale Sonuç Sayfasına Git
                                </Button>
                            </Box>
                        </Paper>

                    </Grid>
                </Grid>

                <Grid item xl={4} md={4} xs={12}>
                    <Typography mb={2} mt={3} pl={3} fontSize='26px'>
                        Firma Bilgileriniz
                    </Typography>

                    <Paper sx={{ minHeight: '120px' }} elevation={0}>
                        <Grid sx={{ minHeight: '120px' }} container spacing={0}>
                            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xl={9} md={9} xs={12}>
                                <Box>
                                    <Typography color={IhaleThemeGeneral.palette.gray.primary}>
                                        Firma Adı
                                    </Typography>
                                    <Typography
                                        fontSize='20px'
                                    >
                                        YapıRadar Bilgi Teknolojileri A.Ş.
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xl={3} md={3} xs={12}>
                                <Box>
                                    <Typography color={IhaleThemeGeneral.palette.gray.primary}>
                                        İhale Sayısı
                                    </Typography>
                                    <Typography
                                        fontSize='20px'
                                        sx={{ width: '100%', textAlign: 'center' }}
                                    >
                                        2
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </IhaleLayout>
    )
}

export default IhaleTeklif