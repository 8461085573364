import { Button, Card, CardContent, Grid, TablePagination, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { postData } from '../../Assets/Scripts/dataAxios'
import ItemCard from '../PazarYeri/ItemCard'

function MostSellerItems(children) {
    const [currenttablePage, SetCurrenttablePage] = useState();
    const [currenttableSize, SetCurrenttableSize] = useState();

    const [newItemsData, SetNewItemsData] = useState([]);
    const [newItemsDataRequest, SetNewItemsDataRequest] = useState(
        {
            "orderBy": {
                "key": "urunViewCount",
                "value": false
            },
            "pageSize": currenttableSize ? currenttableSize : 12,
            "currentPage": currenttablePage ? currenttablePage : 0,
            "isPagingEnabled": true,
            "firmaids": [],
            "kategoriIds": [],
            "urunadi": ""
        }
    )

    useEffect(() => {
        const getSearchString = () => {
            if (children.urunAdi) {
                SetNewItemsDataRequest({
                    ...newItemsDataRequest,
                    urunadi: children.urunAdi
                })
            }
        }
        getSearchString();
    }, [])

    useEffect(() => {
        const GetNewItemsData = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', newItemsDataRequest);
            SetNewItemsData(response.data.value.data);
            SetResultCount(response.data.value.recordsFiltered)
        }
        GetNewItemsData()
            .catch(console.error);
    }, [newItemsDataRequest])


    const styles = {
        Header1: {
            "height": "56px",
            "top": "calc(50% - 56px/2 - 2987.5px)",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "40px",
            "lineHeight": "140%",
            "display": "flex",
            "alignItems": "center",
            "color": "#000000"
        },
        Card: {
            width: '250px',
            backgroundColor: '#F4F7F9',
            padding: 0,
            boxShadow: 'none',
            margin: '0px 3vw',
        },
        CardContent: {
            padding: 0,
        },
        cardHeader: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#8FBFFA",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer'
            }
        },
        cardText: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "22px",
            "lineHeight": "120%",
            "color": "#000000",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer'
            }
        },
        newItemsWrapper: {
            display: 'inline-flex',
        },
        ShowMoreBtn: {
            "boxSizing": "border-box",
            "border": "1px solid #BCC7CE",
            "borderRadius": "25px",
            width: '750px',
            height: '50px',
        },

    }
    const navigate = useNavigate();

    const handleCardClick = (link) => {
        navigate(link)
    }

    const [resultCount, SetResultCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        SetNewItemsDataRequest({
            ...newItemsDataRequest,
            currentPage: newPage,
        })


        const scrollElement = document.getElementById('scrollToTable');
        scrollElement.scrollIntoView({
            behavior: 'smooth'
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        SetNewItemsDataRequest({
            ...newItemsDataRequest,
            pageSize: event.target.value
        })
    };

    return (
        <>
            <Grid id='scrollToTable' pl={10} pr={10} pt={10} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                <Grid pb={5} item xl={12} md={12} xs={12}>
                    <Typography sx={styles.Header1} >
                        {children.urunAdi ? 'Arama Sonucu' : 'Ürünler'}
                    </Typography>
                </Grid>
                {newItemsData.map((item) => (
                    <Grid key={item.id} mb={5} item xl={3} md={3} xs={3}>
                        <ItemCard props={item} />
                    </Grid>
                ))}
                <Grid align='center' item xl={12} md={12} xs={12} pr={20} mt={5}>
                    <TablePagination
                        labelDisplayedRows={
                            ({ from, to, count }) => {
                                return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                            }}
                        component="div"
                        count={resultCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Sayfada şu kadar sonuç göster"
                        rowsPerPageOptions={[
                            12, 20, 48, 96
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default MostSellerItems