import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'

function MaximumEfficiency(children) {
  const data = children.data;
  const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
  const languageCode = useSelector((state) => state.Language.userLanguageFromLocalStorage);

  const styles = {
    compHeaderText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '50px',
      fontWeight: 1000,
      color: '#FFFFFF',
      width: "100%",
      marginBottom:"20px",
    },
    compDescText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '20px',
      fontWeight: 500,
      color: '#FFFFFF',
      width: '705px',
    },
    cardContent: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      marginTop: '3em',
      maxWidth: '350px',
    },
    cardHeaderText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '20px',
      fontWeight: 700,
      color: '#FFFFFF',
      margin: '1em 0'
    },
    cardText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 400,
      color: '#FFFFFF'
    }
  }

  const EnglishData =  [
    {
      "id": 1,
      "name": "Current Construction Site Image",
      "text": "See the current images of the construction sites visited by our expert field teams and track the progress of the sites.",
      "logo": "EfficiencyImage"
    },
    {
      "id": 2,
      "name": "Construction Site Location",
      "text": "Pinpoint your construction site visits with the location information shared directly from within the site by our field teams.",
      "logo": "EfficiencyLocation"
    },
    {
      "id": 3,
      "name": "Solution Partners",
      "text": "Access information about not only contractors but also the solution partners of the projects, including architects, electrical and mechanical design firms, and contracting companies.",
      "logo": "EfficiencyPartners"
    },
    {
      "id": 4,
      "name": "60-Day Update",
      "text": "Access the most accurate construction site information with project details updated at 60-day intervals.",
      "logo": "EfficiencyUpdate"
    },
    {
      "id": 5,
      "name": "PPTS Module",
      "text": "Maximize sales efficiency by identifying suitable projects for you among thousands of options using the PPTS Module.",
      "logo": "EfficiencyPPTS"
    },
    {
      "id": 6,
      "name": "Weekly Bulletin",
      "text": "Receive a weekly report of newly added and updated projects in your email.",
      "logo": "EfficiencyWeekly"
    }
  ]

  return (
    <Box>
      <Grid sx={{ backgroundColor: '#3C6780', display: { xs: 'none', md: 'flex' } }} container spacing={0}>
        <Grid ml={10} pt={8} pb={8} item xs={12} md={12} xl={12}>
          <Grid container spacing={0}>
            <Grid item xl={12} md={12} xs={12}>
              <Typography align='left' sx={styles.compHeaderText}>
                {translate.MaksimumVerimAlin}
              </Typography >
              <Typography align='left' sx={styles.compHeaderText}>
              {translate.SahaVeProjeArastirmaMaliyetinizi}
              </Typography >
              <Typography align='left' sx={styles.compDescText}>
              {translate.SistemimizdekiBinlerceGuncel}
              </Typography>
            </Grid>
            <Grid item xl={12} xs={12} md={12}>
              <Grid sx={{display : languageCode === 'Turkish' ? '' : 'none'}} container spacing={0}>
                {data.map((data) => (
                  <Grid key={data.id} item xl={4} md={4} xs={4}>
                    <Card sx={styles.cardContent}>
                      <CardContent>
                        <img src={require(`../../Assets/Icons/${data.logo}.png`)} alt='yapiradar.com' />
                        <Typography sx={styles.cardHeaderText}>
                          {data.name}
                        </Typography>
                        <Typography sx={styles.cardText}>
                          {data.text}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <Grid sx={{display : languageCode === 'English' ? '' : 'none'}} container spacing={0}>
                {EnglishData.map((data) => (
                  <Grid key={data.id} item xl={4} md={4} xs={4}>
                    <Card sx={styles.cardContent}>
                      <CardContent>
                        <img src={require(`../../Assets/Icons/${data.logo}.png`)} alt='yapiradar.com' />
                        <Typography sx={styles.cardHeaderText}>
                          {data.name}
                        </Typography>
                        <Typography sx={styles.cardText}>
                          {data.text}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>



      <Grid sx={{ backgroundColor: '#3C6780', display: { xs: 'flex', md: 'none' } }} container spacing={0}>
        <Grid pt={10} pb={10} item xs={12} md={12} xl={12}>
          <Grid container spacing={0}>
            <Grid align='center' item xl={12} md={12} xs={12}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontSize: {xs:"30px",md:'38px'},
                fontWeight: 700,
                color: '#f3bf9d',
              }}>
                {translate.MaksimumVerimAlin}
              </Typography >
              <Typography sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontSize: {xs:"26px",md:'38px'},
                fontWeight: 700,
                color: '#FFFFFF',
                padding:{xs:"20px", md:""},
              }}>
                 {translate.SahaVeProjeArastirmaMaliyetinizi}
              </Typography >
              <Typography sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontSize: '20px',
                fontWeight: 500,
                color: '#FFFFFF',
              }}>
                {translate.SistemimizdekiBinlerceGuncel}
              </Typography>
            </Grid>
            <Grid align='center' item xl={12} xs={12} md={12}>
              <Grid container spacing={0}>
                {data.map((data) => (
                  <Grid key={data.id} item xl={4} md={4} xs={12}>
                    <Card sx={{
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      marginTop: '3em',
                      maxWidth: '350px',
                    }}>
                      <CardContent>
                        <img src={require(`../../Assets/Icons/${data.logo}.png`)} alt='yapiradar.com' />
                        <Typography sx={styles.cardHeaderText}>
                          {data.name}
                        </Typography>
                        <Typography sx={styles.cardText}>
                          {data.text}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Box>
  )
}

export default MaximumEfficiency