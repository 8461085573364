import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import YapiRadarNedir from '../../Assets/Images/browser.webp'
import { useSelector } from 'react-redux';

function WhatWeDo() {
    const languageCode = useSelector((state) => state.Language.userLanguageFromLocalStorage);
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const styles = {
        yapiradarVideo:
            { "background": "linear-gradient(to top,#F4F7F9 0%,#F4F7F9 60%,white 60%,white 100%)" }
    }
    return (
        <>
            <Grid container sx={styles.yapiradarVideo} spacing={0}>
                <Grid sx={{ display: { xs: 'none', md: 'block' } }} align='center' item xl={12} md={12} xs={12}>
                    <Typography sx={{
                        marginBottom: '1em',
                        "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: '42px', md: '50px' }, "lineHeight": "140%", "textAlign": "center", "color": "#FF6600"
                    }}>
                        <span style={{ color: 'black' }}>{translate.NeIsYapar} </span>
                    </Typography>

                    <Box
                        sx={{
                            position: 'relative',
                            width: 'fit-content'
                        }}
                    >
                        <img src={YapiRadarNedir} />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 65,
                                left: '10%'
                            }}
                        >
                            <iframe
                                style={{
                                    borderRadius: '20px'
                                }}
                                width="740"
                                height="450"
                                src="https://tube.rvere.com/embed?v=UQatMiFwv6g&start=0&end=60&fs=0&color=white&rel=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </Box>
                    </Box>
                </Grid>


                <Grid sx={{ display: { xs: 'block', md: 'none' } }} align='center' item xl={12} md={12} xs={12}>
                    <Box sx={{ display: languageCode === 'Turkish' ? '' : 'none' }}>
                        <Typography sx={{
                            marginBottom: '1em',
                            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: '25px', md: '50px' }, "lineHeight": "140%", "textAlign": "center", "color": "#FF6600"
                        }}>
                            <span style={{ color: 'black' }}>Ne İş Yapıyoruz?</span>
                        </Typography>
                    </Box>

                    <Box sx={{ display: languageCode === 'English' ? '' : 'none' }}>
                        <Typography sx={{
                            marginBottom: '1em',
                            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: '25px', md: '50px' }, "lineHeight": "140%", "textAlign": "center", "color": "#FF6600"
                        }}>
                            <span style={{ color: 'black' }}>{translate.NeIsYapar} </span>

                            <span style={{ color: 'black' }}>{translate.Yap} ?</span>
                        </Typography>
                    </Box>


                    <Box
                        sx={{
                            position: 'relative',
                            width: 'fit-content'
                        }}
                    >
                        <img
                            style={{
                                height: '100%',
                                width: '100%'
                            }}
                            src={YapiRadarNedir} />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 45,
                                left: '10%',
                                width: '80%',
                                height: '60%'
                            }}
                        >
                            <iframe
                                style={{
                                    borderRadius: '20px',
                                    width: '100%'
                                }}
                                width='100%'
                                height='100%'
                                src="https://www.youtube.com/embed/UQatMiFwv6g"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default WhatWeDo