import { createSlice } from '@reduxjs/toolkit'
import { loadState, saveState } from '../../Assets/Scripts/LocalStorage';



const user = loadState('User');
if (!user) saveState('User', false)

const initialState = {
    user,
}

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        Login: (state, action) => {
            state.user = JSON.stringify({
                isLogged: true,
                isDigitalKatalog: action.payload.isDigitalKatalog,
                Name: action.payload.adSoyad,
                AuthKey: action.payload.token,
                FirmaId: action.payload.dkFirmaid,
                FirmaAdmin: action.payload.firmaAdmin,
                isAdmin: action.payload.isAdmin,
                dkAdmin: action.payload.dkAdmin
            })
            saveState('User', state.user)
        },
        LogOut: (state) => {
            state.user = false
            saveState('User', state.user)
        }
    },
})

export const { Login, LogOut } = AuthSlice.actions

export default AuthSlice.reducer