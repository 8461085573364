import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Image1 from '../../Assets/Images/ListProject.webp';
import Image2 from '../../Assets/Images/ShowDetails.webp';
import Image3 from '../../Assets/Images/TeklifVerin.webp';
import Image4 from '../../Assets/Images/SitingOnChair.webp';
import { useSelector } from 'react-redux'

function MaksimumFaydalar() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"26px",md:"50px"},
            "lineHeight": "300%",
            "textAlign": "center",
            "color": "#FF6600"
        },
        Header2: {
            "width":{xs:"100%",md:"607px"},
            "height": {xs:"auto",md:"auto"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": {xs:"30px",md:"50px"},
            "textAlign": "center",
            "color": "#000000"
        },
        Header3: {
            "width": {xs:"90%",md:"70%"},
            "height": "135px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"16px",md:"22px"},
            "lineHeight": "170%",
            "textAlign": "center",
            "color": "#303C43"
        },
        Header4: {
            "width": {xs:"100%",md:"80%"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"25px",md:"22px"},
            "lineHeight": "170%",
            "color": "#FF6600",
            "textAlign":{xs:"justify",md:"left"}
        },
        Header5: {
            "width": {xs:"100%",md:"80%"},
            "height": "130px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": {xs:"25px",md:"50px"},
            "lineHeight": "130%",
            "color": "#000000",
            "textAlign":{xs:"justify",md:"left"}
        },
        Header6: {
            "width": {xs:"100%",md:"80%"},
            "height": "auto",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize":{xs:"16px",md:"22px"} ,
            "lineHeight": "170%",
            "color": "#303C43",
            "marginTop":{xs:"0",md:"2em"},
            "textAlign":{xs:"justify",md:"left"}
        },
        card: {
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            textAlign: 'center'
        },
        cardImage: {
            width: '273px',
            height: '158px',
        },
        cardHeader1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "20px",
            "lineHeight": "170%",
            "color": "#000000"
        },
        cardHeader2Box: {
            display: 'flex-inline',
            aligin: 'center',
        },
        cardHeader2: {
            margin: 0,
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "170%",
            "color": "#303C43"
        }
    }


    return (
        <>
            <Grid container spacing={0}>
                <Grid p={{xs:3,md:1}}  align='center' item xl={12} md={12} xs={12}>
                <Typography mt={5} sx={styles.Header1}>
                    {translate.IsleriniziKolaylastiriyoruz}
                    </Typography>
                    <Typography sx={styles.Header2}>
                    {translate.MinimumEforMaksimumVerimlilik}
                    </Typography>
                    <Typography mt={5} sx={styles.Header3}>
                        {translate.CokSayidaPaydasinBirArada}
                    </Typography>
                </Grid>

                <Grid mt={5} container spacing={0}>
                    <Grid item xl={4} md={4} xs={12}>
                        <Card sx={styles.card}>
                            <CardContent>
                                <img style={{
                                    height: {xs:"auto",md:"158px"},
                                    width: {xs:"100%",md:"273px"}
                                }} src={Image1} alt='Projeleri Listeleyin' />
                                <Typography sx={styles.cardHeader1}>
                                   {translate.ProjeleriListeleyin}
                                </Typography>
                                <Box sx={styles.cardHeader2Box}>
                                    <Typography sx={styles.cardHeader2}>
                                        {translate.OturdugunuzYerdenKilometrelerceUzakliktaki}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xl={4} md={4} xs={12}>
                        <Card sx={styles.card}>
                            <CardContent>
                                <img style={{
                                    height: {xs:"auto",md:"70%"},
                                    width: {xs:"100%",md:"70%"}
                                }} src={Image3} alt='Projeleri Listeleyin' />
                                <Typography sx={styles.cardHeader1}>
                                    {translate.TeklifleriniziVerin}
                                </Typography>
                                <Box sx={styles.cardHeader2Box}>
                                    <Typography sx={styles.cardHeader2}>
                                        {styles.YapiradarcomAracigiliylaUlasmisOldugunuz}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xl={4} md={4} xs={12}>
                        <Card sx={styles.card}>
                            <CardContent>
                                <img style={{
                                    height: {xs:"auto",md:"158px"},
                                    width: {xs:"100%",md:"224px"}
                                }} src={Image2} alt='Projeleri Listeleyin' />
                                <Typography sx={styles.cardHeader1}>
                                    {translate.DetaylariGoruntuleyin}
                                </Typography>
                                <Box sx={styles.cardHeader2Box}>
                                    <Typography sx={styles.cardHeader2}>
                                        {translate.ListelemeProjelerinCozumOrtaklarini}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                   
                    <Grid  container mt={5} pl={5} pr={5} spacing={0}>
                        <Grid item xl={5} md={5} xs={12}>
                                <img style={{
                                    height:"auto",
                                    width: "100%"
                                }} src={Image4} alt='Buyuk veri ile zaman kazanin' />
                        </Grid>
                        <Grid align='center'item mt={4} xl={7} md={7} xs={12} >
                            <Typography sx={styles.Header4}>
                              {translate.VakitNakittir}
                            </Typography>
                            <Typography mt='0.5em' sx={styles.Header5}>
                               {translate.BuyukVeriIleZamanKazanin}
                            </Typography>   
                            <Typography sx={styles.Header6}>
                                {translate.UyelerimizinGuncelInsaatProjeleri}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default MaksimumFaydalar