import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Image404 from '../../Assets/Images/Green-Check.png';
import { Button, Grid, Typography } from '@mui/material';
import Footer from '../../Components/Footer';
import Navbar from '../../Components/Navbar';

function LeadBasvuruBasarili() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        navigate('/');
    };

    const styles = {
        bodyGrid: {
            backgroundColor: '#F4F7F9',
        },
        pageText: {
            fontFamily: "'Poppins'",
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '130%',
            textAlign: 'center',
            color: '#889AA4',
        },
        leadTitle: {
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '130%',
            textAlign: 'center',
            color: '#333333',
            marginTop: '20px',
        },
        leadText: {
            fontSize: '16px',
            lineHeight: '150%',
            textAlign: 'left',
            color: '#333333',
            marginTop: '10px',
            maxWidth: '1150px',
            margin: '0 auto', // Ortalamak için ekledik
            padding: '0 20px', // Dar ekranlar için sağ ve sol boşluk ekledik
        },
        buttonText: {
            color: '#FFFFFF',
            flex: 'none',
            order: '0',
            flexGrow: '0',
        },
        returnHomeButton: {
            padding: '15px 25px',
            gap: '10px',
            width: '278px',
            height: '63px',
            background: '#FF6600',
            boxShadow: '0px 9px 22px rgba(34, 12, 0, 0.25)',
            borderRadius: '20px',
            ':hover': {
                background: '#FF6600',
            },
        },
        continueButton: {
            marginTop: '30px',
            padding: '10px 20px',
            background: '#FF6600',
            color: '#FFFFFF',
            borderRadius: '20px',
            boxShadow: '0px 9px 22px rgba(34, 12, 0, 0.25)',
            ':hover': {
                background: '#FF6600',
            },
        },
    };

    return (
        <>
            <Navbar />
            <Grid align="center" mt={15} sx={styles.bodyGrid} container spacing={0}>
                <Grid pt={4} item xl={12} md={12} xs={12}>
                    <img height="auto" width="15%" src={Image404} alt="yapiradar.com page not found" />
                    <Typography mb={4} mt={5} sx={styles.pageText}>
                        Talebiniz Başarıyla Alınmıştır.
                    </Typography>
           

                    {/* Lead Generation Title */}
                    <Typography sx={styles.leadTitle}>
                        LEAD GENERATION HİZMETİ
                    </Typography>
                    <br />         <br />
                    {/* Lead Generation Text */}
                    <Typography sx={styles.leadText}>
                        Merhaba, Yapiradar.com olarak, inşaat sektöründeki lider markalardan biri olarak, size potansiyel müşteri çekme sürecinizde etkili ve özelleştirilmiş bir Lead Generation çözümü sunmak için buradayız.
                        <br /><br />
                        Türkiye'deki inşaat projelerindeki potansiyel müşteri kitleniz ve bu kitlenin doğru bir şekilde hedeflenmesi için özel bir strateji geliştiriyoruz. İnşaat projelerinde müşteri çekme sürecinde karşılaşılan zorlukları biliyoruz. Doğru müşterilere ulaşmak ve onları dönüştürmek, rekabetin yoğun olduğu bir ortamda daha da zorlaşıyor.
                        <br /><br />
                        Bizim çözümümüz, dijital pazarlama stratejilerimiz, güçlü saha operasyonumuz, proje istihbarat ekibimiz ve özelleştirilmiş hizmetlerimizle müşteri çekme sürecinizi optimize etmek.
                        <br /><br />
                        Lead Generation (Ürün Sorgulama) Hizmetimiz, ürünlerinizi hedef kitlenize hızlı ve etkili bir şekilde ulaştırmanızı sağlar. Belirlediğiniz proje kategorileri ve bölgeler doğrultusunda, potansiyel müşterilere ürünlerinizi tanıtıyor ve onların ihtiyaçlarına uygun çözümler sunuyoruz. Her ay düzenli olarak gerçekleştirdiğimiz proje sorgulamaları ile sürekli yeni fırsatlar yaratıyor ve bu projelerden elde edilen bilgileri detaylı raporlarla sizlere sunuyoruz. Anlık ve sıcak lead'leri tercih ettiğiniz kanallar üzerinden sizinle paylaşarak, satış süreçlerinizi hızlandırıyor ve verimliliğinizi artırıyoruz.
                        <br /><br />
                        Lead Generation Hizmetimizin Avantajları:
                        <ul>
                            <li>Hızlı ve Kolay: Ürünlerinizi potansiyel müşterilerle hızlıca buluşturun.</li>
                            <li>Hedef Odaklı: Doğru kitleye ulaşarak satışlarınızı artırın.</li>
                            <li>Zaman Tasarrufu: Karmaşık süreçleri bizimle kolayca yönetin.</li>
                        </ul>
                        Hizmetimizin İşleyişi:
                        <br /><br />
                        Proje Kategorileri ve Bölgeler: Sorgulamanın yapılacağı proje kategorilerini ve bölgeleri sizlerden öğrenerek sistemimizdeki sorgulama yapılacak proje sayısını çıkarıyor ve sürecin planlamasını yapıyoruz.
                        <br /><br />
                        Eğitim: Sorgulamayı yapacak olan Yapıradar bünyesindeki lead personeli için sektörünüz ve ürünlerinizle ilgili sizinle mini bir eğitim planlıyoruz.
                        <br /><br />
                        Ürün Sorgulama: Sizlerden aldığımız kriterler doğrultusunda ürünlerinizi potansiyel müşterilere tanıtıyor, sizin belirlediğiniz sorular ile (Maksimum 3 adet) ihtiyacı belirledikten sonra uygun çözümlerinizi anlatıyoruz ve kendimizi referans göstererek ''..................' firmasını size yönlendirmemizi ister misiniz?' diyerek fırsatı sizinle buluşturuyoruz.
                        <br /><br />
                        Aylık Proje Sorgulama: Aylık belirli sayıda proje sorgulaması yaparak, sürekli olarak yeni fırsatlar yaratıyoruz.
                        <br /><br />
                        Raporlama ve Lead Takibi: Sorgulanan projeler ve alınan cevapları aylık olarak raporluyoruz. Anlık ve sıcak lead'leri sizinle tercih ettiğiniz kanal üzerinden paylaşıyoruz.
                        <br /><br />
                        Sorularınız için bize her zaman ulaşabilirsiniz.
                        <br /><br />
                        Yapıradar.com Ekibi
                    </Typography>

                    {/* Devam Et Button */}
                    <Button onClick={handleClick} sx={styles.continueButton}>
                        Devam Et
                    </Button>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default LeadBasvuruBasarili;
