import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Logo1 from '../../Assets/Images/Bag.webp'
import Logo2 from '../../Assets/Images/Control.webp'
import Logo3 from '../../Assets/Images/FeedBack.webp'
import Logo4 from '../../Assets/Images/AddNote.webp'
import Logo5 from '../../Assets/Images/SaveSearch.webp'
import Logo6 from '../../Assets/Images/DontNeed.webp'
import Logo7 from '../../Assets/Images/Remember.webp'
import Logo8 from '../../Assets/Images/ProjectSearch.webp'
import { useSelector } from 'react-redux'

function SatisSonrasiDestek() {
  const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
  const styles = {
    Header1: { "width":{ xs: "100%", md: "750px"} , "height": { xs: "auto", md:"130px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize":{ xs: "20px", md: "50px"} , "lineHeight": "130%", "color": "#000000", },
    Header2: { "width":{ xs: "100%", md: "716px"},  "height":{ xs: "auto", md: "81px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":"16px" , "lineHeight": "170%", "color": "#303C43" },
    paperHeader: {
      "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": "20px", "lineHeight": "170%", "color": "#000000"
    },
    paperText: {
      "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "170%", "color": "#889AA4"
    },
    Paper: {
      margin: '25px 0px',
      border: '0',
      boxShadow: 'none',
      width: '260px'
    }
  }



  return (
    <>
      <Grid  p={4} container spacing={0}>
        <Grid item xl={12} md={12} xs={12}>
          <Typography  sx={styles.Header1}>
            {translate.SatisSonrasiDestekModuluIleHepYaninizdayiz}
          </Typography>
          <Typography mt='2em' sx={styles.Header2}>
           {translate.SatisSonrasiDestekModullerimiz}
          </Typography>
        </Grid>
        <Grid pt={5} item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo1} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
              {translate.TakipEt}
            </Typography>
            <Typography sx={styles.paperText}>
             {translate.IlgilendiginizProjeVeFirmalariTakibe}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo2} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
              {translate.KontrolEt}
            </Typography>
            <Typography sx={styles.paperText}>
             {translate.IncelediginizProjeyleIlgiliHerhangiBirSorunuz}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo3} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
              {translate.GeriBildirim}
            </Typography>
            <Typography sx={styles.paperText}>
              {translate.GeriBildirimButonunuKullanarak}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo4} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
              {translate.NotEkle}
            </Typography>
            <Typography sx={styles.paperText}>
              {translate.NokEkleOzelligiSayesinde}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo5} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
             {translate.BuAramayiKaydet}
            </Typography>
            <Typography sx={styles.paperText}>
              {translate.BuAramayiKaydetOzelligiSayesinde}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo6} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
             {translate.BuProjeyleIlgilenmiyorum}
            </Typography>
            <Typography sx={styles.paperText}>
              {translate.BuProjeyleIlgilenmiyorumOzelligiSayesinde}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo7} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
            {translate.Hatirlatmalar}
            </Typography>
            <Typography sx={styles.paperText}>
              {translate.IncelediginizProjelereTakvimAracigiliyla}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={3} md={3} xs={12} >
          <Paper sx={styles.Paper}>
            <img src={Logo8} alt='yapiradar.com' />
            <Typography sx={styles.paperHeader}>
             {translate.ProjeninArastirilmasiniIstiyorum}
            </Typography>
            <Typography sx={styles.paperText}>
              {translate.AradiginizProjeyiBulamadiysaniz}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default SatisSonrasiDestek