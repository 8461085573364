import { Box, Card, CardContent, Chip, Typography, capitalize } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function SpotUrunlerItemCard({ props }) {
    const navigate = useNavigate();
    const handleCardClick = (link) => {
        navigate(link)
    }

    const styles = {
        Card: {
            position: 'relative',
            width: 'auto',
            height: '100%',
            padding: 0,
            margin: '0px 1vw',
            borderRadius: '15px',
            ':hover': {
                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                "& .itemImage": {
                    width: '110%',
                    height: '110%',
                    objectFit: 'fill'
                }
            }
        },
        CardContent: {
            padding: 0,
        },
    }
    return (
        <>
            <Card key={props.id} sx={styles.Card}>
                <CardContent
                    sx={styles.CardContent}>
                    <Box onClick={() => window.location.href = `/SpotUrunler/Urun/${props.seourl}`}
                        sx={{
                            width: '260px',
                            height: '200px',
                            '&:hover': {
                                cursor: 'pointer',
                            }
                        }}
                    ><img
                            className='itemImage'
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }} src={`/images/SpotUrunler/${props.urunGorselVarsayilan ? props.urunGorselVarsayilan : props.dk_firma_urunler_gorsels?.[0]?.gorsel}`} alt='yapiradar.com Item' />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} padding={{xs:"10vw 1vw",md:'1vw 1vw'}}>
                        <Box>
                            <a style={{ textDecoration: 'none', }} href={`/SpotUrunler/Urun/${props.seourl}`}>
                                <Typography
                                    sx={{
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "16px",
                                        "lineHeight": "120%",
                                        "color": "#FF6600",
                                        "flex": "none",
                                        "order": "0",
                                        "flexGrow": "0",
                                        textTransform: 'capitalize',
                                        ":hover": {
                                            cursor: 'pointer',
                                            textShadow: '0.2px 0.2px'
                                        },
                                        ':first-letter': {
                                            textTransform: 'uppercase'
                                        }
                                    }} mt={2}>
                                    {props.marka ? props.marka.toLowerCase() : <p>&nbsp;</p>}
                                </Typography>
                            </a>
                        </Box>
                        <Box>
                            <a style={{ textDecoration: 'none', }} href={`/SpotUrunler/Urun/${props.seourl}`}>
                                <Typography inputProps={{ maxLength: 12 }} sx={{
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "700",
                                    "fontSize": "18px",
                                    "lineHeight": "120%",
                                    "color": "#000000",
                                    "flex": "none",
                                    "order": "0",
                                    "flexGrow": "0",
                                    textTransform: 'capitalize',
                                    ":hover": {
                                        cursor: 'pointer',
                                        textShadow: '0.2px 0.2px'
                                    }
                                }} mt={2}>
                                    {props.urunadi.length > 48 ? (props.urunadi.toLowerCase().substr(0, 48) + "...") : props.urunadi.toLowerCase()}
                                </Typography>
                            </a>
                        </Box>
                        <Box>
                            <a style={{ textDecoration: 'none', }} href={`/PazarYeri/Firma/${props?.firma?.seourl}`}>
                                <Typography
                                    sx={{
                                        textDecoration: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "12px",
                                        "lineHeight": "120%",
                                        "color": "#adadad",
                                        "flex": "none",
                                        "order": "0",
                                        "flexGrow": "0",
                                        textTransform: 'capitalize',
                                        ":hover": {
                                            cursor: 'pointer',
                                            textShadow: '0.2px 0.2px'
                                        }
                                    }} mt={2}>
                                    {props.firma?.firmaadikisa ? props.firma.firmaadikisa.toLowerCase() : props.firma?.firmaadi.toLowerCase()}
                                </Typography>
                            </a>
                        </Box>
                        <Box sx={{ position: 'absolute', bottom: '5%' }}>
                            <a style={{ textDecoration: 'none', }} href={`/SpotUrunler/Urun/${props.seourl}`}>
                                <Typography
                                    sx={{
                                        textDecoration: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "400",
                                        "fontSize": "18px",
                                        "lineHeight": "120%",
                                        "color": "black",
                                        "flex": "none",
                                        "order": "0",
                                        "flexGrow": "0",
                                        display: 'inherit',
                                        textTransform: 'capitalize',
                                        ":hover": {
                                            cursor: 'pointer',
                                            textShadow: '0.2px 0.2px'
                                        }
                                    }} mt={2}>
                                    {props.fiyat + " ₺ " + " - "}
                                </Typography>
                                <Typography
                                    sx={{
                                        display: 'inherit',
                                        textDecoration: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "12px",
                                        "lineHeight": "120%",
                                        "color": "#adadad",
                                        "flex": "none",
                                        "order": "0",
                                        "flexGrow": "0",
                                        textTransform: 'capitalize',
                                        ":hover": {
                                            cursor: 'pointer',
                                            textShadow: '0.2px 0.2px'
                                        }
                                    }} mt={2}>
                                    {props.adet}  {props.birimLabel}
                                </Typography>
                            </a>
                        </Box>
                        <Box sx={{
                            display: props.aktif === 0 ? 'block' : 'none',
                            position: 'absolute',
                            top: 10,
                            left: 10
                        }}>
                            <a style={{ textDecoration: 'none', }} href={`/PazarYeri/Urun/${props.seourl}`}>
                                <Chip label={props.onayistek === 1 && props.aktif === 0 ? 'Onay Bekliyor' : 'Pasif'} sx={{ background: 'red', color: 'white' }} />
                            </a>
                        </Box>
                    </Box>
                </CardContent>
            </Card >
        </>
    )
}

export default SpotUrunlerItemCard