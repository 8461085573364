import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { postData } from '../../Assets/Scripts/dataAxios';
import { ShowMessage } from '../Common/Alerts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Margin } from '@mui/icons-material';

function TakeYourPlacement() {
    const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))
    console.log(UserData)
    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "auto", md: "50px"},
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#FFFFFF"
        },
        Header2: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize":  { xs: "auto", md: "16px"},
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#BCC7CE"
        },
        Header3:{
            "padding":{ xs: "20px", md: "auto"},
            "width":{ xs: "90%", md: "100px%"}
        },
        TextArea: {
            "borderRadius": "20px",
            marginTop: '2em',
            "background": "#FFFFFF",
            width: { xs: "90%", md: "50%"},
            [`& fieldset`]: {
                border: `none`,
            },
            '& label.Mui-focused': {
                color: 'black',
                fontSize: { xs: "auto", md: "18px"},
                marginBottom: '100px'
            },
        },
        SektorSelect: {
            "width": { xs: "90%", md: "50%"},
            "height": "auto",
            "background": "#F4F7F9",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
                color: '#333'
            },
        },
        submitTalepBtn: {
            "padding": "15px 25px",
            "gap": "10px",
            "width": { xs: "90%", md: "50%"},
            "height": "auto",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "padding": "15px 25px",
                "gap": "10px",
                "width":{ xs: "90%", md: "50%"},
                "height": "auto",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "20px",
            }
        },
        submitTalepBtnText: {
            color: '#FFFFFF',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": { xs: "auto", md: "18px"},
            "lineHeight": "120%",
            "textAlign": "center",
        }

    }

    const navigate = useNavigate();
    const handlecreateFirmaBtn = () => {
        navigate('/PazarYeri/FirmaEkle')
    }
    const link = useLocation();
    const [flag, setFLAG] = useState(false);
    useEffect(() => {
        const scrollToElement = () => {
            const id = 'scrollToJoinCatalogue';
            const yOffset = -30;
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        if (link.hash === '#MagazaniziAcin' && flag === false) {
            setTimeout(function () { scrollToElement() }, 1000);
            setFLAG(true)
        }
    }, [])


    const [firmaadi, SetFirmaadi] = useState(false);
    const [yetkili, SetYetkili] = useState(false);
    const [web, SetWeb] = useState(false);
    const [telefon, SetTelefon] = useState(false);
    const [eposta, SetEposta] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    const handleSubmit = async () => {
        if (!firmaadi) {
            ShowMessage('error', "Lütfen Firma Adınızı Giriniz.")
        }
        else if (!yetkili) {
            ShowMessage('error', "Lütfen Bir Firma Yetkilisi Giriniz.")
        }
        else if (!web) {
            ShowMessage('error', "Lütfen Geçerli Bir Web Adresinizi Giriniz.")
        }
        else if (!telefon) {
            ShowMessage('error', "Lütfen Bir Telefon Numarası Giriniz.")
        }
        else if (!eposta) {
            ShowMessage('error', "Lütfen Eposta Adresinizi Giriniz")
        } else {
            setButtonDisable(true);
            const reqData = {
                firmaadi: firmaadi,
                adsoyad: yetkili,
                web: web,
                telefon: telefon,
                mail: eposta,
                basvuru: 1
            }
            const res = await postData('auth/DKYeriniziAlin', reqData);
            if (res.data.isSucceeded) {
                ShowMessage('success', res.data.message)
                setButtonDisable(false);
            } else {
                ShowMessage('error', res.data.error.errorMessage)
                setButtonDisable(false);
            }

        }

    }
    return (
        <>
            <Box
                id='scrollToJoinCatalogue'
                sx={{
                    display: UserData.firmaId !== 0 || !UserData
                        ? '' : 'none'
                }}>
                <Grid sx={{
                    backgroundColor: '#3C6780'
                }} pt={10} pb={10} container spacing={0}>
                    <Grid align='center' item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header1}>
                            Pazar Yerinde Mağazanızı  Açın!
                        </Typography>
                        <Typography sx={styles.Header2}>
                            Mağaza hesabınızı oluşturmak için gerekli bilgileri doldurun
                        </Typography>
                    </Grid>
                    <Grid mt={2} align='center' sx={styles.Header3}  item xl={12} md={12} xs={12}>
                        <TextField
                            onChange={e => SetFirmaadi(e.target.value)}
                            placeholder="Firma Adı"
                            sx={styles.TextArea} />
                    </Grid>
                    <Grid mt={2} align='center' sx={styles.Header3} item xl={12} md={12} xs={12}>
                        <TextField
                            required
                            onChange={e => SetYetkili(e.target.value)}
                            placeholder="Yetkili Ad Soyad" sx={styles.TextArea} />
                    </Grid>
                    <Grid mt={2} align='center' sx={styles.Header3} item xl={12} md={12} xs={12}>
                        <TextField
                            required
                            onChange={e => SetEposta(e.target.value)}
                            placeholder="İş Email Adresi" sx={styles.TextArea} />
                    </Grid>
                    <Grid mt={2} align='center' sx={styles.Header3} item xl={12} md={12} xs={12}>
                        <TextField
                            required
                            onChange={e => SetTelefon(e.target.value)}
                            placeholder="Telefon Numarası" sx={styles.TextArea} />
                    </Grid>
                    <Grid mt={2} align='center' sx={styles.Header3} item xl={12} md={12} xs={12}>
                        <TextField
                            required
                            onChange={e => SetWeb(e.target.value)}
                            placeholder="Web Adresi"
                            sx={styles.TextArea} />
                    </Grid>

                    <Grid mt={5} align='center' sx={styles.Header3} item xl={12} md={12} xs={12}>
                        <Button
                            disabled={buttonDisable}
                            onClick={handleSubmit}
                            sx={styles.submitTalepBtn}>
                            <Typography sx={styles.submitTalepBtnText}>
                                Ücretsiz Mağazanızı açın
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{
                display: UserData.firmaId === 0 &&
                    UserData ? 'block' : 'none', width: '100%'
            }}>
                <Grid id='scrollToJoinCatalogue' sx={{
                    backgroundColor: '#3C6780'
                }} pt={10} pb={10} container spacing={0}>
                    <Grid align='center' item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header1}>
                            Pazar Yerinde Firmanızı Hemen Oluşturun !
                        </Typography>
                        <Typography sx={styles.Header2}>
                            Talebinizi oluşturmak ve projeleri incelemek için lütfen aşağıdaki formu doldurunuz.
                        </Typography>
                    </Grid>
                    <Grid mt={5} align='center' item xl={12} md={12} xs={12}>
                        <Button
                            onClick={handlecreateFirmaBtn}
                            sx={styles.submitTalepBtn}>
                            <Typography sx={styles.submitTalepBtnText}>
                                Firma Oluştur
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}

export default TakeYourPlacement