import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useEffect } from 'react';
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import { ShowMessage } from '../Common/Alerts';
import { useSelector } from 'react-redux';
import TakeYourPlacement from './TakeYourPlacement';

function AskForAnOffer(children) {
    const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))


    const [cities, SetCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(0);
    const [talepEdilenUrunMiktari, setTalepEdilenUrunMiktari] = useState(0);
    const [adreseTeslim, setAdreseTeslim] = useState(1);
    const [uygulamaIstek, setUygulamaIstek] = useState(1);
    const [mesaj, setMesaj] = useState('');
    const [firmaId, setFirmaId] = useState(0);
    const [urunId, setUrunId] = useState(0);

    const handleSubmitButton = async () => {
        if (
            (urunId === '' && children.type === 'firm') ||
            talepEdilenUrunMiktari === 0 ||
            mesaj === ''
        ) {
            ShowMessage('error', 'Teklif istemek için lütfen tüm alanları eksiksiz doldurunuz.')
        } else if (selectedCity === 0 && adreseTeslim === 'evet') {
            ShowMessage('error', 'Adrese Teslimat için lütfen bir şehir seçiniz')
        }
        else {
            const dataforPost = {
                "urunId": urunId,
                "miktar": talepEdilenUrunMiktari,
                "birim": 2,
                "isUygulamali": parseInt(uygulamaIstek),
                "isTeslimat": adreseTeslim,
                "sehirId": adreseTeslim === 0 || adreseTeslim === '0' ? 0 : selectedCity,
                "ilceId": 0,
                "mahalleId": 0,
                "mesaj": mesaj
            }
            const res = await postData('DijitalKatalog/DijitalFirmaTeklif', dataforPost);
            if (res.data.isSucceeded) {
                ShowMessage('success', 'İstediğiniz Gönderilmiştir');
            } else {
                ShowMessage('error', res.data.error.errorMessage)
            }
        }
    }

    const styles = {
        Header1: {
            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "50px", "lineHeight": "130%", "textAlign": "center", "color": "#000000"
        },
        Header2: {
            width: {xs:"100%",md:"491px"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "16px",
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#3C6780"
        },
        TextField: {
            "width":{xs:"100%",md:"307px"},
            "height": "60px",
            "background": "#F4F7F9",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
                color: '#333'
            },
        },
        MessageTextField: {
            margin: '10px 0',
            "width": {xs:"94%",md:"627px"},
            "height": "119px",
            "background": "#F4F7F9",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
                color: '#333'
            },
        },
        CitySelect: {
            "width": "307px",
            "height": "60px",
            "background": "#F4F7F9",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
                color: '#333'
            },
        },
        ImportFileWrapper: {},
        ImportFileText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "21px",
            "color": "#667286"
        },
        ImportFileButton: {
            textTransform: 'none',
            "boxSizing": "border-box",
            "border": "1px solid #FF6600",
            "borderRadius": "6px"
        },
        ImportFileButtonText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "21px",
            "textAlign": "center",
            "color": "#FF6600"
        },
        CheckBox: {},
        ChecBoxText: {},
        SubmitBtn: {
            "padding": "15px 25px",
            "gap": "10px",
            "width": {xs:"100%",md:"627px"},
            "height": "63px",
            "background": UserData === false ? 'gray' : "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "background": "#FF6600",
                boxShadow: 'none'
            }
        },
        SubmitBtnText: {
            textTransform: 'none',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "18px",
            "lineHeight": "120%",
            "color": "#FFFFFF",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
        }
    }

    const handleChangeTeslimat = (event) => {
        console.log(event.target.value);
        setAdreseTeslim(event.target.value);
    };

    const handleChangeUygulama = (event) => {
        setUygulamaIstek(event.target.value);
    };


    const [urunler, setUrunler] = useState([]);

    const fixFirmaUrunleri = (param) => {
        const dummyData = [];
        param.forEach((item) => {
            dummyData.push({
                label: item.urunadi,
                id: item.id
            })
        })
        setUrunler(dummyData)
    }
    const handleUrunSelect = (param) => {
        setUrunId(param.id);
    }

    useEffect(() => {
        const GetFirmData = async () => {
            const response = await getData(`DijitalKatalog/DijitalFirmaById?seourl=${children.firmaSeoUrl}`,);
            setFirmaId(response.data.value.id)
            fixFirmaUrunleri(response.data.value.dk_firma_urunlers)
        }

        const GetItemData = async () => {
            const response = await getData(`DijitalKatalog/DijitalFirmaUrunById?seourl=${children.itemSeoUrl}`,);
            setUrunId(response.data.value.id)
        }
        if (children.type === 'firm') {
            GetFirmData()
                .catch(console.error);
        } else {
            GetItemData()
                .catch(console.error);
        }

    }, [])

    useEffect(() => {
        const GetCitiesData = async () => {
            const res = await postData('DijitalKatalog/SehirList', {
                "boldeIds": [
                ],
                "ulkeIds": [
                    216
                ],
                "ids": [
                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetCities(tempval);
        }

        GetCitiesData()
            .catch(console.error);
    }, [])

    const handleCityChange = (param) => {
        setSelectedCity(param.id)
    }


    return (
        <>
            <Grid id={UserData === false ? 'teklifAlComp' : 'teklifAlComp2'} align='center' pt={5} style={{ display: UserData === false ? 'block' : 'none' }} container spacing={0}>
                <Typography
                    sx={{
                        width:{xs:"100%", md:'600px'} ,
                        "fontFamily": "'Poppins'",
                        "fontStyle": "normal",
                        "fontWeight": "400",
                        "fontSize": "22px",
                        "lineHeight": "130%",
                        "textAlign": "center",
                        "color": "tomato"
                    }}>
                    Teklif İsteden Yararlanmak İçin Lütfen Giriş Yapınız. Eğer Hesabınız Yoksa Ücretsiz Hesabınızı Oluşturmak İçin  <a style={{ textDecoration: 'none', fontWeight: 800, color: 'tomato' }} href='/PazarYeri#MagazaniziAcin'>Tıklayınız.</a>
                </Typography>
            </Grid>

            <Grid id='teklifAlComp' pt={5} container spacing={0} mb={10}>
                <Grid align='center' item xl={12} md={12} xs={12}>
                    <Typography sx={styles.Header1}>
                        Teklif İste
                    </Typography>
                    <Typography sx={styles.Header2}>
                        Aşağıdaki formu eksiksiz doldurarak teklif, detaylı bilgi, numune, katalog, vb. taleplerinizi iletebilirsiniz.
                    </Typography>
                    <Box sx={{ margin: '20px 10px' }}>
                        <Autocomplete
                            onChange={(e, v) => handleUrunSelect(v)}
                            disablePortal
                            id="combo-box-demo"
                            options={urunler}
                            sx={{
                                display: children.type === 'firm' ? 'inline-block' : 'none',
                                "width": {xs:"100%",md:"307px"},
                                "height": "60px",
                                "background": "#F4F7F9",
                                "border": "1px solid #DAE2E8",
                                "borderRadius": "20px",
                                [`& fieldset`]: {
                                    border: `none`,
                                    color: '#333'
                                },
                            }}
                            renderInput={(params) => <TextField {...params} label="Talep Edilen Ürün" />}
                        />
                        <TextField
                            disabled={!UserData}
                            label='Talep Edilen Miktar'
                            number
                            sx={styles.TextField}
                            onChange={(e) => setTalepEdilenUrunMiktari(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ display: 'inline-flex', margin: '20px 10px' }}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label1">
                                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
                                    Teslimat Şekli
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                disabled={!UserData}
                                onChange={handleChangeTeslimat}
                                row
                                aria-labelledby="demo-radio-buttons-group-label1"
                                defaultValue={1}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value={1} control={<Radio disabled={!UserData} />} label="Adrese Teslim" />
                                <FormControlLabel value={0} control={<Radio disabled={!UserData} />} label="Depodan Teslim" />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    <Box sx={{ display: 'inline-flex', margin: '20px 10px' }}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label2">
                                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>
                                    Uygulama Şekli
                                </Typography>
                            </FormLabel>
                            <RadioGroup
                                onChange={handleChangeUygulama}
                                row
                                aria-labelledby="demo-radio-buttons-group-label2"
                                defaultValue={1}
                                name="radio-buttons-group2"
                            >
                                <FormControlLabel value={1} control={<Radio disabled={!UserData} />} label="Uygulamalı" />
                                <FormControlLabel value={0} control={<Radio disabled={!UserData} />} label="Uygulamasız" />
                            </RadioGroup>
                        </FormControl>

                    </Box>
                    <Box sx={{ margin: '20px 10px', display: adreseTeslim === 1 || adreseTeslim === '1' ? 'block' : 'none' }}>
                        <Autocomplete
                            id="CitySelect"
                            onChange={(e, v) => handleCityChange(v)}
                            options={cities}
                            disabled={!UserData}
                            renderInput={(params) => <TextField {...params} label="Şehir" />}
                            sx={{
                                "width": {xs:"100%",md:"307px"},
                                "height": "60px",
                                "background": "#F4F7F9",
                                "border": "1px solid #DAE2E8",
                                "borderRadius": "20px",
                                [`& fieldset`]: {
                                    border: `none`,
                                    color: '#333'
                                },
                            }}
                        />
                    </Box>
                    <Box>
                        <TextField
                            onChange={(e) => setMesaj(e.target.value)}
                            multiline
                            maxRows={5}
                            label='Mesaj'
                            disabled={!UserData}
                            sx={styles.MessageTextField}
                        />

                    </Box>
                    <Box>
                        <Button
                            disabled={!UserData}
                            onClick={handleSubmitButton}
                            sx={styles.SubmitBtn}>
                            <Typography sx={styles.SubmitBtnText}>
                                Teklif İste
                            </Typography>
                        </Button>
                    </Box>

                </Grid>
            </Grid>

        </>
    )
}

export default AskForAnOffer