import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/BayiYonetimEntering.webp'
import Image2 from '../Assets/Images/maksimumisverimliligi.webp'
import Logo8 from '../Assets/Images/Programming Script 2.webp'
import Logo7 from '../Assets/Images/Interface File Text-1.webp'
import Logo6 from '../Assets/Images/Interface File Text.webp'
import Logo5 from '../Assets/Images/Interface User Circle-1.webp'
import Logo3 from '../Assets/Images/Interface Building.webp'
import Logo4 from '../Assets/Images/Shopping Store 3.webp'
import Logo2 from '../Assets/Images/Travel Map Location Pin.webp'
import Logo1 from '../Assets/Images/Interface User Circle.webp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function BayiYonetimModulu() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | Bayi Yönetim Modülü';
    }, []);

    const styles = {
        Header1: { "width": { xs: "100%", md: "592px"}, "height": { xs: "auto", md:"36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "16px", md: "24px"} , "lineHeight": "36px", "color": "#000000","textAlign":{xs: "center", md:"left" } },
        Header2: { "width":{ xs: "100%", md: "473px" }, "height":{ xs: "auto", md: "210px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: "30px", md: "54px"}, "lineHeight": "130%", "color": "#000000" ,"textAlign":{xs: "center", md:"left" }},
        Header3: { "width": { xs: "100%", md: "561.11px"}, "height":{ xs: "auto", md: "93px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "15px", md:  "18px"}, "lineHeight": "170%", "color": "#889AA4","textAlign":{xs: "center", md:"left" },"marginTop":{xs:"0",md:"20px"} },
        Header4: { "width": { xs: "100%", md: "480px"}, "height": { xs: "auto", md: "130px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "25px", md: "50px"}, "lineHeight": "130%", "color": "#000000" ,"textAlign":{xs: "center", md:"left" }},
        Header5: { "width": { xs: "100%", md: "480px"}, "height":{ xs: "auto", md: "81px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "15px", md: "16px"} , "lineHeight": "170%", "color": "#303C43","textAlign":{xs: "center", md:"left" } },
        Header6: { "width": { xs: "100%", md: "750px"}, "height":{ xs: "auto", md:"130px"} , "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize":{ xs: "20px", md: "50px"} , "lineHeight": "130%", "textAlign": "center", "color": "#FFFFFF" },
        Header7: { "width": { xs: "100%", md: "512px"}, "height": { xs: "auto", md:"93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": { xs: "20px", md: "18px"}, "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" },
        paperText: { "width": { xs: "100%", md: "200px"}, "height": { xs: "auto", md: "68px"}, "left": "97px", "top": "483px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": { xs: "20px", md: "18px"}, "lineHeight": "170%", "textAlign": "center", "color": "#FFFFFF" },
        Paper: {
            backgroundColor: '#3C6780',
            margin: '35px 0px',
            border: '0',
            boxShadow: 'none',
        }
    }
    return (
        <>
            <Navbar />
            <Grid mt={12} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pt={5} p={{xs:5,md:6}} pl={{xs:0,md:12}} pb={{xs:0,md:5}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header1}>
                            {translate.BayiYonetimModulu}
                        </Typography>
                        <Typography mt={2} sx={styles.Header2}>
                            {translate.TumBayilerinizleTekCatiAltindasiniz}
                        </Typography>
                        <Typography mt={2} pt={5} sx={styles.Header3}>
                            {translate.CokSayidaBayisiOlanFirmalaraOzel}
                        </Typography>
                    </Grid>
                    <Grid mt={-10} item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid container spacing={0}>
                   
                    <Grid pt={5} p={{xs:5,md:12}} pl={{xs:0,md:12}}   item xl={6} md={6} xs={12}>
                        <Typography mt={2} sx={styles.Header4}>
                           {translate.MaksimumIsVerimliligi}
                        </Typography>
                        <Typography pt={5} sx={styles.Header5}>
                        {translate.BayilerinProjeTakipPerformanslarinin}
                        </Typography>
                    </Grid>
                    <Grid p={10} item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image2} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid sx={{ backgroundColor: '#3C6780' }} pb={10} container spacing={0}>
                    <Grid align='center' p={5} pt={10} item xl={12} md={12} xs={12}>
                        <Typography sx={styles.Header6}>
                        {translate.BayiYonetimModulundeNelerVar}
                        </Typography>
                    </Grid>
                    <Grid align='center' p={5} container >
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo1} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.HerBayiyeOzelKullaniciHesabi}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo2} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.BolgeYetkilendirme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo3} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.SehirYetklilendirme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo4} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.IlceYetkilendirme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo5} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.CozumOrtagiYetkilendirme}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo6} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.BayiBazliOkumaLimiti}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo7} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.BayiProjeOkumaRaporu}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12} >
                            <Paper sx={styles.Paper}>
                                <img src={Logo8} alt='yapiradar.com' />
                                <Typography sx={styles.paperText}>
                                {translate.AylikBayiPerformansOzeti}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}
