import { createSlice } from '@reduxjs/toolkit'
import TurkishData from '../../DATA/Turkish.json';
import EnglishData from '../../DATA/English.json';
import { loadState, saveState } from '../../Assets/Scripts/LocalStorage';

const userLanguageFromLocalStorage = loadState('UserLangugageCode');
if (!userLanguageFromLocalStorage) saveState('UserLangugageCode', 'Turkish')

const currentLan = userLanguageFromLocalStorage === 'English' ?  EnglishData :TurkishData


const initialState = {
  userLanguageFromLocalStorage,
  currentLanguage: JSON.stringify(currentLan),
}

export const userLanguageSlice = createSlice({
  name: 'Language',
  initialState,
  reducers: {
    ChangeLanguage: (state) => {
      if (state.userLanguageFromLocalStorage === 'English') {
        state.userLanguageFromLocalStorage = 'Turkish'
        saveState('UserLangugageCode', 'Turkish')
        state.currentLanguage = JSON.stringify(TurkishData);
      } else {
        state.userLanguageFromLocalStorage = 'English'
        saveState('UserLangugageCode', 'English')
        state.currentLanguage = JSON.stringify(EnglishData);
      }
    }
  },
})

export const { ChangeLanguage } = userLanguageSlice.actions

export default userLanguageSlice.reducer