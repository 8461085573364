import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FilterDropdownMenu } from '../Styled/DropdownMenus/StyledDropdowns';
import { StyledFilterHeader } from '../Styled/Labels/StyledLabels';
import { StyledFilterInput } from '../Styled/Inputs/StyledInputs';
import { StyledFilterSearchButton } from '../Styled/Buttons/StyledButtons';
import { getData, postData } from '../../Assets/Scripts/dataAxios';

const ihaleler = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
];

const ihaleBaslangic = [
    { label: '5 Gün İçerisinde', value: 5 },
    { label: '10 Gün İçerisinde', value: 10 },
    { label: '15 Gün İçerisinde', value: 15 },
    { label: '30 Gün İçerisinde', value: 30 },
    { label: '45 Gün İçerisinde', value: 45 },
];

const ihaleBolgeleri = [
    { label: 'Marmara Bölgesi', value: 1 },
    { label: 'Akdeniz Bölgesi', value: 2 },
    { label: 'Ege Bölgesi', value: 3 },
    { label: 'Karadeniz Bölgesi', value: 4 },
    { label: 'İç Anadolu Bölgesi', value: 5 },
    { label: 'Doğu Anadolu Bölgesi', value: 6 },
    { label: 'Güneydoğu Anadolu Bölgesi', value: 7 },
];

function IhaleArama() {
    const [cities, SetCities] = useState([]);
    const [ihaleData, setIhaleData] = useState({
        "bolgeId": 0,
        "ulkeId": 216,
        "sehirs": [],
        "ilceId": 0,
        "mahalleId": 0,
        "isIhaleStart": 0,
        'kategoriIds': [],
        'isIhaleStartDatePart': null,
        "cadSk": "",
        'kelime': ''
    })

    useEffect(() => {
        const GetCitiesData = async () => {
            const res = await postData('DijitalKatalog/SehirList', {
                "boldeIds": [
                ],
                "ulkeIds": [
                    216
                ],
                "ids": [
                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetCities(tempval);
        }
        GetCitiesData()
            .catch(console.error);
    }, [])

    const handleCityChange = (param) => {
        if (param.length > 1) {
            const dummyCities = [];
            param.forEach((item) => {
                dummyCities.push(item.id)
            })
            selectedCategories.push(dummyCities);
            setIhaleData(prevState => ({
                ...prevState,
                sehirs: [...selectedCategories]
            }))
        } else if (param.length === 0) {
            selectedCategories.push([]);
            setIhaleData(prevState => ({
                ...prevState,
                sehirs: [...selectedCategories]
            }))
        }
        else {
            selectedCategories.push(param?.[0]?.id)
            setIhaleData(prevState => ({
                ...prevState,
                sehirs: [...selectedCategories]
            }))
        }
    }

    const handleIhaleStartDateChange = (param) => {
        console.log(param);
        setIhaleData(prevState => ({
            ...prevState,
            isIhaleStartDatePart: param?.value
        }))
    }

    var selectedCategories = [];
    const FixCategoryData = (param) => {
        const fixedData = [];
        param.forEach((item, index) => {
            item?.children?.forEach((item2, index2) => {
                fixedData.push({
                    key: index.toString() + index2.toString(),
                    name: item2.adi,
                    id: item2.id,
                    parent: item.adi,
                    type: 'SubCategory'
                })
                item2?.children?.forEach((item3, index3) => {
                    fixedData.push({
                        key: index.toString() + index2.toString() + index3.toString(),
                        name: item3.adi,
                        id: item3.id,
                        parent: item.adi,
                        type: 'child'
                    })

                })
            })
        })
        SetCategories(fixedData);
    }

    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('DijitalKatalog/KategoriList');
            FixCategoryData(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, [])


    const handleCategoryChange = (param) => {
        if (param.length > 1) {
            const dummyCities = [];
            param.forEach((item) => {
                dummyCities.push(item.id)
            })
            selectedCategories.push(dummyCities);
            setIhaleData(prevState => ({
                ...prevState,
                kategoriIds: [...selectedCategories]
            }))
        } else if (param.length === 0) {
            selectedCategories.push([]);
            setIhaleData(prevState => ({
                ...prevState,
                kategoriIds: [...selectedCategories]
            }))
        }
        else {
            selectedCategories.push(param?.[0]?.id)
            setIhaleData(prevState => ({
                ...prevState,
                kategoriIds: [...selectedCategories]
            }))
        }
    }

    const handleIhaleSearch = () => {
        console.log(ihaleData);
        let url = '/Ihale/IhaleListesi?';
        // sehirs seçilmişse
        if (ihaleData.sehirs.length > 0) {
            url += `sehir=${ihaleData.sehirs}&`;
        }
        // isIhaleStart eklenmesi gerekiyorsa
        url += `isIhaleStart=${ihaleData.isIhaleStart}&`;
        // kategoriIds seçilmişse
        if (ihaleData.kategoriIds.length > 0) {
            url += `kategoriIds=${ihaleData.kategoriIds}&`;
        }
        if (ihaleData.isIhaleStartDatePart !== null) {
            url += `IhaleBaslangic=${ihaleData.isIhaleStartDatePart}&`;
        }
        // kelime eklenmesi gerekiyorsa
        if (ihaleData.kelime !== "") {
            url += `kelime=${ihaleData.kelime}&`;
        }
        // Son karakter '/' ise kaldır
        if (url.charAt(url.length - 1) === '&') {
            url = url.slice(0, -1);
        }

        // Yeni URL'ye yönlendir
        window.location.href = url;


    }

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIhaleData(prevState => ({
            ...prevState,
            "isIhaleStart": isChecked ? 1 : 0,
        }));
    };

    return (
        <>
            <StyledFilterHeader sx={{ paddingBottom: '36px' }}>
                İhale Ara
            </StyledFilterHeader>

            <Box sx={{ paddingBottom: '24px' }}>
                <FilterDropdownMenu
                    disablePortal
                    id="combo-box-demo"
                    options={ihaleBaslangic}
                    onChange={(e, v) => handleIhaleStartDateChange(v)}
                    renderInput={(params) => <TextField {...params} label="İhale Başlangıç Zamanı" />}
                />
            </Box>

            <Box sx={{ paddingBottom: '24px' }}>
                <FilterDropdownMenu
                    multiple
                    getOptionLabel={(option) => option.name}
                    groupBy={(option) => option.parent}
                    onChange={(e, v) => handleCategoryChange(v)}
                    limitTags={4}
                    renderOption={(props, option) => option.type == 'SubCategory' ? (
                        <li {...props}>
                            <Box
                                sx={{
                                    fontWeight: '700',
                                }}
                            >
                                {option.name}
                            </Box>
                        </li>) : (<li key={option.id} {...props}> {option.name} </li>)
                    }
                    id="disabled-options-demo"
                    options={categories}
                    renderInput={(params) => <TextField {...params} label="Kategori" />}
                />
            </Box>

            <Box sx={{ paddingBottom: '24px' }}>
                <FilterDropdownMenu
                    disablePortal
                    id="combo-box-demo"
                    options={ihaleBolgeleri}
                    renderInput={(params) => <TextField {...params} label="İhale Bölgesi" />}
                />
            </Box>

            <Box sx={{ paddingBottom: '24px' }}>
                <FilterDropdownMenu
                    disablePortal
                    id="combo-box-demo"
                    options={cities}
                    multiple
                    onChange={(e, v) => handleCityChange(v)}
                    renderInput={(params) => <TextField {...params} label="Şehir" />}
                />
            </Box>

            <Box sx={{ paddingBottom: '16px' }}>
                <TextField
                    sx={{
                        width: '100%',
                        height: '50px',
                        alignContent: 'center',
                        borderRadius: "20px",
                        borderColor: 'none',
                        '& .MuiInputBase-input': {
                            borderRadius: "20px",
                            color: "rgb(43,43,43)",
                            fontsize: '15px',
                            fontWeight: 400,
                            borderColor: 'none',
                            "fontFamily": "'Poppins'",
                        },
                        '& .MuiInputBase-root': {
                            borderRadius: "20px",
                            height: '50px',
                            borderColor: 'none',
                        }
                    }}
                    value={ihaleData?.kelime}
                    onChange={e => setIhaleData(prevState => ({ ...prevState, kelime: e.target.value }))}
                    placeholder='Kelime ile ara'
                />
            </Box>

            <Box sx={{ paddingBottom: '24px' }}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox onChange={handleCheckboxChange} color="success" />}
                        label='Bitmiş İhaleleri Göster'
                    />
                </FormGroup>
            </Box>

            <Box sx={{ width: '100%', paddingBottom: '24px' }}>
                <StyledFilterSearchButton
                    onClick={handleIhaleSearch}
                >
                    İhale Ara
                </StyledFilterSearchButton>
            </Box>
        </>
    )
}

export default IhaleArama