import { Grid } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import EnteringHizmetlerimiz from '../Components/Landing Hizmetlerimiz/EnteringHizmetlerimiz'
import GuncelVeriler from '../Components/Landing Hizmetlerimiz/GuncelVeriler'
import Katalog from '../Components/Landing Hizmetlerimiz/Katalog'
import SahaDanismanligi from '../Components/Landing Hizmetlerimiz/SahaDanismanligi'
import SatisSonrasiDestek from '../Components/Landing Hizmetlerimiz/SatisSonrasiDestek'
import Navbar from '../Components/Navbar'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import { useEffect } from 'react'
function Hizmetlerimiz() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <Navbar />
      <Grid container mt={12} spacing={0}>
        <EnteringHizmetlerimiz />
        <GuncelVeriler />
        <SahaDanismanligi />
        <Katalog />
        <SatisSonrasiDestek />
        <TryForFree />
        <Footer />
      </Grid>
    </>
  )
}

export default Hizmetlerimiz