import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Image from '../../Assets/Images/PPTS.webp'

function PPTS() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": {xs:"38px",md:"45px"},
            "lineHeight": "125.5%",
            "textAlign": "center",
            "letterSpacing": "0.01em",
            "color": "#000000",
            "maxWidth": "688px",
            "paddingBottom":"70px"

        },
        Header2: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"19px",md:"20px"},
            "lineHeight": "170%",
            "textAlign": "center",
            "letterSpacing": "0.01em",
            "color": "#889AA4",
            "opacity": "0.9",
            "maxWidth": "700px",
        },
        TextDesc2: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "170%",
            "letterSpacing": "0.01em",
            "color": "#889AA4",
            "opacity": "0.9",
            "textAlign": 'left'
        },
        BoxTMWrapper: {
            display: 'flex',
            "background": "#FFFFFF",
            "boxShadow": "0px 12px 40px rgba(211, 223, 234, 0.87)",
            "borderRadius": "20px",
            "width": "300px", "height": "64px"
        },
        BoxMuteahhit: {
            display: "flex",
            justifyContent: "center",
            "background": "#303C43",
            "borderRadius": "20px",
        },
        BoxMuteahhitText: {
            textTransform: "none",
            display: 'flex',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "24px",
            "color": "#FFFFFF",
        },
        BoxTedarikci: {
            display: 'flex',
            "background": "#FFFFFF",
            "borderRadius": "20px",
        },
        BoxTedarikciText: {
            textTransform: "none",
            display: 'inline',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "24px",
            "color": "#A1ADB7",

        },
        BoxTedarikci2: {
            display: "flex",
            justifyContent: "center",
            width: '176px',
        },
        BoxInline: {
            display: 'inline',
        },
        text1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "26px",
            "lineHeight": "156.5%",
            "letterSpacing": "0.01em",
            "color": "#000000",
            "opacity": "0.9",
            width: '336px',
        },
        text2: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "16px",
            "lineHeight": "149.5%",
            "letterSpacing": "0.01em",
            "color": "#889AA4",
            "opacity": "0.9",
            width: '336px'
        },
        line1: {
            "maxWidth": "432px",
            "height": "4px",
            "left": "129px",
            "top": "5925.35px",
            "background": "#8FBFFA"
        },
        text3: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "26px",
            "lineHeight": "287.5%",
            "letterSpacing": "0.01em",
            "color": "#889AA4",
            "opacity": "0.9"
        },
        line2: {
            "maxWidth": "432px",
            "height": "1px",
            "left": "129px",
            "top": "6120px",
            "background": "#f4f7f9"
        }
    }

    const [pptsData, setPptsData] = useState({
        Header1: translate.GuvenilirTedarikcilerinVeKolay,
        Header2: translate.SizdeYapiradarliOlunSatinAlma,
        Text1: translate.SatinAlmaTalepleri,
        TDesc1: translate.MuteahhitlerIleTedarikcileriAyniPlatformda,
        Text2: translate.KamuYapimIhaleleri,
        TDesc2: translate.GuncelKamuYapimOIhaleDuyurulari,
    })


    const [selectedType,SetSelectedType] = useState(1);
    
    
    const handleButtonClick = (key) => {
        if (key === 1) {
            SetSelectedType(2);
            setPptsData({
                Header1: translate.YeniIsVeKazancFirsatlarininAdresi,
                Header2: translate.SizdeYapiradarliOlunZamanIsgucuSatis,
                Text1: translate.GuncelInsaatProjeleri,
                TDesc1: translate.YapiradarcomlaTurkMuteahhitlerininTurkiyeVe,
                Text2: translate.SatinAlmaTalepleri,
                TDesc2: translate.MuteahhitVeYatirimcilarinAktifSatinAlma,
            })
        } else {
            SetSelectedType(1);
            setPptsData({
                Header1: translate.GuvenilirTedarikcilerinVeKolay,
                Header2: translate.SizdeYapiradarliOlunSatinAlma,
                Text1: translate.SatinAlmaTalepleri,
                TDesc1: translate.MuteahhitlerIleTedarikcileriAyniPlatformda,
                Text2: translate.KamuYapimIhaleleri,
                TDesc2: translate.GuncelKamuYapimOIhaleDuyurulari,
            })
        }
    }

    return (
        <>
            <Grid container spacing={0} pt={10}  pb={10} sx={{ backgroundColor: '#F4F7F9' }}>
                <Grid align='center' item xl={12} md={12} xs={12}>
                    <Typography sx={styles.Header1}>
                       {translate.ProjeVePerformansTakibiArtik}
                    </Typography>
                    <Typography sx={styles.Header2}>
                        {translate.ProjeTakibiProjeVeSatis}
                    </Typography>
                </Grid>
                <Grid align='center' mt='4em' mb='4em' item xl={12} md={12} xs={12}>
                    <Box sx={styles.BoxInline}>
                        <Box sx={styles.BoxTMWrapper}>
                            <Box sx={selectedType === 1 ?  styles.BoxMuteahhit : styles.BoxTedarikci}>
                                <Button sx={{width:'160px'}} onClick={() => handleButtonClick(0)}>
                                    <Typography alignItems='center' sx={ selectedType === 1 ?  styles.BoxMuteahhitText : styles.BoxTedarikciText}>
                                       {translate.Müteahhitler}
                                    </Typography>
                                </Button>
                            </Box>
                            <Box sx={selectedType === 2 ?  styles.BoxMuteahhit : styles.BoxTedarikci}>
                                <Button sx={{width:'160px'}} onClick={() => handleButtonClick(1)}>
                                    <Typography alignItems='center' sx={ selectedType === 2 ?  styles.BoxMuteahhitText : styles.BoxTedarikciText}>
                                    {translate.Tedarikciler}
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item m={{xs:2,md:0}} pl={{xs:0,md:10}} xl={7} md={7} xs={12}>
                    <Typography sx={styles.text1}>
                        {pptsData.Header1}
                    </Typography >
                    <Typography mt='1em' sx={styles.text2}>
                        {pptsData.Header2}
                    </Typography>
                    <Box mt='2em' sx={styles.line1}></Box>
                    <Typography mt='0.5em' sx={styles.text3}>  {pptsData.Text1}</Typography>
                    <Typography mt='0.5em' sx={styles.TextDesc2}>  {pptsData.TDesc1}</Typography>
                    <Box sx={styles.line2}></Box>
                    <Typography mt='0.5em' sx={styles.text3}> {pptsData.Text2} </Typography>
                    <Typography mt='0.5em' sx={styles.TextDesc2}>  {pptsData.TDesc2}</Typography>
                    <Box sx={styles.line2}></Box>
                </Grid>
                <Grid align='right'  item xl={5} md={5} xs={12}>
                    <img
                    style={{
                        maxWidth:'100%',
                        objectFit:'cover',
                       
                    }}
                    src={Image} alt='yapiradar.com' />
                </Grid>
            </Grid>
        </>
    )
}

export default PPTS