import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import Image4 from '../../Assets/Images/Engineer.webp'

function NoktaAtisi() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header4: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"25px",md:"22px"},
            "lineHeight": "170%",
            "color": "#FF6600",
            "textAlign":{xs:"justify",md:"left"}
        },
        Header5: {
            "width": {xs:"100%",md:"80%"},
            "height": {xs:"60px",md:"140px"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": {xs:"25px",md:"50px"},
            "lineHeight": "130%",
            "color": "#000000",
            "textAlign":{xs:"justify",md:"left"}
        },
        Header6: {
            "width": {xs:"100%",md:"80%"},
            "height": "auto",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize":{xs:"16px",md:"22px"} ,
            "lineHeight": "170%",
            "color": "#303C43",
            "marginTop": "0.5em","textAlign":{xs:"justify",md:"left"}
        },
        HeaderTop :{
           
            backgroundColor: "#F4F7F9",
        },
    }
    return (
        <>
            <Grid container spacing={0} >
                <Grid container mt={5}  pl={6} pr={6} spacing={0} sx={styles.HeaderTop}>               
                    <Grid  item mt={10} xl={6} md={6} xs={12} >
                        <Typography sx={styles.Header4}>
                            {translate.NoktaAtisiYapin}
                        </Typography>
                        <Typography mt='0.5em' sx={styles.Header5}>
                            {translate.DogruKisilereUlasin}
                        </Typography>
                        <Typography sx={styles.Header6}>
                          {translate.YapiradarcomGuncelInsaatProjelerinin}
                        </Typography>
                    </Grid>
                    <Grid item pt={{xs:6,md:3}}xl={6} md={6} xs={12}>
                        <img style={{
                            height: 'auto',
                            width:"100%"
                        }} src={Image4} alt='Buyuk veri ile zaman kazanin' />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default NoktaAtisi