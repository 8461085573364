import React from 'react'
import { useEffect } from 'react';
import { getData } from '../Assets/Scripts/dataAxios';
import { ShowMessage } from '../Components/Common/Alerts';
import { useDispatch } from 'react-redux';
import { LogOut } from '../Redux/Slices/AuthSlices';
import { deleteUserInfo } from '../Redux/Slices/UserInfoSlices';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function Logout() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoggedOut,SetisLoggedOut] = useState(false);

    useEffect(() => {
        const LogOut = async () => {
            const res = await getData('Auth/LogOut')
                .then(result => {
                    if (result.data.isSucceeded) {
                        SetisLoggedOut(true);
                    }
                })
        }
        LogOut()
            .catch(console.error);
    },[])

    useEffect(() => {
        dispatch(LogOut());
        dispatch(deleteUserInfo());
        navigate('/')
    },[isLoggedOut])

    return (
        <>
        </>
    )
}

export default Logout