import { Autocomplete, Box, Button, Card, CardContent, Grid, Link, Modal, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import LanguageIcon from '@mui/icons-material/Language';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { ShowMessage } from '../Common/Alerts';
import { loadState } from '../../Assets/Scripts/LocalStorage';
import ReactImageFileToBase64 from 'react-file-image-to-base64';
import NoFirmLogo from '../../Assets/Images/FirmNoLogo.webp';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setUserInfo } from '../../Redux/Slices/UserInfoSlices';
import { useDispatch } from 'react-redux';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ItemCard from '../PazarYeri/ItemCard';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
function FirmPreview(children) {
  //Set Firma id Variables
  const [firmaId, setFirmaId] = useState(0);

  //Style Object
  const styles = {
    HeaderImageWrapper: {
      position: 'relative',
      width: { xs: "100%", md: 'auto' },
      height: { xs: "auto", md: '312px' },
      objectFit: "initial",
    },
    FirmLogoWrapper: {
      display: 'inline-block',
      position: 'absolute',
      bottom: '0%',
      left: '5%',
    },
    FirmName: {
      marginBottom: '0.5em',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": { xs: "100%", md: "44px" },
      "lineHeight": "140%",
      "alignItems": "center",
      "color": "black",
    },
    InfoBarLogoWrapper: {
      display: 'inline-block',
      margin: '0 10px',
      color: '#8FBFFA'
    },
    InfoBarHeader: {
      "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "14px", "lineHeight": "28px", "color": "#303C43", "flex": "none", "order": "1", "flexGrow": "0"
    },
    InfoBarText: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "14px",
      "lineHeight": "22px",
      "color": "#303C43",
      "opacity": "0.75",
      display: 'inline',
      ml: 1
    },
    SocialIcons: {
      margin: '0 5px',
      color: "#b1b3b5",
      cursor: 'pointer',
    },
    Card: {
      width: { xs: "100%", md: '265px' },
      backgroundColor: '#F4F7F9',
      padding: 0,
      boxShadow: 'none',
      margin: '0px 3vw 0px 0px',
    },
    CardContent: {
      padding: 0,
    },
    cardHeader: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#8FBFFA",
      "flex": "none",
      "order": "0",
      "flexGrow": "0",
      ":hover": {
        cursor: 'pointer'
      }
    },
    cardText: {
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "700",
      "fontSize": "16px",
      "lineHeight": "120%",
      "color": "#000000",
      "flex": "none",
      "order": "0",
      "flexGrow": "0",
      ":hover": {
        cursor: 'pointer'
      }
    },
    newItemsWrapper: {
      display: 'inline-flex',
    },
    ShowMoreBtn: {
      "boxSizing": "border-box",
      "border": "1px solid #BCC7CE",
      "borderRadius": "25px",
      width: { xs: "100%", md: '750px' },
      height: '50px',
    },
    SeeAllBtn: {
      textTransform: 'none',
      color: '#FF6600'
    },
    ModalStyle: {
      position: 'absolute',
      top: '10%',
      left: '10%',
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    textField: {
      input: { WebkitBoxShadow: "0 0 0 1000px white inset" },
      marginTop: '1em',
      "background": "#FFFFFF",
      backgroundColor: 'transparent',
      width: { xs: "100%", md: '97%' },
      [`& fieldset`]: {
        "border": "1px solid #BCC7CE",
        "borderRadius": "20px"
      },
      "& label": {
        "fontFamily": "'Poppins'",
        "fontStyle": "normal",
        "fontWeight": "500",
        "fontSize": "14px",
        "lineHeight": "130%",
        "color": '#889AA4'
      }
    },
    textFieldMultiLine: {
      marginTop: '1em',
      "background": "#FFFFFF",
      width: { xs: "100%", md: '437px' }
    },
    SubmitButton: {
      marginTop: '2em',
      color: 'white',
      "padding": "15px 25px",
      "gap": "10px",
      "width": { xs: "100%", md: "437px" },
      "height": "63px",
      "background": "#FF6600",
      "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
      "borderRadius": "20px",
      ':hover': {
        "background": "#FF6600",
        "boxShadow": "none",
      }
    },
    TextHeader: {
      marginTop: '1em',
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "400",
      "fontSize": "20px",
      "lineHeight": "130%"
    },
    Header1: {
      "width": "100%", "height": "26px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "22px", "lineHeight": "120%", "color": "#000000"
    },
    Selects: {
      marginTop: '1em',
      width: { xs: "100%", md: '481px' },
      "border": "1px solid #BCC7CE",
      "borderRadius": "20px",
      [`& fieldset`]: {
        border: `none`,
        color: '#333'
      },
      "& label": {
        marginTop: '0.5em',
        "fontFamily": "'Poppins'",
        "fontStyle": "normal",
        "fontWeight": "500",
        "fontSize": "14px",
        "lineHeight": "130%",
        "color": '#889AA4'
      }
    },
  }
  //Redux Variables
  const UserData = JSON.parse(loadState('User'));
  const UserInfoData = JSON.parse(loadState('UserInfo'));

  const Location = useLocation();

  //Modal Open-Close Functions
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //URL Variables
  const firmId = Location.pathname.split('/')[3]

  //Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Hook Functions
  const handleCardClick = (link) => {
    navigate(link)
  }

  const handleUrunEkleClick = () => {
    navigate(`/PazarYeri/UrunEkle?firmaid=${firmaId},firmaurl=${children.firmaSeoUrl}`)
  }


  //Varibales
  const [firmaData, SetFirmaData] = useState([]);
  const [urlLinkStyle, SetUrlLinkStyle] = useState('Urunler');

  const [firmaAdi, SetFirmaAdi] = useState(null);
  const [firmaAdiKisa, SetFirmaAdiKisa] = useState(null);
  const [firmayetkili, SetFirmayetkili] = useState(null);
  const [firmaWeb, SetFirmaWeb] = useState(null);
  const [firmaMail, SetFirmaMail] = useState(null);
  const [firmaTelefon, SetFirmaTelefon] = useState(null);
  const [firmaHakkinda, SetFirmaHakkinda] = useState(null);

  const [firmaLogoPreview, SetFirmaLogoPreview] = useState(false);
  const [firmaLogo, SetFirmaLogo] = useState(null);
  const [firmaGorsel, SetFirmaGorsel] = useState(null);
  const [firmaGorselPreview, SetFirmaGorselPreview] = useState(false);

  const [facebookLink, SetfacebookLink] = useState('')
  const [twitterLink, SettwitterLink] = useState('')
  const [linkedinLink, SetlinkedinLink] = useState('')
  const [youtubeLink, SetyoutubeLink] = useState('')
  const [instagramLink, SetinstagramLink] = useState('')


  const [facebook, Setfacebook] = useState('')
  const [twitter, Settwitter] = useState('')
  const [linkedin, Setlinkedin] = useState('')
  const [youtube, Setyoutube] = useState('')
  const [instagram, Setinstagram] = useState('')
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);

  const [firmaisAktif, SetFirmaisAktif] = useState(0);
  useEffect(() => {
    document.title = 'YapıRadar.com | ' + firmaAdi;
  }, [firmaAdi]);



  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const GetItemData = async () => {
      const response = await getData(`DijitalKatalog/DijitalFirmaById?seourl=${children.firmaSeoUrl}`,);
      if (response.data.value === null) {
        window.location.href = '/404'
      } else {
        if (response.data.value.sosyal_medias.length > 0) {
          response.data.value.sosyal_medias.forEach(item => {
            if (item.dk_sosyalmedya.adi === "Facebook") {
              SetfacebookLink(item.hesapadi);
              Setfacebook(item)
            }
            if (item.dk_sosyalmedya.adi === "Instagram") {
              SetinstagramLink(item.hesapadi);
              Setinstagram(item)
            }
            if (item.dk_sosyalmedya.adi === "Linkedin") {
              SetlinkedinLink(item.hesapadi);
              Setlinkedin(item)
            }
            if (item.dk_sosyalmedya.adi === "Twitter") {
              SettwitterLink(item.hesapadi);
              Settwitter(item)
            }
            if (item.dk_sosyalmedya.adi === "Youtube") {
              SetyoutubeLink(item.hesapadi);
              Setyoutube(item)
            }
          })
        }
        if (response?.data?.value?.dk_firma_documents.length > 0) {
          response?.data?.value?.dk_firma_documents?.map((item) => {
            if (item.documentType === 0) {
              setkaliteBelgeleri(current => [...current, item])
            }
            if (item.documentType === 1) {
              setgarantiBelgeleri(current => [...current, item])
            }
            if (item.documentType === 2) {
              setkatalog(current => [...current, item])
            }
            if (item.documentType === 3) {
              setsertifikaBelgeleri(current => [...current, item])
            }
          })
        }

        setFirmaId(response.data.value.id)
        SetFirmaisAktif(response.data.value.aktif);
        SetFirmaData(response.data.value);
        SetFirmaAdi(response.data.value.firmaadi)
        SetFirmaAdiKisa(response.data.value.firmaadikisa)
        SetFirmayetkili(response.data.value.yetkili)
        SetFirmaWeb(response.data.value.web)
        SetFirmaMail(response.data.value.eposta)
        SetFirmaTelefon(response.data.value.dk_firma_telefons?.[0]?.telefon)
        SetFirmaHakkinda(response.data.value.hakkinda);
        setFimaAktifAdresFunc(response.data.value.dk_firma_adres);



        SetFirmaLogo('')
        SetFirmaGorsel('')
      }

    }
    GetItemData()
      .catch(console.error);
  }, [refreshData])

  //Functions
  const handleSelectFirmaImage = files => {
    SetFirmaGorsel(files[0].base64_file.split(',')[1]);
  };

  const handleSelectFirmaLogo = files => {
    SetFirmaLogoPreview(files[0].base64_file)
    SetFirmaLogo(files[0].base64_file.split(',')[1]);
  };

  const handleSelectFirmaBackgroundImage = files => {
    SetFirmaGorselPreview(files[0].base64_file)
    SetFirmaGorsel(files[0].base64_file.split(',')[1]);
  };
  const [file, setFile] = useState();

  const kaliteRef = useRef(null);
  const [kaliteBelgeleri, setkaliteBelgeleri] = useState([]);
  const handleOpenKaliteBelgesei = (e) => {
    kaliteRef.current.click();
  };
  const handleSelectKaliteBelgesei = (e) => {
    if (e.target.files) {
      setkaliteBelgeleri(current => [...current, e.target.files[0]])
    }
  };

  const garantiRef = useRef(null);
  const [garantiBelgeleri, setgarantiBelgeleri] = useState([]);
  const handleOpenGarantiBelgesei = (e) => {
    garantiRef.current.click();
  };
  const handleSelectGarantiBelgesei = (e) => {
    if (e.target.files) {
      setgarantiBelgeleri(current => [...current, e.target.files[0]])
    }
  };


  const katalogRef = useRef(null);
  const [katalogBelgeleri, setkatalog] = useState([]);
  const handleOpenKatalogBelgesei = (e) => {
    katalogRef.current.click();
  };
  const handleSelectKatalogBelgesei = (e) => {
    if (e.target.files) {
      setkatalog(current => [...current, e.target.files[0]])
    }
  };
  const [firmaIdNew, setFirmaIdNew] = useState(0);
  const SertifikaRef = useRef(null);
  const [sertifikaBelgeleri, setsertifikaBelgeleri] = useState([]);
  const handleOpenSertifikaBelgesei = (e) => {
    SertifikaRef.current.click();
  };
  const handleSelectSertifikaBelgesei = (e) => {
    if (e.target.files) {
      setsertifikaBelgeleri(current => [...current, e.target.files[0]])
    }
  };
  const handleSubmit = async () => {
    var reqBodySaveAdress = null;
    if (isAdresChanged) {
      reqBodySaveAdress = {
        "id": firmaAdresId === null || firmaAdresId === 0 ? 0 : firmaAdresId,
        "aktif": 1,
        "firmaid": parseInt(firmaId),
        "tipi": firmaAdresTipi,
        "adres": firmaAdresString,
        "ulke": 0,
        "sehir": firmaSehir.id,
        "ilce": firmaIlce.id,
        "mahalle": firmaMahalle.id,
      }
    }

    const reqBodySaveFirm = {
      "id": parseInt(firmaId),
      "aktif": null,
      "firmaadi": firmaAdi,
      "firmaadikisa": firmaAdiKisa,
      "yetkili": firmayetkili,
      "web": firmaWeb,
      "isBasvuru": 0,
      "eposta": firmaMail,
      "telefon": firmaTelefon,
      "hakkinda": firmaHakkinda,
      "uyeid": null,
      "adres": reqBodySaveAdress,
      "logos": [
        {
          "id": 0,
          "aktif": 1,
          "firmaid": parseInt(firmaId),
          "base64": firmaLogo
        }
      ],
      "firmaGorsels": [
        {
          "id": 0,
          "aktif": 1,
          "firmaid": parseInt(firmaId),
          "base64": firmaGorsel
        }
      ],
      "socialMedias": [
        {
          "id": facebook?.id,
          "aktif": 0,
          "firmaid": parseInt(firmaId),
          "sid": 1,
          "hesapadi": facebookLink,
          "silindi": false
        },
        {
          "id": youtube?.id,
          "aktif": 0,
          "firmaid": parseInt(firmaId),
          "sid": 2,
          "hesapadi": youtubeLink,
          "silindi": false
        },
        {
          "id": instagram?.id,
          "aktif": 0,
          "firmaid": parseInt(firmaId),
          "sid": 3,
          "hesapadi": instagramLink,
          "silindi": false
        },
        {
          "id": linkedin?.id,
          "aktif": 0,
          "firmaid": parseInt(firmaId),
          "sid": 4,
          "hesapadi": linkedinLink,
          "silindi": false
        },
        {
          "id": twitter?.id,
          "aktif": 0,
          "firmaid": parseInt(firmaId),
          "sid": 5,
          "hesapadi": twitterLink,
          "silindi": false
        },
      ]
    }


    const res1 = await postData('DijitalKatalog/DKFirmalarInsertorUpdate', reqBodySaveFirm)
    if (res1) {
      handleClose();
      ShowMessage('success', 'Firma Bilgileri Güncellendi.');
      // window.location.href = `/PazarYeri/Firma/${res1.data.value.seourl}`
    } else {
      ShowMessage('error', 'Bir Hata Oluştu.')
    }
    if (res1.data.isSucceeded) {
      setFirmaIdNew(res1.data.value.id);

      if (kaliteBelgeleri.length > 1) {
        await Promise.all(kaliteBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 0, 1)
        }));
      } else {
        await Promise.all(kaliteBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 0, 0)
        }));
      }


      if (garantiBelgeleri.length > 1) {
        await Promise.all(garantiBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 1, 1)
        }));
      } else {
        await Promise.all(garantiBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 1, 1)
        }));
      }



      if (katalogBelgeleri.length > 1) {
        await Promise.all(katalogBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 2, 1)
        }));
      } else {
        await Promise.all(katalogBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 2, 1)
        }));

      }



      if (sertifikaBelgeleri.length > 1) {
        await Promise.all(sertifikaBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 3, 1)
        }));
      } else {
        await Promise.all(sertifikaBelgeleri.map(async (file) => {
          const contents = await handleUploadfile(res1.data.value.id, file, 3, 0)
        }));
      }

      ShowMessage('success', 'Bilgileriniz Güncellendi. Yönlendiriliyorsunuz.');
      const userDataRes = await getData('Auth/GetCurrectUser')
        .then(result => {
          dispatch(setUserInfo(result.data.value));
        }
        ).then(() => {
          navigate(`/PazarYeri/Firma/${res1.data.value.seourl}`);
          setSubmitButtonDisable(false);
        })

    } else {
      ShowMessage('error', 'Firma kayit ederken bir hata ile karsilasildi. Yoneticinize basvurunuz.');
      setSubmitButtonDisable(false);
    }
  }
  const handleUploadfile = async (firmaId, val, type, processType) => {
    debugger;
    const formdata = new FormData();
    formdata.append('id', val?.id ? val.id : 0);
    formdata.append('aktif', 1);
    formdata.append('file', val);
    formdata.append('type', type);
    formdata.append('isDelete', val?.isDelete === true ? true : false);
    formdata.append('firmaid', firmaId);

    const filepostResponse = await postData('DijitalKatalog/FirmaDocumentInsertorUpdate', formdata)
    if (filepostResponse) {
      return filepostResponse
    }
  };



  const handleOnayIste = async () => {
    const res = await getData(`DijitalKatalog/FirmaOnayIstek?dKfirmaId=${parseInt(firmaId)}`);
    if (res.data.isSucceeded === true) {
      ShowMessage('success', res.data.message);
      const refreshUserInfo = await getData('Auth/GetCurrectUser')
        .then(result => {
          dispatch(setUserInfo(result.data.value));
          setRefreshData(!refreshData);
        })
    } else {
      ShowMessage('error', res.data.error.errorMessage)
    }
  }

  const handleOnayla = async () => {
    const res = await getData(`DijitalKatalog/FirmaOnay?dKfirmaId=${parseInt(firmaId)}`);
    if (res) {
      ShowMessage('success', 'Firma Onaylama İşlemi Başarı İle Tamamlandı.');
      setRefreshData(!refreshData);
    }
  }

  const handleTumUrunlerleOnayla = async () => {
    const res = await getData(`DijitalKatalog/FirmaOnay?dKfirmaId=${parseInt(firmaId)}&isAllUrun=true`);
    if (res) {
      ShowMessage('success', 'Firma Tüm Ürünleri ile birlikte Onaylanmıştır.');
      setRefreshData(!refreshData);
    }
  }




  const [categories, SetCategories] = useState([]);
  useEffect(() => {
    const GetCategoriesData = async () => {
      const res = await getData('DijitalKatalog/KategoriList');
      FixCategoryData(res.data.value);
    }
    GetCategoriesData()
      .catch(console.error);
  }, [])


  const FixCategoryData = (param) => {
    const fixedData = [];
    param.forEach((item, index) => {
      item?.children?.forEach((item2, index2) => {
        fixedData.push({
          key: index.toString() + index2.toString(),
          name: item2.adi,
          id: item2.id,
          parent: item.adi,
          type: 'SubCategory'
        })
        item2?.children?.forEach((item3, index3) => {
          fixedData.push({
            key: index.toString() + index2.toString() + index3.toString(),
            name: item3.adi,
            id: item3.id,
            parent: item.adi,
            type: 'child'
          })

        })
      })
    })
    SetCategories(fixedData);
  }



  // ADRES FUNCTIONS AND VARIABLES
  const [firmaAdresId, setFirmaAdresId] = useState(0);
  const [firmaSehir, SetFirmaSehir] = useState(null);
  const [firmaIlce, SetFirmaIlce] = useState(null);
  const [firmaMahalle, SetFirmaMahalle] = useState(null);
  const [firmaAdresTipi, SetFirmaAdresTipi] = useState(null);
  const [firmaAdresString, SetFirmaAdresString] = useState(null);

  const [isAdresChanged, setIsAdresChanged] = useState(false);
  const [firmaAktifAdres, SetFirmaAktifAdres] = useState();


  const [cities, SetCities] = useState([]);
  const [ilceler, SetIlceler] = useState([]);
  const [mahalleler, SetMahalleler] = useState([]);


  const handleAdresTipiChange = (param) => {
    SetFirmaAdresTipi(param.id);
    setIsAdresChanged(true);
  }

  const handleCityChange = (param) => {
    console.log(param);
    SetFirmaSehir(param);
    SetFirmaIlce([]);
    SetFirmaMahalle([]);
    setIsAdresChanged(true);
  }
  const handleIlcelerChange = (param) => {
    SetFirmaIlce(param)
    SetFirmaMahalle([]);
    setIsAdresChanged(true);
  }

  const handleMahallelerChange = (param) => {
    SetFirmaMahalle(param)
    setIsAdresChanged(true);
  }

  const handleFirmaAdresStringChange = (param) => {
    SetFirmaAdresString(param);
    setIsAdresChanged(true);
  }


  useEffect(() => {
    const GetCitiesData = async () => {
      const res = await postData('DijitalKatalog/SehirList', {
        "boldeIds": [
        ],
        "ulkeIds": [
          216
        ],
        "ids": [
        ]
      });
      const tempval = [];
      res?.data?.value.forEach((item) => {
        tempval.push(
          {
            label: item.ad,
            id: item.id
          }
        )
      })
      SetCities(tempval);
    }

    GetCitiesData()
      .catch(console.error);
  }, [])

  useEffect(() => {
    const GetIlcelerData = async () => {
      if (firmaSehir) {
        const res = await postData('DijitalKatalog/IlceList', {
          "sehirIds":
            [firmaSehir.id]
          ,
          "ids": [

          ]
        });
        const tempval = [];
        res?.data?.value.forEach((item) => {
          tempval.push(
            {
              label: item.ad,
              id: item.id
            }
          )
        })
        SetIlceler(tempval);
      }
    }

    GetIlcelerData()
      .catch(console.error);
  }, [firmaSehir])


  useEffect(() => {
    const GetMahalleData = async () => {
      if (firmaIlce) {
        const res = await postData('DijitalKatalog/MahalleList', {
          "ilceIds": [
            firmaIlce.id
          ],
          "ids": [
          ]
        });
        const tempval = [];
        res?.data?.value.forEach((item) => {
          tempval.push(
            {
              label: item.ad,
              id: item.id
            }
          )
        })
        SetMahalleler(tempval);
      }
    }

    GetMahalleData()
      .catch(console.error);
  }, [firmaIlce])


  const [adrestipiList, SetadrestipiList] = useState(false);
  useEffect(() => {
    const GetADresTipiData = async () => {
      const res = await postData('DijitalKatalog/AdresTipi', {});
      const tempval = [];
      res?.data?.value.forEach((item) => {
        tempval.push(
          {
            label: item.adi,
            id: item.id
          }
        )
      })
      SetadrestipiList(tempval);
    }
    GetADresTipiData()
      .catch(console.error);
  }, [])


  const setFimaAktifAdresFunc = (adresler) => {
    if (firmaData?.onayli === 0) {
      SetFirmaAktifAdres(adresler[0]);
      SetFirmaAdresString(firmaData?.dk_firma_adres?.[0].adres);
    } else {
      adresler?.forEach((item) => {
        console.log(adresler);
        if (item.aktif === 1) {
          const tempSehir = { 'label': item.sehirNavigation?.ad, 'id': item.sehirNavigation?.id }
          const tempIlce = { 'label': item.ilceNavigation?.ad, 'id': item.ilceNavigation?.id }
          const tempMahalle = { 'label': item.mahalleNavigation?.ad, 'id': item.mahalleNavigation?.id }

          SetFirmaSehir(tempSehir);
          SetFirmaIlce(tempIlce);
          SetFirmaMahalle(tempMahalle);

          setFirmaAdresId(item.id)
          SetFirmaAktifAdres(item);
          SetFirmaAdresString(item.adres)
        }
      })
    }

  }

  const handleFirmaPasifAktifClick = async () => {
    const reqBodyFirm = {
      "id": parseInt(firmaId),
      "aktif": firmaisAktif === 0 ? 1 : 0
    }

    const res11 = await postData('DijitalKatalog/DKFirmalarInsertorUpdate', reqBodyFirm)
    if (res11) {
      ShowMessage('success', 'Firma Bilgileri Güncellendi.')
      window.location.reload(true);
    } else {
      ShowMessage('error', 'Bir Hata Oluştu.')
    }
  }

  const markAllAsDeleted = (state, setState) => {
    const updatedKaliteBelgeleri = state.map(item => ({
      ...item,
      isDelete: true
    }));
    setState(updatedKaliteBelgeleri);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          overflow: 'scroll',
          height: '100%',
        }}
      >
        <>
          <Box sx={styles.ModalStyle}>
            <Grid container spacing={0}>
              <Grid align='left' item xl={12} md={12} xs={12}>
                <Typography sx={styles.Header1}>
                  Firma Güncelle
                </Typography>
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={firmaAdi}
                    onChange={(e) => SetFirmaAdi(e.target.value)}
                    label='Firma Ünvanı*'
                  />
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={firmaAdiKisa}
                    onChange={(e) => SetFirmaAdiKisa(e.target.value)}
                    label='Firma Kısa Adı*'
                  />
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={firmaWeb}
                    onChange={(e) => SetFirmaWeb(e.target.value)}
                    label='Firma Web Adresi*'
                  />
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={firmaMail}
                    onChange={(e) => SetFirmaMail(e.target.value)}
                    label='Firma E-mail*'
                  />
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={firmaTelefon}
                    onChange={(e) => SetFirmaTelefon(e.target.value)}
                    label='Firma Telefon Numarası'
                  />
                </Grid>


                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={facebookLink}
                    onChange={(e) => SetfacebookLink(e.target.value)}
                    label='Facebook'
                  />
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={linkedinLink}
                    onChange={(e) => SetlinkedinLink(e.target.value)}
                    label='Linkedin'
                  />
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={instagramLink}
                    onChange={(e) => SetinstagramLink(e.target.value)}
                    label='İnstagram'
                  />
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={twitterLink}
                    onChange={(e) => SettwitterLink(e.target.value)}
                    label='Twitter'
                  />
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={youtubeLink}
                    onChange={(e) => SetyoutubeLink(e.target.value)}
                    label='Youtube'
                  />
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <Typography sx={styles.TextHeader}>
                    Adres
                  </Typography>

                  <Autocomplete
                    onChange={(e, v) => handleAdresTipiChange(v)}
                    value={firmaAktifAdres?.tipiNavigation?.adi}
                    isOptionEqualToValue={(option, value) => option.adi === value.adi}
                    id="disabled-options-demo"
                    options={adrestipiList}
                    limitTags={2}
                    sx={styles.textField}
                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="Adres Tipi" />}
                  />


                  <Autocomplete
                    onChange={(e, v) => handleCityChange(v)}
                    id="disabled-options-demo"
                    value={firmaSehir}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    options={cities}
                    limitTags={2}
                    sx={styles.textField}
                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="İl" />}
                  />

                  <Autocomplete
                    onChange={(e, v) => handleIlcelerChange(v)}
                    id="disabled-options-demo"
                    options={ilceler}
                    value={firmaIlce}
                    isOptionEqualToValue={(option, value) => option.ad === value.ad}
                    limitTags={2}
                    sx={styles.textField}
                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="İlçe" />}
                  />


                  <Autocomplete
                    onChange={(e, v) => handleMahallelerChange(v)}
                    id="disabled-options-demo"
                    value={firmaMahalle}
                    isOptionEqualToValue={(option, value) => option.ad === value.ad}
                    options={mahalleler}
                    limitTags={2}
                    sx={styles.textField}
                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="Mahalle" />}
                  />
                  <TextField
                    sx={styles.textField}
                    value={firmaAdresString}
                    onChange={(e) => handleFirmaAdresStringChange(e.target.value)}
                    label='Cadde / Sokak / Numara'
                  />
                </Grid>

              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    sx={styles.textField}
                    value={firmayetkili}
                    onChange={(e) => SetFirmayetkili(e.target.value)}
                    label='Firma Yetkilisi*'
                  />
                </Grid>

                <Grid height='fit-content' item xl={12} md={12} xs={12}>
                  <Typography sx={styles.TextHeader}>
                    Firma Hakkında
                  </Typography>
                  <ReactQuill
                    theme='snow'
                    value={firmaHakkinda}
                    onChange={SetFirmaHakkinda}
                    style={{
                      widht: '100%',
                      height: '250px'
                    }}
                  />
                </Grid>
                <Grid container mt={10} spacing={0}>
                  {/* <Grid align='left' item xl={6} md={6} xs={12}>
                    <Typography sx={styles.TextHeader}>
                      Firma Görsel
                    </Typography>
                    <ReactImageFileToBase64 preferredButtonText="Görsel Seç" onCompleted={handleSelectFirmaImage} />
                  </Grid> */}
                  <Grid item xl={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.TextHeader}>
                      Logo
                    </Typography>
                    <Box mt={1}>
                      <img
                        style={{ maxHeight: '175px', maxWidth: '175px', fontSize: '12px' }}
                        src={firmaLogoPreview ? firmaLogoPreview : `/images/Logo/${firmaData?.dk_firma_logos?.[0]?.logo}`}
                        alt='Logo Bulunamadı' />
                    </Box>
                    <ReactImageFileToBase64 preferredButtonText="Logo Seç" onCompleted={handleSelectFirmaLogo} />

                  </Grid>

                  <Grid item xl={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.TextHeader}>
                      Arkaplan Görüntüsü
                    </Typography>
                    <Box mt={1}>
                      <img
                        style={{ maxHeight: '175px', maxWidth: '175px', fontSize: '12px' }}
                        src={firmaGorselPreview ? firmaGorselPreview : `/images/FirmaGorsel/${firmaData?.dk_firma_gorsels?.[0]?.gorsel}`}
                        alt='Görsel Bulunamadı' />
                    </Box>
                    <ReactImageFileToBase64 preferredButtonText="Logo Seç" onCompleted={handleSelectFirmaBackgroundImage} />

                  </Grid>
                  <Grid pr={5} pb={5} item xl={6} sm={6} md={6} xs={12} mt={10}>
                    <input style={{ display: 'none' }} type="file" ref={kaliteRef} onChange={handleSelectKaliteBelgesei} />
                    <Typography sx={{
                      "width": "100%",
                      "height": "26px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "600",
                      "fontSize": "13px",
                      "lineHeight": "120%",
                      "color": "#000000"
                    }}>
                      Kalite Belgeleri
                    </Typography>

                    <Box mt={3} mb={3}>
                      <Button
                        onClick={handleOpenKaliteBelgesei}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belge Yükle
                      </Button>
                      <Button
                        onClick={() => markAllAsDeleted(kaliteBelgeleri, setkaliteBelgeleri)}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belgeleri sil
                      </Button>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%", padding: "0" }} aria-label="simple table">
                        <TableHead>
                          <TableRow >
                            <TableCell sx={{ minWidth: "100%", padding: "4px", fontSize: "12px" }} >Dosya Adı</TableCell>
                            {/* <TableCell sx={{ minWidth: "100%" ,padding: "4px" ,align:"right",fontSize:"12px"}} >Dosya Tipi </TableCell>
                                                <TableCell sx={{ minWidth: "100%" ,padding: "4px",align:"right",fontSize:"12px" }} >Dosya Boyutu</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {kaliteBelgeleri
                            .filter(row => !row.isDelete)  // isDelete true olan öğeleri filtrele
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontSize: "12px" }}>
                                  {row.name}
                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </Grid>
                  <Grid pr={5} pb={5} item xl={6} md={6} sm={6} xs={12} mt={10}>
                    <input style={{ display: 'none' }} type="file" ref={garantiRef} onChange={handleSelectGarantiBelgesei} />
                    <Typography sx={{
                      "width": "100%",
                      "height": "26px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "600",
                      "fontSize": "13px",
                      "lineHeight": "120%",
                      "color": "#000000"
                    }}>
                      Garanti Belgeleri
                    </Typography>

                    <Box mt={3} mb={3}>
                      <Button
                        onClick={handleOpenGarantiBelgesei}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belge Yükle
                      </Button>
                      <Button
                        onClick={() => markAllAsDeleted(garantiBelgeleri, setgarantiBelgeleri)}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belgeleri sil
                      </Button>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead>
                          <TableRow >
                            <TableCell sx={{ minWidth: "100%", padding: "4px", fontSize: "12px" }} >Dosya Adı</TableCell>
                            {/* <TableCell sx={{ minWidth: "100%" ,padding: "4px" ,align:"right",fontSize:"12px"}} >Dosya Tipi </TableCell>
                                                <TableCell sx={{ minWidth: "100%" ,padding: "4px",align:"right",fontSize:"12px" }} >Dosya Boyutu</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {garantiBelgeleri
                            .filter(row => !row.isDelete)
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontSize: "12px" }}>
                                  {row.name}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid pr={5} pb={5} item xl={6} md={6} sm={6} xs={12}>
                    <input style={{ display: 'none' }} type="file" ref={katalogRef} onChange={handleSelectKatalogBelgesei} />
                    <Typography sx={{
                      "width": "100%",
                      "height": "26px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "600",
                      "fontSize": "13px",
                      "lineHeight": "120%",
                      "color": "#000000"
                    }}>
                      Kataloglar
                    </Typography>

                    <Box>
                      <Button
                        onClick={handleOpenKatalogBelgesei}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belge Yükle
                      </Button>
                      <Button
                        onClick={() => markAllAsDeleted(katalogBelgeleri, setkatalog)}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belgeleri sil
                      </Button>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead>
                          <TableRow >
                            <TableCell sx={{ minWidth: "100%", padding: "4px", fontSize: "12px" }} >Dosya Adı</TableCell>
                            {/* <TableCell sx={{ minWidth: "100%" ,padding: "4px" ,align:"right",fontSize:"12px"}} >Dosya Tipi </TableCell>
                                                <TableCell sx={{ minWidth: "100%" ,padding: "4px",align:"right",fontSize:"12px" }} >Dosya Boyutu</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {katalogBelgeleri
                            .filter(row => !row.isDelete)
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontSize: "12px" }}>
                                  {row.name}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid pr={5} pb={5} item xl={6} md={6} sm={6} xs={12}>
                    <input style={{ display: 'none' }} type="file" ref={SertifikaRef} onChange={handleSelectSertifikaBelgesei} />
                    <Typography sx={{
                      "width": "100%",
                      "height": "26px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "600",
                      "fontSize": "13px",
                      "lineHeight": "120%",
                      "color": "#000000"
                    }}>
                      Sertifikalar
                    </Typography>

                    <Box>
                      <Button
                        onClick={handleOpenSertifikaBelgesei}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belge Yükle
                      </Button>

                      <Button
                        onClick={() => markAllAsDeleted(sertifikaBelgeleri, setsertifikaBelgeleri)}
                        sx={{
                          "width": "100%",
                          "margin-bottom": "15px",
                          "font-size": "10px",
                          "height": "30px",
                          "background": "#F4F7F9",
                          // "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                          "borderRadius": "14px",
                          ":hover": {
                            "boxShadow": "none",
                          }
                        }}
                        startIcon={<BorderColorIcon />} >
                        Belgeleri sil
                      </Button>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: "100%", padding: "0" }} aria-label="simple table">
                        <TableHead>
                          <TableRow >
                            <TableCell sx={{ minWidth: "100%", padding: "4px", fontSize: "12px" }} >Dosya Adı</TableCell>
                            {/* <TableCell sx={{ minWidth: "100%" ,padding: "4px" ,align:"right",fontSize:"12px"}} >Dosya Tipi </TableCell>
                                                <TableCell sx={{ minWidth: "100%" ,padding: "4px",align:"right",fontSize:"12px" }} >Dosya Boyutu</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sertifikaBelgeleri
                            .filter(row => !row.isDelete)
                            .map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row" sx={{ fontSize: "12px" }}>
                                  {row.name}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid align='center' item xl={12} md={12} xs={12}>
                <Button
                  disable={submitButtonDisable}
                  sx={styles.SubmitButton}
                  onClick={handleSubmit}
                >
                  Kaydet
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      </Modal>
      <Box display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ background: '#F4F7F9' }} width='100%'>
        <Box >
          <Grid sx={{ background: '#F4F7F9' }} container mb={20} spacing={0} >
            <Grid align='center' item mb={5} mt={5} xl={12} md={12} xs={12}>
              <Box sx={{
                display: (firmaData?.onayli === 0 && firmaData?.onayistek === 1 && UserInfoData?.pazarYeriAdmin == true && UserData?.FirmaId == firmaId)
                  ? '' : 'none'
              }}>
                <Paper
                  sx={{
                    mt: 1,
                    height: '40px',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'red'
                  }}
                >
                  <Typography
                    sx={{
                      "width": "100%",
                      "height": "18px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "400",
                      "fontSize": "14px",
                      "lineHeight": "130%",
                      "color": "#FFFFFF"
                    }}
                  >
                    Firmanız Şuan Yönetici Onayı Bekliyor.
                  </Typography>
                </Paper>
              </Box>

              <Box sx={{
                display: (firmaData?.onayli === 0 && firmaData?.onayistek === 0 && UserInfoData?.pazarYeriAdmin == true && UserData?.FirmaId == firmaId && firmaData?.dk_firma_urunlers?.length === 0) ||
                  (firmaData?.onayli === 0 && firmaData?.onayistek === 0 && UserData?.isAdmin == true && firmaData?.dk_firma_urunlers?.length === 0) ? '' : 'none'
              }}>
                <Paper
                  sx={{
                    mt: 1,
                    height: '40px',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'red'
                  }}
                >
                  <Typography
                    sx={{
                      "width": "100%",
                      "height": "18px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "400",
                      "fontSize": "14px",
                      "lineHeight": "130%",
                      "color": "#FFFFFF"
                    }}
                  >
                    Firmanızın yayınlanması için en az 1 adet ürün eklemelisiniz. Ürün eklemek için
                    <Button
                      onClick={handleUrunEkleClick}
                      sx={{ height: '19px', fontSize: '14px', textTransform: 'none', fontWeight: '700', color: 'white' }}
                    >
                      Tıklayınız
                    </Button>
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{
                display: firmaData?.aktif === 0 ? '' : 'none'
              }}>
                <Paper
                  sx={{
                    mt: 1,
                    height: '40px',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'red'
                  }}
                >
                  <Typography
                    sx={{
                      "width": "100%",
                      "height": "18px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "400",
                      "fontSize": "14px",
                      "lineHeight": "130%",
                      "color": "#FFFFFF"
                    }}
                  >
                    Firma Pasif Durumda
                  </Typography>
                </Paper>
              </Box>
              <Box sx={{
                display: (firmaData?.onayli === 0 && firmaData?.onayistek === 0 && UserInfoData?.pazarYeriAdmin == true && UserData?.FirmaId == firmaId && firmaData?.dk_firma_urunlers?.length > 0)
                  ? '' : 'none'
              }}>
                <Paper
                  sx={{
                    mt: 1,
                    height: '40px',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'red'
                  }}
                >
                  <Typography
                    sx={{
                      "width": "100%",
                      "height": "18px",
                      "fontFamily": "'Poppins'",
                      "fontStyle": "normal",
                      "fontWeight": "400",
                      "fontSize": "14px",
                      "lineHeight": "130%",
                      "color": "#FFFFFF"
                    }}
                  >
                    Firmanızın yayınlanması için bilgilerinizi kontrol edip
                    <Button
                      sx={{ height: '19px', fontSize: '14px', textTransform: 'none', fontWeight: '700', color: 'white' }}
                      onClick={handleOnayIste}
                    >
                      Buradan
                    </Button>
                    onay isteyebilirsiniz.
                  </Typography>
                </Paper>
              </Box>
            </Grid>
            <Box mb={1} pl={{ xs: 1, md: "10%" }} sx={{
              display: UserData?.isAdmin == true && firmaData?.onayli === 1 ? 'grid' : 'none'
            }}>
              <Button
                onClick={handleFirmaPasifAktifClick}
                sx={{ backgroundColor: "#ff66001a", color: "#ff6600", textTransform: 'none', textAlign: "right", flexDirection: "row-reverse", justifyContent: "flex-start", fontSize: "12px", fontFamily: 'Poppins' }}
              >
                {firmaisAktif === 1 ? 'Firmayı Pasife Al' : 'Firmayı Aktife Al'}
              </Button>
            </Box>
            <Box ml={{ xs: 1, md: 1 }} mb={1} sx={{
              display: (UserData?.FirmaId == firmaId && UserInfoData?.pazarYeriAdmin == true) || UserData?.isAdmin == true ? 'grid' : 'none', fontFamily: 'Poppins'
            }}>
              <Button
                onClick={handleOpen}
                sx={{ backgroundColor: "#ff66001a", color: "#ff6600", textTransform: 'none', textAlign: "right", flexDirection: "row-reverse", justifyContent: "flex-start", fontSize: "12px", fontFamily: 'Poppins' }}
              >
                Bilgilerini Güncelle
              </Button>
            </Box>
            <Box ml={{ xs: 1, md: 1 }} mb={1} sx={{
              display: (UserData?.FirmaId == firmaId && UserInfoData?.pazarYeriAdmin == true) || UserData?.isAdmin == true ? 'grid' : 'none'
            }}>
              <Button
                onClick={handleUrunEkleClick}
                sx={{ backgroundColor: "#ff66001a", color: "#ff6600", textTransform: 'none', textAlign: "right", flexDirection: "row-reverse", justifyContent: "flex-start", fontSize: "12px", fontFamily: 'Poppins' }}
              >
                Ürün Ekle
              </Button>
            </Box>
            <Grid padding="0 7%" align='center' item xs={12} md={12} xl={12}>

              <Box sx={styles.HeaderImageWrapper}>
                <img
                  width='100%'
                  height='319px'
                  src={firmaData?.dk_firma_gorsels?.length > 0 ? `/images/FirmaGorsel/${firmaData?.dk_firma_gorsels?.[0]?.gorsel}` : require(`../../Assets/Images/FirmaNoBackground2.webp`)}
                  alt='yapiradar Firms' />
                <Box sx={styles.FirmLogoWrapper}>
                  <img style={{ maxHeight: '175px', maxWidth: '175px', "borderRadius": "10px 10px 0px 0px" }}
                    src={firmaData?.dk_firma_logos?.length > 0 ? `/images/Logo/${firmaData?.dk_firma_logos?.[0]?.logo}` : NoFirmLogo} alt='Firma Logo' />
                </Box>
              </Box>
            </Grid>
            <Grid display="flex"
              justifyContent="center"
              alignItems="center" pl={{ xs: 1, md: "10%" }} item xl={8} md={8} xs={8}>
              <Grid container spacing={0}>
                <Grid item xl={8} md={8} xs={8}>
                  <Box sx={{ maxWidth: { xs: "100%", md: '1280px' }, pl: 2 }}>
                    <Typography sx={styles.FirmName}>
                      {firmaData?.firmaadikisa ? firmaData?.firmaadikisa : firmaData?.firmaadi}
                    </Typography>
                  </Box>
                </Grid>
                <Grid display='flex' item xl={8} md={8} xs={8}>
                  <Box sx={styles.InfoBarLogoWrapper}>
                    <LanguageIcon />
                  </Box>
                  <Box>
                    <Typography sx={styles.InfoBarHeader}>
                      Web
                    </Typography>
                    <a rel="noreferrer" href={firmaData?.web} target='_blank'>
                      <Typography sx={styles.InfoBarText}>
                        {firmaData?.web}
                      </Typography>
                    </a>
                  </Box>
                </Grid>
                <Grid display='flex' item xl={12} md={12} xs={12}>
                  <Box sx={styles.InfoBarLogoWrapper}>
                    <LocationOnIcon />
                  </Box>
                  <Box>
                    <Typography sx={styles.InfoBarHeader}>
                      Adres
                    </Typography>
                    <Typography sx={styles.InfoBarText}>
                      {firmaData?.dk_firma_adres?.adres}
                    </Typography>
                    <Typography sx={styles.InfoBarText}>
                      {firmaData?.dk_firma_adres?.ilceNavigation?.ad}
                    </Typography>
                    <Typography sx={styles.InfoBarText}>
                      {firmaData?.dk_firma_adres?.sehirNavigation?.ad}
                    </Typography>
                  </Box>
                </Grid>
                <Grid display='flex' item xl={12} md={12} xs={12}>
                  {/* <Box sx={styles.InfoBarLogoWrapper}>
                    <SupportAgentIcon />
                  </Box>
                  <Box>
                    <Typography sx={styles.InfoBarHeader}>
                      Müşteri Hizmetleri
                    </Typography>
                    <Typography sx={styles.InfoBarText}>
                      {firmaData?.dk_firma_telefons?.[0]?.telefon}
                    </Typography>
                  </Box> */}
                </Grid>

              </Grid>
            </Grid>
            <Grid
              sx={{
                display: firmaData?.id ? '' : 'none', marginTop: "20px"
              }}
              align='right' pr={{ xs: 1, md: 25 }} item xl={4} md={4} xs={4}>
              <Box>
                <Link display={facebook === '' ? 'none' : ''} href={facebookLink} target="_blank" rel="noopener noreferrer" >
                  <FacebookIcon
                    sx={styles.SocialIcons} />
                </Link>
                <Link display={twitter === '' ? 'none' : ''} href={twitterLink} target="_blank" rel="noopener noreferrer" >
                  <TwitterIcon
                    sx={styles.SocialIcons} />
                </Link>
                <Link display={instagram === '' ? 'none' : ''} href={instagramLink} target="_blank" rel="noopener noreferrer" >
                  <InstagramIcon
                    sx={styles.SocialIcons} />
                </Link>
                <Link display={youtube === '' ? 'none' : ''} href={youtubeLink} target="_blank" rel="noopener noreferrer" >
                  <YouTubeIcon
                    sx={styles.SocialIcons} />
                </Link>
                <Link display={linkedin === '' ? 'none' : ''} href={linkedinLink} target="_blank" rel="noopener noreferrer" >
                  <LinkedInIcon
                    sx={styles.SocialIcons} />
                </Link>
              </Box>


              <Box sx={{
                display: UserData?.isAdmin == false && UserData?.isAdmin == false && firmaData?.onayli === 0 && firmaData?.onayistek === 0 && firmaData?.dk_firma_urunlers?.length > 0 ? 'grid' : 'none'
              }}>
                <Button
                  onClick={handleOnayIste}
                  sx={{ textTransform: 'none', flexDirection: "row-reverse", justifyContent: "flex-start", fontSize: "12px", fontFamily: 'Poppins' }}
                >
                  Firma Onay İste
                </Button>
              </Box>
              <Box sx={{
                display: firmaData?.onayli === 0 && UserData?.isAdmin == true ? 'grid' : 'none'
              }}>
                <Button
                  onClick={handleOnayla}
                  sx={{ textTransform: 'none', flexDirection: "row-reverse", justifyContent: "flex-start", fontSize: "12px", fontFamily: 'Poppins' }}
                >
                  Firmayı Aktife Al Ve Onayla
                </Button>
              </Box>

              <Box sx={{
                display: firmaData?.onayli === 0 && UserData?.isAdmin == true ? 'grid' : 'none'
              }}>
                <Button
                  onClick={handleTumUrunlerleOnayla}
                  sx={{ textTransform: 'none', flexDirection: "row-reverse", justifyContent: "flex-start", fontSize: "12px", fontFamily: 'Poppins' }}
                >
                  Firmayı Tüm Ürünleriyle Onayla
                </Button>
              </Box>
            </Grid>

            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={5} pr={{ xs: 2, md: "10%" }} pl={{ xs: 2, md: "10%" }} mt={5} container spacing={0}>
              <Box width={"100%"}>
                <Grid width={"100%"} item mb={5} xl={6} md={6} xs={9}>
                  <Typography sx={{
                    "height": "42px",
                    "fontFamily": "'Poppins'",
                    "fontStyle": "normal",
                    "fontWeight": "600",
                    "fontSize": "30px",
                    "lineHeight": "140%",
                    "display": "flex",
                    "alignItems": "center",
                    "color": "#889AA4"
                  }}>
                    Hakkında
                  </Typography>
                </Grid>
                <Grid item mb={10} xl={12} md={12} xs={12}>
                  <div
                    dangerouslySetInnerHTML={{ __html: firmaData?.hakkinda }}
                  />
                </Grid>
              </Box>
            </Grid>

            {/* <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              pl={20} mt={5} container spacing={0}>
              <Box width={1180}>
                <Grid sx={{ display: 'contents' }} mt={3} item xl={12} md={12} xs={12}>
                  {
                    firmaData?.dk_firma_kategorisis &&
                    <Typography
                      onClick={() => SetUrlLinkStyle('Urunler')}
                      sx={{
                        marginTop: '9px',
                        display: 'inline',
                        margin: '0px 5px',
                        "padding": "10px 10px",
                        "width": "fit-content",
                        "height": "fit-content",
                        "borderRadius": "100px",
                        "fontFamily": "'Poppins'",
                        "fontStyle": "normal",
                        "fontWeight": "500",
                        "fontSize": "16px",
                        "border": "1px solid #BCC7CE",
                        "lineHeight": "120%",
                        "background": urlLinkStyle === 'Urunler' ? '#303C43' : '#FFFFFF',
                        "color": urlLinkStyle === 'Urunler' ? '#FFFFFF' : '#03C43',
                      }}>
                      {firmaData?.firmaadikisa} Ürünleri
                    </Typography>
                  }
                </Grid>
              </Box>

            </Grid> */}

            <Grid
              display='flex'
              justifyContent='center'
              mt={5} p={2} pr={{ xs: 2, md: "10%" }} pl={{ xs: 2, md: "10%" }} container spacing={0}>
              <Box sx={{
                width: { xs: "100%", md: '100%' },
                maxWidth: { xs: "100%", md: "100%" },
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}>
                <Grid item mb={10} xl={6} md={6} xs={6}>
                  <Typography sx={{
                    "height": "42px",
                    "fontFamily": "'Poppins'",
                    "fontStyle": "normal",
                    "fontWeight": "600",
                    "fontSize": "30px",
                    "lineHeight": "140%",
                    "display": "flex",
                    "alignItems": "center",
                    "color": "#889AA4"
                  }}>
                    Firma Ürünleri
                  </Typography>
                </Grid>
                <Grid align='right' item mb={10} xl={6} md={6} xs={6}>
                  <Button
                    onClick={() => handleCardClick(`/PazarYeri/Urunler?firmaid=${firmaId}`)}
                    sx={{
                      textTransform: 'none',
                      color: '#FF6600',
                      display: firmaData?.dk_firma_urunlers?.length > 0 ? '' : 'none',
                    }} endIcon={<ArrowForward />}>
                    <Typography sx={styles.SeeAllText}>
                      Tümünü Gör
                    </Typography>
                  </Button>
                </Grid>

                <Grid display='contents' item xl={12} md={12} xs={12}>
                  {firmaData?.dk_firma_urunlers?.length > 0 ? (firmaData?.dk_firma_urunlers?.map(item => (
                    <Grid key={item.id} mb={5} item xl={3} md={3} xs={12}>
                      <ItemCard props={item} />
                    </Grid>
                  ))) :
                    (
                      <div>
                        Firmanın Kayıtlı Bir Ürünü Yok.
                      </div>
                    )}
                </Grid>
              </Box>
            </Grid>
            <Grid pr={{ xs: 2, md: "10%" }} pl={{ xs: 2, md: "10%" }}
              display='flex'
              justifyContent='center'
              mt={8} container spacing={0}>
              <Box sx={{
                width: { xs: "100%", md: "100%" },
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}>
                <Grid item xl={12} md={12} xs={12}>
                  <Typography sx={{
                    "height": "42px",
                    "fontFamily": "'Poppins'",
                    "fontStyle": "normal",
                    "fontWeight": "600",
                    "fontSize": "30px",
                    "lineHeight": "140%",
                    "display": "flex",
                    "alignItems": "center",
                    "color": "#889AA4"
                  }}>
                    Firma Dosyaları
                  </Typography>
                </Grid>

                <Grid display='contents' item xl={12} md={12} xs={12}>
                  {firmaData?.dk_firma_documents?.length > 0 ? (
                    <Grid align='center' container spacing={0}>
                      <Grid align='center' mt={10} pr={30} item xl={12} md={12} xs={12}>
                        <Typography sx={{ "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "20px", "lineHeight": "35px", "display": "flex", textAlign: 'center', "color": "#303C43" }}>
                          Kalite Belgeleri
                        </Typography>
                        {firmaData?.dk_firma_documents?.map((item) => {
                          if (item.documentType === 0) {
                            return (
                              <Link underline='none' href={`/images/FirmaDocuments/${item.document}`}>
                                <Typography sx={{ textAlign: 'left' }}>
                                  {item?.name}
                                </Typography>
                              </Link>
                            )
                          }
                        })}
                      </Grid>

                      <Grid align='center' mt={10} pr={30} item xl={12} md={12} xs={12}>
                        <Typography sx={{ "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "20px", "lineHeight": "35px", "display": "flex", textAlign: 'center', "color": "#303C43" }}>
                          Garanti Belgeleri
                        </Typography>
                        {firmaData?.dk_firma_documents?.map((item) => {
                          if (item.documentType === 1) {
                            return (
                              <Link underline='none' href={`/images/FirmaDocuments/${item.document}`}>
                                <Typography sx={{ textAlign: 'left' }}>
                                  {item?.name}
                                </Typography>
                              </Link>
                            )
                          }
                        })}
                      </Grid>



                      <Grid align='center' mt={10} pr={30} item xl={12} md={12} xs={12}>
                        <Typography sx={{ "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "20px", "lineHeight": "35px", "display": "flex", textAlign: 'center', "color": "#303C43" }}>
                          Kataloglar
                        </Typography>
                        {firmaData?.dk_firma_documents?.map((item) => {
                          if (item.documentType === 2) {
                            return (
                              <Link underline='none' href={`/images/FirmaDocuments/${item.document}`}>
                                <Typography sx={{ textAlign: 'left' }}>
                                  {item?.name}
                                </Typography>
                              </Link>
                            )
                          }
                        })}
                      </Grid>



                      <Grid align='center' mt={10} pr={30} item xl={12} md={12} xs={12}>
                        <Typography sx={{ "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "20px", "lineHeight": "35px", "display": "flex", textAlign: 'center', "color": "#303C43" }}>
                          Sertifikalar
                        </Typography>
                        {firmaData?.dk_firma_documents?.map((item) => {
                          if (item.documentType === 3) {
                            return (
                              <Link underline='none' href={`/images/FirmaDocuments/${item.document}`}>
                                <Typography sx={{ textAlign: 'left' }}>
                                  {item?.name}
                                </Typography>
                              </Link>
                            )
                          }
                        })}
                      </Grid>
                    </Grid>
                  ) :
                    (
                      <div>
                        Firmanın Kayıtlı Bir Dosyası Yok.
                      </div>
                    )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default FirmPreview