import { Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import IphoneImage from '../../Assets/Images/iPhone 7.webp'
import MarketPlaceImage from '../../Assets/Images/MarketPlaceImage.webp'

import { getData } from '../../Assets/Scripts/dataAxios'
function ProductOfWeek(children) {
    const data = children.data
    const styles = {
        Header1: { "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "40px", "lineHeight": "140%", "display": "flex", "alignItems": "center", "color": "#000000" },
        ProductBrand: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#FF6600",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer',
                textShadow: '0.2px 0.2px'
            }
        },
        ProductHeader: { "width": "731px", "height": "39px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "30px", "lineHeight": "130%", "color": "#000000" },
        ProductText: { "width": "731px", "height": "72px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "16px", "lineHeight": "150%", "color": "#3C6780" },
        PaperHeader: { textDecoration: 'none', width: '300px', "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "900", "fontSize": "34px", "lineHeight": "40px", "display": "flex", "alignItems": "center", "textAlign": "center", "textTransform": "uppercase", "color": "#303C43" },
        MobileScreenWrapper: {
            paddingTop: '1vh',
            position: 'relative',
            marginBottom: '-7px'
        },
        MobileScreen: {
            position: 'absolute',
            top: '5.5em',
            left: '4.45em'
        },
        Paper: {
            cursor: 'pointer',
            padding: 0,
            border: '1px solid #BCC7CE',
            boxShadow: 'none',
        }
    }

    const [weekProductData, setWeekProductData] = useState([]);
    useEffect(() => {
        const getWeekData = async () => {
            const response = await getData('DijitalKatalog/DKHaftaninUrunu');
            setWeekProductData(response.data.value);
        }
        getWeekData();
    }, [])

    const handleClick = () => {
        const id = 'scrollToJoinCatalogue';
        const yOffset = -30;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
    return (
        <>
            <Grid mt={10} pr={10} pl={10} pb={10} container spacing={0}>
                <Typography sx={styles.Header1}>
                    Haftanın Ürünü
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xl={8} md={8} xs={8}>
                        <Grid container spacing={0}>
                            <Grid mt={5} item xl={12} md={12} xs={12} >
                                <a href={`/PazarYeri/Urun/${weekProductData?.seourl}`}>
                                    <img
                                        src={`/images/urunler/${weekProductData?.urunGorselNavigation?.length > 0 ? weekProductData.urunGorselNavigation[0].gorsel : ''}`}
                                        alt='Product of Week' />
                                </a>
                            </Grid>
                            <Grid mt={3} item xl={12} md={12} xs={12} >
                                <a style={{ textDecoration: 'none' }} href={`/PazarYeri/Urun/${weekProductData?.seourl}`}>
                                    <Typography mt={2} sx={styles.ProductBrand}>
                                        {weekProductData.firma?.firmaadikisa ? weekProductData.firma?.firmaadikisa : weekProductData.firma?.firmaadi}
                                    </Typography>
                                </a>
                                <a style={{ textDecoration: 'none' }} href={`/PazarYeri/Urun/${weekProductData?.seourl}`}>
                                    <Typography mt={2} sx={styles.ProductHeader}>
                                        {weekProductData.urunadi}
                                    </Typography>
                                </a>
                                <a style={{ textDecoration: 'none', color: 'black' }} href={`/PazarYeri/Urun/${weekProductData?.seourl}`}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: weekProductData.tanim }}
                                    />
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid align='center' mt={3} item xl={4} md={4} xs={4}>
                        <a style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={handleClick}>
                            <Box sx={styles.MobileScreenWrapper}>
                                <img src={MarketPlaceImage} alt='yapiradar.com mobile' />
                            </Box>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ProductOfWeek