import ArrowForward from '@mui/icons-material/ArrowForward'
import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import itemImage from '../../Assets/Images/Item1.png'
import { postData } from '../../Assets/Scripts/dataAxios'
import ItemCard from '../PazarYeri/ItemCard'

function NewItems(children) {
    // const newItemsData = children.data.NewItemsData;
    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(id)
    }

    const [newItemsData, SetNewItemsData] = useState([]);
    const [newItemsDataRequest, SetNewItemsDataRequest] = useState(
        {
            "orderBy": {
                "key": "urunViewCount",
                "value": false
            },
            "pageSize": 10,
            "currentPage": 0,
            "isPagingEnabled": true,
            "firmaids": []
        }
    )

    useEffect(() => {
        const GetNewItemsData = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', newItemsDataRequest);
            SetNewItemsData(response.data.value.data);
        }
        GetNewItemsData()
            .catch(console.error);
    }, [newItemsDataRequest])

    const styles = {
        Header1: {
            "height": "auto",
            "top": "calc(50% - 56px/2 - 2987.5px)",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize":{xs:"20px", md:"40px"},
            "lineHeight": "140%",
            "display": "flex",
            "alignItems": "center",
            "color": "#000000"
        },
        Card: {
            width: '250px',
            backgroundColor: '#F4F7F9',
            padding: 0,
            boxShadow: 'none',
            margin: '0px 3vw',
            cursor: 'pointer',
        },
        CardContent: {
            padding: 0,
            height: 'fitContent'
        },
        SeeAllBtn: {
            textTransform: 'none',
            color: '#FF6600',
            borderRadius: "30px",
            backgroundColor: "#fdf3ed",
        },
        SeeAllText: {
           



        },
        cardHeader: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#8FBFFA",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
        },
        cardText: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "22px",
            "lineHeight": "120%",
            "color": "#000000",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
        },
        newItemsWrapper: {
            display: 'inline-flex',
        }
    }



    return (
        <>
            <Grid pl={5} pb={5} pt={5}container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        En Beğenilen Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={12} md={12} xs={12}>
                    <Box sx={styles.newItemsWrapper}>
                        {newItemsData?.map((item) => (
                            <ItemCard props={item} />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default NewItems