import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Chip, Grid, Link, Pagination, Paper, TablePagination, Tooltip, Typography } from '@mui/material'
import { IhaleCardFirmaAdi, IhaleCustomTableColumnHeader, IhaleDescHeader, IhalePageCardHeader, IhalePageCardLabel, IhalePageDescriptionLabel, IhalePageHeaderLabel, IhalePageHeaderLabel2, IhaleTableHeaderLabel, PageDescriptionText, PageHeaderText } from '../../Components/Styled/Labels/StyledLabels'
import { StyledIhaleInfoCard, StyledIhaleInfoCardOrange } from '../../Components/Styled/Papers/StyledPapers'
import IhaleArama from '../../Components/Ihale/IhaleArama';
import IhalePaperImage from '../../Assets/Images/IhaleBackground.jpg'
import IhaleBannerImage from '../../Assets/Images/IhaleBanner.jpg'
import IhaleLayout from '../Layouts/IhaleLayout';
import TurkeyMap from 'turkey-map-react';
import IhaleCustomTable from '../../Components/Ihale/IhaleCustomTable';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ArrowForward } from '@mui/icons-material';
import { postData } from '../../Assets/Scripts/dataAxios';
import { ShowMessage } from '../../Components/Common/Alerts';
import { loadState } from '../../Assets/Scripts/LocalStorage';
import { useNavigate } from 'react-router-dom';
function IhaleIndex() {
    //State and Variables 
    const navigate = useNavigate();
    const [ihaleler, SetIhaleler] = useState([]);
    const UserInfoData = JSON.parse(loadState('UserInfo'));
    const [resultCount, SetResultCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [filters, SetFilters] = useState(
        {
            "orderBy": {
                "key": "Id",
                "value": true
            },
            "pageSize": 10,
            "currentPage": 0,
            "isPagingEnabled": true,
            "firmaids": [],
            "kategoriIds": [],
            "ihaleadi": "",
            'sehirs': []
        }
    )



    const options = {
        chart: {
            type: 'pie',
            fontFamily: "'Poppins'",
            backgroundColor: '#3C6780',
        },
        title: {
            text: 'Toplam İhale Verisi',
            style: {
                color: '#FFFFFF',
                font: '18px "Poppins", Verdana, sans-serif'
            }
        },
        tooltip: {
            valueSuffix: ''
        },
        subtitle: {
            text:
                '*'
        },
        plotOptions: {
            pie: {
                colors: [
                    '#50B432',
                    '#ED561B',
                    '#DDDF00',
                    '#24CBE5',
                    '#64E572',
                    '#FF9655',
                    '#FFF263',
                    '#6AF9C4'
                ],
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            },
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    style: {
                        color: '#FFFFFF',
                        font: '16px "Poppins", Verdana, sans-serif'
                    },
                    enabled: false,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '1.2em',
                        textOutline: 'none',
                        color: '#FFFFFF',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                }]
            }
        },
        series: [
            {
                name: 'Toplam',
                colorByPoint: true,
                data: [
                    {
                        name: 'Tamamlanmış İhaleler',
                        y: 55
                    },
                    {
                        name: 'Başlamamış İhaleler',
                        sliced: true,
                        selected: true,
                        y: 26
                    },
                    {
                        name: 'Şuan Devam Eden İhaleler',
                        y: 4
                    },
                ]
            }
        ]
    }


    const options2 = {
        chart: {
            type: 'pie',
            backgroundColor: '#3C6780',
        },
        title: {
            text: 'Kategorilere Göre İhale Dağılımı',
            style: {
                color: '#FFFFFF',
                font: '18px "Poppins", Verdana, sans-serif'
            }
        },
        tooltip: {
            valueSuffix: ''
        },
        subtitle: {
            text:
                '*'
        },
        plotOptions: {
            pie: {
                colors: [
                    '#50B432',
                    '#ED561B',
                    '#DDDF00',
                    '#24CBE5',
                    '#64E572',
                    '#FF9655',
                    '#FFF263',
                    '#6AF9C4'
                ],
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
            },
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    style: {
                        color: '#FFFFFF',
                        font: '16px "Poppins", Verdana, sans-serif'
                    },
                    enabled: false,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '0.9em',
                        textOutline: 'none',
                        color: '#FFFFFF',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                }]
            }
        },
        series: [
            {
                name: 'İhale Sayısı',
                colorByPoint: true,
                data: [
                    {
                        name: 'İş Sağlığı ve Güvenliği',
                        y: 13
                    },
                    {
                        name: 'Alt Yapı Elemanları',
                        y: 66
                    },
                    {
                        name: 'Hizmetler',
                        y: 88
                    },
                    {
                        name: 'Kaba Yapı',
                        y: 134
                    },
                    {
                        name: 'Kapı ve Pencere',
                        y: 33
                    },
                    {
                        name: 'Mutfak Banyo ve Seramik',
                        y: 13
                    },
                    {
                        name: 'Cam',
                        y: 4
                    },
                    {
                        name: 'Bina Donanımları',
                        y: 55
                    },
                    {
                        name: 'Kapı',
                        sliced: true,
                        selected: true,
                        y: 77
                    },
                    {
                        name: 'Asansör',
                        y: 22
                    },
                    {
                        name: 'Elektrik Elektronik',
                        y: 14
                    },
                    {
                        name: 'Otopark sistemleri',
                        y: 1
                    },
                    {
                        name: 'Zemin Kaplamalari',
                        y: 14
                    },
                    {
                        name: 'Çatı ve Cephe',
                        y: 34
                    }


                ]
            }
        ]
    }

    // Hooks
    const fetchData = useCallback(async () => {
        try {
            const response = await postData('Ihale/DijitalFirmaIhaleList', filters)
            SetIhaleler(response.data.value.data)
            SetResultCount(response.data.value.recordsFiltered)

        } catch (error) {
            ShowMessage('error', error)
        }
    }, [filters])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Function and methods

    const renderCity = (cityComponent, cityData) => (
        <Tooltip title={cityData.name} key={cityData.id}>
            {cityComponent}
        </Tooltip>
    );

    const handleChangePage = (event, newPage) => {
        setPage(parseInt(newPage));
        SetFilters({
            ...filters,
            'currentPage': newPage
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        SetFilters({
            ...filters,
            'pageSize': event.target.value
        })
    };

    return (
        <div>
            <IhaleLayout >
                <Grid pl={5} pr={5} mt={15} mb={15} container spacing={0}>
                    <Grid pr={5} item xl={3} lg={3} md={4} sm={12} xs={12}>
                        <IhaleArama />
                    </Grid>
                    <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                        <Grid item xl={12} md={12} xs={12}>
                            <Box sx={{
                                backgroundImage: `url(${IhaleBannerImage})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                height: "50px",
                                p: '30px',
                                borderRadius: '10px',
                                mb: 2
                            }}>
                                <IhalePageDescriptionLabel mt={2}>
                                    Siz de Avantajlı Tekliflerimizden yararlanmak için hemen Ücretsiz Demo Başvurusu yapın !
                                </IhalePageDescriptionLabel>
                            </Box>
                        </Grid>

                        <Grid sx={{ position: 'relative' }} item xl={12} md={12} xs={12}>
                            <Box sx={{
                                backgroundImage: `url(${IhalePaperImage})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                height: "440px",
                                p: '40px',
                                borderRadius: '10px',
                            }}>
                                <IhalePageHeaderLabel>
                                    Listele , Ara ve
                                </IhalePageHeaderLabel>
                                <IhalePageHeaderLabel>
                                    İhale Kazan
                                </IhalePageHeaderLabel>

                                <IhalePageDescriptionLabel mt={5}>
                                    Türkiye'nin Her Köşesine Yön Veren İhale Sistemi: İnşaatı Keşfet, Fırsatları Yakala!
                                </IhalePageDescriptionLabel>

                                <Grid sx={{ position: 'absolute', bottom: '20px', display: { xs: 'none', md: 'flex' } }} pr={10} align='center' container spacing={0}>
                                    <Grid item xl={3} md={3} xs={12}>
                                        <StyledIhaleInfoCardOrange onClick={() => navigate('/PazarYeri#MagazaniziAcin')}>
                                            <IhalePageCardLabel>  <span style={{ color: "white" }}> Hemen </span> </IhalePageCardLabel>
                                            <IhalePageCardHeader>  <span style={{ color: "white" }}> Ücretsiz </span> </IhalePageCardHeader>
                                            <IhalePageCardLabel> <span style={{ color: "white" }}> Dene</span> </IhalePageCardLabel>
                                        </StyledIhaleInfoCardOrange>
                                    </Grid>
                                    <Grid item xl={3} md={3} xs={12}>
                                        <StyledIhaleInfoCard>
                                            <IhalePageCardLabel> <span style={{ color: "#ABABAB" }}> Toplam </span> </IhalePageCardLabel>
                                            <IhalePageCardHeader> <span style={{ color: "#ABABAB" }}> 1.903 Adet </span> </IhalePageCardHeader>
                                            <IhalePageCardLabel> <span style={{ color: "#ff6600" }}> İhale </span>  </IhalePageCardLabel>
                                        </StyledIhaleInfoCard>


                                    </Grid>
                                    <Grid item xl={3} md={3} xs={12}>
                                        <StyledIhaleInfoCard>
                                            <IhalePageCardLabel> <span style={{ color: "#ABABAB" }}> Teklif Bekleyen </span> </IhalePageCardLabel>
                                            <IhalePageCardHeader> <span style={{ color: "#ABABAB" }}> 122 Adet </span></IhalePageCardHeader>
                                            <IhalePageCardLabel> <span style={{ color: "#ff6600" }}> İhale </span>  </IhalePageCardLabel>
                                        </StyledIhaleInfoCard>
                                    </Grid>


                                    <Grid item xl={3} md={3} xs={12}>
                                        <StyledIhaleInfoCard>
                                            <IhalePageCardLabel> <span style={{ color: "#ABABAB" }}> Tamamlanmış </span> </IhalePageCardLabel>
                                            <IhalePageCardHeader><span style={{ color: "#ABABAB" }}> 1.781 Adet </span> </IhalePageCardHeader>
                                            <IhalePageCardLabel> <span style={{ color: "#ff6600" }}> İhale </span>  </IhalePageCardLabel>
                                        </StyledIhaleInfoCard>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid mt={7} item xl={8} lg={8} md={8} xs={12} sm={12}>
                        <IhalePageHeaderLabel2>
                            Şehirlerdeki İhale Sayıları
                        </IhalePageHeaderLabel2>

                        <Box>
                            <TurkeyMap
                                customStyle={{ idleColor: "#78A3BC" }}
                                hoverable={false}
                                cityWrapper={renderCity}
                                showTooltip={true}
                                tooltipText='İhale Sayısı : 12'
                                onClick={({ plateNumber, name }) => window.location.href = `/Ihale/IhaleListesi?sehir=${plateNumber}`}
                            />
                        </Box>
                    </Grid>

                    <Grid mt={7} item xl={4} lg={4} md={4} xs={12} sm={12}>
                        <Box
                            sx={{
                                background: '#F9FBFC',
                                padding: '3em',
                                width: '85%',
                                height: '70%',
                                borderRadius: '16px'
                            }}
                        >
                            <Typography sx={{
                                color: '#0A354E',
                                "width": "100%",
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": 800,
                                "fontSize": "28px",
                                "lineHeight": "120%",
                                "textAlign": "left",
                            }}>
                                İnşaat Sektörüne Yön Veren İhale Sistemi: İnşaatı Keşfet, Fırsatları Yakala!
                            </Typography>

                            <Typography
                                sx={{
                                    "width": "100%",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": 400,
                                    "fontSize": "18px",
                                    "lineHeight": "140%",
                                    "textAlign": "left",
                                    "color": "#3C6780"
                                }}
                                mt={5}>
                                E-İhale sistemimiz, İnşaat projelerini bir araya getirerek çeşitli ürün ve hizmetlerin satın alınmasında veya işlerin yürütülmesini sağlayan dijital bir platformdur.
                            </Typography>

                            <Typography
                                sx={{
                                    "width": "100%",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": 400,
                                    "fontSize": "18px",
                                    "lineHeight": "140%",
                                    "textAlign": "left",
                                    "color": "#3C6780"
                                }}
                                mt={5}>
                                E-ihale sisteminde yer almak ve katılmak, alıcı ve satıcılar için  portföylerini genişletmelerine, yeni iş fırsatlarına, uygun fiyat ve kaliteyi sağlayan iş ortaklarına erişmelerine yardımcı olabilir.
                            </Typography>

                            <Typography
                                sx={{
                                    "width": "100%",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": 400,
                                    "fontSize": "18px",
                                    "lineHeight": "140%",
                                    "textAlign": "left",
                                    "color": "#3C6780"
                                }}
                                mt={5}>
                                Yenilikçi ve kullanıcı dostu arayüzümüz, hem alıcılar hem de satıcılar için işlemleri kolaylaştırarak daha güvenilir ve kaliteli bir ortam sağlıyor..
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xl={12} md={12} sm={12}>
                        <Grid sx={{ background: '#3C6780' }} container spacing={0}>
                            <Grid sx={{ p: '36px' }} item xl={12} md={12} xs={12}>
                                <Box sx={{ width: '100%' }}>
                                    <PageHeaderText
                                        sx={{
                                            fontSize: '33px',
                                            color: 'white'
                                        }}
                                    >
                                        Verilerin gücünü grafiğe dönüştür, inşaat sektöründe fırsatları görselleştir!
                                    </PageHeaderText>

                                    <PageDescriptionText
                                        sx={{
                                            mt: 5,
                                            mb: 2,
                                            color: 'white',
                                            fontSize: '18px'
                                        }}>
                                        Grafikler aracılığıyla sunulan inşaat ihale verileri, proje geliştirme süreçlerinde kritik bir avantaj sağlıyor. Bu görsel analiz, karmaşık veri setlerini anlamlı trendlere dönüştürerek, karar verme süreçlerini optimize ediyor. Veri odaklı grafikler, Türkiye genelindeki inşaat projelerinin dağılımını ve büyüme potansiyelini açıkça ortaya koyarak, stratejik planlamalara ışık tutuyor.
                                    </PageDescriptionText>

                                    <PageDescriptionText
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            color: 'white',
                                            fontSize: '18px'
                                        }}>
                                        İhale süreçlerinde bu grafiksel bilgileri kullanmak, şeffaflığı artırarak daha etkili ve rekabetçi bir ortamın oluşmasına katkı sağlıyor. Grafiklerle desteklenen veri analizi, inşaat sektöründeki paydaşlara bilgi tabanlı bir perspektif sunarak, sektördeki gelişim fırsatlarını en iyi şekilde değerlendirmelerine yardımcı oluyor.
                                    </PageDescriptionText>
                                </Box>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options2}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid sx={{ background: '#F9FBFC', padding: '1em' }} mt={5} item xl={12} md={12} xs={12}>
                        <Box sx={{
                            width: '96%',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            margin: '1em',
                        }}>
                            <IhalePageHeaderLabel2>
                                Son Eklenen İhaleler
                            </IhalePageHeaderLabel2>

                            <Button
                                sx={{
                                    textTransform: 'none',
                                    color: '#FF6600',
                                }} endIcon={<ArrowForward />}>
                                <Typography sx={{
                                    display: 'inline'
                                }}>
                                    Tümünü Gör
                                </Typography>
                            </Button>
                        </Box>

                        <Box padding='2em 1em'>
                            {
                                Array.isArray(ihaleler) && ihaleler.length > 0 ? (
                                    ihaleler.map((item, index) => (
                                        <IhaleCustomTable
                                            key={index}
                                            props={item}
                                        />
                                    ))
                                ) : (
                                    <>veriyok</>
                                )
                            }

                            <Box mt={2} sx={{ width: '100%', display: 'inline-flex', justifyContent: 'end' }}>
                                <TablePagination
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                            return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                                        }}
                                    component="div"
                                    count={resultCount}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Sayfada şu kadar sonuç göster"
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50
                                    ]}
                                />

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </IhaleLayout>
        </div>
    )
}

export default IhaleIndex