import { Button,  Grid,  Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import image from '../../Assets/Images/hemen deneyin.webp'

function TryNow() {
  const styles = {
    Header: {
        fontSize: {xs:'18px',md:'60px'},
        fontStyle: 'normal',
        fontWeight: '700',
        color: '#f9ffff',
        textAlign: 'center',
        "width":"100%",
        "position":"absolute",
        top:{xs:"30%",sm:"35%",md:"35%",xl:"35%"}
    },
    HeadComp: {
      fontSize: {xs:"8px",sm:"13px",md:"16px",lg:"10px",xl:"25px"},
      fontStyle: 'normal',
      fontWeight: '500',
      color: '#dbe3e4',
      textAlign: 'center',
      "width":{xs:"60%",sm:"50%",md:"40%",xl:"42%"},
      "left":{xs:"20%",sm:"25%",md:"30%",xl:"30%"},
      "position":"absolute",
      top:{xs:"47%",sm:"51%",md:"55%",xl:"53%"}
  },
  navDeneBtnText: {
    textTransform: "none",
    color: "#FFFFFF",
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "120%"
},

navDeneBtnMobile: {
    textTransform: "none",
    "justifyContent": "center",
    "alignItems": "center",
    "padding": "15px 25px",
    "gap": "10px",
    "width": "333px",
    "height": "63px",
    "background": "#FF6600",
    "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
    "borderRadius": "20px",
    ':hover': {
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "15px 25px",
        "gap": "10px",
        "width": "333px",
        "height": "63px",
        "background": "#FF6600",
        "boxShadow": "none",
        "borderRadius": "20px",
    }
},
navDeneBtn: {
  backgroundColor: "#FF6600",
  boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
  borderRadius: "14px",
  width: {xs:"162px",md:"162px",xl:"262px"},
  height: {xs:"20px",md:"40px",xl:"50px"},
  "position":"absolute",
  "bottom":{xs:"5%",sm:"10%",md:"10%",xl:"10%"},
  left:{xs:"30%",sm:"40%",md:"42%",xl:"42%"},
  marginRight: '1em',
  ':hover': {
      bgcolor: '#FF6600',
      boxShadow: 'none',
  },
}
  }
  const navigate = useNavigate();
  const handleLinkNavigate = (url) => {
    navigate(url);
    navigate(0);
}
  const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
  return (
   
    <>
    <Grid container spacing={0} item xl={12} md={12} xs={12} position={'relative'} textAlign={'center'}>
    <Typography align='center'
                        sx={styles.Header}>
                      {translate.HemenDeneyin}
                    </Typography>
                    <Typography align='center'
                        sx={styles.HeadComp}>
                      {translate.HemenDeneyinComponent}
                    </Typography>
      <img  style={{objectFit:'cover',width:'inherit'}} src={image} alt='yapiradar.com' />
      <Button onClick={() => handleLinkNavigate('/Register')} variant='contained' sx={styles.navDeneBtn}>
                                    <Typography sx={styles.navDeneBtnText}>
                                        {translate.UcretsizDene}
                                    </Typography>
                                </Button>
    </Grid>
    </>
  )
}

export default TryNow