import { Button, Card, CardContent, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getData } from '../../Assets/Scripts/dataAxios'

function FirmAllItems(children) {
    const [firmData, SetFirmData] = useState([]);
    useEffect(() => {
        const GetNewItemsData = async () => {
            const response = await getData(`DijitalKatalog/DijitalFirmaById?Id=${children.firmaId}`);
            SetFirmData(response.data.value);
        }
        GetNewItemsData()
            .catch(console.error);
    }, [])

    const styles = {
        Header1: {
            "height": "56px",
            "top": "calc(50% - 56px/2 - 2987.5px)",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "40px",
            "lineHeight": "140%",
            "display": "flex",
            "alignItems": "center",
            "color": "#000000"
        },
        Card: {
            width: '250px',
            backgroundColor: '#F4F7F9',
            padding: 0,
            boxShadow: 'none',
            margin: '0px 3vw',
        },
        CardContent: {
            padding: 0,
        },
        cardHeader: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#8FBFFA",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer'
            }
        },
        cardText: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "22px",
            "lineHeight": "120%",
            "color": "#000000",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            ":hover": {
                cursor: 'pointer'
            }
        },
        newItemsWrapper: {
            display: 'inline-flex',
        },
        ShowMoreBtn: {
            "boxSizing": "border-box",
            "border": "1px solid #BCC7CE",
            "borderRadius": "25px",
            width: '750px',
            height: '50px',
        },

    }
    const navigate = useNavigate();

    const handleCardClick = (link) => {
        navigate(link)
    }
    return (
        <>

            <Grid pl={10} pr={10} pt={10} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                <Grid pb={5} item xl={12} md={12} xs={12}>
                    <Typography sx={styles.Header1} >
                        {firmData?.firmaadikisa ? firmData?.firmaadikisa : firmData?.firmaadi} Ürünleri
                    </Typography>
                </Grid>
                {firmData?.dk_firma_urunlers?.map((item) => (
                    <Grid key={item.id} mb={5} item xl={3} md={3} xs={3}>
                        <Card key={item.id} sx={styles.Card}>
                            <CardContent sx={styles.CardContent}>
                                <Box onClick={() => handleCardClick(`/PazarYeri/Urun/${item.id}`)}
                                    sx={{
                                        ':hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                >
                                    <img style={{
                                        width: '260px',
                                        height: '200px'
                                    }} src={`/images/urunler/${item.dk_firma_urunler_gorsels?.[0]?.gorsel}`} alt='yapiradar.com items digital catalogue' />
                                </Box>
                                <Box>
                                    <Typography
                                        onClick={() => handleCardClick(`/PazarYeri/Firma/${item.id}`)}
                                        sx={styles.cardHeader} mt={2}>
                                        {item.marka}
                                    </Typography>
                                    <Typography onClick={() => handleCardClick(`/PazarYeri/Urun/${item.id}`)} sx={styles.cardText} mt={2}>
                                        {item.urunadi}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid align='center' mt={5} mb={5} item xl={12} md={12} xs={12}>
                    <Button
                    sx={styles.ShowMoreBtn}>
                        <Typography>
                            Daha Fazla
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default FirmAllItems