import { Autocomplete, Paper, Button, FormControlLabel, FormGroup, Grid, Step, StepLabel, Stepper, TextField, Typography, FormControl, RadioGroup, Radio, FormLabel } from '@mui/material'
import React, { useRef, useState } from 'react'
import { getData, postData } from '../Assets/Scripts/dataAxios';
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import ReactImageFileToBase64 from "react-file-image-to-base64";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box } from '@mui/system';
import { ShowMessage } from '../Components/Common/Alerts';
import 'react-dropdown-tree-select/dist/styles.css'
import { useEffect } from 'react';
import { loadState } from '../Assets/Scripts/LocalStorage';
import { useNavigate } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LanguageIcon from '@mui/icons-material/Language';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../Redux/Slices/UserInfoSlices';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

function FirmaEkle() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch();
    const UserData = JSON.parse(loadState('UserInfo'));
    const navigate = useNavigate();
    useEffect(() => {
        if (UserData.dkFirmaid !== 0 && UserData.isAdmin !== true) {
            navigate(`/PazarYeri/Firma/${UserData.dkFirmaid}`)
        }
    }, [])

    const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
    const [firmaAdi, SetFirmaAdi] = useState(false);
    const [firmaKategori, SetFirmaKategori] = useState(false);
    const [firmaKisaAdi, SetFirmaKisaAdi] = useState(false);
    const [firmayetkili, SetFirmayetkili] = useState(false);
    const [firmaWeb, SetFirmaWeb] = useState(false);
    const [firmaMail, SetFirmaMail] = useState(false);
    const [firmaTelefon, SetFirmaTelefon] = useState(false);
    const [firmaHakkinda, SetFirmaHakkinda] = useState(false);
    const [firmaLogo, SetFirmaLogo] = useState(false);
    const [firmaBackgroundImage, SetFirmaBackgroundImage] = useState(false);
    const [facebookLink, SetFacebookLink] = useState('');
    const [twitterLink, SetTwitterLink] = useState('');
    const [youtubeLink, SetYoutubeLink] = useState('');
    const [linkedinLink, SetLinkedinLink] = useState('');
    const [instagramLink, SetInstagramLink] = useState('');

    const [stepsActive, SetStepsActive] = useState(0);
    const [uploadedImage, setUploadedImage] = useState(false);
    const [uploadedBackgroundImage, setUploadedBackgroundImage] = useState(false);
    const styles = {
        finalHeader1: {
            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": "18px", "lineHeight": "140%", marginTop: '2em'
        },
        finalText1: {
            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "16px", "lineHeight": "120%", marginTop: '1em'
        },
        textField: {
            marginTop: '2.5em',
            "background": "#FFFFFF",
            backgroundColor: 'transparent',
            width:  {xs:"90%"},
            [`& fieldset`]: {
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px"
            },
            "& label": {
                "fontFamily": "'Poppins'",
                "fontStyle": "normal",
                "fontWeight": "500",
                "fontSize": "14px",
                "lineHeight": "130%",
                "color": '#889AA4'
            }
        },
        textFieldAdresString: {
            marginTop: '1em',
            height: '100px',
            "background": "#FFFFFF",
            backgroundColor: 'transparent',
            [`& fieldset`]: {
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px"
            },
            "& label": {
                "fontFamily": "'Poppins'",
                "fontStyle": "normal",
                "fontWeight": "500",
                "fontSize": "14px",
                "lineHeight": "130%",
                "color": '#889AA4'
            }
        },
        textFieldMultiLine: {
            marginTop: '1em',
            "background": "#FFFFFF",
            width: {xs:"100%",md:'437px'}
        },
        SubmitButton: {
            marginTop: '2em',
            color: 'white',
            "padding": "15px 25px",
            "gap": "10px",
            "width": "337px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "background": "#FF6600",
                "boxShadow": "none",
            }
        },
        TextHeader: {
            marginTop: '1em',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "18px",
            "lineHeight": "130%"
        },
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "28px",
            "lineHeight": "140%"
        },
        Selects: {
            marginTop: '1em',
            width: '400px',
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
                color: '#333'
            },
            "& label": {
                marginTop: '0.5em',
                "fontFamily": "'Poppins'",
                "fontStyle": "normal",
                "fontWeight": "500",
                "fontSize": "14px",
                "lineHeight": "130%",
                "color": '#889AA4'
            }
        },
        btnPrevious: {
            display: stepsActive === 0 ? 'none' : 'block',
            marginRight: '0em',
            "boxSizing": "border-box",
            "width": "164px",
            "height": "63px",
            "background": "#F4F7F9",
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            color: '#303C43',

        },
        btnNext: {
            display: stepsActive === 4 ? 'none' : 'block',
            marginRight: '0em',
            "boxSizing": "border-box",
            "width": "164px",
            "height": "63px",
            "background": "#FF6600",
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                "background": "#FF6600",
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px",
                boxShadow: ''
            }
        },
        btnSave: {
            display: stepsActive === 4 ? 'block' : 'none',
            "boxSizing": "border-box",
            "width": "164px",
            "height": "63px",
            "background": "#FF6600",
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                "background": "#FF6600",
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px",
                boxShadow: ''
            }
        },
        HeaderImageWrapper: {
            maxWidth: 'fit-content',
            position: 'relative',
        },
        FirmLogoWrapper: {
            display: 'inline-block',
            position: 'absolute',
            bottom: '0%',
            left: '5%',
        },
        FirmName: {
            marginBottom: '0.5em',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "44px",
            "lineHeight": "140%",
            "display": "flex",
            "alignItems": "center",
            "color": "#FFFFFF",
        },
        InfoBarLogoWrapper: {
            display: 'inline-block',
            margin: '0 10px',
            color: '#faaa77'
        },
        InfoBarHeader: {
            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "14px", "lineHeight": "28px", "color": "#303C43", "flex": "none", "order": "1", "flexGrow": "0"
        },
        InfoBarText: {
            "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "22px", "color": "#303C43", "opacity": "0.75"
        },
    }
    const handleSubmit = async () => {
        setSubmitButtonDisable(false);
        var sosyalMedyaReq = [];
        if (facebookLink !== '') {
            sosyalMedyaReq.push(
                {
                    "id": 0,
                    "aktif": 0,
                    "firmaid": 0,
                    "sid": 1,
                    "hesapadi": facebookLink,
                    "silindi": false
                }
            )
        }
        if (youtubeLink !== '') {
            sosyalMedyaReq.push(
                {
                    "id": 0,
                    "aktif": 0,
                    "firmaid": 0,
                    "sid": 2,
                    "hesapadi": youtubeLink,
                    "silindi": false
                }
            )
        }
        if (instagramLink !== '') {
            sosyalMedyaReq.push(
                {
                    "id": 0,
                    "aktif": 0,
                    "firmaid": 0,
                    "sid": 3,
                    "hesapadi": instagramLink,
                    "silindi": false
                }
            )
        }
        if (linkedinLink !== '') {
            sosyalMedyaReq.push(
                {
                    "id": 0,
                    "aktif": 0,
                    "firmaid": 0,
                    "sid": 4,
                    "hesapadi": linkedinLink,
                    "silindi": false
                }
            )
        }
        if (twitterLink !== '') {
            sosyalMedyaReq.push(
                {
                    "id": 0,
                    "aktif": 0,
                    "firmaid": 0,
                    "sid": 5,
                    "hesapadi": twitterLink,
                    "silindi": false
                }
            )
        }



        const reqBody = {
            "id": 0,
            "aktif": 0,
            "firmaadi": firmaAdi,
            "yetkili": firmayetkili,
            "web": firmaWeb,
            "eposta": firmaMail,
            "telefon": firmaTelefon,
            "hakkinda": firmaHakkinda,
            "uyeid": null,
            'firmaadikisa': firmaKisaAdi,
            "logos": [
                {
                    "id": 0,
                    "aktif": 1,
                    "firmaid": 0,
                    "base64": firmaLogo
                }
            ],
            "firmaGorsels": [
                {
                    "id": 0,
                    "aktif": 1,
                    "firmaid": 0,
                    "base64": firmaBackgroundImage
                }
            ],
            "adres": {
                "id": 0,
                "aktif": 0,
                "firmaid": 0,
                "tanim": "",
                "tipi": firmaAdresTipi,
                "adres": firmaAdresString,
                "ulke": null,
                "sehir": firmaSehir,
                "ilce": firmaIlce,
                "mahalle": firmaMahalle
            },
            "socialMedias": sosyalMedyaReq
        }
        const res = await postData('DijitalKatalog/DKFirmalarInsertorUpdate', reqBody)
        if (res.data.isSucceeded) {
            setFirmaIdNew(res.data.value.id);
            if (kaliteBelgeleri.length > 0) {
                if (kaliteBelgeleri.length > 1) {
                    await Promise.all(kaliteBelgeleri.map(async (file) => {
                        const contents = await handleUploadfile(res.data.value.id, file, 0,)
                    }));
                } else {
                    const contents = await handleUploadfile(res.data.value.id, kaliteBelgeleri[0], 0)
                }
            }

            if (garantiBelgeleri.length > 0) {
                if (garantiBelgeleri.length > 1) {
                    await Promise.all(garantiBelgeleri.map(async (file) => {
                        const contents = await handleUploadfile(res.data.value.id, file, 1)
                    }));
                } else {
                    const contents = await handleUploadfile(res.data.value.id, garantiBelgeleri[0], 1)
                }
            }

            if (katalogBelgeleri.length > 0) {
                if (katalogBelgeleri.length > 1) {
                    await Promise.all(katalogBelgeleri.map(async (file) => {
                        const contents = await handleUploadfile(res.data.value.id, file, 2)
                    }));
                } else {
                    const contents = await handleUploadfile(res.data.value.id, katalogBelgeleri[0], 2)
                }
            }

            if (sertifikaBelgeleri.length > 0) {
                if (sertifikaBelgeleri.length > 1) {
                    await Promise.all(sertifikaBelgeleri.map(async (file) => {
                        const contents = await handleUploadfile(res.data.value.id, file, 3)
                    }));
                } else {
                    const contents = await handleUploadfile(res.data.value.id, sertifikaBelgeleri[0], 3)
                }
            }
            ShowMessage('success', 'Firma Kayıt edildi. Yönlendiriliyorsunuz.');
            const userDataRes = await getData('Auth/GetCurrectUser')
                .then(result => {
                    dispatch(setUserInfo(result.data.value));
                }
                ).then(() => {
                    navigate(`/PazarYeri/Firma/${res.data.value.seourl}`);
                    setSubmitButtonDisable(false);
                })

        } else {
            ShowMessage('error', 'Firma kayit ederken bir hata ile karsilasildi. Yoneticinize basvurunuz.');
            setSubmitButtonDisable(false);
        }
    }

    const handleSelectFirmaLogo = files => {
        setUploadedImage(files[0].base64_file)
        SetFirmaLogo(files[0].base64_file.split(',')[1]);
    };

    const handleSelectFirmaCoverImage = files => {
        setUploadedBackgroundImage(files[0].base64_file)
        SetFirmaBackgroundImage(files[0].base64_file.split(',')[1])
    };

    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('DijitalKatalog/KategoriList');
            FixCategoryData(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, [])

    const FixCategoryData = (param) => {
        const fixedData = [];
        param.forEach((item, index) => {
            item?.children?.forEach((item2, index2) => {
                fixedData.push({
                    key: index.toString() + index2.toString(),
                    name: item2.adi,
                    id: item2.id,
                    parent: item.adi,
                    type: 'SubCategory'
                })
                item2?.children?.forEach((item3, index3) => {
                    fixedData.push({
                        key: index.toString() + index2.toString() + index3.toString(),
                        name: item3.adi,
                        id: item3.id,
                        parent: item.adi,
                        type: 'child'
                    })

                })
            })
        })
        SetCategories(fixedData);
    }


    var selectedCategories = [];
    const handleCategoryChange = (param) => {
        if (param.length > 1) {
            const dummyCities = [];
            param.forEach((item) => {
                dummyCities.push(item.id)
            })
            selectedCategories.push(dummyCities);

        } else if (param.length === 0) {
            selectedCategories.push([]);
        }
        else {
            selectedCategories.push([param?.[0]?.id])
        }
    }
    const [firmaAdresTipi, SetFirmaAdresTipi] = useState(null);
    const [firmaSehir, SetFirmaSehir] = useState(34);
    const [firmaIlce, SetFirmaIlce] = useState(1183);
    const [firmaMahalle, SetFirmaMahalle] = useState(null);
    const [firmaAdresString, SetFirmaAdresString] = useState(null);
    const [adrestipiList, SetadrestipiList] = useState([]);


    const [cities, SetCities] = useState([]);
    const [ilceler, SetIlceler] = useState([]);
    const [mahalleler, SetMahalleler] = useState([]);

    useEffect(() => {
        const GetCitiesData = async () => {
            const res = await postData('DijitalKatalog/SehirList', {
                "boldeIds": [
                ],
                "ulkeIds": [
                    216
                ],
                "ids": [
                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetCities(tempval);
        }

        GetCitiesData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        const GetADresTipiData = async () => {
            const res = await postData('DijitalKatalog/AdresTipi', {});
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.adi,
                        id: item.id
                    }
                )
            })
            SetadrestipiList(tempval);
        }
        GetADresTipiData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        const GetIlcelerData = async () => {
            const res = await postData('DijitalKatalog/IlceList', {
                "sehirIds":
                    [firmaSehir]
                ,
                "ids": [

                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetIlceler(tempval);
        }

        GetIlcelerData()
            .catch(console.error);
    }, [firmaSehir])

    useEffect(() => {
        const GetMahalleData = async () => {
            const res = await postData('DijitalKatalog/MahalleList', {
                "ilceIds": [
                    firmaIlce
                ],
                "ids": [
                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetMahalleler(tempval);
        }

        GetMahalleData()
            .catch(console.error);
    }, [firmaIlce])

    const handleAdresTipiChange = (event) => {
        SetFirmaAdresTipi(parseInt(event.target.value));
    }

    const handleCityChange = (param) => {
        SetFirmaSehir(param.id);
    }
    const handleIlcelerChange = (param) => {
        SetFirmaIlce(param.id)
    }

    const handleMahallelerChange = (param) => {
        SetFirmaMahalle(param.id)
    }


    const steps = [
        'Firma Bilgileri',
        'Firma İletişim Bilgileri',
        'Logo ve Görsel',
        'Son Bilgiler',
    ];




    const validateNext = () => {
        if (stepsActive === 0) {
            SetStepsActive(1);
        } else if (stepsActive === 1) {
            SetStepsActive(2);
        } else if (stepsActive === 2) {
            SetStepsActive(3);
        } else if (stepsActive === 3) {
            SetStepsActive(4);
        } else if (stepsActive === 4) {

        } else {
            ShowMessage('error', 'Lütfen Alanları Eksiksiz Doldurunuz.')
        }
    }

    const handlePrevious = () => {
        if (stepsActive === 0) {
            SetStepsActive(0);
        } else if (stepsActive === 1) {
            SetStepsActive(0);
        } else if (stepsActive === 2) {
            SetStepsActive(1);
        } else if (stepsActive === 3) {
            SetStepsActive(2);
        } else if (stepsActive === 4) {
            SetStepsActive(3);
        }
    }

    const CustomisedButton = ({ triggerInput }) => {
        return (
            <Button
                onClick={triggerInput}
                sx={{ "padding": "15px 25px", "gap": "10px", "width": {xs:"130px",md:"154px"}, "height": "50px", "background": "#F4F7F9", "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)", "borderRadius": "14px" , fontSize:"5px"}}
                startIcon={<BorderColorIcon />}
            >
                <Typography sx={{
                    textTransform: 'none', "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "14px", "lineHeight": "120%", "color": "#303C43", "flex": "none", "order": "1", "flexGrow": "0"
                }}>
                    Logo Yükle
                </Typography>
            </Button>
        )
    }

    const CustomisedButton2 = ({ triggerInput }) => {
        return (
            <Button
                onClick={triggerInput}
                sx={{ "padding": "15px 25px", "gap": "10px", "width": {xs:"175px",md:"190px"}, "height": "50px", "background": "#F4F7F9", "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)", "borderRadius": "14px" }}
                startIcon={<BorderColorIcon />}
            >
                <Typography sx={{
                    textTransform: 'none', "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "14px", "lineHeight": "120%", "color": "#303C43", "flex": "none", "order": "1", "flexGrow": "0"
                }}>
                    Cover foto yükle
                </Typography>
            </Button>
        )
    }


    const [file, setFile] = useState();

    const kaliteRef = useRef(null);
    const [kaliteBelgeleri, setkaliteBelgeleri] = useState([]);
    const handleOpenKaliteBelgesei = (e) => {
        kaliteRef.current.click();
    };
    const handleSelectKaliteBelgesei = (e) => {
        if (e.target.files) {
            setkaliteBelgeleri(current => [...current, e.target.files[0]])
        }
    };

    const garantiRef = useRef(null);
    const [garantiBelgeleri, setgarantiBelgeleri] = useState([]);
    const handleOpenGarantiBelgesei = (e) => {
        garantiRef.current.click();
    };
    const handleSelectGarantiBelgesei = (e) => {
        if (e.target.files) {
            setgarantiBelgeleri(current => [...current, e.target.files[0]])
        }
    };


    const katalogRef = useRef(null);
    const [katalogBelgeleri, setkatalog] = useState([]);
    const handleOpenKatalogBelgesei = (e) => {
        katalogRef.current.click();
    };
    const handleSelectKatalogBelgesei = (e) => {
        if (e.target.files) {
            setkatalog(current => [...current, e.target.files[0]])
        }
    };
    const [firmaIdNew, setFirmaIdNew] = useState(0);
    const SertifikaRef = useRef(null);
    const [sertifikaBelgeleri, setsertifikaBelgeleri] = useState([]);
    const handleOpenSertifikaBelgesei = (e) => {
        SertifikaRef.current.click();
    };
    const handleSelectSertifikaBelgesei = (e) => {
        if (e.target.files) {
            setsertifikaBelgeleri(current => [...current, e.target.files[0]])
        }
    };

    const handleUploadfile = async (firmaId, val, type) => {
        if (!kaliteBelgeleri) {
            return;
        }
        const formdata = new FormData();
        formdata.append('id', 0);
        formdata.append('aktif', 1);
        formdata.append('file', val);
        formdata.append('type', type);
        formdata.append('isDelete', false);
        formdata.append('firmaid', firmaId);

        const filepostResponse = await postData('DijitalKatalog/FirmaDocumentInsertorUpdate', formdata)
        if (filepostResponse) {
            return filepostResponse
        }
    };


    const [test, setTest] = useState(false);
    return (
        <>
            <Navbar />
            <Grid
                align='center'
                position='relative'
                mb={10} container spacing={0} >
                <Grid
                    pt={15}
                    sx={{ backgroundColor: '#F4F7F9' }}
                    item xl={12} md={12} xs={12}>
                    <Grid container pb={12} spacing={0}>
                        <Grid align='left' p={5} item xl={12} xs={12} md={12}>
                            <Typography
                                sx={{
                                    "width":  {xs:"100%",md:"713px"},
                                    "height": "48px",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "600",
                                    "fontSize": "40px",
                                    "lineHeight": "120%",
                                    "color": "#000000"
                                }}
                            >
                                Firma Ekle
                            </Typography>
                        </Grid>
                        <Grid item pl={5} pr={5} xl={9} md={9} xs={12}>
                            <Box sx={{ width: '90%' }}>
                                <Stepper sx={{flexDirection: {xs:"column ",md:"row"} }} activeStep={stepsActive}>
                                    {steps.map((label) => (
                                        <Step
                                            sx={{
                                                '& .MuiStepLabel-root .Mui-completed': {
                                                    color: '#8FBFFA', // circle color (COMPLETED)
                                                },
                                                '& .MuiStepLabel-root .Mui-completed':
                                                {
                                                    color: '#BCC7CE', // Just text label (COMPLETED)
                                                },
                                                '& .MuiStepLabel-root .Mui-active': {
                                                    color: '#8FBFFA', // circle color (ACTIVE)
                                                },
                                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                {
                                                    color: '#FFFFFF', // Just text label (ACTIVE)
                                                },
                                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                {
                                                    color: '#FFFFFF', // Just text label (ACTIVE)
                                                },
                                            }}
                                            key={label}>
                                            <Box sx={{
                                                background: "#3c6780",
                                                filter: "drop-shadow(0px 4px 4px gray)",
                                                border: '1px solid #BCC7CE',
                                                borderRadius: '100px',
                                                borderColor: '#BCC7CE',
                                                height: '49px',
                                                width:{xs: '35vh', md:'100%'},
                                                display: 'inline-flex',
                                                color: 'white',
                                                padding: '0px 5px'
                                            }}>
                                                <StepLabel>{label}</StepLabel>
                                            </Box>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    sx={{ width:'100%', marginTop: 5 }}
                >
                    <Grid
                        sx={{ display: stepsActive === 0 ? 'block' : 'none' }}
                        item xl={12} md={12} xs={12} mt={0}  p={5}
                    >
                        <Grid container spacing={0}>
                            <Grid align='left' item xl={12} md={12} xs={12}>
                                <Typography sx={{
                                    "width": {xs:"100%",md:"713px"}, "height": "26px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "22px", "lineHeight": "120%", "color": "rgb(255 255 255)"
                                }}>
                                    Firma bilgileri
                                </Typography>
                                <Typography
                                    
                                    sx={{
                                        "width":  {xs:"100%",md:"713px"}, "height": "25px",paddingBottom:"10px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "130%", "color": "#889AA4"
                                    }}>
                                    Katalogunuzun eksiksiz görünmesi için bütün bilgileri doldurunuz.
                                </Typography>
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFirmaAdi(e.target.value)}
                                    label='Firma Adı *'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFirmaKisaAdi(e.target.value)}
                                    label='Firma Kısa Adı *'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <Autocomplete
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    groupBy={(option) => option.parent}
                                    onChange={(e, v) => handleCategoryChange(v)}
                                    limitTags={4}
                                    multiple
                                    renderOption={(props, option) => option.type == 'SubCategory' ? (
                                        <li {...props}>
                                            <Box
                                                sx={{
                                                    fontWeight: '700',
                                                }}
                                            >
                                                {option.name}
                                            </Box>
                                        </li>) : (<li key={option.id} {...props}> {option.name} </li>)
                                    }
                                    id="disabled-options-demo"
                                    options={categories}
                                    sx={styles.textField}
                                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="İlgili Kategoriyi Seçiniz *" />}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFirmayetkili(e.target.value)}
                                    label='Firma Yetkilisi '
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFirmaMail(e.target.value)}
                                    label='Firma E-mail *'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFirmaTelefon(e.target.value)}
                                    label='Telefon *'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFirmaWeb(e.target.value)}
                                    label='Firma Web Adresi *'
                                />
                            </Grid>
                            <Grid item mt={2} mb={6} xl={12} md={12} xs={12}>
                                <ReactQuill
                                    placeholder="Firmanız Hakkında Kısmını Kendi Stiliniz İle Buraya Ekleyebilirsiniz"
                                    theme='snow'
                                    value={firmaHakkinda}
                                    onChange={SetFirmaHakkinda}
                                    style={{
                                        widht: '90%',
                                        height: '250px'
                                    }}
                                />
                            </Grid>

                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetFacebookLink(e.target.value)}
                                    label='Facebook URL'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetTwitterLink(e.target.value)}
                                    label='Twitter URL'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetInstagramLink(e.target.value)}
                                    label='Instagram URL'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetYoutubeLink(e.target.value)}
                                    label='Youtube URL'
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textField}
                                    onChange={(e) => SetLinkedinLink(e.target.value)}
                                    label='Linkedin URL'
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid p={6} sx={{ display: stepsActive === 1 ? 'block' : 'none' }} item xl={12} md={12} xs={12}>
                        <Grid container spacing={0}>
                            <Grid align='left' item  xl={12} md={12} xs={12}>
                                <Typography sx={{
                                    "width": {xs:"100%",md:"713px"}, "height": "26px",marginTop:"10px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "22px", "lineHeight": "120%", "color": "#000000"
                                }}>
                                    Firma iletişim bilgileri
                                </Typography>
                                <Typography
                                   
                                    sx={{
                                        "width":{xs:"100%",md:"713px"}, "height": "18px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "130%", "color": "#889AA4"
                                    }}>
                                    Katalogunuzun eksiksiz görünmesi için bütün bilgileri doldurunuz.
                                </Typography>
                            </Grid>

                            <Grid mb={1} mt={3} item xl={12} md={12} xs={12}>
                                <FormControl sx={{
                                    display: 'inline-block',
                                    float: 'left',
                                    width: {xs:"100%"},
                                    display:{xs:"wrap",md:"nowrap"}
                                    
                                }}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="11"
                                        onChange={handleAdresTipiChange}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel sx={{
                                            "boxSizing": "border-box",
                                            "width": {xs:"100%",md:"165px"},
                                            "height": "41px",
                                            "background": "#EDF2F6",
                                            "borderRadius": "12px",
                                            justifyContent: 'center',
                                            

                                        }} value="11" control={<Radio />} label={
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "color": "#303C43"
                                                }}>
                                                Merkez
                                            </Typography>
                                        } />

                                        <FormControlLabel sx={{
                                            "boxSizing": "border-box",
                                            "width": {xs:"100%",md:"165px"},
                                            "height": "41px",
                                            "background": "#EDF2F6",
                                            "borderRadius": "12px",
                                            justifyContent: 'center',

                                        }} value="12" control={<Radio />} label={
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "color": "#303C43"
                                                }}>
                                                Şube
                                            </Typography>
                                        } />

                                        <FormControlLabel sx={{
                                            "boxSizing": "border-box",
                                            "width":{xs:"100%",md:"165px"},
                                            "height": "41px",
                                            "background": "#EDF2F6",
                                            "borderRadius": "12px",
                                            justifyContent: 'center',

                                        }} value="13" control={<Radio />} label={
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "color": "#303C43",
                                                    
                                                }}>
                                                Fabrika
                                            </Typography>
                                        } />

                                        <FormControlLabel sx={{
                                            "boxSizing": "border-box",
                                            "width": {xs:"100%",md:"165px"},
                                            "height": "41px",
                                            "background": "#EDF2F6",
                                            "borderRadius": "12px",
                                            justifyContent: 'center',

                                        }} value="14" control={<Radio />} label={
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "color": "#303C43"
                                                }}>
                                                Depo
                                            </Typography>
                                        } />

                                        <FormControlLabel sx={{
                                            "boxSizing": "border-box",
                                            "width": {xs:"100%",md:"165px"},
                                            "height": "41px",
                                            "background": "#EDF2F6",
                                            "borderRadius": "12px",
                                            justifyContent: 'center',

                                        }} value="15" control={<Radio />} label={
                                            <Typography
                                                sx={{
                                                    display: 'inline',
                                                    "fontFamily": "'Poppins'",
                                                    "fontStyle": "normal",
                                                    "fontWeight": "500",
                                                    "fontSize": "14px",
                                                    "color": "#303C43"
                                                }}>
                                                Showroom
                                            </Typography>
                                        } />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid align='left' item xl={4} md={4} xs={12}>
                                <Autocomplete
                                    onChange={(e, v) => handleCityChange(v)}
                                    id="disabled-options-demo"
                                    options={cities}
                                    limitTags={2}
                                    sx={styles.textField}
                                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="Şehir Seçiniz*" />}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <Autocomplete
                                    onChange={(e, v) => handleIlcelerChange(v)}
                                    id="disabled-options-demo"
                                    options={ilceler}
                                    limitTags={2}
                                    sx={styles.textField}
                                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="İlçe Seçiniz*" />}
                                />
                            </Grid>
                            <Grid item xl={4} md={4} xs={12}>
                                <Autocomplete
                                    onChange={(e, v) => handleMahallelerChange(v)}
                                    id="disabled-options-demo"
                                    options={mahalleler}
                                    limitTags={2}
                                    sx={styles.textField}
                                    renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} label="Mahalle Seçiniz*" />}
                                />
                            </Grid>
                            <Grid  item xl={12} md={12} xs={12}>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    sx={styles.textFieldAdresString}
                                    onChange={(e) => SetFirmaAdresString(e.target.value)}
                                    label='Açık Adres (Cadde/Sokak/Numara)*'
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid
                        sx={{ display: stepsActive === 2 ? 'block' : 'none', maxWidth: "100% !important"}} item xl={12} md={12} xs={9} mt={3} 
                    >
                        <Grid  p={5} container spacing={0}>
                            <Grid align='left' item mb={5} xl={12} md={12} xs={12}>
                                <Typography sx={{
                                    "width":"95%" , "height": "26px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "22px", "lineHeight": "120%", "color": "#000000"
                                }}>
                                    Ürün Belgeleri
                                </Typography>
                                <Typography
                                    mt={1}
                                    sx={{
                                        "width":"95%", "height": "18px", "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "130%", "color": "#889AA4"
                                    }}>
                                    Ürününüzün eksiksiz görünmesi için bütün bilgileri doldurunuz.
                                </Typography>
                            </Grid>
                            <Grid pr={5} pb={5} item xl={6} md={6} xs={12}>
                                <input style={{ display: 'none' }} type="file" ref={kaliteRef} onChange={handleSelectKaliteBelgesei} />
                                <Typography sx={{
                                    "width":{xs:"100%", md:"713px"} ,
                                    "height": "26px",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "600",
                                    "fontSize": "18px",
                                    "lineHeight": "120%",
                                    "color": "#000000"
                                }}>
                                    Kalite Belgeleri
                                </Typography>

                                <Box mt={3} mb={3}>
                                    <Button
                                        onClick={handleOpenKaliteBelgesei}
                                        sx={{
                                            margin:"10px",
                                            "padding": "15px 25px",
                                            "width":"200px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belge Yükle
                                    </Button>
                                    <Button
                                        onClick={() => setkaliteBelgeleri([])}
                                        sx={{
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "width":"200px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belgeleri sil
                                    </Button>
                                </Box>

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dosya Adı</TableCell>
                                                <TableCell align="right">Dosya Tipi </TableCell>
                                                <TableCell align="right">Dosya Boyutu</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {kaliteBelgeleri.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.type}</TableCell>
                                                    <TableCell align="right">{row.size}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                            <Grid pr={5} pb={5} item xl={6} md={6} xs={12}>
                                <input style={{ display: 'none' }} type="file" ref={garantiRef} onChange={handleSelectGarantiBelgesei} />
                                <Typography sx={{
                                    "width":{xs:"100%", md:"713px"} ,
                                    "height": "26px",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "600",
                                    "fontSize": "18px",
                                    "lineHeight": "120%",
                                    "color": "#000000"
                                }}>
                                    Garanti Belgeleri
                                </Typography>

                                <Box mt={3} mb={3}>
                                    <Button
                                        onClick={handleOpenGarantiBelgesei}
                                        sx={{
                                            margin:"10px",
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belge Yükle
                                    </Button>
                                    <Button
                                        onClick={() => setgarantiBelgeleri([])}
                                        sx={{
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belgeleri sil
                                    </Button>
                                </Box>

                                <TableContainer component={Paper}>
                                    <Table sx={{minWidth: "100%"  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dosya Adı</TableCell>
                                                <TableCell align="right">Dosya Tipi </TableCell>
                                                <TableCell align="right">Dosya Boyutu</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {garantiBelgeleri.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.type}</TableCell>
                                                    <TableCell align="right">{row.size}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid pr={5} pb={5} item xl={6} md={6} xs={12}>
                                <input style={{ display: 'none' }} type="file" ref={katalogRef} onChange={handleSelectKatalogBelgesei} />
                                <Typography sx={{
                                    "width": {xs:"100%", md:"713px"},
                                    "height": "26px",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "600",
                                    "fontSize": "18px",
                                    "lineHeight": "120%",
                                    "color": "#000000"
                                }}>
                                    Kataloglar
                                </Typography>

                                <Box mt={3} mb={3}>
                                    <Button
                                        onClick={handleOpenKatalogBelgesei}
                                        sx={{
                                            margin:"10px",
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belge Yükle
                                    </Button>
                                    <Button
                                        onClick={() => setkatalog([])}
                                        sx={{
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belgeleri sil
                                    </Button>
                                </Box>

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: "100%"  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dosya Adı</TableCell>
                                                <TableCell align="right">Dosya Tipi </TableCell>
                                                <TableCell align="right">Dosya Boyutu</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {katalogBelgeleri.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.type}</TableCell>
                                                    <TableCell align="right">{row.size}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid pr={5} pb={5} item xl={6} md={6} xs={12}>
                                <input style={{ display: 'none' }} type="file" ref={SertifikaRef} onChange={handleSelectSertifikaBelgesei} />
                                <Typography sx={{
                                    "width": {xs:"100%", md:"713px"},
                                    "height": "26px",
                                    "fontFamily": "'Poppins'",
                                    "fontStyle": "normal",
                                    "fontWeight": "600",
                                    "fontSize": "18px",
                                    "lineHeight": "120%",
                                    "color": "#000000"
                                }}>
                                    Sertifikalar
                                </Typography>

                                <Box mt={3} mb={3}>
                                    <Button
                                        onClick={handleOpenSertifikaBelgesei}
                                        sx={{
                                            margin:"10px",
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belge Yükle
                                    </Button>

                                    <Button
                                        onClick={() => setsertifikaBelgeleri([])}
                                        sx={{
                                            "padding": "15px 25px",
                                            "gap": "10px",
                                            "height": "50px",
                                            "background": "#F4F7F9",
                                            "boxShadow": "0px 9px 22px rgba(20, 20, 20, 0.25)",
                                            "borderRadius": "14px",
                                            ":hover": {
                                                "boxShadow": "none",
                                            }
                                        }}
                                        startIcon={<BorderColorIcon />} >
                                        Belgeleri sil
                                    </Button>
                                </Box>

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: "100%"  }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dosya Adı</TableCell>
                                                <TableCell align="right">Dosya Tipi </TableCell>
                                                <TableCell align="right">Dosya Boyutu</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sertifikaBelgeleri.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.type}</TableCell>
                                                    <TableCell align="right">{row.size}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid sx={{ display: stepsActive === 3 ? 'block' : 'none', paddingLeft:{xs:"10px",md:"40px"},paddingRight:{xs:"10px",md:"40px"}  }} item xl={12} md={12} xs={12} mt={3} >
                        <Grid container spacing={0}>
                            <Grid align='left' item xl={12} md={12} xs={12}>
                                <Typography sx={{
                                    "width": {xs:"100%", md:"713px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize": "22px", "lineHeight": "120%", "color": "#000000"
                                }}>
                                    Firma logo ve görsel yükleme
                                </Typography>
                                <Typography
                                    mt={1}
                                    sx={{
                                        "width": {xs:"100%", md:"713px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "400", "fontSize": "14px", "lineHeight": "130%", "color": "#889AA4"
                                    }}>
                                    Katalogunuzun eksiksiz görünmesi için bütün bilgileri doldurunuz.
                                </Typography>
                            </Grid>
                            <Grid align='center' marginTop={{xs:'40%',md:'24px'}} item xs={12} md={12} xl={12}>
                                <Box sx={styles.HeaderImageWrapper}>
                                    <img style={{
                                        width: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'cover'
                                    }} src={!uploadedBackgroundImage ? require(`../Assets/Images/UploadBackgroundFirmPreview.png`) : uploadedBackgroundImage} alt='yapiradar Firms' />
                                    <Box sx={styles.FirmLogoWrapper}>
                                        <img style={{ maxHeight: '100%', "borderRadius": "30px 30px 0px 0px", }}
                                            src={!uploadedImage ? require(`../Assets/Images/UploadLogoFirmPreview.webp`) : uploadedImage} alt='Firma Logo' />
                                    </Box>
                                    <Box sx={{ position: 'absolute', bottom: '15%', left: {xs:'5%',md:'25%'}, maxWidth: '800px' }}>
                                        <ReactImageFileToBase64
                                            preferredButtonText="Logo yükle"
                                            CustomisedButton={CustomisedButton}
                                            onCompleted={handleSelectFirmaLogo} />
                                    </Box>

                                    <Box sx={{ position: 'absolute', bottom: '15%', right: {xs:'0',md:'5%'}, maxWidth: '800px' }}>
                                        <ReactImageFileToBase64
                                            preferredButtonText="Cover foto yükle"
                                            CustomisedButton={CustomisedButton2}
                                            onCompleted={handleSelectFirmaCoverImage} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xl={6} md={6} xs={6}>
                                <Typography
                                    sx={{
                                        "width":{xs:"70%", md:"313px"} , "height": {xs:"auto",md:"36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "14px", "lineHeight": "130%", "color": "#889AA4"
                                    }}
                                >
                                    175 x 175px boyutlarında en fazla 2mb yüksekliğinde  jpg, png imaj yüklemelisiniz.
                                </Typography>
                            </Grid>
                            <Grid item xl={6} md={6} xs={6}>
                                <Typography
                                    sx={{
                                        "width": {xs:"100%", md:"313px"}, "height": {xs:"auto",md:"36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "14px", "lineHeight": "130%", "color": "#889AA4"
                                    }}
                                >
                                    1500x1000px boyutlarında en fazla 2mb yüksekliğinde  jpg, png imaj yüklemelisiniz.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        align='left'
                        pl={20}
                        pr={20}
                        sx={{ display: stepsActive === 4 ? 'block' : 'none' }}
                        item xl={12} md={12} xs={12} mt={0}>
                        <Grid container mt={10} mb={20} spacing={0} >
                            <Grid align='center' item xs={12} md={12} xl={12}>
                                <Box sx={styles.HeaderImageWrapper}>
                                    <img style={{
                                        width: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'cover'
                                    }} src={uploadedBackgroundImage} alt='yapiradar Firms' />
                                    <Box sx={styles.FirmLogoWrapper}>
                                        <img style={{ maxHeight: '175px', "borderRadius": "30px 30px 0px 0px", }}
                                            src={uploadedImage} alt='Firma Logo' />
                                    </Box>
                                    <Box sx={{ position: 'absolute', bottom: '0', left: '30%', maxWidth: '800px' }}>
                                        <Typography sx={styles.FirmName}>
                                            {firmaKisaAdi}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid display="flex"
                                justifyContent="center"
                                alignItems="center" mt={3} pl={12} item xl={12} md={12} xs={12}>
                                <Grid container maxWidth={1280} spacing={0}>
                                    <Grid display='flex' item xl={3} md={3} xs={3}>
                                        <Box sx={styles.InfoBarLogoWrapper}>
                                            <LanguageIcon />
                                        </Box>
                                        <Box>
                                            <Typography sx={styles.InfoBarHeader}>
                                                Web
                                            </Typography>
                                            <a rel="noreferrer" href={firmaWeb} target='_blank'>
                                                <Typography sx={styles.InfoBarText}>
                                                    {firmaWeb}
                                                </Typography>
                                            </a>
                                        </Box>
                                    </Grid>
                                    <Grid display='flex' item xl={3} md={3} xs={3}>
                                        <Box sx={styles.InfoBarLogoWrapper}>
                                            <SupportAgentIcon />
                                        </Box>
                                        <Box>
                                            <Typography sx={styles.InfoBarHeader}>
                                                Müşteri Hizmetleri
                                            </Typography>
                                            <Typography sx={styles.InfoBarText}>
                                                {firmaTelefon}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid display='flex' item xl={3} md={3} xs={3}>
                                        <Box sx={styles.InfoBarLogoWrapper}>
                                            <LocationOnIcon />
                                        </Box>
                                        <Box>
                                            <Typography sx={styles.InfoBarHeader}>
                                                Adres
                                            </Typography>
                                            <Typography sx={styles.InfoBarText}>
                                                {firmaAdresString}
                                            </Typography>
                                            <Typography sx={styles.InfoBarText}>
                                                {firmaMahalle} {firmaIlce} {firmaSehir}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    <Grid mt={3} item mb={5} xl={12} md={12} xs={12}>
                                        <Typography sx={{
                                            "height": "42px",
                                            "fontFamily": "'Poppins'",
                                            "fontStyle": "normal",
                                            "fontWeight": "600",
                                            "fontSize": "30px",
                                            "lineHeight": "140%",
                                            "display": "flex",
                                            "alignItems": "center",
                                            "color": "#889AA4"
                                        }}>
                                            Hakkında
                                        </Typography>
                                    </Grid>
                                    <Grid item xl={12} md={12} xs={12}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: firmaHakkinda }}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Grid sx={{ display: 'inline-flex', justifyContent: 'center' }} mt={4} item xs={12} md={12} xl={12}>
                    <Button
                        sx={styles.btnPrevious}
                        onClick={handlePrevious}
                    >
                        <Typography
                            sx={{
                                textTransform: 'none',
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "600",
                                "fontSize": "18px",
                                "lineHeight": "120%",
                                "textAlign": "center",

                            }}
                        >
                            Geri Dön
                        </Typography>
                    </Button>

                    <Button
                        sx={styles.btnNext}
                        onClick={validateNext}
                    >
                        <Typography
                            sx={{
                                textTransform: 'none',
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "600",
                                "fontSize": "18px",
                                "lineHeight": "120%",
                                "textAlign": "center"
                            }}
                        >
                            İleri
                        </Typography>
                    </Button>
                    <Button
                        disable={submitButtonDisable}
                        sx={styles.btnSave}
                        onClick={handleSubmit}
                    >
                        <Typography>
                            Kaydet
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default FirmaEkle