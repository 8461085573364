import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import carImage from '../../Assets/Images/Car2.webp'
function SahaDanismanligi() {
  const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
  const styles = {
    Header1: {
      "width": "100%",
      "height": "auto",
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "600",
      "fontSize": { xs: "20px", md: "50px"},
      "lineHeight": "130%",
      "color": "#000000"
    },
    Header2: {
      "width": "100%",
      "height": "auto",
      "left": "126px",
      "top": "6739px",
      "fontFamily": "'Poppins'",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": { xs: "16px", md: "18px"},
      "lineHeight": "170%",
      "color": "#303C43"
    },
    Button: {
      textTransform: "none",
      "display": "flex",
      "flexDirection": "row",
      "justifyContent": "center",
      "alignItems": "center",
      "padding": "15px 25px",
      "gap": "10px",
      "position": "absolute",
      "width": "170px",
      "height": "50px",
      "background": "#FF6600",
      "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
      "borderRadius": "14px",
      color: 'white',
      ':hover': {
        bgcolor: '#FF6600',
        boxShadow: 'none',
      }
    },
    HeaderTop :{
      display: {xs:"flex"}, 
      flexDirection: {xs:"row-reverse"},
      backgroundColor:'white'
  },
  }

  return (
    <>
      <Grid pt={{xs:1, md:5}} container spacing={0} sx={styles.HeaderTop}   >
       

        <Grid pl={{xs:5, md:2.5}} pr={5} mt={{xs:3, md:10}}  sx={{backgroundColor: 'white' }} item xl={6} md={6} xs={12}>
          <Typography mb={2} sx={styles.Header1}>
           {translate.FirmayaOzelSahaDanismanligi}
          </Typography>
          <Typography mb={5} sx={styles.Header2}>
           {translate.InsaatSektorundeFaaliyetGosterenFirmalarin}
          </Typography>
        </Grid>
        <Grid item xl={6} md={6} xs={12}>
          <img width='100%' src={carImage} alt='yapiradar.com' />
        </Grid>


      </Grid>
    </>
  )
}

export default SahaDanismanligi


