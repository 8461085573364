import { Button, Link, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Frame from '../Assets/Images/Login_Frame.webp'
import YapiRadarIcon from '../Assets/Icons/YapiRadar_White.png'
import DotIcon from '../Assets/Icons/LoginDotIcon.png'
import { useEffect } from 'react'
import { useState } from 'react'
import { ShowMessage } from '../Components/Common/Alerts'
import { getData, postData } from '../Assets/Scripts/dataAxios'
import { useDispatch, useSelector } from 'react-redux'
import { Login } from '../Redux/Slices/AuthSlices'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { saveState } from '../Assets/Scripts/LocalStorage'
import { setUserInfo } from '../Redux/Slices/UserInfoSlices'
import Navbar from '../Components/Navbar'




function LoginPage() {
    const [cookies, setCookie, removeCookie] = useCookies(['yapiradar']);
    const domainString = '.yapiradar.com'
    function handleCookie(e) {
        var future = new Date();
        future.setDate(future.getDate() + 30);
        setCookie('yapiradar', e, {
            path: '/',
            domain: '.yapiradar.com',
            expires: future,
            httpOnly: false,
            secure: false
        });
        console.log(cookies);
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => JSON.parse(state.Auth.user));

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | Giriş Yap';
        if (user) {
            navigate('/')
        }
    }, []);

    const styles = {
        Header1: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "28px",
            "lineHeight": "130%",
            "color": "#000000",
            textAlign: 'left'
        },
        Header2: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "12px",
            "lineHeight": "130%",
            "color": "#889AA4",
            textAlign: 'left'
        },
        mailtxtbx: {
            marginTop: '2em',
            "background": "#FFFFFF",
            width: '437px',
            [`& fieldset`]: {
                borderRadius: 20,
                backgroundColor: 'transparent'
            },
        },
        passwordtxtbx: {
            width: '437px',
            "background": "#FFFFFF",
            marginTop: '1.5em',
            [`& fieldset`]: {
                borderRadius: 20,
            },
        },
        LoginBtn: {
            textTransform: "none",
            "gap": "10px",
            "width": "437px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            }
        },
        LoginWithGoogleBtn: {
            textTransform: "none",
            "boxSizing": "border-box",
            "width": "437px",
            "height": "63px",
            "background": "#F4F7F9",
            "border": "1px solid #DAE2E8",
            "borderRadius": "20px"
        },
        LoginWithGoogleText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "18px",
            "lineHeight": "120%",
            "color": "#303C43"
        },
        textOR: {
            "width": "17px",
            "height": "18px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#BCC7CE"
        },
        DemoLink: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#8FBFFA"
        },
        DemoText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "130%",
            "color": "#BCC7CE"
        },
        headerRight: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "40px",
            "lineHeight": "130%",
            "color": "#F4F7F9",
            width: '469px'
        }
    }


    const [username, SetUsername] = useState(false);
    const [password, SetPassword] = useState(false);

    const handleLoginSubmit = async () => {
        if (!username) {
            ShowMessage('error', "Lütfen Kullanıcı Adınızı Giriniz.")
        }
        else if (!password) {
            ShowMessage('error', "Lütfen Şifrenizi Giriniz.")
        } else {
            const reqData = {
                eposta: username,
                sifre: password,
                "adsoyad": "",
                "passwordHash": "",
                "passwordSalt": ""
            }
            const res = await postData('Auth/login', reqData);
            if (res.data?.value?.token) {
              
                dispatch(Login(res.data.value));
                handleCookie(res.data.value.cookieVal);
                const userDataRes = await getData('Auth/GetCurrectUser')
                    .then(result => {
                        dispatch(setUserInfo(result.data.value));
                    }
                    ).then(() => {
                        if (!res.data.value.isDigitalKatalog) {
                            setTimeout(() => {
                                ShowMessage('success', 'Giriş yapıldı. Yönlendiriliyorsunuz.')
                                 window.open("https://app.yapiradar.com", "_self");
                            }, 2000)
                        } else {
                            if(res.data?.value?.isUyelikExpire === true ){
                                if(res.data?.value?.uyetipi === 1 ){
                                    ShowMessage('uyelikSuresi1','Detaylı bilgi için +90 (850) 441 55 54 numaralı telefondan bize ulaşabilirsiniz.')
                                } else {
                                    ShowMessage('uyelikSuresi2','Detaylı bilgi için +90 (850) 441 55 54 numaralı telefondan bize ulaşabilirsiniz.')
                                }
                            }else if(res.data?.value?.isUyelikExpire === false ){
                                ShowMessage('success', 'Giriş yapıldı. Yönlendiriliyorsunuz.')
                                window.open("https://app.yapiradar.com", "_self");
                            }
                            else {
                                 window.open("/", "_self");
                            }
                        }
                    })
            } else {
                ShowMessage('error', res.data?.message)
            }
        }
    }

    return (
        <>
            <Navbar />
            <Grid mt={12} sx={{ height: '100vh', display: { xs: 'none', md: 'flex' } }} container spacing={0}>
                <Grid pt={3} pl={3} sx={{ backgroundColor: '#889AA4', display: { xs: 'none', md: 'block' } }} item xl={6} md={6} xs={6}>
                    <Box>
                        <img src={YapiRadarIcon} alt='yapiradar.com' />
                    </Box>
                    <Box ml={6} mt={4} sx={{}}>
                        <Typography sx={styles.headerRight}>
                            İnşaat sektörünün veri bankasına hoşgeldiniz
                        </Typography>
                    </Box>
                    <Box ml={6} mt={2}>
                        <img src={DotIcon} alt='yapiradar.com Giris Yap' />
                    </Box>
                    <Box ml={25} mt={5}>
                        <img src={Frame} alt='yapiradar.com Login' />
                    </Box>
                </Grid>
                <Grid pt={20} align='center' item xl={6} md={6} xs={12}>
                    <Card sx={{ minHeight: '98%', boxShadow: 'none' }}>
                        <CardContent align='left' sx={{ width: '30vw' }}>
                            <Typography sx={styles.Header1}>
                                Üye Girişi
                            </Typography>
                            <Typography sx={styles.Header2}>
                                Giriş yapmak için lütfen kullanıcı adı ve şifrenizi yazınız
                            </Typography>
                            <Box>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => SetUsername(e.target.value)}
                                    label="E-Posta"
                                    sx={styles.mailtxtbx}
                                    autoComplete="current-mail"
                                />
                            </Box>
                            <Box>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => SetPassword(e.target.value)}
                                    label="Şifre"
                                    type='password'
                                    autoComplete="current-password"
                                    sx={styles.passwordtxtbx}
                                />
                            </Box>
                            <FormControlLabel
                                sx={{ marginTop: '1em' }}
                                control={
                                    <Checkbox
                                        name='Beni Hatırla'
                                        value='123'
                                    />
                                }
                                label='Beni Hatırla'
                            />
                            <Box display='inline'>
                                <Box mt='1.5em' sx={{ float: 'right' }}>
                                    <Link underline="none" href="https://app.yapiradar.com/account/SifremiUnuttum" >
                                        Parolamı Unuttum
                                    </Link>
                                </Box>
                                <Box mt='2em'>
                                    <Button onClick={handleLoginSubmit} sx={styles.LoginBtn}> Giriş Yap </Button>
                                </Box>
                            </Box>


                            <Box mt='3em' align='center'>
                                <Typography mr='0.5em' display='inline' sx={styles.DemoText}>
                                    Hesabınız yok mu?
                                </Typography>
                                <Link underline="none" href="/Register" sx={styles.DemoLink}>
                                    Demo Talebi oluşturun.
                                </Link>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid sx={{ display: { xs: 'flex', md: 'none' } }} container spacing={0}>
                <Grid pt={15} align='center' item xl={12} md={12} xs={12}>
                    <Card sx={{ boxShadow: 'none' }}>
                        <CardContent align='left'>
                            <Typography sx={{
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "400",
                                "fontSize": "28px",
                                "lineHeight": "130%",
                                "color": "#000000",
                                textAlign: 'center'
                            }}>
                                Üye Girişi
                            </Typography>
                            <Typography sx={{
                                "fontFamily": "'Poppins'",
                                "fontStyle": "normal",
                                "fontWeight": "400",
                                "fontSize": "12px",
                                "lineHeight": "130%",
                                "color": "#889AA4",
                                textAlign: 'center'
                            }}>
                                Giriş yapmak için lütfen kullanıcı adı ve şifrenizi yazınız
                            </Typography>
                            <Box>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => SetUsername(e.target.value)}
                                    label="E-Posta"
                                    sx={{
                                        marginTop: '2em',
                                        "background": "#FFFFFF",
                                        width: '90vw',
                                        [`& fieldset`]: {
                                            borderRadius: 20,
                                            backgroundColor: 'transparent'
                                        },
                                    }}
                                    autoComplete="current-mail"
                                />
                            </Box>
                            <Box>
                                <TextField
                                    inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                    onChange={e => SetPassword(e.target.value)}
                                    label="Şifre"
                                    type='password'
                                    autoComplete="current-password"
                                    sx={{
                                        width: '90vw',
                                        "background": "#FFFFFF",
                                        marginTop: '1.5em',
                                        [`& fieldset`]: {
                                            borderRadius: 20,
                                        },
                                    }}
                                />
                            </Box>
                            <FormControlLabel
                                sx={{ marginTop: '1em' }}
                                control={
                                    <Checkbox
                                        name='Beni Hatırla'
                                        value='123'
                                    />
                                }
                                label='Beni Hatırla'
                            />
                            <Box display='inline'>
                                <Box mt='1.5em' sx={{ float: 'right' }}>
                                    <Link underline="none" href="https://app.yapiradar.com/account/SifremiUnuttum" >
                                        Parolamı Unuttum
                                    </Link>
                                </Box>
                                <Box mt='2em'>
                                    <Button onClick={handleLoginSubmit} sx={{
                                        textTransform: "none",
                                        "gap": "10px",
                                        "width": "90vw",
                                        "height": "63px",
                                        "background": "#FF6600",
                                        "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
                                        "borderRadius": "20px",
                                        color: 'white',
                                        ':hover': {
                                            bgcolor: '#FF6600',
                                            boxShadow: 'none',
                                        }
                                    }}> Giriş Yap </Button>
                                </Box>
                            </Box>
                            <Box mt='3em' align='center'>
                                <Typography mr='0.5em' display='inline' sx={styles.DemoText}>
                                    Hesabınız yok mu?
                                </Typography>
                                <Link underline="none" href="/Register" sx={styles.DemoLink}>
                                    Demo Talebi oluşturun.
                                </Link>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default LoginPage