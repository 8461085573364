import { useEffect } from 'react';
import './App.css';
import './Assets/CSS/Internal.css';
import Navbar from './Components/Navbar';
import Landing1 from './Pages/Landing1';
import ReactGA from "react-ga4";

ReactGA.initialize("G-P2ZT4WLF53");

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Landing1 />
    </div>
  );
}

export default App;
