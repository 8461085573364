import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import Image1 from '../../Assets/Images/Engineer2.webp'
function EnteringFaydalar() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header1: {
            "width":{ xs: "100%", md: "592px"},
            "height": { xs: "auto", md: "140px"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": { xs: "35px", md: "60px"},
            "lineHeight": "130%",
            "color": "#000000",
            textAlign: 'left',
            "textAlign":{xs: "center", md:"left" }
        },
        Header2: {
            textAlign: 'left',
            "width": { xs: "100%", md: "592px"},
            "height": "36px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "18px", md: "30px"},
            "lineHeight": "130%",
            "color": "#000000",
            "textAlign":{xs: "center", md:"left" }
        },
        Header3: {
            textAlign: 'left',
            "width": { xs: "100%", md: "592px"},
            "height": "93px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": { xs: "16px", md: "22px"},
            "lineHeight": "130%",
            "color": "#889AA4",
            "textAlign":{xs: "center", md:"left" }
        },
        

    }

    return (    
        <>
            <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                <Grid pl={5} pr={5} pt={5} align='center' item xl={6} md={6} xs={12}>
                    <Typography mt={8} pb={2} sx={styles.Header1}>
                        {translate.InsaatProjesiAvcisiyiz}
                    </Typography>
                    <Typography mt={1} pb={2} sx={styles.Header2}>
                        {translate.SantiyeninOlduguHerYerdeyiz}
                    </Typography>
                    <Typography mt={1} sx={styles.Header3}>
                        {translate.NeredeOlursaOlsunGuncelInsaat}
                    </Typography>
                </Grid>
                <Grid align='right' pl={2} pr={2} item xl={6} md={6} xs={12}>
                    <img width='100%' src={Image1} alt='yapiradar faydalar' />
                </Grid>
            </Grid>
        </>
    )
}

export default EnteringFaydalar