import { Grid } from '@mui/material'
import React from 'react'
import EnteringFaydalar from '../Components/Landing Faydalar/EnteringFaydalar'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import MaksimumFaydalar from '../Components/Landing Faydalar/MaksimumFaydalar'
import Maliyet from '../Components/Landing Faydalar/Maliyet'
import NoktaAtisi from '../Components/Landing Faydalar/NoktaAtisi'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import { useEffect } from 'react'

function Faydalar() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <Navbar />
      <Grid container mt={12} spacing={0}>
      <Maliyet />
      <EnteringFaydalar />
      <MaksimumFaydalar />
        
        
        

        <NoktaAtisi />
        <TryForFree />
        <Footer />
      </Grid>
    </>
  )
}

export default Faydalar