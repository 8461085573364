import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getData } from '../../Assets/Scripts/dataAxios';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';

function WithNumbers() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const [numbers, SetNumbers] = useState([
        {
            id: 1,
            value2: 'Güncel Proje Bilgisi',
            value1: '27.336',
            logo: 'Toplam Proje'
        },
        {
            id: 2,
            value2: 'Proje Güncellemesi ',
            value1: '44.925',
            logo: 'Son 30'
        },
        {
            id: 3,
            value2: 'Firma Rehberi',
            value1: '25.919',
            logo: 'Toplam Firma'
        },
        {
            id: 4,
            value2: 'Satın Alma Talebi',
            value1: '13.085',
            logo: 'Toplam Talep'
        }
    ]);

    useEffect(() => {
        const getNumbersYapiradar = async () => {
            const res = await getData('Landing/GetLandingFirstFour');
            SetNumbers(res.data.value);
        }
        getNumbersYapiradar()
            .catch(console.error);
    }, []);




    const styles = {
        cardNumbers: {
            fontSize: {md:'60px' , xs : '40px'},
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '130%',
            color: '#303C43',
            letterSpacing: '0.01em',
            maxWidth: '680px'
        },
        cardName: {
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '130%',
            color: '#889AA4',
            letterSpacing: '0.01em',
            maxWidth: '680px'
        },
        CardContent: {
            maxWidth: 220,
            boxShadow: 'none',
            backgroundColor: 'transparent'
        },
        CardMedia: {
            marginBottom: -2,
            marginLeft: 2
        }
    }

    return (
        <>
            <Grid align='center' item xl={12} md={12} xs={12} mt={10}>
                <Grid align='center' container spacing={0}>
                    <Grid item xl={3} md={3} xs={6} key={1} >
                        <Card key={1} sx={styles.CardContent}>
                            <CardMedia sx={styles.CardMedia}>
                                <img src={require(`../../Assets/Icons/Toplam Proje.png`)} alt='yapiradar.com' />
                            </CardMedia>
                            <CardContent>
                                <Typography
                                    sx={styles.cardNumbers}>
                                    <CountUp duration={5} end={parseInt(numbers[0].value1)} />
                                </Typography>
                                <Typography
                                    sx={styles.cardName}>
                                  {translate.GuncelProjeBilgisi}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xl={3} md={3} xs={6} key={2}>
                        <Card key={2} sx={styles.CardContent}>
                            <CardMedia sx={styles.CardMedia}>
                                <img src={require(`../../Assets/Icons/Son 30.png`)} alt='yapiradar.com' />
                            </CardMedia>
                            <CardContent>
                                <Typography
                                    sx={styles.cardNumbers}>
                                    <CountUp duration={5} end={parseInt(numbers[3].value1)} />
                                </Typography>
                                <Typography
                                    sx={styles.cardName}>
                                    {translate.AylikGuncelleme}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xl={3} md={3} xs={6} key={3}>
                        <Card key={3} sx={styles.CardContent}>
                            <CardMedia sx={styles.CardMedia}>
                                <img src={require(`../../Assets/Icons/Toplam Firma.png`)} alt='yapiradar.com' />
                            </CardMedia>
                            <CardContent>
                                <Typography
                                    sx={styles.cardNumbers}>
                                    <CountUp duration={5} end={parseInt(numbers[1].value1)} />
                                </Typography>
                                <Typography
                                    sx={styles.cardName}>
                                   {translate.FirmaSayisi}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xl={3} md={3} xs={6} key={4}>
                        <Card key={4} sx={styles.CardContent}>
                            <CardMedia sx={styles.CardMedia}>
                                <img src={require(`../../Assets/Icons/Toplam Talep.png`)} alt='yapiradar.com' />
                            </CardMedia>
                            <CardContent>
                                <Typography
                                    sx={styles.cardNumbers}>
                                    <CountUp duration={5} end={parseInt(numbers[2].value1)} />
                                </Typography>
                                <Typography
                                    sx={styles.cardName}>
                                   {translate.TalepSayisi}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WithNumbers