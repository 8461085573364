import { Box, Card, CardContent, Chip, Tooltip, Typography, capitalize } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ItemCard({ props }) {
    const navigate = useNavigate();
    const handleCardClick = (link) => {
        navigate(link)
    }

    const styles = {
        Card: {
            position: 'relative',
            textAlign: 'left',
            width: { xs: "310px", md: '270px' },
            height: "350px",
            padding: "5px",
            margin: '0px 1vw',
            borderRadius: '15px',
            ':hover': {
                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                "& .itemImage": {
                    width: { xs: "250px", md: '300px' },
                    height: 'auto',
                    objectFit: 'fill'
                }
            }
        },
        CardContent: {
            padding: 0,
        },
    }
    return (
        <>
            <Card key={props.id} sx={styles.Card}>
                <CardContent
                    sx={styles.CardContent}>
                    <Box onClick={() => window.location.href = `/PazarYeri/Urun/${props.seourl}`}
                        sx={{
                            height: "145px",
                            '&:hover': {
                                cursor: 'pointer',
                            }
                        }}
                    ><img
                            className='itemImage'
                            style={{
                                width: '300px',
                                height: '160px',
                                objectFit: 'cover'
                            }} src={`/images/urunler/${props.urunGorselVarsayilan ? props.urunGorselVarsayilan : props.dk_firma_urunler_gorsels?.[0]?.gorsel}`} alt='yapiradar.com Item' />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} padding='1.5vw 1vw 0'>
                        <Tooltip title={props.marka}>
                            <Box>
                                <a style={{ textDecoration: 'none', }} href={`/PazarYeri/Urun/${props.seourl}`}>
                                    <Typography
                                        sx={{
                                            "fontFamily": "'Poppins'",
                                            "fontStyle": "normal",
                                            "fontWeight": "500",
                                            "fontSize": "16px",
                                            "lineHeight": "120%",
                                            "color": "#FF6600",
                                            "flex": "none",
                                            "order": "0",
                                            "flexGrow": "0",
                                            height: "30px",
                                            textTransform: 'capitalize',
                                            ":hover": {
                                                cursor: 'pointer',
                                                textShadow: '0.2px 0.2px'
                                            },
                                            ':first-letter': {
                                                textTransform: 'uppercase'
                                            }
                                        }} mt={5}>
                                        {props.marka ? (props.marka.length > 25 ? props.marka.toLowerCase().substr(0, 25) + "..." : props.marka.toLowerCase()) : <p>&nbsp;</p>}
                                    </Typography>
                                </a>
                            </Box>
                        </Tooltip>
                        <Box>
                            <Tooltip title={props.urunadi.toLowerCase()}>
                                <a style={{ textDecoration: 'none', }} href={`/PazarYeri/Urun/${props.seourl}`}>
                                    <Typography sx={{
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "700",
                                        "fontSize": "18px",
                                        "lineHeight": "120%",
                                        "color": "#000000",
                                        "flex": "none",
                                        "order": "0",
                                        "flexGrow": "0",
                                        textTransform: 'capitalize',
                                        ":hover": {
                                            cursor: 'pointer',
                                            textShadow: '0.2px 0.2px'
                                        }
                                    }}>
                                        {props.urunadi.length > 48 ? (props.urunadi.toLowerCase().substr(0, 48) + "...") : props.urunadi.toLowerCase()}
                                    </Typography>
                                </a>
                            </Tooltip>
                        </Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                left: '7%',
                                bottom: '5%',
                            }}
                        >
                            <a style={{ textDecoration: 'none', }} href={`/PazarYeri/Firma/${props?.firma?.seourl}`}>
                                <Typography
                                    sx={{
                                        textDecoration: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "500",
                                        "fontSize": "12px",
                                        "lineHeight": "120%",
                                        "color": "#adadad",
                                        "flex": "none",
                                        "order": "0",
                                        "flexGrow": "0",
                                        textTransform: 'capitalize',
                                        ":hover": {
                                            cursor: 'pointer',
                                            textShadow: '0.2px 0.2px'
                                        }
                                    }} mt={2}>
                                    {props.firma?.firmaadikisa ? props.firma.firmaadikisa.toLowerCase() : props.firma?.firmaadi.toLowerCase()}
                                </Typography>
                            </a>
                        </Box>
                        <Box sx={{
                            display: props.aktif === 0 ? 'block' : 'none',
                            position: 'absolute',
                            top: 10,
                            left: 10
                        }}>
                            <a style={{ textDecoration: 'none', }} href={`/PazarYeri/Urun/${props.seourl}`}>
                                <Chip label={props.onayistek === 1 && props.aktif === 0 ? 'Onay Bekliyor' : 'Pasif'} sx={{ background: 'red', color: 'white' }} />
                            </a>
                        </Box>
                    </Box>
                </CardContent>
            </Card >
        </>
    )
}

export default ItemCard