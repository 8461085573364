import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import heroArea from '../../Assets/Images/hero area.webp'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import WithNumbers from './WithNumbers';
import { textAlign } from '@mui/system';

function Entering() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const languageCode = useSelector((state) => state.Language.userLanguageFromLocalStorage);

    const navigate = useNavigate();

    const handleNavigate = (link) => {
        navigate(link);
    }

    const styles = {
        Header1: {
            mt: 6,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "700",
            fontSize: "50px",
            lineHeight: "65px",
            color: "#000000",
            maxWidth: '593px',
            textAlign: "left"
        },
        Header2: {
            mt: 6,
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "22px",
            lineHeight: "130%",
            color: "#889AA4",
            textAlign: "left"
        },
        Header3: {
            mt: 6,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "500",
            fontSize: "30px",
            lineHeight: "65px",
            color: "#000000",
            maxWidth: '593px',
            textAlign: "left"
        },
        TryNowButton: {
            width: '65%',
            textTransform: "none",
            background: "#FF6600",
            boxShadow: "0px 9px 22px rgba(34, 12, 0, 0.25)",
            borderRadius: "20px",
            padding: "15px 25px",
            gap: "10px",
            marginTop: '3em',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            },
        },
        TryNowButtonText: {
            color: "#FFFFFF",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "22px",
            lineHeight: "120%"
        },
        YapiradarWithNumbers: {
            marginLeft: '5%',
            fontSize: '50px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '130%',
            color: '#000000',
            maxWidth: '680px',
        },
        cardNumbers: {
            fontSize: '60px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '130%',
            color: '#303C43',
            letterSpacing: '0.01em',
            maxWidth: '680px'
        },
        cardName: {
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '130%',
            color: '#889AA4',
            letterSpacing: '0.01em',
            maxWidth: '680px'
        },
        CardContent: {
            maxWidth: 220,
            boxShadow: 'none',
            backgroundColor: 'transparent'
        },
        CardMedia: {
            marginBottom: -2,
            marginLeft: 2
        }
    }

    return (
        <>
            <Grid sx={{ display: { xs: 'none', md: 'flex' } }} maxWidth={1920} mt={5} ml={10} pr={10} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header2} mb='1em'>
                        {translate.InsaatSektorununVeriBankasi}
                    </Typography>

                    <Typography sx={styles.Header1} >
                        {translate.InsaatVeriBankasiOzellikleri}
                    </Typography>

                    <Typography sx={styles.Header3} >
                        {translate.InsaatVeriBankasiOzellikleri2}
                    </Typography>

                    <Button
                        onClick={() => handleNavigate('/Register')}
                        variant='contained'
                        sx={styles.TryNowButton}>
                        <Typography sx={styles.TryNowButtonText}>
                            {translate.TryNow}
                        </Typography>

                    </Button>
                </Grid>
                <Grid align='right' item xl={6} md={6} xs={12}>
                    <img style={{ width: '100%', 'height': '100%' }} src={heroArea} alt='heroimage' />
                </Grid>
            </Grid>

            <Grid align='center' sx={{ display: { xs: 'flex', md: 'none' } }} mt={1} mb={5} container spacing={0}>
                <Grid align='right' item xl={6} md={6} xs={12}>
                    <img style={{
                        height: 'auto',
                        width: '100%'
                    }}
                        src={heroArea} alt='heroimage' />
                </Grid>

                <Grid mt={3} item xl={6} md={6} xs={12}>
                    <Typography sx={{
                        fontFamily: "'Poppins'",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "22px",
                        lineHeight: "130%",
                        color: "#889AA4",
                        textAlign: "center"
                    }} mb='2em'>
                        {translate.InsaatSektorununVeriBankasi}
                    </Typography>

                    <Typography textAlign='center' sx={{
                        fontFamily: "'Poppins', sans-serif",
                        width: '100%',
                        fontWeight: "700",
                        fontSize: "40px",
                        color: "#000000"
                    }} >
                        {translate.InsaatVeriBankasiOzellikleri}
                    </Typography>

                    <Button
                        onClick={() => handleNavigate('/Register')}
                        variant='contained'
                        sx={styles.TryNowButton}>
                        <Typography sx={styles.TryNowButtonText}>
                            {translate.TryNow}
                        </Typography>

                    </Button>
                </Grid>
            </Grid>

            <Grid
                display="flex"
                justifyContent="center"
                alignItems="center"
                maxWidth={1920}
                container spacing={0}>
                <Grid item xl={12} md={12} xs={12}>
                    <Box sx={{ display: languageCode === 'Turkish' ? '' : 'none' }}>
                        <Typography
                            sx={styles.YapiradarWithNumbers}>
                            {translate.SayılarlaYapRad} <span style={{ color: '#FF6600' }}>Yapı</span><span style={{ color: '#3C6780' }}>radar</span>
                        </Typography>
                    </Box>

                    <Box sx={{ display: languageCode === 'English' ? '' : 'none' }}>
                        <Typography
                            sx={styles.YapiradarWithNumbers}> 
                            <span style={{ color: '#FF6600' }}>Yapı</span><span style={{ color: '#3C6780' }}>radar</span>   {translate.SayılarlaYapRad}
                        </Typography>
                    </Box>

                    <WithNumbers />
                </Grid>
            </Grid>
        </>
    )
}

export default Entering