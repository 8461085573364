import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { getData } from '../../Assets/Scripts/dataAxios';




function CustomerPartners(children) {
    const value = [];
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage))

    const styles = {
        Header1: {
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            color: '#889AA4',
            textAlign: 'center',
        },
        ImageWrapper: {
            marginTop: '5em',
            width: '100%',
            display: 'inline-flex',
            overflowX: "scroll",

        },
        ImagePaper: {
            margin: '0px 3vw 0px 0vw',
        },

    }
    function scrollDelay(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    useState(() => {
        setTimeout(async () => {
            const scrollElement = document.getElementById('scrollElement');
            const scrollElementwidth = document.getElementById('scrollElement').offsetWidth;
            for (var y = 0; y <= 21000; y += 100) {
                scrollElement.scrollTo({ left: y, behavior: 'smooth' })
                await scrollDelay(1000)
            }

        }, 1500)
    }, [])

    const [logoData, setLogoData] = useState([]);
    useEffect(() => {
        const GetLogoData = async () => {
            const response = await getData(`Landing/GetAllHomeReferans`,);
            setLogoData(response.data.value);
        }
        GetLogoData()
            .catch(console.error);
    }, [])
    return (
        <>
            <Grid sx={{ backgroundColor: '#FFFFFF' }} pb={10} container spacing={0}>
                <Grid align='center' m={2} mt={7} xl={12} md={12} xs={12} item>
                    <Typography sx={styles.Header1}>
                        {translate.KucukIsletmelerdenKurumsalFirmalara}
                    </Typography>
                </Grid>
                <Grid p={{ xs: 3, md: 9 }} container spacing={0}>
                    <Grid item xl={9} md={9} xs={12}>

                    </Grid>
                    <Grid sx={{ position: 'relative' }} item xl={3} md={3} xs={3}>
                    </Grid>
                    <Box id='scrollElement' sx={styles.ImageWrapper}>
                        {logoData.map(item => (
                            <Paper elevation={0} key={item.id} sx={styles.ImagePaper}> {/* item'ın benzersiz bir id'si olduğunu varsayarak */}
                                <img src={`https://www.yapiradar.com${item?.logoUrl}`} width='auto' height='40px' alt='Product of Week' />
                            </Paper>
                        ))}
                    </Box>

                </Grid>
            </Grid>
        </>
    )
}

export default CustomerPartners