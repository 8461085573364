import { Grid, Typography } from '@mui/material'
import React from 'react'
import Footer from '../Components/Footer'
import TryForFree from '../Components/Landing Faydalar/TryForFree'
import Navbar from '../Components/Navbar'
import Image1 from '../Assets/Images/KamuYapimEntering.webp'
import Image2 from '../Assets/Images/guncel-ihale-duyurulari.webp'
import Image4 from '../Assets/Images/ihaleye-en-avantajli-teklifi.webp'
import Image5 from '../Assets/Images/ihaleyi-kazanan-firmanin-bilgisi.webp'

import Image3 from '../Assets/Images/KamuYapimEnteringImage..webp'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function KamuYapimIhaleleri() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const styles = {
        Header1: { "width": { xs: "100%", md: "592px"} , "height": { xs: "auto", md: "36px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "20px", md: "24px" } , "lineHeight": "36px", "color": "#000000" },
        Header2: { "width":  { xs: "100%", md:"580px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "700", "fontSize": { xs: "20px", md: "54px" }, "lineHeight": "130%", "color": "#000000" },
        Header3: { "width": { xs: "100%", md: "561.11px"},  "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize": "18px", "lineHeight": "170%", "color": "#889AA4" },
        Header4: { "width":  { xs: "100%", md: "490px"}, "height":  { xs: "auto", md: "130px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize":{ xs: "20px", md: "50px"} , "lineHeight": "130%", "color": "#000000" },
        Header5: { "width":  { xs: "100%", md: "480px"}, "height":  { xs: "auto", md: "81px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "20px", md: "16px"} , "lineHeight": "170%", "color": "#303C43" },
        Header6: { "width":  { xs: "100%", md: "500px"}, "height":  { xs: "auto", md: "195px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "600", "fontSize":{ xs: "20px", md: "50px"}, "lineHeight": "130%", "color": "#FFFFFF" },
        Header7: { "width":  { xs: "100%", md: "512px"}, "height":  { xs: "auto", md: "93px"}, "fontFamily": "'Poppins'", "fontStyle": "normal", "fontWeight": "500", "fontSize":{ xs: "20px", md: "18px"} , "lineHeight": "170%", "letterSpacing": "0.01em", "color": "#F4F7F9" },
    
    
        HeaderTop :{
            display: {md:"flex"}, 
            flexDirection: {md:"row-reverse"}
        },
        Header1Top:{
        "paddingLeft": {xs:0,md:"10%"},
        "textAlign": {xs:"center",md:"left"}
        },
        Header4Top:{
            "paddingLeft": {xs:0,md:"10%"},
            "textAlign": {xs:"center",md:"left"},
            
            
            },
            Header5Top:{
                "paddingLeft": {xs:0,md:"10%"},
                "textAlign": {xs:"center",md:"left"}
                },
                Image2Top:{
                    "relative": {xs:"", md:"right"},
                    "display":{xs:"", md:"flex"},
                    "flexDirection": {xs:"",md:"row-reverse"}

                },
    }
    return (
        <>
            <Navbar />
            <Grid mt={12} container spacing={0}>
                <Grid sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pt={10} pl={{xs:5,md:10}} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header1}>
                            {translate.KamuYapimIhaleleri}
                        </Typography>
                        <Typography mt={2} sx={styles.Header2}>
                        {translate.IhaleDuyurusundanCokDahaFazlasi}
                        </Typography>
                        <Typography mt={4} sx={styles.Header3}>
                        {translate.GuncelKamuYapimIhaleDuyurulari}
                        </Typography>
                    </Grid>
                    <Grid  item xl={6} md={6} xs={12}>
                        <img width='90%'  src={Image1} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid mt={5} container spacing={0} sx={styles.HeaderTop}>                   
                    <Grid pl={5} pt={{xs:8,md:15}} item xl={6} md={6} xs={12} >
                        <Typography sx={styles.Header4}>
                        {translate.GuncelIhaleDuyurulari}
                        </Typography>
                        <Typography pt={6} sx={styles.Header5}>
                        {translate.SadeceAcikIhaleUsulu}
                        </Typography>
                    </Grid>
                    <Grid pl={5} pb={10} pt={9} item xl={6} md={6} xs={12}>
                        <img width='90%' src={Image4} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid pt={5} sx={{ backgroundColor: '#F4F7F9' }} container spacing={0}>
                    <Grid pl={{xs:5,md:10}}  pt={{xs:8,md:20}}  item xl={6} md={6} xs={12}>
                        <Typography  sx={styles.Header4}>
                        {translate.IhaleyeEnAvantajliTeklifi}
                        </Typography>
                        <Typography pt={{xs:8,md:12}} sx={styles.Header5}>
                        {translate.IhaleyeEnAvantajliTeklifiVererek}
                        </Typography>
                    </Grid>
                    <Grid pl={5} pb={10} pt={9} item xl={6} md={6} xs={12}>
                        <img width='90%' src={Image5} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
                <Grid mt={10} container spacing={0} sx={styles.HeaderTop}>
                   
                    <Grid pl={5} mt={5}  pt={{xs:8,md:10}} item xl={6} md={6} xs={12}>
                        <Typography mb={5} sx={styles.Header4}>
                        {translate.IhaleyiKazananFirmaninBilgisi}
                        </Typography>
                        <Typography pt={3} sx={styles.Header5}>
                        {translate.IhaleyiKazananFirmalarinYetkisi}
                        </Typography>
                    </Grid>
                    <Grid pl={5} pt={5} pb={10} item xl={6} md={6} xs={12}>
                        <img width='90%' src={Image2} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>

                <Grid sx={{ backgroundColor: '#3C6780' }} container spacing={0}>
                    <Grid pt={10} pl={5} item xl={6} md={6} xs={12}>
                        <Typography sx={styles.Header6}>
                        {translate.BastanSonaProjeTakibi}
                        </Typography>
                        <Typography mt={3} sx={styles.Header7}>
                        {translate.IhaleDetaylariniSadeceYazmaklaKalmiyor}
                        </Typography>
                    </Grid>
                    <Grid align='right' pt={0} pr={10} item xl={6} md={6} xs={12}>
                        <img width='100%' src={Image3} alt='Guncel Insaat Projeleri' />
                    </Grid>
                </Grid>
            </Grid>

            <TryForFree />
            <Footer />
        </>
    )
}
