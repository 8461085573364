import ArrowForward from '@mui/icons-material/ArrowForward'
import { Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Image1 from '../../Assets/Images/Group 2.webp'
import Image2 from '../../Assets/Images/Group 25.webp'

function TryForFree() {
    const user = useSelector((state) => JSON.parse(state.Auth.user));
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const styles = {
        Header: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": {xs:"45px",md:"25px"},
            "lineHeight": "130%",
            "display": "flex",
            "alignItems": "center",
            "color": "#303C43",
            "width":{xs:"100%",md:"80%"}
        },
        TryNowButton: {
            marginTop: '-5px',
            marginLeft: "15px",
            "background": "#FF6600",
            "boxShadow": "0px 12.4054px 30.3243px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            color: 'white',
            width: '50px',
            height: '50px',
            ':hover': {
                background: '#FF6600',
                "boxShadow": 'none',
                "borderRadius": "none",
            }
        },
        textWrapper: {
            display: 'inline-flex',

        },
        WrapperGrid: {
            display: user?.isDigitalKatalog === true ? 'none' : '',
            minHeight: {xs:"465px",md:"200px"},
            backgroundColor: '#8FBFFA',
            position: 'relative'
        },
        WrapperInsideGrid: {
            paddingTop: {xs:"45px",md:"85px"},
            paddingLeft: {xs:"40px",md:"75px"},
        },
        imagesWrapper: {
            display: 'inline-flex',
            position: 'absolute',
            left: {xs:"0",md:"60vw"},
            top:{xs:"60%",md:"14px"},
            
            
       
        },
        Image1Wrapper: {
            position: 'absolute',
            left: {xs:"-10%",md:"-25%"},
            

        

        },
        Image2Wrapper: {
            position: 'absolute',
            top: '37px',
            left: {xs:"-10%",md:"-15%"},
           

        }
    }

    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(url);
    } 

    return (
        <>
            <Grid sx={styles.WrapperGrid} container spacing={0}>
                <Grid sx={styles.WrapperInsideGrid} item xl={12} md={12} xs={6}>
                    <Box
                     onClick={() => handleClick('/Register')}
                    sx={styles.textWrapper}>
                        <Typography sx={styles.Header}>
                            {translate.SimdiUcretsizDene}
                        </Typography>
                        <IconButton sx={styles.TryNowButton}>
                            <ArrowForward />
                        </IconButton>
                    </Box>
                    <Box sx={styles.imagesWrapper}>
                        <Box sx={styles.Image1Wrapper}>
                        <img src={Image1} alt='tesst' />
                        </Box>
                        <Box sx={styles.Image2Wrapper}>
                        <img  src={Image2} alt='tesst' />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default TryForFree