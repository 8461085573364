import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import { Avatar, Button, FormControl, Grid, IconButton, Menu, MenuItem, Paper, TextField, Typography } from '@mui/material';
import Logo from '../../Assets/Images/MarketPlaceLogoNavbar.jpg'
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import { LogOut } from '../../Redux/Slices/AuthSlices';
import { ShowMessage } from '../Common/Alerts';
import { deleteUserInfo } from '../../Redux/Slices/UserInfoSlices';
import { getData } from '../../Assets/Scripts/dataAxios';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import InterestsIcon from '@mui/icons-material/Interests';
import imageReklam from '../../Assets/Images/PazarYeriAcildi.jpg'
import PazarYeriSVG from '../../Assets/Icons/PazarYeriSVG.svg'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function NavbarSearchable() {
    const user = useSelector((state) => JSON.parse(state.Auth.user));
    const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const [searchWord, SetSearchWord] = useState('');
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorElLogin, setAnchorElLogin] = React.useState(null);
    const openLoginMenu = Boolean(anchorElLogin);
    const handleClickLogin = (event) => {
        setAnchorElLogin(event.currentTarget);
    };
    const handleCloseLogin = () => {
        setAnchorElLogin(null);
    };

    const handleLinkNavigate = (url) => {
        navigate(url);
        navigate(0);
    }

    const dispatch = useDispatch();
    const handleLogOut = async () => {
        const resLogOut = await getData('Auth/LogOut');
        if (resLogOut.data.isSucceeded || (resLogOut.data.isSucceeded === false && resLogOut.data.value === 'Bulunamadi')) {
            dispatch(LogOut());
            dispatch(deleteUserInfo());
            ShowMessage('success', 'Çıkış yapıldı. Anasayfaya Yönlendiriliyorsunuz.');
            setTimeout(() => {
                navigate('/')
            }, 1500)
        } else {
            ShowMessage('error', resLogOut.data.error.errorMessage);
        }
    }
    const styles = {
        appBar: {
            minHeight: '100px',
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'transparent',
            backdropFilter: "none",
            boxShadow: "none",
            paddingRight: '2em',
            display: 'contents'
        },
        navLinks: {
            margin: "'0 10px'",
            textTransform: "none",
            color: "#3C6780",
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "17px",
            lineHeight: "120%",
            ':hover': {
                color: ' #303C43',
                "& .navLinkBlog": {
                    color: '#FF6600'
                }
            },
        },
        imageReklam: {
            display: { xs: "none", md: "" }
        },
        buttonColor: {
            ':hover': {
                backgroundColor: 'transparent',
            },
        },
        navDeneBtn: {
            backgroundColor: "#FF6600",
            boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
            borderRadius: "14px",
            width: "162px",
            height: "47px",
            marginRight: '1em',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            },
        },
        navKatalogBtn: {
            backgroundColor: "#3C6780",
            boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
            borderRadius: "14px",
            width: "150px",
            height: "47px",
            marginRight: '1em',
            ':hover': {
                bgcolor: '#3C6780',
                boxShadow: 'none',
            },
        },
        navKatalogBtnText: {
            margin: 0,
            padding: 0,
            textTransform: "none",
            color: "#FFFFFF",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%"
        },
        navDeneBtnText: {
            textTransform: "none",
            color: "#FFFFFF",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%"
        },

        navDeneBtnMobile: {
            textTransform: "none",
            "justifyContent": "center",
            "alignItems": "center",
            "padding": "15px 25px",
            "gap": "10px",
            "width": "333px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "justifyContent": "center",
                "alignItems": "center",
                "padding": "15px 25px",
                "gap": "10px",
                "width": "333px",
                "height": "63px",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "20px",
            }
        },

        navLoginBtn: {
            textTransform: "none",
            boxSizing: "border-box",
            width: "94px",
            height: "47px",
            opacity: "0.5",
            border: "1px solid #3C6780",
            filter: "drop-shadow(0px 18px 50px rgba(111, 160, 221, 0.25))",
            borderRadius: "14px"
        },
        navLoginBtnMobile: {
            textTransform: "none",
            "alignItems": "center",
            "padding": "15px 25px",
            "gap": "10px",
            "width": "333px",
            "height": "63px",
            "background": "#FFFFFF",
            "boxShadow": "0px 18px 50px rgba(111, 160, 221, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                "alignItems": "center",
                "padding": "15px 25px",
                "gap": "10px",
                "width": "333px",
                "height": "63px",
                "background": "#FFFFFF",
                "boxShadow": "none",
                "borderRadius": "20px",
            }
        },
        navLinkBlog: {
            width: "129px",
            height: "19px",
            fontFamily: "'Damion', cursive",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%",
            color: "#889AA4",
            flex: "none",
            order: "4",
            flexGrow: "0"
        },
        navLinksGrid: {
            left: '5vw',
            zIndex: 99,
            color: 'black',
            top: '14vh',
            position: 'fixed',
            backgroundColor: 'white',
            "boxSizing": "border-box",
            "width": "90vw",
            "height": "395px",
            "background": "#FBFBFB",
            "boxShadow": "0px 76.07px 91.113px -25.04px rgba(0, 0, 0, 0.18)",
            "backdropFilter": "blur(20px)",
            "borderRadius": "30px"
        },
        LinkPaper: {
            "marginLeft": '2em',
            "background": "#FBFBFB",
            "width": "301px",
            "height": "163px",
            "left": "85px",
            "top": "168px",
            "border": "none",
            "boxShadow": "none",
            "borderRadius": "20px",
            cursor: 'pointer',
            ':hover': {
                "background": "#FFFFFF",
                "border": "1px solid #8FBFFA",
                "boxShadow": "0px 44px 40px -10px rgba(37, 50, 66, 0.25)",
                "borderRadius": "20px",
            },
            '&.active': {
                "background": "#FFFFFF",
                "border": "1px solid #8FBFFA",
                "boxShadow": "0px 44px 40px -10px rgba(37, 50, 66, 0.25)",
                "borderRadius": "20px",
            },
        },
        PaperHeader: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#000000"
        },
        PaperText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "16px",
            "lineHeight": "150%",
            "color": "#889AA4",
            "left": "7.71%",
            "right": "75.9%",
            "top": "46.55%",
            "bottom": "43.98%"
        },
        LinkPaperTryNow: {
            "width": "330px",
            "height": "186px",
            "background": "#FF6600",
            "backdropFilter": "blur(22px)",
            "borderRadius": "30px 0px",
            bottom: 0,
            position: 'absolute'
        },
        LinkPaperTryNowText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "33px",
            "lineHeight": "120%",
            "color": "#FFFFFF"
        },
        goTryBtn: {
            "background": "#8FBFFA",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "12px",
            marginTop: '13px',
            marginLeft: '20px',
            ':hover': {
                background: ' #8FBFFA'
            },
        },
        userNameText: {
            textTransform: "none",
            color: "#889AA4",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%",
            width: '162px',
            textAlign: 'center'
        },
        UrunlerSelectControl: {
            m: 1,
            width: '90%'
        },
        SearcTextField: {
            background: 'white'
        },
        UrunlerSelectIcon: {
            color: '#FF6600',
            backgroundColor: 'transparent'
        },
        UrunlerSelectText: {
            marginLeft: '20px',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "18px",
            "lineHeight": "30px",
            "fontFeatureSettings": "'liga' off",
            "color": "#000000"
        },
        MainCategoryWrapper: {
            cursor: 'pointer',
            paddingLeft: '0.5em',
            width: '100%',
            height: '55px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '1.5em',
            marginLeft: '1em',
            ':hover': {
                background: '#ebf6fc',
                borderRadius: '40px'
            }
        },
        MainCategoryText: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            fontSize: { xs: "12px", md: "1rem" }
        },
        SubCategoryWrapperPaper: {
            borderRadius: '30px',
            height: '90%',
            width: '90%',
            background: '#ebf6fc'
        },
        categoriesDisplay: {
            position: 'absolute',
            minWidth: { xs: '100%', md: '1400px' },
            maxWidth: { xs: "100%" }
        }
    }
    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('DijitalKatalog/KategoriList');
            SetCategories(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, []);

    const [anchorElMobileMenu, setAnchorElMobileMenu] = React.useState('none');
    const handleClickMobileMenu = (event) => {
        setAnchorElMobileMenu('block');
    };
    const handleCloseMobileMenu = () => {
        setAnchorElMobileMenu('none');
    };

    const HandleSearch = () => {
        window.location.href = `/PazarYeri/AramaSonucu?kelime=${searchWord}`
    }

    const HandleCategoryHoverAndClickEnter = (e) => {
        categories.length > 0 && categories?.map(item => {
            const element = document.getElementById(`${item.id}`)
            element.style.background = 'transparent';


            const subElement = document.getElementById(`SubCategoryPaper${item.id}`)
            subElement.style.display = 'none';
        })

        const sub = document.getElementById(`SubCategoryPaper${e}`)
        sub.style.display = 'block';

        const parent = document.getElementById(`${e}`)
        parent.style.background = '#ebf6fc';
        parent.style.borderTopLeftRadius = '40px'
        parent.style.borderBottomLeftRadius = '40px'
    }

    const HandleCategoryHoverAndClickLeave = (e) => {
        categories.length > 0 && categories?.map(item => {
            const element = document.getElementById(`${item.id}`)
            element.style.background = 'transparent';
        })

        const element = document.getElementById(`${e}`)
        element.style.background = '#ebf6fc';
    }

    const [categoriesDisplay, setCategoriesDisplay] = useState(false);

    const handleParentCategorySearch = (param) => {
        let AltKategori = '';
        let KategoriDetay = '';
        categories.forEach((item) => {
            if (item.id === param) {
                item.children.forEach(subItem => {
                    AltKategori += subItem.id + '-'

                    subItem.children.forEach(subItemChild => {
                        KategoriDetay += subItemChild.id + '-'
                    })
                })
            }
        })
        AltKategori = AltKategori.slice(0, -1)
        KategoriDetay = KategoriDetay.slice(0, -1)
        window.location.href = `/PazarYeri/AramaSonucu?Kategori=${param},AltKategori=${AltKategori},KategoriDetay=${KategoriDetay},AramaTuru=Urunler`
    }

    const handleMiddleCategorySearch = (katid, parentId, ChildId) => {
        let AltKategori = '';
        let KategoriDetay = '';
        categories.forEach((item) => {
            if (item.id === parentId) {
                item.children.forEach(subItem => {
                    AltKategori += subItem.id + '-'
                    if (subItem.id === ChildId) {
                        subItem.children.forEach(subItemChild => {
                            KategoriDetay += subItemChild.id + '-'
                        })
                    }

                })
            }
        })
        AltKategori = AltKategori.slice(0, -1)
        KategoriDetay = KategoriDetay.slice(0, -1)
        window.location.href = `/PazarYeri/AramaSonucu?Kategori=${katid},AltKategori=${parentId},KategoriDetay=${KategoriDetay},AramaTuru=Urunler`
    }

    const handleOpenStore = () => {
        window.location.href = '/PazarYeri#MagazaniziAcin'

        const id = 'scrollToJoinCatalogue';
        const yOffset = -30;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
    return (
        <>
            <AppBar position='absolute' sx={styles.appBar}>
                <Grid sx={{ alignItems: 'center' }} container justify='center'>
                    <Grid pl={5} mt='17px' item xl={2.5} md={2.5} xs={2.5}>
                        <Box sx={{ cursor: 'pointer' }} onClick={() => handleLinkNavigate('/PazarYeri')}>
                            <img src={Logo} alt='Yapiradar.com Logo' />
                        </Box>
                    </Grid>
                    <Grid
                        sx={{
                            display: {
                                xs: 'none', md: 'flex'
                            }
                        }}
                        mt='10px' item display='flex' justifyContent='center' xl={7} md={6} xs={5.5}>
                        <div style={{ display: 'inline-flex', width: '80%', justifyContent: 'center' }}>
                            <FormControl sx={styles.UrunlerSelectControl}>
                                <TextField
                                    onKeyDown={
                                        (e) => {
                                            if (e.key === 'Enter') {
                                                HandleSearch()
                                            }
                                        }
                                    }
                                    onChange={e => SetSearchWord(e.target.value)}
                                    sx={styles.SearcTextField}
                                    value={searchWord}
                                    name={"search"}
                                    label={
                                        <div style={{
                                            display: 'inline-flex',
                                            background: 'white'
                                        }}>
                                            <InterestsIcon sx={styles.UrunlerSelectIcon} display='inline' />
                                            <Typography sx={styles.UrunlerSelectText} display='inline'>
                                                Ürün ve Firma Ara
                                            </Typography>
                                        </div>
                                    }
                                />
                            </FormControl>
                            <IconButton
                                onClick={HandleSearch}
                            >
                                <Avatar sx={{ width: '60px', height: '60px', backgroundColor: 'white' }}>
                                    <SearchIcon sx={{ color: '#889AA4' }} />
                                </Avatar>
                            </IconButton>
                        </div>
                    </Grid>

                    <Grid item xl={2.5} md={3.5} xs={9.5} pr={2} mt='0.4em' textAlign='right'>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none', md: 'block'
                                }
                            }}
                        >
                            {/* <Button onClick={() => handleLinkNavigate('/Pazaryeri')} sx={{
                                backgroundColor: "#3C6780",
                                boxShadow: "0px 12px 20px rgba(180, 72, 0, 0.16)",
                                borderRadius: "14px",
                                width: "220px",
                                height: "47px",
                                marginRight: '1em',
                                ':hover': {
                                    bgcolor: '#3C6780',
                                    boxShadow: 'none',
                                },
                            }}>
                                <img
                                    style={{ filter: 'brightness(0) invert(1)', height: '30px', marginRight: '10px' }}
                                    src={PazarYeriSVG} />
                                <Typography
                                    mr={2} ml={2} sx={{
                                        margin: 0,
                                        padding: 0,
                                        textTransform: "none",
                                        color: "#FFFFFF",
                                        fontFamily: "'Poppins'",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "120%"
                                    }} display="inline">
                                    {translate.InsaatPazarYeri}
                                </Typography>
                            </Button> */}

                            {user === false ? (
                                <Button onClick={handleOpenStore} variant='contained' sx={styles.navDeneBtn}>
                                    <Typography sx={styles.navDeneBtnText}>
                                        Mağazanızı Açın
                                    </Typography>
                                </Button>
                            ) :
                                (<>
                                <Button
                                        variant='contained'
                                        sx={styles.navDeneBtn}
                                        id="basic-button"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <Typography sx={styles.navDeneBtnText}>
                                            Uygulamaya Git
                                        </Typography>

                                    </Button>
                                    <Menu
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        PaperProps={{
                                            style: {
                                                width: 180,
                                            },
                                        }}
                                        disableScrollLock={true}
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            onMouseLeave: handleClose
                                        }}
                                    >
                                        <MenuItem
                                            onClick={handleClose}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }} href='https://app.yapiradar.com/'>
                                                <Typography
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#FF6600",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}
                                                    display='inline'>
                                                    YAPI
                                                </Typography>
                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    RADAR
                                                </Typography>
                                                <Typography sx={{
                                                    margin: "'0 10px'",
                                                    textTransform: "none",
                                                    color: "#FF6600",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "10px",
                                                    lineHeight: "120%",
                                                }}
                                                    display='inline'>
                                                    .com
                                                </Typography>
                                            </a>
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ display: UserData.dkFirmaid === 0 ? 'none' : '' }}
                                            onClick={handleClose}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href={`/PazarYeri/Firma/${UserData.dkFirmaSeo}`}>
                                                <Typography sx={{
                                                    margin: "'0 10px'",
                                                    textTransform: "none",
                                                    color: "#3C6780",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    lineHeight: "120%",
                                                    ':hover': {
                                                        color: ' #303C43',
                                                        "& .navLinkBlog": {
                                                            color: '#FF6600'
                                                        }
                                                    },
                                                }}>
                                                    Firma Profilim
                                                </Typography>
                                            </a>
                                        </MenuItem>

                                        <MenuItem
                                            sx={{ display: UserData.dkFirmaid !== 0 ? 'none' : '' }}
                                            onClick={handleClose}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href={`/PazarYeri/FirmaEkle`}>
                                                <Typography sx={{
                                                    margin: "'0 10px'",
                                                    textTransform: "none",
                                                    color: "#3C6780",
                                                    fontFamily: "'Poppins', sans-serif",
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                    lineHeight: "120%",
                                                    ':hover': {
                                                        color: ' #303C43',
                                                        "& .navLinkBlog": {
                                                            color: '#FF6600'
                                                        }
                                                    },
                                                }}>
                                                    Mağazanızı Açın
                                                </Typography>
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </>
                                )}
                            {user === false ? (
                                <>
                                    <Button
                                        sx={styles.navLoginBtn}
                                        id="basic-button"
                                        aria-controls={openLoginMenu ? 'login-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openLoginMenu ? 'true' : undefined}
                                        onClick={handleClickLogin}
                                        onMouseOver={handleClickLogin}
                                    >
                                        <Typography className='navLoginBtnText'>
                                            {translate.GirisYap}
                                        </Typography>

                                    </Button>
                                    <Menu
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        PaperProps={{
                                            style: {
                                                width: 150,
                                            },
                                        }}
                                        disableScrollLock={true}
                                        id="login-menu"
                                        anchorEl={anchorElLogin}
                                        open={openLoginMenu}
                                        onClose={handleCloseLogin}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            onMouseLeave: handleCloseLogin
                                        }}
                                    >
                                        <MenuItem
                                            onClick={handleCloseLogin}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href="/Login">
                                                <Typography
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#FF6600",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}
                                                    display='inline'>
                                                    YAPI
                                                </Typography>
                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    RADAR
                                                </Typography>

                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "0px 5px",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    Giriş
                                                </Typography>
                                            </a>
                                        </MenuItem>

                                        <MenuItem
                                            onClick={handleCloseLogin}>
                                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                                                href="/PazarYeriLogin">
                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "'0 7px'",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    PAZAR
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        margin: "'0 10px'",
                                                        textTransform: "none",
                                                        color: "#FF6600",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}
                                                    display='inline'>
                                                    YERİ
                                                </Typography>

                                                <Typography
                                                    display='inline'
                                                    sx={{
                                                        margin: "5px 10px",
                                                        textTransform: "none",
                                                        color: "#3C6780",
                                                        fontFamily: "'Poppins', sans-serif",
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        fontSize: "14px",
                                                        lineHeight: "120%",
                                                    }}>
                                                    Giriş
                                                </Typography>
                                            </a>
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (<Button onClick={() => handleLogOut()} sx={styles.navLoginBtn}>
                                <Typography className='navLoginBtnText'>
                                    {translate.CikisYap}
                                </Typography>
                            </Button>)}
                        </Box>
                        <Box
                            sx={{
                                display: {
                                    xs: 'block', md: 'none'
                                }
                            }}
                        >
                            <IconButton
                                sx={{
                                    display: anchorElMobileMenu === 'none' ? 'contents' : 'none'
                                }}
                                onClick={handleClickMobileMenu}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                sx={{
                                    display: anchorElMobileMenu === 'none' ? 'none' : 'contents'
                                }}
                                onClick={handleCloseMobileMenu}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item p={4} xl={12} md={12} xs={12}>
                        <Button
                            onClick={(e) => setCategoriesDisplay(!categoriesDisplay)}
                            sx={{
                                color: 'red',
                                mr: 2,
                                textTransform: "none",
                                height: "42px",
                                "borderRadius": "8px",
                            }}
                            endIcon={categoriesDisplay ? <KeyboardArrowUpIcon fontSize="inherit" /> : <KeyboardArrowDownIcon />}
                        >
                            <Typography
                                sx={{
                                    color: '#686868',
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    fontFamily: '"Poppins",sans-serif'
                                }}>
                                Kategoriler
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                color: 'black',
                                mr: 2,
                                textTransform: "none",
                                height: "42px",
                                "borderRadius": "8px",
                            }}
                            onClick={() => { window.open('https://yapigundem.com/') }}>
                            <Typography
                                sx={{
                                    color: '#686868',
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    fontFamily: '"Poppins",sans-serif'
                                }}>
                                Yapigundem.com
                            </Typography>
                        </Button>
                        <Button
                            disabled
                            sx={{
                                color: 'black',
                                mr: 2,
                                textTransform: "none",
                                height: "42px",
                                "borderRadius": "8px",
                            }}
                            onClick={() => { navigate('/SpotUrunler') }}>
                            <Typography
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#686868',
                                    fontSize: '18px',
                                    fontWeight: '600',
                                    fontFamily: '"Poppins",sans-serif'
                                }}>
                                Spot Ürünler
                            </Typography>
                        </Button>

                    </Grid>
                </Grid>
            </AppBar>
            <Box sx={{ display: categoriesDisplay ? 'block' : 'none', position: 'relative', zIndex: '99999' }} >
                <Grid sx={styles.categoriesDisplay} container spacing={0}>
                    <Grid item xl={12} md={12} xs={12}>
                        <Paper sx={styles.categoriesDisplay}>
                            <Grid p={2} height="auto" container spacing={0} >
                                <Grid item xl={2} md={2} xs={4}>
                                    {categories?.map((item) => (
                                        <Box key={item.id}
                                            id={item.id}
                                            onMouseOver={e => {
                                                HandleCategoryHoverAndClickEnter(e.target.id);
                                            }}
                                            sx={{
                                                cursor: 'context-menu',
                                                paddingLeft: '0.5em',
                                                width: '100%',
                                                height: '75px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: { xs: '0', md: "1em" },
                                                fontFamily: "'Poppins', sans-serif",
                                                fontWeight: 700,
                                                fontSize: { xs: "12px", md: "1rem" },

                                            }}>


                                            {item.adi}
                                        </Box>
                                    ))}
                                </Grid>
                                <Grid height="unset" align='left' item xl={10} md={10} xs={8}>
                                    {categories.length > 0 && categories?.map((item) => (
                                        <Paper
                                            key={item.id}
                                            id={`SubCategoryPaper${item.id}`}
                                            sx={{
                                                borderRadius: { xs: '', md: '30px' },
                                                height: '100%',
                                                background: '#ebf6fc',
                                                display: 'none'
                                            }} elevation={0}>
                                            <Grid sx={{ height: '100%' }} container spacing={0}>
                                                <Grid p={3} item xl={4} md={4} xs={6}>
                                                    <Typography
                                                        onClick={() => handleParentCategorySearch(item.id)}
                                                        sx={{
                                                            fontFamily: "'Poppins', sans-serif",
                                                            fontWeight: 700,
                                                            cursor: 'pointer',
                                                            fontSize: { xs: "12px", md: "1rem" }
                                                        }}>
                                                        {item.adi}
                                                    </Typography>
                                                    {
                                                        item?.children.map((subItem) => (
                                                            <Box sx={{ marginTop: 2 }}>
                                                                <a
                                                                    onClick={() => handleMiddleCategorySearch(item.id, subItem.parentid, subItem.id)}
                                                                    style={{ textDecoration: 'none', textDecorationColor: 'black', color: 'black', cursor: 'pointer' }}
                                                                // href={`/PazarYeri/AramaSonucu?Kategori=${subItem.parentid},AltKategori=${subItem.id},AramaTuru=Urunler`}
                                                                >
                                                                    <Typography sx={{ fontFamily: "'Poppins', sans-serif", }} key={subItem.id}>{subItem.adi}</Typography>
                                                                </a>
                                                            </Box>
                                                        ))
                                                    }
                                                </Grid>
                                                <Grid item xl={8} md={4} xs={6}>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            background: '#cbe8f7',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: { xs: "none", md: "flex " },
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: '100%',
                                                                height: "auto",
                                                            }}

                                                            src={imageReklam} alt='Yapiradar Reklam' />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    ))}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default NavbarSearchable