import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Image404 from '../../Assets/Images/Green-Check.png'
import ImageNoMailSend from '../../Assets/Images/no-spam.webp'
import { Button, Grid, Typography } from '@mui/material'
import { getData } from '../../Assets/Scripts/dataAxios'
import Footer from '../../Components/Footer'
import Navbar from '../../Components/Navbar'

function DemoKayitBasarili() {
    const [directed, SetDirected] = useState(false);
    useEffect(() => {
         const interval = setInterval(() => {
             window.location.href = '/'
             SetDirected(true);
         }, 3000);

         return () => !directed && clearInterval(interval);
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = () => {
        navigate('/')
    }


    const styles = {
        bodyGrid: {
            backgroundColor: '#F4F7F9',
        },
        pageText:
        {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "16px",
            "lineHeight": "130%",
            "textAlign": "center",
            "color": "#889AA4"
        },
        buttonText: {
            "color": "#FFFFFF",
            "flex": "none",
            "order": "0",
            "flexGrow": "0"
        },
        returnHomeButton: {
            "padding": "15px 25px",
            "gap": "10px",
            "width": "278px",
            "height": "63px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "20px",
            ':hover': {
                background: ' #FF6600'
            }
        }


    }


    return (
        <>
            <Navbar />
            <Grid align='center' mt={15} sx={styles.bodyGrid} container spacing={0} >
                <Grid pt={4} item xl={12} md={12} xs={12} >
                    <img height='auto' width='35%' src={Image404} alt='yapiradar.com page not found' />
                    <Typography mb={4} mt={5} sx={styles.pageText}>
                        Talebiniz Başarıyla Alınmıştır. Ana Sayfaya Yönlendiriliyorsunuz.
                    </Typography>
                    <Button onClick={handleClick} sx={styles.returnHomeButton}>
                        <Typography sx={styles.buttonText}>
                            Ana Sayfaya Dön
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default DemoKayitBasarili


