import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function EnteringCatalogue() {
    const UserData = useSelector((state) => JSON.parse(state.UserInfo.userInfo))
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const styles = {
        Header1: {
            "width": "100%",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "800",
            "fontSize": { xs: "auto", md: "50px"},
            "lineHeight": "120%",
            "textAlign": "center",
            "color": "#000000"
        },
        Header2: {
            "width": "100%",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize":{ xs: "auto", md:  "22px"},
            "lineHeight": "140%",
            "textAlign": "center",
            "color": "#303C43"
        },
        JoinButton: {
            "padding": "15px 25px",
            "gap": "10px",
            "height": "50px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "14px",
            ':hover': {
                "padding": "15px 25px",
                "gap": "10px",
                "height": "50px",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "14px",
            }
        },
        JoinButton1: {
            display: UserData.firmaId !== 0 && !UserData ? '' : 'none',
            "padding": "15px 25px",
            "gap": "10px",
            "height": "50px",
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "14px",
            ':hover': {
                "padding": "15px 25px",
                "gap": "10px",
                "height": "50px",
                "background": "#FF6600",
                "boxShadow": "none",
                "borderRadius": "14px",
            }
        },
    }

    const navigate = useNavigate();
    const handleClickCreateFirma = () => {
        navigate('/PazarYeri/FirmaEkle')
    }

    const handleClickJoinCatalogue = () => {
        const id = 'scrollToJoinCatalogue';
        const yOffset = -30;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
    return (
        <>
            <Grid
                sx={{
                    display: UserData.firmaId !== 0 && !UserData ? '' : 'none'
                }}
                pb={0} p={5} backgroundColor="#ea651c14"  align='center' item xl={12} md={12} xs={12}>
                <Typography  sx={styles.Header1}>
                    {translate.PazarYeriHeader1}
                </Typography>
                <Typography mt={5} mb={5} sx={styles.Header2}>
                    {translate.PazarYeriHeader2}
                </Typography>
                <Button
                    onClick={handleClickJoinCatalogue}
                    sx={styles.JoinButton1}>
                    <Typography sx={{
                        color: 'white',
                        "fontFamily": "'Poppins'",
                        "fontStyle": "normal",
                        "fontWeight": { xs: "500", md: "600"},
                        "fontSize": { xs: "10px", md: "1rem"},
                        "textAlign": "center",
                    }}>
                        {translate.PazarYerindeYeriniziAlin}
                    </Typography>
                </Button>
            </Grid>

            <Grid
                sx={{
                    display: UserData.firmaId !== 0 && UserData ? '' : 'none'
                }}
                pb={5} pl={{xs:2,md:10}} pr={{xs:2,md:10}} backgroundColor="#ea651c14"  align='center' item xl={12} md={12} xs={12}>
                <Typography mt={5} sx={styles.Header1}>
                    {translate.PazarYeriHeader1}
                </Typography>
                <Typography mt={5} mb={5} sx={styles.Header2}>
                    {translate.PazarYeriHeader2}
                </Typography>
                <Button
                    onClick={handleClickJoinCatalogue}
                    sx={styles.JoinButton1}>
                    <Typography sx={{
                        color: 'white',
                        "fontFamily": "'Poppins'",
                        "fontStyle": "normal",
                        "fontWeight": { xs: "500", md: "600"},
                        "fontSize": { xs: "10px", md: "1rem"},
                        "textAlign": "center",
                    }}>
                        {translate.PazarYerindeYeriniziAlin}
                    </Typography>
                </Button>
            </Grid>
            
            <Grid
                sx={{
                    display: UserData.firmaId == 0 && UserData ? '' : 'none'
                }}
                pl={10} pr={10} mb={10} align='center' item xl={12} md={12} xs={12}>
                <Typography mt={5} sx={styles.Header1}>
                    Pazar Yeri İle Firmanızı Oluşturun
                </Typography>
                <Typography mt={7} mb={5} sx={styles.Header2}>
                    Ürünlerini ve firmasını sınırları online mağazada tanıtmak isteyen firmalar çoktan burada yerini aldı. Dijital Yapı Katalogu ile siz de sınırlarınızı aşmaya hazır mısınız?
                </Typography>
                <Button
                    onClick={handleClickCreateFirma}
                    sx={styles.JoinButton}>
                    <Typography sx={{ color: 'white' }}>
                        Firmanızı Oluşturun
                    </Typography>
                </Button>
            </Grid>
        </>
    )
}

export default EnteringCatalogue