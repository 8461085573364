import { Button, Grid, IconButton, Link, TextField, Typography } from '@mui/material'
import React from 'react'
import Logo from '../Assets/Images/logo.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/system';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Amex from '../Assets/Icons/Payment Method/Amex.png'
import MasterCard from '../Assets/Icons/Payment Method/Mastercard.png'
import Visa from '../Assets/Icons/Payment Method/Visa.png'
import facebook from '../Assets/Icons/Social/fb.png'
import instagram from '../Assets/Icons/Social/ig.png'
import linkedin from '../Assets/Icons/Social/in.png'
import youtube from '../Assets/Icons/Social/Subtract.png'
import twitter from '../Assets/Icons/Social/tw.png'
import { useSelector, useDispatch } from 'react-redux'
import { ChangeLanguage } from '../Redux/Slices/userLanguageSlices';

function Footer() {

    const languageCode = useSelector((state) => state.Language.userLanguageFromLocalStorage);
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));
    const dispatch = useDispatch();

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    const styles = {
        mailText: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "14px",
            "lineHeight": "28px",
            "color": "#303C43"
        },
        mailLabel: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "22px",
            "color": "#303C43"
        },
        endIconAdorment: {
            "background": "#FF6600",
            "boxShadow": "0px 9px 22px rgba(34, 12, 0, 0.25)",
            "borderRadius": "12px",
            color: 'white',
            width: '36px',
            height: '36px'
        },
        FooterMenuHeader: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": "14px",
            "lineHeight": "28px",
            "color": "#303C43",
        },
        MenuLink: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "14px",
            "lineHeight": "22px",
            "color": "#303C43",
            "opacity": "0.75",
            'textDecoration': 'none'
        },
        LinkBlog: {
            width: "129px",
            height: "19px",
            fontFamily: "'Damion', cursive",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "120%",
            color: "#889AA4",
            flex: "none",
            order: "4",
            flexGrow: "0"
        },
        linkWrapper: {
            marginTop: '1em'
        },
        goUpBtn: {
            "width": "61px",
            "height": "61px",
            "background": "#FF6600",
            "boxShadow": "0px 18px 52px rgba(196, 78, 0, 0.25)",
            color: 'white',
            ':hover': {
                bgcolor: '#FF6600',
                boxShadow: 'none',
            }
        },
        Copyright: {
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "12px",
            "lineHeight": "20px",
            "color": "#0A142F",
            "opacity": "0.6"
        },
        SocialIcons: {
            paddingRight: '2em',
            cursor: 'pointer'
        },
        ChangeLanguageButton: {
            textTransform: 'none'
        }
    }

    return (
        <>
            <Grid sx={{ backgroundColor: '#F4F7F9' }} container pt={10} spacing={0} >
                <Grid pl={5} item xl={3} md={3} xs={8}>
                    <img src={Logo} alt='yapiradar.com' />
                    <Typography mt={3} sx={styles.mailText}>
                        {translate.MailListesiAboneOl}
                    </Typography>
                    <TextField
                        variant='standard'
                        label={translate.EpostaAdresiniziYazin}
                        sx={styles.mailLabel}
                        InputProps={{
                            endAdornment: (
                                <IconButton position="end">
                                    <ArrowForwardIcon sx={styles.endIconAdorment} />
                                </IconButton>
                            ),
                        }}
                    >
                    </TextField>
                </Grid>
                <Grid sx={{ display: { xs: 'flex', md: 'none' } }} item xl={1} md={1} xs={4}>
                    <IconButton
                        onClick={handleScrollToTop}
                        sx={styles.goUpBtn}>
                        <ArrowUpwardIcon />
                    </IconButton >
                </Grid>
                <Grid
                    sx={{
                        marginLeft: { xs: '3em', md: '0' },
                        marginTop: { xs: '2em', md: '0' }
                    }}
                    item mb={4} xl={8} md={8} xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xl={3} md={3} xs={6}>
                            <Typography sx={styles.FooterMenuHeader}>
                                {translate.Menu}
                            </Typography>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/Faydalar" sx={styles.MenuLink}>
                                    {translate.Faydalar}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/Hizmetler" sx={styles.MenuLink}>
                                    {translate.Hizmetler}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/Hakkimizda" sx={styles.MenuLink}>
                                    {translate.Hakkimizda}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="https://yapigundem.com/" sx={styles.MenuLink}>
                                    Yapı Gündem <span className='navLinkBlog' id='navLinkBlog' style={styles.LinkBlog}>Blog</span>
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none"
                                    href="/PazarYeri"
                                    sx={styles.MenuLink}>
                                    Pazar Yeri
                                </Link>
                            </Box>

                        </Grid>
                        <Grid item xl={3} md={3} xs={12}>
                            <Typography sx={styles.FooterMenuHeader}>
                                {translate.Urunler}
                            </Typography>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/GuncelInsaatProjeleri" sx={styles.MenuLink}>
                                    {translate.GuncelInsaatProjeleri}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/KamuYapimIhaleleri" sx={styles.MenuLink}>
                                    {translate.KamuYapinIhaleleri}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/SatinAlmaTalepleri" sx={styles.MenuLink}>
                                    {translate.SatinAlmaTalepleri}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/FirmaRehberi" sx={styles.MenuLink}>
                                    {translate.FirmaRehberi}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/YapiradarCRM" sx={styles.MenuLink}>
                                    Yapıradar CRM
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/PSTS" sx={styles.MenuLink}>
                                    (P.S.T.S)
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/BayiYonetimModulu" sx={styles.MenuLink}>
                                    {translate.BayiYonetimModulu}
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12}>
                            <Typography sx={styles.FooterMenuHeader}>
                                {translate.Diger}
                            </Typography>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="#" sx={styles.MenuLink}>
                                    {translate.basindabiz}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="#" sx={styles.MenuLink}>
                                    {translate.InsaatTerimleri}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/BizeUlasin" sx={styles.MenuLink}>
                                    {translate.Iletisim}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/SSS" sx={styles.MenuLink}>
                                    {translate.SSS}
                                </Link>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <Link underline="none" href="/KVKK" sx={styles.MenuLink}>
                                    {translate.KVKK}
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xl={3} md={3} xs={12}>
                            <Typography sx={styles.FooterMenuHeader}>
                                {translate.Eposta}
                            </Typography>
                            <Box sx={styles.linkWrapper}>
                                <a href="mailto:info@yapiradar.com" style={styles.MenuLink}>
                                    info@yapiradar.com
                                </a>
                            </Box>

                            <Typography mt={2} sx={styles.FooterMenuHeader}>
                                {translate.MusteriHizmetleri}
                            </Typography>
                            <Box sx={styles.linkWrapper}>
                                <a href="tel:908504415554" style={styles.MenuLink}>
                                    +90 (850) 441 55 54
                                </a>
                            </Box>
                            <Box sx={styles.linkWrapper}>
                                <a href="tel:902168070737" style={styles.MenuLink}>
                                    +90 (216) 807 07 37
                                </a>
                            </Box>
                            <Button onClick={() => dispatch(ChangeLanguage())} sx={styles.ChangeLanguageButton}>
                                <Typography mt={2} sx={styles.FooterMenuHeader}>
                                    {languageCode === 'Turkish' ? 'English' : 'Turkish'}
                                </Typography>
                            </Button>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xl={1} md={1} xs={12}>
                    <IconButton
                        onClick={handleScrollToTop}
                        sx={styles.goUpBtn}>
                        <ArrowUpwardIcon />
                    </IconButton >
                </Grid>
                <Grid sx={{ backgroundColor: 'white' }} height='5em' item xl={12} md={12} xs={12}>
                    <Grid container spacing={0}>
                        <Grid mt={3} align='center' item xl={2} md={2} xs={12}>
                            <img src={Visa} alt='payment' />
                            <img src={MasterCard} alt='payment' />
                            <img src={Amex} alt='payment' />
                        </Grid>
                        <Grid mt={4} align='center' item xl={7} md={7} xs={12}>
                            <Typography sx={styles.Copyright}>
                                {translate.Telif}
                            </Typography>
                        </Grid>
                        <Grid mt={3} item xl={3} md={3} xs={12} sx={{ textAlign: { xs: "center", md: "left" } }}>
                            <img onClick={() => { window.location.href = 'https://www.facebook.com/yapiradar1' }} style={styles.SocialIcons} src={facebook} alt='social' />
                            <img onClick={() => { window.location.href = 'https://twitter.com/yapiradar' }} style={styles.SocialIcons} src={twitter} alt='social' />
                            <img onClick={() => { window.location.href = 'https://tr.linkedin.com/company/yapiradarcom' }} style={styles.SocialIcons} src={linkedin} alt='social' />
                            <img onClick={() => { window.location.href = 'https://www.instagram.com/yapiradar' }} style={styles.SocialIcons} src={instagram} alt='social' />
                            <img onClick={() => { window.location.href = 'https://www.youtube.com/@YAPIRADAR' }} style={styles.SocialIcons} src={youtube} alt='social' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}

export default Footer