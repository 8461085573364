import ArrowForward from '@mui/icons-material/ArrowForward'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import itemImage from '../../Assets/Images/Item1.webp'
import ItemCard from './ItemCard'
import { postData } from '../../Assets/Scripts/dataAxios'
import ArrowBack from '@mui/icons-material/ArrowBack'

function KategoridekiUrunler(props) {
    // const newItemsData = children.data.NewItemsData;
    const navigate = useNavigate();

    const handleCardClick = (id) => {
        navigate(id)
    }

    const [newItemsDataHizmetSaglayicilar, SetNewItemsDataHizmetSaglayicilar] = useState([]);
    const [newItemsDataKabaYapi, SetNewItemsDataKabaYapi] = useState([]);
    const [newItemsDataInceYapi, SetNewItemsDataInceYapi] = useState([]);
    const [newItemsDataElektrikElektronik, SetNewItemsDataElektrikElektronik] = useState([]);
    const [newItemsDataMekanikTesisat, SetNewItemsDataMekanikTesisat] = useState([]);
    const [newItemsDataBitirmeIsleri, SetNewItemsDataBitirmeIsleri] = useState([]);
    useEffect(() => {
        const GetNewItemsDataHizmetSaglayicilar = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
                "orderBy": {
                    "key": "random",
                    "value": true
                },
                "pageSize": 20,
                "currentPage": 0,
                "isPagingEnabled": true,
                "firmaids": [],
                "kategoriIds": [101],
            });
            SetNewItemsDataHizmetSaglayicilar(response.data.value.data);
        }
        const GetNewItemsDataKabaYapi = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
                "orderBy": {
                    "key": "random",
                    "value": true
                },
                "pageSize": 20,
                "currentPage": 0,
                "isPagingEnabled": true,
                "firmaids": [],
                "kategoriIds": [102],
            });
            SetNewItemsDataKabaYapi(response.data.value.data);
        }

        const GetNewItemsDataInceYapi = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
                "orderBy": {
                    "key": "random",
                    "value": true
                },
                "pageSize": 20,
                "currentPage": 0,
                "isPagingEnabled": true,
                "firmaids": [],
                "kategoriIds": [103],
            });
            SetNewItemsDataInceYapi(response.data.value.data);
        }

        const GetNewItemsDataElektrikElektronik = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
                "orderBy": {
                    "key": "random",
                    "value": true
                },
                "pageSize": 20,
                "currentPage": 0,
                "isPagingEnabled": true,
                "firmaids": [],
                "kategoriIds": [104],
            });
            SetNewItemsDataElektrikElektronik(response.data.value.data);
        }

        const GetNewItemsDataMekanikTesisat = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
                "orderBy": {
                    "key": "random",
                    "value": true
                },
                "pageSize": 20,
                "currentPage": 0,
                "isPagingEnabled": true,
                "firmaids": [],
                "kategoriIds": [105],
            });
            SetNewItemsDataMekanikTesisat(response.data.value.data);
        }

        const GetNewItemsDataBitirmeIsleri = async () => {
            const response = await postData('DijitalKatalog/DijitalFirmaUrunList', {
                "orderBy": {
                    "key": "random",
                    "value": true
                },
                "pageSize": 20,
                "currentPage": 0,
                "isPagingEnabled": true,
                "firmaids": [],
                "kategoriIds": [106],
            });
            SetNewItemsDataBitirmeIsleri(response.data.value.data);
        }

        GetNewItemsDataHizmetSaglayicilar()
            .catch(console.error);
        GetNewItemsDataKabaYapi()
            .catch(console.error);
        GetNewItemsDataMekanikTesisat()
            .catch(console.error);
        GetNewItemsDataInceYapi()
            .catch(console.error);
        GetNewItemsDataElektrikElektronik()
            .catch(console.error);
        GetNewItemsDataBitirmeIsleri()
            .catch(console.error);
    }, [])

    const styles = {
        Header1: {
            "height": {xs:"auto", md: "auto"},
            "top": "calc(50% - 56px/2 - 2987.5px)",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "600",
            "fontSize": {xs:"auto", md: "30px"} ,
            "lineHeight": "140%",
            "display": "flex",
            "alignItems": "center",
            "color": "#000000"
        },
        Card: {
            width: '250px',
            backgroundColor: '#F4F7F9',
            padding: 0,
            boxShadow: 'none',
            margin: '0px 3vw',
            cursor: 'pointer',
        },
        CardContent: {
            padding: 0,
            height: 'fitContent'
        },
        SeeAllBtn: {
            textTransform: 'none',
            color: '#FF6600',
            borderRadius:"30px",
            backgroundColor:"#fdf3ed",
        },
        SeeAllText: {

        },
        cardHeader: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": "16px",
            "lineHeight": "120%",
            "color": "#8FBFFA",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
        },
        cardText: {
            paddingLeft: '1vw',
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": "22px",
            "lineHeight": "120%",
            "color": "#000000",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
        },
        newItemsWrapper: {
            display: 'inline-flex',
        },
        ArrowButton: {
            margin: '0px 10px',
            "width": "61px",
            "height": "61px",
            "background": "#FFFFFF",
            "opacity": "0.5",
            "border": "1px solid #BCC7CE"
        },
        ArrowButtonWrapper: {
            position: 'absolute',
            bottom: '0'
        },
        Iconbutton: {
            color: '#BCC7CE',
            ':hover': {
                color: '#FF6600',
            }
        }
    }

    const scrollElementHizmet = useRef(null)
    const scrollLeftHizmet = (val) => {
        scrollElementHizmet.current.scrollLeft += val
    }

    const scrollElementKaba = useRef(null)
    const scrollLeftKaba = (val) => {
        scrollElementKaba.current.scrollLeft += val
    }

    const scrollElementInce = useRef(null)
    const scrollLeftInce = (val) => {
        scrollElementInce.current.scrollLeft += val
    }

    const scrollElementMekanik = useRef(null)
    const scrollLeftMekanik = (val) => {
        scrollElementMekanik.current.scrollLeft += val
    }

    const scrollElementElektrik = useRef(null)
    const scrollLeftElektrik = (val) => {
        scrollElementElektrik.current.scrollLeft += val
    }

    const scrollElementBitirme = useRef(null)
    const scrollLeftBitirme = (val) => {
        scrollElementBitirme.current.scrollLeft += val
    }

    return (
        <>
            <Grid sx={{ position: 'relative' }} pl={{xs:5,md:10}} pr={{xs:0,md:10}}pt={10} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        Hizmet Sağlayıcılar Kategorisindeki Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/AramaSonucu?Kategori=101,AltKategori=107-108-109-110-448,KategoriDetay=202-203-204-205-206-462-463-208-209-210-211-212-213-214-215-216-217-218-219-221-222-223-225-226-227-449-450-453-455-471-472-473-475-476-477,AramaTuru=Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid ref={scrollElementHizmet} sx={{ overflowX: 'scroll' }} mt={5} pb={5} item xl={12} md={12} xs={12}>
                    <Box sx={styles.newItemsWrapper}>
                        {newItemsDataHizmetSaglayicilar?.map((item) => (
                            <ItemCard props={item} />
                        ))}
                    </Box>

                </Grid>

            </Grid >


            <Grid sx={{ position: 'relative' }} pl={{xs:5,md:10}} pr={{xs:0,md:10}} pt={5} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        Kaba Yapı Kategorisindeki Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/AramaSonucu?Kategori=102,AltKategori=111-112-113,KategoriDetay=231-232-233-234-235-236-237-238-239-240-241-242-452-456-464-244-245-246-247-248-250-251-252-253,AramaTuru=Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid ref={scrollElementKaba} mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={12} md={12} xs={12}>
                    <Box sx={styles.newItemsWrapper}>
                        {newItemsDataKabaYapi?.map((item) => (
                            <ItemCard props={item} />
                        ))}
                    </Box>
                </Grid>
              
              
            </Grid>


            <Grid sx={{ position: 'relative' }} pl={{xs:5,md:10}} pr={{xs:0,md:10}} pt={5} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        İnce Yapı Kategorisindeki Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/AramaSonucu?Kategori=103,AltKategori=114-115-116-117-118-119-120-121-122-123,KategoriDetay=256-257-258-259-260-261-262-263-264-265-266-267-443-465-469-269-270-271-272-273-274-275-276-277-278-279-280-281-444-283-284-285-286-287-288-289-290-466-292-293-294-295-296-297-298-299-460-301-302-303-304-305-306-307-461-309-310-311-312-313-315-316-317-318-319-320-322-323-324-325-326-327-329-330-331-332-333-335-336-337-338-339-340-341-342-343-344-468,AramaTuru=Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid ref={scrollElementInce} mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={12} md={12} xs={12}>
                    <Box sx={styles.newItemsWrapper}>
                        {newItemsDataInceYapi?.map((item) => (
                            <ItemCard props={item} />
                        ))}
                    </Box>
                </Grid>
               


              
            </Grid>


            <Grid sx={{ position: 'relative' }} pl={{xs:5,md:10}} pr={{xs:0,md:10}} pt={5} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        Elektrik Elektronik Kategorisindeki Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/AramaSonucu?Kategori=104,AltKategori=124-125-126,KategoriDetay=347-348-349-350-351-352-353-354-355-356-357-358-359-360-361-447-467-470-363-445-365-366-367-368-369,AramaTuru=Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid ref={scrollElementElektrik} mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={12} md={12} xs={12}>
                    <Box sx={styles.newItemsWrapper}>
                        {newItemsDataElektrikElektronik?.map((item) => (
                            <ItemCard props={item} />
                        ))}
                    </Box>
                </Grid>
               


               
            </Grid>


            <Grid sx={{ position: 'relative' }} pl={{xs:5,md:10}} pr={{xs:0,md:10}} pt={5} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        Mekanik Tesisat Kategorisindeki Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/AramaSonucu?Kategori=105,AltKategori=127,KategoriDetay=372-373-374-375-376-377-378-379-380-381-382-383-384-385-386-387-388-389-446-451-459,AramaTuru=Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid ref={scrollElementMekanik} mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={12} md={12} xs={12}>
                   
                </Grid>
               


                
            </Grid>


            <Grid sx={{ position: 'relative' }} pl={{xs:5,md:10}} pr={{xs:0,md:10}}  pt={5} container spacing={0}>
                <Grid item xl={6} md={6} xs={12}>
                    <Typography sx={styles.Header1}>
                        Bitirme İşleri Kategorisindeki Ürünler
                    </Typography>
                </Grid>
                <Grid align='right' mt={1} item xl={6} md={6} xs={12}>
                    <Button
                        onClick={() => handleCardClick(`/PazarYeri/AramaSonucu?Kategori=106,AltKategori=128-129-130-131-132-133,KategoriDetay=392-393-394-395-396-397-398-399-400-401-402-404-405-406-407-408-409-410-411-412-413-414-415-416-454-418-419-420-421-422-423-424-425-474-427-428-429-430-431-432-433-435-436-437-438-439-442,AramaTuru=Urunler`)}
                        sx={styles.SeeAllBtn} endIcon={<ArrowForward />}>
                        <Typography sx={styles.SeeAllText}>
                            Tümünü Gör
                        </Typography>
                    </Button>
                </Grid>
                <Grid ref={scrollElementBitirme} mt={5} pb={5} sx={{ overflowX: 'scroll' }} item xl={12} md={12} xs={12}>
                    <Box sx={styles.newItemsWrapper}>
                        {newItemsDataBitirmeIsleri?.map((item) => (
                            <ItemCard props={item} />
                        ))}
                    </Box>
                </Grid>
                


               
            </Grid>

        </>
    )
}

export default KategoridekiUrunler