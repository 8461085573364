import styled from "@emotion/styled";
import { Paper } from "@mui/material";



export const StyledIhaleInfoCard = styled(Paper)({
    padding: '20px 20px 15px',
    width: '70%',
    minWidth: '120px',
    height: '100px',
    borderRadius: '10px',
    ':hover': {
        'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    }
})

export const StyledIhaleInfoCardOrange = styled(Paper)({
    cursor:'pointer',
    padding: '20px 20px 15px',
    background: 'linear-gradient(90deg, rgba(255,144,70,1) 0%, rgba(255,102,0,1) 100%)',
    width: '70%',
    minWidth: '120px',
    height: '100px',
    borderRadius: '10px',
    ':hover': {
        'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    }
})