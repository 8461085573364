import React from 'react'
import { Autocomplete, Box, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { IhaleCardFirmaAdi, IhalePageHeaderLabel } from '../../Components/Styled/Labels/StyledLabels'
import IhaleKarti from '../../Components/Ihale/IhaleKarti'
import IhaleLayout from '../Layouts/IhaleLayout';
import TurkeyMap from 'turkey-map-react';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { FilterDropdownMenu } from '../../Components/Styled/DropdownMenus/StyledDropdowns';
import TablePagination from '@mui/material/TablePagination';
import { useState } from 'react';
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import { ShowMessage } from '../../Components/Common/Alerts';
import { useCallback } from 'react';
import IhaleCustomTable from '../../Components/Ihale/IhaleCustomTable';
import { loadState } from '../../Assets/Scripts/LocalStorage';

function IhaleListesi() {
    //State and Variables________________________________________________________________________________________________________________________________________
    const params = useParams();
    const [ihaleler, SetIhaleler] = useState([]);
    const [ihaleTipleri, SetIhaleTipleri] = useState([]);
    const [resultCount, SetResultCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const UserInfoData = JSON.parse(loadState('UserInfo'));
    const [filters, SetFilters] = useState(
        {
            "orderBy": {
                "key": "Id",
                "value": true
            },
            "pageSize": 10,
            "currentPage": 0,
            'isIhaleStart': 0,
            'isIhaleStartDatePart': null,
            "isPagingEnabled": true,
            "firmaids": [],
            "kategoriIds": [],
            "ihaleadi": "",
            'sehirs': [],
            'ihaleadi': '',
        }
    )
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sehirParam = searchParams.get('sehir');
        const sehirler = sehirParam ? sehirParam.split(',').map(sehir => parseInt(sehir)) : [];

        const isIhaleStartParam = searchParams.get('isIhaleStart');
        const isIhaleStart = isIhaleStartParam ? parseInt(isIhaleStartParam) : 0;

        const IhaleBaslangicParam = searchParams.get('IhaleBaslangic');
        const IhaleBaslangic = IhaleBaslangicParam ? parseInt(IhaleBaslangicParam) : null;

        const kategoriIdsparam = searchParams.get('kategoriIds');
        const kategoriIds = kategoriIdsparam ? kategoriIdsparam.split(',').map(kategori => parseInt(kategori)) : [];

        const kelimeParam = searchParams.get('kelime');

        SetFilters(prevFilters => ({
            ...prevFilters,
            "sehirs": sehirler,
            "isIhaleStart": isIhaleStart,
            'kategoriIds': kategoriIds,
            'isIhaleStartDatePart': IhaleBaslangic,
            'ihaleadi': kelimeParam === '' || kelimeParam === null ? '' : kelimeParam,
        }));
    }, []);

    //Hooks________________________________________________________________________________________________________________________________________
    useEffect(() => {
        filters.sehirs.forEach(plakaKodu => {
            const elements = document.querySelector(`[data-plakakodu="${parseInt(plakaKodu)}"] path`);
            if (elements) {
                elements.style.fill = '#FF6600';
            }
        });
    }, [filters]);

    const fetchData = useCallback(async () => {
        try {
            const response = await postData('Ihale/DijitalFirmaIhaleList', filters)
            SetIhaleler(response.data.value.data)
            SetResultCount(response.data.value.recordsFiltered)
        } catch (error) {
            ShowMessage('error', error)
        }
    }, [filters]);

    var selectedCategories = [];
    const FixCategoryData = (param) => {
        const fixedData = [];
        param.forEach((item, index) => {
            item?.children?.forEach((item2, index2) => {
                fixedData.push({
                    key: index.toString() + index2.toString(),
                    name: item2.adi,
                    id: item2.id,
                    parent: item.adi,
                    type: 'SubCategory'
                })
                item2?.children?.forEach((item3, index3) => {
                    fixedData.push({
                        key: index.toString() + index2.toString() + index3.toString(),
                        name: item3.adi,
                        id: item3.id,
                        parent: item.adi,
                        type: 'child'
                    })

                })
            })
        })
        SetCategories(fixedData);
    }

    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('DijitalKatalog/KategoriList');
            FixCategoryData(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, [])


    useEffect(() => {
        const GetIhaleTipleri = async () => {
            try {
                const response = await getData('Ihale/TenderTypes')
                if (response.data.value) {
                    const tempData = [];
                    response.data.value.forEach(item => {
                        tempData.push(
                            {
                                label: item.name,
                                id: item.id,
                                description: item.description
                            }
                        )
                    })
                    SetIhaleTipleri(tempData);
                }
            } catch (error) {
                ShowMessage('error', error)
            }
        }
        GetIhaleTipleri();
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Function and methods________________________________________________________________________________________________________________________________________
    const renderCity = (cityComponent, cityData) => (
        <Tooltip title={cityData.name} key={cityData.id}>
            {cityComponent}
        </Tooltip>
    );

    const handleChangePage = (event, newPage) => {
        setPage(parseInt(newPage));
        SetFilters({
            ...filters,
            'currentPage': newPage
        })
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        SetFilters({
            ...filters,
            'pageSize': event.target.value
        })
    };

    useEffect(() => {
        const paths = document.querySelectorAll('[data-plakakodu] path');

        paths.forEach(path => {
            path.addEventListener('mouseenter', handleMouseEnter);
            path.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            paths.forEach(path => {
                path.removeEventListener('mouseenter', handleMouseEnter);
                path.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    const handleMouseEnter = (event) => {
        const pathElement = event.target;
        // Mouse hover olduğunda rengi değiştir
        pathElement.style.fill = '#78A3BC';
    };

    const handleMouseLeave = (event) => {
        const pathElement = event.target;
        // Mouse ayrıldığında orijinal rengi geri yükle
        pathElement.style.fill = '#78A3BC'; // veya orijinal renginizi buraya ekleyin
    };

    return (
        <div>
            <IhaleLayout >
                <Grid pl={5} pr={5} mt={15} container spacing={0}>
                    <Grid item xl={12} md={12} xs={12}>
                        <IhalePageHeaderLabel>
                            <span style={{ color: 'black' }}>Arama Sonuçları</span>
                        </IhalePageHeaderLabel>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={0}>
                            <Grid sx={{ position: 'relative' }} item xl={12} md={12} xs={12}>
                                <Box>
                                    <TurkeyMap
                                        customStyle={{ idleColor: "#78A3BC", hoverColor: "#78A3BC" }}
                                        cityWrapper={renderCity}
                                        showTooltip={true}
                                        tooltipText='İhale Sayısı : 12'
                                        onClick={({ plateNumber, name }) => window.location.href = `/Ihale/IhaleListesi?sehir=${plateNumber}`}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid align='center' columns={6} container spacing={2}>
                        <Grid item xl={2} md={2} xs={12}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                multiple={true}
                                options={categories}
                                defaultValue={filters.kategoriIds.map(index => categories[index])}
                                renderInput={(params) => <TextField {...params} label="İhale Kategorisi" />}
                            />
                        </Grid>

                        <Grid item xl={2} md={2} xs={12}>
                            <Autocomplete
                                disablePortal
                                multiple={true}
                                id="combo-box-demo"
                                options={ihaleTipleri}
                                renderInput={(params) => <TextField {...params} label="İhale Bölgesi" />}
                            />
                        </Grid>

                        <Grid item xl={2} md={2} xs={12}>
                            <Autocomplete
                                disablePortal
                                multiple={true}
                                id="combo-box-demo"
                                options={ihaleTipleri}
                                renderInput={(params) => <TextField {...params} label="İhale Tipi" />}
                            />
                        </Grid>
                    </Grid>

                    <Grid sx={{ background: '#F4F7F9', padding: '1em' }} mt={8} mb={8} item xl={12} md={12} xs={12}>
                        <Box sx={{display:'flex',justifyContent:'space-between'}} padding='1em 1em 0em 1em'>
                            <Box sx={{display:'block'}}>
                                <Typography
                                    sx={{
                                        fontFamily: '"Nunito", sans-serif',
                                        fontSize: '20px',
                                        lineHeight: '24.55px',
                                        fontWeight: 700,
                                        color: '#3C6780'
                                    }}
                                >
                                    Arama Sonuçları
                                </Typography>

                                <Typography
                                 sx={{
                                    fontFamily: '"Nunito", sans-serif',
                                    fontSize: '13px',
                                    lineHeight: '17.73px',
                                    fontWeight: 700,
                                    color: '#007F90'
                                }}>
                                    Girmiş olduğunuz filtreleme kriterlerine göre {resultCount} adet proje bulundu.
                                </Typography>
                            </Box>

                            <Box>
                                <TablePagination
                                    labelDisplayedRows={
                                        ({ from, to, count }) => {
                                            return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                                        }}
                                    labelRowsPerPage="Sayfada şu kadar sonuç göster"
                                    sx={{color:'#B6CCD8'}}
                                    component="div"
                                    count={100}
                                    page={filters.currentPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={filters.pageSize}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        </Box>

                        <Box pt='1em'>
                            {
                                Array.isArray(ihaleler) && ihaleler.length > 0 ? (
                                    ihaleler.map((item, index) => (
                                        <IhaleCustomTable
                                            key={index}
                                            props={item}
                                        />
                                    ))
                                ) : (
                                    <>Arama Sonucunda Bir İhale Bulunamadı.</>
                                )
                            }

                            <TablePagination
                                labelDisplayedRows={
                                    ({ from, to, count }) => {
                                        return '' + count + ' ' + 'Kayıttan' + ' ' + from + '-' + to + ' ' + 'Arası Sonuç Gösteriliyor '
                                    }}
                                component="div"
                                count={resultCount}
                                sx={{color:'#B6CCD8'}}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage="Sayfada şu kadar sonuç göster"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50
                                ]}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </IhaleLayout>
        </div>
    )
}
export default IhaleListesi
