import React, { useEffect, useRef, useState } from 'react'
import IhaleLayout from '../Layouts/IhaleLayout'
import { Autocomplete, Box, Button, Grid, Paper, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material'
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import { StyledLabel } from '../../Components/Styled/Labels/StyledLabels';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { loadState } from '../../Assets/Scripts/LocalStorage';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ShowMessage } from '../../Components/Common/Alerts';

function IhaleOlustur() {
    const UserInfoData = JSON.parse(loadState('UserInfo'));
    const [stepsActive, SetStepsActive] = useState(0);
    const [cities, SetCities] = useState([]);
    const [ilceler, SetIlceler] = useState([]);
    const [mahalleler, SetMahalleler] = useState([]);
    const [ihaleData, setIhaleData] = useState({
        'Uyefirmaid': UserInfoData.UyefirmaId,
        "id": 0,
        "aktif": 0,
        "ihaleAdi": "",
        "ihaletarihiBegin": 0,
        "ihaletarihiEnd": 0,
        "teslimtarihi": 0,
        "tanim": "",
        "kisaTanim": "",
        "ihaletipi": 0,
        "katilimTipId": 0,
        "segmentId": 0,
        "bolgeId": 0,
        "ulkeId": 216,
        "sehirId": 0,
        "ilceId": 0,
        "mahalleId": 0,
        "cadSk": "",
        "latitude": "",
        "longitude": "",
        "deleteKatilimcis": [],
        "addKatilimcis": [],
        "kategoris": []
    });
    const steps = [
        'İhale Bilgileri',
        'İhale Özellikleri',
        'İhale Tarihi',
        'İşin Konumu',
        'Şartname',
    ];

    const fileInputRef = useRef(null);
    var selectedCategories = [];
    const [selectedFileName, setSelectedFileName] = useState(null);
    const styles = {
        btnPrevious: {
            display: stepsActive === 0 ? 'none' : 'block',
            marginRight: '1em',
            "boxSizing": "border-box",
            "width": "164px",
            "height": "63px",
            "background": "#F4F7F9",
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            color: '#303C43',

        },
        btnNext: {
            display: stepsActive === 4 ? 'none' : 'block',
            marginRight: '0em',
            "boxSizing": "border-box",
            "width": "164px",
            "height": "63px",
            "background": "#FF6600",
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                "background": "#FF6600",
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px",
                boxShadow: ''
            }
        },
        btnSave: {
            display: stepsActive === 4 ? 'block' : 'none',
            "boxSizing": "border-box",
            "height": "63px",
            "background": "#FF6600",
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            color: 'white',
            ':hover': {
                "background": "#FF6600",
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px",
                boxShadow: ''
            }
        },
        CategoriesList: {
            "border": "1px solid #BCC7CE",
            "borderRadius": "100px",
            "padding": "10px 15px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "400",
            "fontSize": "12px",
            "lineHeight": "120%",
            "color": "#303C43",
            "flex": "none",
            "order": "0",
            "flexGrow": "0",
            marginTop: '0.5em'
        },
        CategoriesListWrapper: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            display: 'flex',
            maxWidth: '440px'
        },
        Selects: {
            marginTop: '1em',
            width: "90%",
            height: '52px',
            "border": "1px solid #BCC7CE",
            "borderRadius": "20px",
            [`& fieldset`]: {
                border: `none`,
                color: '#333'
            },
            "& label": {
                marginTop: '0.5em',
                "fontFamily": "'Poppins'",
                "fontStyle": "normal",
                "fontWeight": "500",
                "fontSize": "14px",
                "lineHeight": "130%",
                "color": '#889AA4'
            }
        },
        textField: {
            marginTop: '1em',
            "background": "#FFFFFF",
            backgroundColor: 'transparent',
            width: "90%",
            [`& fieldset`]: {
                "border": "1px solid #BCC7CE",
                "borderRadius": "20px"
            },
            "& label": {
                "fontFamily": "'Poppins'",
                "fontStyle": "normal",
                "fontWeight": "500",
                "fontSize": "14px",
                "lineHeight": "130%",
                "color": '#889AA4'
            }
        },
    }

    const FixCategoryData = (param) => {
        const fixedData = [];
        param.forEach((item, index) => {
            item?.children?.forEach((item2, index2) => {
                fixedData.push({
                    key: index.toString() + index2.toString(),
                    name: item2.adi,
                    id: item2.id,
                    parent: item.adi,
                    type: 'SubCategory'
                })
                item2?.children?.forEach((item3, index3) => {
                    fixedData.push({
                        key: index.toString() + index2.toString() + index3.toString(),
                        name: item3.adi,
                        id: item3.id,
                        parent: item.adi,
                        type: 'child'
                    })

                })
            })
        })
        SetCategories(fixedData);
    }

    useEffect(() => {
        const GetCitiesData = async () => {
            const res = await postData('DijitalKatalog/SehirList', {
                "boldeIds": [
                ],
                "ulkeIds": [
                    216
                ],
                "ids": [
                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetCities(tempval);
        }

        GetCitiesData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        const GetIlcelerData = async () => {
            const res = await postData('DijitalKatalog/IlceList', {
                "sehirIds":
                    [ihaleData.sehirId]
                ,
                "ids": [

                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetIlceler(tempval);
        }

        GetIlcelerData()
            .catch(console.error);
    }, [ihaleData.sehirId])

    useEffect(() => {
        const GetMahalleData = async () => {
            const res = await postData('DijitalKatalog/MahalleList', {
                "ilceIds": [
                    ihaleData.ilceId
                ],
                "ids": [
                ]
            });
            const tempval = [];
            res?.data?.value.forEach((item) => {
                tempval.push(
                    {
                        label: item.ad,
                        id: item.id
                    }
                )
            })
            SetMahalleler(tempval);
        }

        GetMahalleData()
            .catch(console.error);
    }, [ihaleData.ilceId])

    const handleCityChange = (param) => {
        setIhaleData(prevState => ({
            ...prevState,
            'sehirId': param.id
        }))
    }
    const handleIlcelerChange = (param) => {
        setIhaleData(prevState => ({
            ...prevState,
            'ilceId': param.id
        }))
    }

    const handleMahallelerChange = (param) => {
        setIhaleData(prevState => ({
            ...prevState,
            'mahalleId': param.id
        }))
    }

    const [ihaleKatilimTipleri, SetIhaleKatilimTipleri] = useState([]);
    useEffect(() => {
        const GetKatilimData = async () => {
            const res = await getData('Ihale/IhaleKatilimTipleri');
            SetIhaleKatilimTipleri(res.data.value);
        }
        GetKatilimData()
            .catch(console.error);
    }, [])

    const [ihaleSegmentler, SetIhaleSegmentler] = useState([]);
    useEffect(() => {
        const getSegmentler = async () => {
            const res = await getData('Ihale/IhaleSegmentler');
            SetIhaleSegmentler(res.data.value);
        }
        getSegmentler()
            .catch(console.error);
    }, [])


    const [ihaleTipleri, SetIhaleTipleri] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('Ihale/TenderTypes');
            SetIhaleTipleri(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, [])

    const [categories, SetCategories] = useState([]);
    useEffect(() => {
        const GetCategoriesData = async () => {
            const res = await getData('DijitalKatalog/KategoriList');
            FixCategoryData(res.data.value);
        }
        GetCategoriesData()
            .catch(console.error);
    }, [])

    const handleCategoryChange = (param) => {
        if (param) {
            setIhaleData(prevState => ({
                ...prevState,
                kategoris: [param.id]
            }))
        } else {
            setIhaleData(prevState => ({
                ...prevState,
                kategoris: 0
            }))
        }
    }

    const handleTenderTypeChange = (param) => {
        if (param) {
            setIhaleData(prevState => ({
                ...prevState,
                ihaletipi: param.id
            }))
        } else {
            setIhaleData(prevState => ({
                ...prevState,
                ihaletipi: 0
            }))
        }
    }

    const handleKatilimTipiChange = (param) => {
        if (param) {
            setIhaleData(prevState => ({
                ...prevState,
                katilimTipId: param.id
            }))
        } else {
            setIhaleData(prevState => ({
                ...prevState,
                katilimTipId: 0
            }))
        }
    }


    const handleSegmentChange = (param) => {
        if (param) {
            setIhaleData(prevState => ({
                ...prevState,
                segmentId: param.id
            }))
        } else {
            setIhaleData(prevState => ({
                ...prevState,
                segmentId: 0
            }))
        }
    }

    const handleUploadSartname = async () => {
        const formData = new FormData();
        const selectedFile = fileInputRef.current.files[0];
        setSelectedFileName(selectedFile.name);
        console.log(selectedFile)
    }

    const handleSave = async () => {
        if (fileInputRef.current.files[0]) {
            try {
                const response = await postData('Ihale/IhaleInsertorUpdate', ihaleData);
                console.log(response)
                if (response.data.isSucceeded) {
                    const formData = new FormData();
                    formData.append('id', 0);
                    formData.append('aktif', 1);
                    formData.append('type', 0);
                    formData.append('ihaleId', response?.data.value.ihaleId);
                    formData.append('file', fileInputRef.current.files[0])

                    const response2 = await postData('Ihale/IhaleDocument', formData);
                    console.log(response2);
                    if (response2.data.isSucceeded) {
                        window.location.href = `/Ihale/Detay/${response?.data.value.seourl}`
                    } else {
                        ShowMessage('error', response2?.data?.error?.errorMessage)
                    }
                } else {
                    ShowMessage('error', response?.data?.error?.errorMessage)
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            ShowMessage('error', 'Lütfen Ürün Teknik Şartnamenizi Yükleyiniz.')
        }
    }

    const [minIhaleIsTarihiDate, SetminIhaleIsTarihiDate] = useState(null);
    const handleChangeIhaleTarihi = (e) => {
        SetminIhaleIsTarihiDate(e);
        let year = e.$y;
        let mouth = e.$M + 1
        let day = e.$D;
        let hour = e.$H;
        let minute = e.$m;
        let date = year;

        if (mouth < 10) {
            date += '0' + mouth
        } else {
            date += mouth
        }

        if (day < 10) {
            date += '0' + day
        } else {
            date += day
        }

        if (hour < 10) {
            date += '0' + hour
        } else {
            date += hour
        }

        if (minute < 10) {
            date += '0' + minute
        } else {
            date += minute
        }
        date += '00'

        setIhaleData(prevState => ({
            ...prevState,
            ihaletarihiBegin: date
        }))

        let endDate = date;
        let endHours = endDate.slice(8, 10);
        endHours = parseInt(endHours) + 1
        if (endHours < 10) {
            endHours = '0' + endHours
        } else {
            endHours.toString();
        }

        const EndDateFull = date.slice(0, 8) + endHours + date.slice(10)

        setIhaleData(prevState => ({
            ...prevState,
            ihaletarihiEnd: EndDateFull
        }))
    }

    const handleChangeIhaleTeslimTarihi = (e) => {
        let year = e.$y;
        let mouth = e.$M + 1
        let day = e.$D;
        let hour = e.$H;
        let minute = e.$m;
        let date = year;

        if (mouth < 10) {
            date += '0' + mouth
        } else {
            date += mouth
        }

        if (day < 10) {
            date += '0' + day
        } else {
            date += day
        }

        if (hour < 10) {
            date += '0' + hour
        } else {
            date += hour
        }

        if (minute < 10) {
            date += '0' + minute
        } else {
            date += minute
        }
        date += '00'

        setIhaleData(prevState => ({
            ...prevState,
            teslimtarihi: date
        }))
    }


    const validateNext = () => {
        if (stepsActive === 0) {
            SetStepsActive(1);
        } else if (stepsActive === 1) {
            SetStepsActive(2);
        } else if (stepsActive === 2) {
            SetStepsActive(3);
        } else if (stepsActive === 3) {
            SetStepsActive(4);
        } else if (stepsActive === 4) {
            SetStepsActive(5);
        } else {
            ShowMessage('error', 'Lütfen Alanları Eksiksiz Doldurunuz.')
        }
    }

    const handlePrevious = () => {
        if (stepsActive === 0) {
            SetStepsActive(0);
        } else if (stepsActive === 1) {
            SetStepsActive(0);
        } else if (stepsActive === 2) {
            SetStepsActive(1);
        } else if (stepsActive === 3) {
            SetStepsActive(2);
        } else if (stepsActive === 4) {
            SetStepsActive(3);
        } else if (stepsActive === 5) {
            SetStepsActive(4);
        }
    }

    return (
        <IhaleLayout>
            <Box sx={{ background: '#F4F7F9' }}>
                <Paper  sx={{ p: 2 }} elevation={0}>
                    <Grid container spacing={3}>
                        <Grid item pl={5} pr={5} xl={2} md={2} xs={12}>
                            <Typography fontSize='22px' mb={5}>
                                İhale Oluştur
                            </Typography>
                        </Grid>
                        <Grid item pl={5} pr={5} xl={10} md={10} xs={12}>
                            <Box sx={{ width: '100%' }}>
                                <Stepper sx={{ flexDirection: { xs: "column ", md: "row" } }} activeStep={stepsActive}>
                                    {steps.map((label) => (
                                        <Step
                                            sx={{
                                                '& .MuiStepLabel-root .Mui-completed': {
                                                    color: '#8FBFFA', // circle color (COMPLETED)
                                                },
                                                '& .MuiStepLabel-root .Mui-completed':
                                                {
                                                    color: '#BCC7CE', // Just text label (COMPLETED)
                                                },
                                                '& .MuiStepLabel-root .Mui-active': {
                                                    color: '#8FBFFA', // circle color (ACTIVE)
                                                },
                                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                {
                                                    color: '#FFFFFF', // Just text label (ACTIVE)
                                                },
                                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                {
                                                    color: '#FFFFFF', // Just text label (ACTIVE)
                                                },
                                            }}
                                            key={label}>
                                            <Box sx={{
                                                border: '1px solid #BCC7CE',
                                                borderRadius: '100px',
                                                borderColor: '#BCC7CE',
                                                height: '49px',
                                                width: { xs: '35vh', md: '100%' },
                                                display: 'inline-flex',
                                                color: 'white',
                                                padding: '0px 5px'
                                            }}>
                                                <StepLabel>{label}</StepLabel>
                                            </Box>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </Grid>

                        <Grid sx={{ justifyContent: 'center', display: stepsActive === 0 ? 'flex' : 'none' }} pt={2} pb={2} item xl={12} md={12} xs={12}>
                            <Grid sx={{ width: '700px', minHeight: '500px' }} container spacing={3}>
                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography >
                                            İhale Kategorisi
                                        </Typography>

                                        <Autocomplete
                                            getOptionLabel={(option) => option.name}
                                            groupBy={(option) => option.parent}
                                            onChange={(e, v) => handleCategoryChange(v)}
                                            limitTags={4}
                                            renderOption={(props, option) => option.type == 'SubCategory' ? (
                                                <li {...props}>
                                                    <Box
                                                        sx={{
                                                            fontWeight: '700',
                                                        }}
                                                    >
                                                        {option.name}
                                                    </Box>
                                                </li>) : (<li key={option.id} {...props}> {option.name} </li>)
                                            }
                                            id="disabled-options-demo"
                                            options={categories}
                                            sx={styles.Selects}
                                            renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Tipi
                                        </Typography>

                                        <Autocomplete
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, v) => handleTenderTypeChange(v)}
                                            limitTags={4}
                                            id="disabled-options-demo"
                                            options={ihaleTipleri}
                                            sx={styles.Selects}
                                            renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Adi
                                        </Typography>
                                        <TextField
                                            value={ihaleData?.ihaleAdi}
                                            onChange={e => setIhaleData(prevState => ({ ...prevState, ihaleAdi: e.target.value }))}
                                            inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                            sx={styles.textField}
                                        />
                                    </Box>
                                </Grid>


                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Açıklaması
                                        </Typography>
                                        <TextField
                                            multiline
                                            rows={5}
                                            value={ihaleData?.kisaTanim}
                                            inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                                            onChange={e => setIhaleData(prevState => ({ ...prevState, kisaTanim: e.target.value }))}
                                            sx={styles.textField}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ justifyContent: 'center', display: stepsActive === 1 ? 'flex' : 'none' }} pt={2} pb={2} item xl={12} md={12} xs={12}>
                            <Grid sx={{ width: '700px', minHeight: '500px' }} container spacing={3}>

                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Katılım Tipi
                                        </Typography>

                                        <Autocomplete
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, v) => handleKatilimTipiChange(v)}
                                            limitTags={4}
                                            id="disabled-options-demo"
                                            options={ihaleKatilimTipleri}
                                            sx={styles.Selects}
                                            renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                        />
                                    </Box>
                                </Grid>


                                <Grid sx={{ display: ihaleData.katilimTipId === 1 ? 'block' : 'none' }} item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Segment
                                        </Typography>

                                        <Autocomplete
                                            getOptionLabel={(option) => option.adi}
                                            onChange={(e, v) => handleSegmentChange(v)}
                                            limitTags={4}
                                            id="disabled-options-demo"
                                            options={ihaleSegmentler}
                                            sx={styles.Selects}
                                            renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid sx={{ justifyContent: 'center', display: stepsActive === 2 ? 'flex' : 'none' }} pt={2} pb={2} item xl={12} md={12} xs={12}>
                            <Grid sx={{ width: '700px', minHeight: '500px' }} container spacing={3}>
                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Tarihi
                                        </Typography>

                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                sx={{
                                                    width: '90%'
                                                }}
                                                components={['DateTimePicker']}>
                                                <DateTimePicker
                                                    views={['day', 'month', 'year', 'hours', 'minutes']}
                                                    ampm={false}
                                                    format='DD/MM/YYYY HH:mm'
                                                    onChange={(newValue) => handleChangeIhaleTarihi(newValue)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Teslim Tarihi <span style={{ display: minIhaleIsTarihiDate === null ? '' : 'none', color: 'red', fontSize: '12px' }}>Önce İhale Tarihini Seçiniz</span>
                                        </Typography>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}>
                                            <DemoContainer
                                                sx={{
                                                    width: '90%'
                                                }}
                                                components={['DateTimePicker']}>
                                                <DateTimePicker
                                                    disabled={minIhaleIsTarihiDate ? false : true}
                                                    minDateTime={minIhaleIsTarihiDate}
                                                    views={['day', 'month', 'year', 'hours', 'minutes']}
                                                    ampm={false}
                                                    format='DD/MM/YYYY HH:mm'
                                                    onChange={(newValue) => handleChangeIhaleTeslimTarihi(newValue)}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ justifyContent: 'center', display: stepsActive === 3 ? 'flex' : 'none' }} pt={2} pb={2} item xl={12} md={12} xs={12}>
                            <Grid sx={{ width: '700px', minHeight: '500px' }} container spacing={3}>
                                <Grid align='left' item xl={12} md={12} xs={12}>
                                    <Typography>
                                        Şehir
                                    </Typography>
                                    <Autocomplete
                                        onChange={(e, v) => handleCityChange(v)}
                                        id="disabled-options-demo"
                                        options={cities}
                                        limitTags={2}
                                        sx={styles.textField}
                                        renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                    />
                                </Grid>

                                <Grid item xl={12} md={12} xs={12}>
                                    <Typography>
                                        İlçe
                                    </Typography>
                                    <Autocomplete
                                        onChange={(e, v) => handleIlcelerChange(v)}
                                        id="disabled-options-demo"
                                        options={ilceler}
                                        limitTags={2}
                                        sx={styles.textField}
                                        renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                    />
                                </Grid>
                                <Grid item xl={12} md={12} xs={12}>
                                    <Typography>
                                        Mahelle
                                    </Typography>
                                    <Autocomplete
                                        onChange={(e, v) => handleMahallelerChange(v)}
                                        id="disabled-options-demo"
                                        options={mahalleler}
                                        limitTags={2}
                                        sx={styles.textField}
                                        renderInput={(params) => <TextField sx={{ marginTop: '-0.5em' }} {...params} />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid sx={{ justifyContent: 'center', display: stepsActive === 4 ? 'flex' : 'none' }} pt={2} pb={2} item xl={12} md={12} xs={12}>
                            <Grid sx={{ width: '700px', minHeight: '500px' }} container spacing={3}>
                                <Grid item xl={12} md={12} xs={12}>
                                    <Box>
                                        <Typography>
                                            İhale Şartnamesi
                                        </Typography>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleUploadSartname}
                                        />
                                        <Button
                                            onClick={() => fileInputRef.current.click()}
                                            sx={{
                                                mt: 2.5,
                                                width: "90%",
                                                height: '50px',
                                                textTransform: 'none',
                                                color: 'black'
                                            }}
                                            variant='outlined'
                                            startIcon={<UploadFileIcon />}
                                        >
                                            <StyledLabel>
                                                {selectedFileName ? selectedFileName : "Ürün Teknik Şartnamesi Yükle"}
                                            </StyledLabel>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>





                    <Grid sx={{ display: 'flex', justifyContent: 'center' }} mt={3} item xs={12} md={12} xl={12}>
                        <Box sx={{ display: 'inline-flex', justifyContent: 'center' }}>
                            <Button
                                sx={styles.btnPrevious}
                                onClick={handlePrevious}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "600",
                                        "fontSize": "18px",
                                        "lineHeight": "120%",
                                        "textAlign": "center",

                                    }}
                                >
                                    Geri Dön
                                </Typography>
                            </Button>

                            <Button
                                sx={styles.btnNext}
                                onClick={validateNext}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "600",
                                        "fontSize": "18px",
                                        "lineHeight": "120%",
                                        "textAlign": "center"
                                    }}
                                >
                                    İleri
                                </Typography>
                            </Button>
                            <Button
                                sx={styles.btnSave}
                                onClick={handleSave}
                            >
                                <Typography
                                    sx={{
                                        textTransform: 'none',
                                        "fontFamily": "'Poppins'",
                                        "fontStyle": "normal",
                                        "fontWeight": "600",
                                        "fontSize": "18px",
                                        "lineHeight": "120%",
                                        "textAlign": "center"
                                    }}
                                >
                                    İhaleyi Oluştur
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        </IhaleLayout>
    )
}

export default IhaleOlustur