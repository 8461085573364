import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import Image1 from '../../Assets/Images/LaptopStandUp.webp'
function EnteringFaydalar() {
    const translate = useSelector((state) => JSON.parse(state.Language.currentLanguage));

    const styles = {
        Header1: {
            "width": {xs:"100%",md:"592px"},
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "700",
            "fontSize": {xs:"34px",md:"54px"},
            "lineHeight": "130%",
            "color": "#000000"
        },
        Header2: {
            "width": {xs:"100%",md:"592px"},
            "height": "36px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"16px",md:"24px"},
            "lineHeight": "130%",
            "color": "#e6842e"
        },
        Header3: {
            "width": {xs:"100%",md:"592px"},
            "height": "93px",
            "fontFamily": "'Poppins'",
            "fontStyle": "normal",
            "fontWeight": "500",
            "fontSize": {xs:"16px",md:"18px"},
            "lineHeight": "130%",
            "color": "#889AA4"
        }, 
         HeaderTop :{
            display: {md:"flex"}, 
            flexDirection: {md:"row-reverse"},
            backgroundColor:'#F4F7F9'
        },

    }

    return (
        <>
            <Grid  sx={styles.HeaderTop} container spacing={0}>
                <Grid  pr={4} pt={25}  sx={{ paddingTop: { xs:"10%" ,md:"15%"}, align:{xs:'center',md:"left"},paddingLeft:{xs:"30px",md:"0px"}}} item xl={6} md={6} xs={12}>
                    <Typography pb={4} sx={styles.Header1}>
                        {translate.BirPlatformBirdenFazlaFayda}
                    </Typography>
                    <Typography mt={1} sx={styles.Header2}>
                        {translate.YapiradarlilarHerZamanKazaniyor}
                    </Typography>
                    <Typography mt={4} sx={styles.Header3}>
                        {translate.MuteahhitlerIleTedarikcilerArasindakiTicareti}
                    </Typography>
                </Grid>
                <Grid align='center' mt={4} item xl={6} md={6} xs={12}>
                <img style={{
                            height: 'auto',
                            width: "80%"
                        }} src={Image1} alt='yapiradar faydalar' />
                </Grid>
            </Grid>
        </>
    )
}

export default EnteringFaydalar