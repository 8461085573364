import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import { Grid } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ShowMessage } from '../../Components/Common/Alerts';
import { Login } from '../../Redux/Slices/AuthSlices';
import { getData, postData } from '../../Assets/Scripts/dataAxios';
import { setUserInfo } from '../../Redux/Slices/UserInfoSlices';

function EMuteahhitSatinalYonlendir() {
    const [cookies, setCookie, removeCookie] = useCookies(['yapiradar']);
    const domainString = '.yapiradar.com';
    const [satinalmaId, setSatinalmaId] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => JSON.parse(state.Auth.user));

    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('param') || 'defaultTokenValue';
    };

    const token = getTokenFromUrl();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'YapıRadar.com | Giriş Yap';
        if (user) {
            window.open(`https://app.yapiradar.com/ESatinalmaMuteahhit/ESatinalmaTeklif?id=${token}`, "_self");    
        } else {
            handleLoginSubmit(token);
        }
    }, []);

    const handleCookie = (e) => {
        var future = new Date();
        future.setDate(future.getDate() + 30);
        setCookie('yapiradar', e, {
            path: '/',
            domain: domainString,
            expires: future,
            httpOnly: false,
            secure: false,
        });
        console.log(cookies);
    };

    const handleLoginSubmit = async (token) => {
        const res = await getData(`Auth/TokenLogin?Token=${token}`);
        if (res.data?.value?.token) {
            dispatch(Login(res.data.value));
            handleCookie(res.data.value.cookieVal);
            const userDataRes = await getData('Auth/GetCurrectUser')
                .then(result => {
                    dispatch(setUserInfo(result.data.value));
                })
                .then(() => {
                    if (!res.data.value.isDigitalKatalog) {
                        setTimeout(() => {
                            window.open(`https://app.yapiradar.com/ESatinalmaMuteahhit/ESatinAlmaTeklif?id=${token}`, "_self");
                        }, 2000);
                    } else {
                        window.open(`https://app.yapiradar.com/ESatinalmaMuteahhit/ESatinAlmaTeklif?id=${token}`, "_self");
                    }
                });
        } else {
            ShowMessage('error', res.data?.message);
        }
    };

    return (
        <div>
        </div>
    );
}

export default EMuteahhitSatinalYonlendir;
